import React from 'react'

import { useAppSelector } from '@/shared/hooks'
import { selectActiveTarget } from '../../redux/dashboardSelectors'
import ItemizedTable from '../ItemizedTable'
import PrimaryReportTable from '../PrimaryReportTable'
import { selectActiveShortcutKey } from '../../../ControlPanel/redux/controlPanelSelectors'
import { AT_SHORTCUT_KEYS } from '../../../../shared/constants/local'
import MyPeopleTable from '../MyPeopleTable'

const MainTableContainer = () => {
  const activeTarget = useAppSelector(selectActiveTarget)
  const isMyPeopleActive = useAppSelector(selectActiveShortcutKey) === AT_SHORTCUT_KEYS.PEOPLE

  return activeTarget ? <ItemizedTable /> : isMyPeopleActive ? <MyPeopleTable /> : <PrimaryReportTable />
}

export default MainTableContainer
