import React, { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { Table, Skeleton } from '@/shared/components'
import itemizedColumnConfig from './helpers/columnConfigs'
import createBrandDivisionEmployeeTableConfig from './helpers/createTableConfig'
import {
  selectEntityContext, selectItemizationData
} from '@/features/shared/redux/sharedSelectors'
import {
  selectBrandTreeData,
  selectCheckedBrandTreeData
} from '@/features/ControlPanel/redux/controlPanelSelectors'
import {
  selectActiveDashboardTabKey,
  selectActiveTarget
} from '@/features/dashboard/redux/dashboardSelectors'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import { fetchItemizationResponse } from '@/features/shared/redux/sharedActions'

const ItemizedTable = () => {
  const data = useAppSelector(selectItemizationData)
  const dispatch = useAppDispatch()
  const selected = useAppSelector(selectCheckedBrandTreeData)
  const entityContext = useAppSelector(selectEntityContext)
  const brandTreeData = useAppSelector(selectBrandTreeData)
  const accountManagementTabActive =
    useAppSelector(selectActiveDashboardTabKey) === DASHBOARD_TAB_KEYS.AM
  const activeTarget = useAppSelector(selectActiveTarget)

  useEffect(() => {
    dispatch(fetchItemizationResponse())
  }, [dispatch, selected, activeTarget])

  const createTableConfig = useCallback(() => {
    if (activeTarget && data && selected) {
      return createBrandDivisionEmployeeTableConfig(
        entityContext,
        data,
        selected
      )
    } else {
      return []
    }
  }, [data, entityContext, selected, activeTarget])
  const tableConfig = createTableConfig()
  const columnConfig = itemizedColumnConfig(activeTarget?.dataIndex)

  return (
    <Skeleton.Table
      active
      title
      loading={!tableConfig.length || !brandTreeData}
      config={Skeleton.Table.PRESET_1}
    >
      <h3 className='c-type c-type--table-header-text'>
        {`${
          accountManagementTabActive ? 'Account Management' : 'Recruiting'
        } Pulse / ${activeTarget ? activeTarget.title : 'No Metric Selected'}`}
      </h3>
      <Table
        sticky
        className='c-table c-table--itemized'
        tableConfig={tableConfig}
        columnConfig={columnConfig}
        defaultExpandAllRows
      />
    </Skeleton.Table>
  )
}

export default ItemizedTable
