/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject
} from '@apollo/client'

type ProviderOptions = {
  client: ApolloClient<NormalizedCacheObject>
}
/**
 * HOC Wrapper for Components that use Apollo Provider
 * @returns JSX.Element
 */
const withApolloProvider =
  (options: ProviderOptions) => (WrappedComponent: React.FC<any>) => {
    const ComponentWithProvider = () => (
      <ApolloProvider {...options}>
        <WrappedComponent />
      </ApolloProvider>
    )
    return ComponentWithProvider
  }

export default withApolloProvider
