// import { createSelector } from 'reselect';

import { RootState } from '@/bootstrap/redux'

export const selectMtAmTableData = (state: RootState) => state.manageTargets.amTableData
export const selectMtRecruitingTableData = (state: RootState) => state.manageTargets.recruitingTableData
export const selectSavedTargets = (state: RootState) => state.manageTargets.savedTargets
export const selectInputRefreshTs = (state: RootState) => state.manageTargets.meta.inputRefreshTs
export const selectTargetsData = (state: RootState) => state.manageTargets.data
export const selectSaveTargetsState = (state: RootState) => state.manageTargets.meta.saveTargetsState
