import * as React from 'react'
import { Skeleton as AntSkeleton } from 'antd'
import SkeletonAvatar from './SkeletonAvatar'
import SkeletonButton from './SkeletonButton'
import SkeletonImage from './SkeletonImage'
import SkeletonInput from './SkeletonInput'
import SkeletonTable from './SkeletonTable'
import SkeletonTree from './SkeletonTree'

function Skeleton(props) {
  return <AntSkeleton {...props} />
}

Skeleton.Avatar = SkeletonAvatar
Skeleton.Button = SkeletonButton
Skeleton.Image = SkeletonImage
Skeleton.Input = SkeletonInput
Skeleton.Table = SkeletonTable
Skeleton.Tree = SkeletonTree

export default Skeleton
