import { getActionTypes, getMultiActionTypes } from '@/bootstrap/redux/action';

const prefix = 'DASHBOARD';

// CLEAR_TOKEN: AUTH/CLEAR_TOKEN
const unitTypes = getActionTypes(
  [
    'SET_ACTIVE_TARGET',
    'SET_PRT_EXPANDED_KEYS_OBJ',
    'ADD_PRT_EXPANDED_KEYS_OBJ',
    'SET_ACTIVE_DASHBOARD_TAB_KEY',
    'SET_SHOW_LOADER'
  ],
  prefix
)

/**
 * REFRESH_TOKEN: AUTH/REFRESH_TOKEN
 * REFRESH_TOKEN_BEGIN: AUTH/REFRESH_TOKEN_BEGIN
 * REFRESH_TOKEN_SUCCESS: AUTH/REFRESH_TOKEN_SUCCESS
 * REFRESH_TOKEN_FAIL: AUTH/REFRESH_TOKEN_FAIL
 **/
const multiTypes = getMultiActionTypes(
  [],
  prefix,
);

const dashboardTypes = {
  ...unitTypes,
  ...multiTypes,
}

export default dashboardTypes
