// @ts-strict
import Excel, { BorderStyle, Fill } from 'exceljs'
import { saveAs } from 'file-saver'
import { IconixxStringKeys } from '../IconixxTable/helpers/columConfigs'
import { IconixxTableData } from '../IconixxTable/helpers/query'

interface IconixxColumnHeader extends Partial<Excel.Column> {
  header: string
  key: IconixxStringKeys
  width: number
}

type IconixxSaveFileOptions = {
  onComplete?: () => void
  onError?: (error: unknown) => void
}

const borderStyle = { style: 'thin' as BorderStyle, color: { argb: '000' } }

const headerStyle = {
  fill: {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffffe0'}
  } as Fill,
  border: {
    top: borderStyle,
    left: borderStyle,
    bottom: borderStyle,
    right: borderStyle
  },
  font: {
    name: 'Tahoma',
    size: 10,
    bold: true
  }
}

const iconixxColumnHeaders: Array<IconixxColumnHeader> = [
  { header: 'Record ID', key: 'id', width: 17.83 },
  { header: 'Week', key: 'week', width: 7.67 },
  { header: 'Customer/Program ID', key: 'customerProgramId', width: 24 },
  { header: 'Client', key: 'client', width: 43.17 },
  { header: 'Consultant', key: 'consultant', width: 32.67 },
  { header: 'Net Spread', key: 'netSpread', width: 14.83 },
  { header: 'Recruiter ID', key: 'recruiterId', width: 14 },
  { header: 'Recruiter', key: 'recruiter', width: 22.67 },
  { header: 'Salesperson ID', key: 'salespersonId', width: 17 },
  { header: 'Salesperson', key: 'salesperson', width: 17.33 },
  { header: 'Department', key: 'department', width: 16 },
  { header: 'RTC', key: 'rTC', width: 10.67 },
  { header: 'BDM', key: 'bDM', width: 10.33 }
]

const headerCells = ['A1','B1','C1','D1','E1','F1','G1','H1','I1','J1','K1','L1','M1']

const createWorksheet = (workbook: Excel.Workbook) => {
  const worksheet = workbook.addWorksheet('People')
  worksheet.columns = iconixxColumnHeaders
  headerCells
    .forEach(key => {
      worksheet.getCell(key).border = headerStyle.border
      worksheet.getCell(key).fill = headerStyle.fill
      worksheet.getCell(key).font = headerStyle.font
    })
  worksheet.getColumn('netSpread').numFmt = '_($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)'

  return worksheet
}

const saveFile = async (iconixxData: IconixxTableData, options?: IconixxSaveFileOptions) => {
  try {
    const workbook = new Excel.Workbook()
    const worksheet = createWorksheet(workbook)
    if (iconixxData?.allIconixx) {
      await worksheet.addRows(iconixxData.allIconixx)
    }
    const xls64 = await workbook.xlsx.writeBuffer()
    saveAs(
      new Blob([xls64],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
      'Iconixx Report.xlsx'
    )

    if (typeof options?.onComplete === 'function') {
      options.onComplete()
    }
  } catch (error) {
    if (typeof options?.onError === 'function') {
      options.onError(error)
    }
  }
}

export default saveFile
