import React from 'react'
import Icon, { IconPositionType } from './Icon'

type AlertProps = {
  position?: IconPositionType;
}

const Alert = ({ position }: AlertProps) => {
  return (
    <Icon position={position}>
      <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.989.002a8.137 8.137 0 00-5.673 2.435A7.866 7.866 0 00.001 8.139 7.852 7.852 0 007.867 16h.143A8.073 8.073 0 0016 7.862 7.843 7.843 0 007.989.002zM6.668 11.759a1.312 1.312 0 011.287-1.36h.024a1.357 1.357 0 011.353 1.307 1.31 1.31 0 01-1.287 1.36H8.02a1.359 1.359 0 01-1.353-1.307zM7 8.606v-5c0-.46.448-.834 1-.834s1 .374 1 .834v5c0 .46-.448.833-1 .833s-1-.373-1-.833z'
          fill='#A00E20'
          fillRule='nonzero'
        />
      </svg>
    </Icon>
  )
}

export default Alert
