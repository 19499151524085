import React from 'react'

import { PulseLink } from '@/shared/components'
import PulseAnimation from '@/shared/components/PulseAnimation'

const NotFound = () => {
  return (
    <div className='c-screen'>
      <div className='c-screen__error'>
        <h1 className='c-type--title-xxl c-type--bold'>
          Oh no! It looks like we lost the Pulse…
        </h1>
        <h2 className='c-type--title-xl'>
          It should still be alive and well{' '}
          <PulseLink to='/'>on the homepage</PulseLink>.
        </h2>
        <PulseAnimation />
      </div>
    </div>
  )
}

export default NotFound
