import React, { useCallback } from 'react'

import { useAppSelector } from '@/shared/hooks'
import { Table, Skeleton } from '@/shared/components'
import createManageTargetTableConfig from './helpers/createTableConfig'
import { selectGovernmentDivisionIds } from '@/features/shared/redux/sharedSelectors'

const skeletonConfig = [
  { firstCellInputSize: 165, titleOnly: true },
  { firstCellInputSize: 90, titleOnly: true },
  { firstCellInputSize: 180, titleOnly: true },
  { firstCellInputSize: 150, titleOnly: true },
  { firstCellInputSize: 165, titleOnly: true },
  { firstCellInputSize: 174, titleOnly: true },
  { firstCellInputSize: 140, titleOnly: true }
]

const ManageTargetsTable = ({ tableData, columnConfig }) => {
  const governmentDivisionIds = useAppSelector(selectGovernmentDivisionIds)
  const createTableConfig = useCallback(() => {
    if (tableData) {
      return createManageTargetTableConfig(
        tableData,
        governmentDivisionIds
      )
    } else {
      return []
    }
  }, [governmentDivisionIds, tableData])
  const tableConfig = createTableConfig()
  const locale = { emptyText: 'Nothing to see here' }

  return (
    <Skeleton.Table
      active
      loading={!tableData}
      config={skeletonConfig}
    >
      <Table
        className='c-table c-table--manage-targets'
        tableConfig={tableConfig}
        columnConfig={columnConfig}
        locale={locale}
      />
    </Skeleton.Table>
  )
}

export default ManageTargetsTable
