import React from 'react'
import {
  BDE_KEY,
  CLIENT_SUBS_KEY,
  CLIENT_VISITS_KEY,
  IN_HOUSE_INTERVIEWS_KEY,
  JOB_ORDERS_KEY,
  LEADS_KEY,
  PIPELINES_KEY,
  RECRUITING_DBAS_KEY,
  RECRUITING_DEALS_KEY,
  RECRUITING_DIALS_KEY,
  RECRUITING_SENDOUTS_KEY,
  SALES_DBAS_KEY,
  SALES_DEALS_KEY,
  SALES_DIALS_KEY,
  SALES_SENDOUTS_KEY,
  SCHEDULED_CALLS_KEY,
  SUBS_KEY
} from '@/shared/constants/dataKeys'
import { BDE_WIDTH, COLUMN_MOD_KEYS } from '@/shared/constants/local'
import ManageTargetInput from '../../ManageTargetInput'

const generateInputConfig = (title, key, governmentOnly) => ({
  title,
  dataIndex: key,
  key,
  align: 'left',
  columnMod: [COLUMN_MOD_KEYS.TARGET_INPUT],
  // eslint-disable-next-line react/display-name
  renderComponent: (record) => <ManageTargetInput record={record} metric={key} isGovernmentMetric={governmentOnly} />
})

const accountManagementManageTargetsColumnConfig =
  [
    {
      title: 'Sector/Division/Employee',
      dataIndex: BDE_KEY,
      key: BDE_KEY,
      ellipsis: true,
      width: BDE_WIDTH,
      columnMod: [COLUMN_MOD_KEYS.TITLE]
    },
    generateInputConfig('Dials', SALES_DIALS_KEY),
    generateInputConfig('DBAs', SALES_DBAS_KEY),
    generateInputConfig('Client Visits', CLIENT_VISITS_KEY, true),
    generateInputConfig('Pipelines', PIPELINES_KEY),
    generateInputConfig('Jobs', JOB_ORDERS_KEY),
    generateInputConfig('Send Outs', SALES_SENDOUTS_KEY),
    generateInputConfig('Deals', SALES_DEALS_KEY)
  ].filter((v) => v)

const recruitingManageTargetsColumnConfig =
  [
    {
      title: 'Sector/Division/Employee',
      dataIndex: BDE_KEY,
      key: BDE_KEY,
      ellipsis: true,
      width: BDE_WIDTH,
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      columnMod: [COLUMN_MOD_KEYS.TITLE]
    },
    generateInputConfig('Dials', RECRUITING_DIALS_KEY),
    generateInputConfig('Sched. Calls', SCHEDULED_CALLS_KEY, true),
    generateInputConfig('DBAs', RECRUITING_DBAS_KEY),
    generateInputConfig('Subs', SUBS_KEY),
    generateInputConfig('Client Subs', CLIENT_SUBS_KEY),
    generateInputConfig('In-Houses', IN_HOUSE_INTERVIEWS_KEY, true),
    generateInputConfig('Send Outs', RECRUITING_SENDOUTS_KEY),
    generateInputConfig('Deals', RECRUITING_DEALS_KEY),
    generateInputConfig('Leads', LEADS_KEY)
  ]

export {
  accountManagementManageTargetsColumnConfig,
  recruitingManageTargetsColumnConfig
}
