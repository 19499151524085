import React from 'react'
import classnames from 'classnames'

interface ButtonProps extends React.ComponentProps<'button'> {
  loading?: boolean;
  styleAsLink?: boolean;
}

const Button = ({ loading, className = '', styleAsLink = false, children, ...props }: ButtonProps) => {
  const btnClassName = classnames('c-btn', className, {
    'c-btn--loading': loading,
    'c-btn--link': styleAsLink
  })

  return (
    <button className={btnClassName} {...props}>
      {children}
    </button>
  )
}

export default Button
