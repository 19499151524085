// @ts-strict
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { DocumentNode, TypedDocumentNode, useLazyQuery } from '@apollo/client'

import { Table, Skeleton } from '@/shared/components'

import { notifyError } from '@/features/shared/redux/sharedActions'
import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { setRefetchReports, setReportHeader } from '@/features/Spread/redux/spreadSlice'
import { selectEtlIsProcessing, selectRefetchReports } from '@/features/Spread/redux/spreadSelectors'
import { IAppColumnConfig } from '@/shared/components/Table'

type Props<R = unknown> = {
  isActive: boolean
  queryString: DocumentNode | TypedDocumentNode
  setHeaderOnLoad?: (firstRecord: R) => string | React.ReactNode
  errorMessage: string
  dataPath: Array<string>
  createTableConfig?: (data: Array<unknown>) => Array<unknown>
  columnConfig: Array<IAppColumnConfig>
  onDataLoad?: (data: unknown) => void
  pageCount?: number
  tableConfig?: Array<unknown>
}

type DataSourceState = {
  page: number
  totalPages: number
  data: Array<unknown>
}

const getDataSource = (data, dataPath) => {
  let dataSource = []
  if (data && dataPath) {
    for (let i = 0; i < dataPath.length; i++) {
      if (i === 0) {
        if (data[dataPath[0]]) dataSource = data[dataPath[0]]
        continue
      }
      if (dataSource[dataPath[i]]) {
        dataSource = dataSource[dataPath[i]]
      } else {
        break
      }
    }
  }
  return dataSource
}

const TableHandler = <R,>(
  {
    isActive,
    createTableConfig,
    columnConfig,
    setHeaderOnLoad,
    queryString,
    errorMessage,
    dataPath,
    onDataLoad,
    pageCount,
    tableConfig
  }: Props<R>) => {
  const dispatch = useAppDispatch()
  const [_pageCount, ] = useState(typeof pageCount === 'number' ? pageCount : 200)
  const [dataSourceState, setDataSourceState] = useState<DataSourceState>({ page: 0, totalPages: 0, data: [] })
  const [ , { data, loading, startPolling, stopPolling, previousData, error, refetch }] = useLazyQuery(queryString, { onCompleted: data => {
    if (typeof onDataLoad === 'function') {
      onDataLoad(data)
    }
  } })
  const isProcessing = useAppSelector(selectEtlIsProcessing)
  const refetchReports = useAppSelector(selectRefetchReports)
  const extractedData = useMemo(() => {
    return getDataSource(data, dataPath)
  }, [data, dataPath])

  if (typeof createTableConfig !== 'function' && typeof tableConfig === 'undefined') {
    console.warn('TableHandler requires a createTableconfig function OR the tableConfig array.')
  }

  useEffect(() => {
    if (isActive) {
      if (extractedData.length) {
        if (typeof setHeaderOnLoad === 'function') {
          const msg = setHeaderOnLoad(extractedData[0])
          dispatch(setReportHeader(msg))
        }

        if (typeof createTableConfig === 'function') {
          setDataSourceState(curState => ({
            ...curState,
            totalPages: Math.ceil(extractedData.length/_pageCount),
            data: createTableConfig(extractedData.slice(0, _pageCount))
          }))
        }
      }
    }
  }, [isActive, dispatch, createTableConfig, setHeaderOnLoad, extractedData, data, _pageCount])

  useEffect(() => {
    if (!isProcessing && !loading) {
      if (previousData === undefined && !extractedData.length) {
        startPolling(1500)
      }
      if (extractedData.length) {
        stopPolling()
        // dispatch(setReportHeader('Spread Report for: TBD'))
      }
      if (error) {
        stopPolling()
        if (typeof errorMessage === 'string') {
          dispatch(notifyError(errorMessage))
        }
        console.error(error)
      }
    }
    return () => {
      stopPolling()
    }
  }, [loading, startPolling, stopPolling, previousData, error, dispatch, errorMessage, extractedData.length, isProcessing])

  useEffect(() => {
    if (refetchReports && !isProcessing) {
      refetch()
      dispatch(setRefetchReports(false))
    }
  }, [refetchReports, isProcessing, refetch, dispatch])

  const onScroll = useCallback((event: Event) => {
    if (dataSourceState.page < dataSourceState.totalPages) {
      const node = event.currentTarget as HTMLElement
      if (node) {
        const scrollTopMax = node.scrollHeight - node.clientHeight
        if (node.scrollTop/scrollTopMax > 0.95) {
          setDataSourceState(prevState => ( {
            ...prevState,
            page: prevState.page + 1,
            data: [...prevState.data, ...createTableConfig(extractedData.slice((prevState.page + 1) * _pageCount, (prevState.page + 2) * _pageCount))]
          }))
        }
      }
    }
  }, [dataSourceState.page, dataSourceState.totalPages, createTableConfig, extractedData, _pageCount])

  const skeletonLoading = loading ? true : !!error || (tableConfig ? !tableConfig.length : !dataSourceState.data.length)

  return (
    <Skeleton.Table
      active
      hideMainHeader
      scrollable
      loading={skeletonLoading}
      config={Skeleton.Table.PRESET_3}
    >
      <Table
        tableConfig={tableConfig ? tableConfig : dataSourceState.data}
        columnConfig={columnConfig}
        className='c-table c-table--spread c-table--striped'
        scroll={!tableConfig ? { x: '100%', y: 500 } : { x: '100%' }}
        onScroll={!tableConfig ? onScroll : undefined}
      />
    </Skeleton.Table>
  )
}

export default TableHandler
