export const powerRankingWeeklyMock = {
  rows: [
    {
      name: 'Bill Cooper',
      tenure: '1-2 yr',
      division: 'SAP',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: 34,
      hoursPaid: 124,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 1
    },
    {
      name: 'Chris Adams',
      tenure: '0-1 yr',
      division: 'Med-CA',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: true,
      color: '#FF8031',
      employeeId: 2
    },
    {
      name: 'Jack Wilson',
      tenure: '1-2 yr',
      division: 'Gov-IntelEng',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#5669EE',
      employeeId: 3
    },
    {
      name: 'Jill Russel',
      tenure: '2+ yr',
      division: 'Med-RA',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 4
    },
    {
      name: 'Emily Cho',
      tenure: '0-1 yr',
      division: 'Explorers',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 5
    },
    {
      name: 'Susan Nelson',
      tenure: '0-1 yr',
      division: 'Med-TE',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: true,
      color: '#5669EE',
      employeeId: 6
    },
    {
      name: 'Mary Álvarez',
      tenure: '0-1 yr',
      division: 'Cerner',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#FF628E',
      employeeId: 7
    },
    {
      name: 'Eric Cunningham',
      tenure: '1-2 yr',
      division: 'BDM',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 8
    },
    {
      name: 'Patricia Lupo',
      tenure: '2+ yr',
      division: 'Kronos',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 9
    },
    {
      name: 'Carol Deleo',
      tenure: '0-1 yr',
      division: 'Data-Science',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 10
    },
    {
      name: 'Chris Washburn',
      tenure: '1-2 yr',
      division: 'Cerner',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 11
    },
    {
      name: 'Sanjay Patel',
      tenure: '2+ yr',
      division: 'Pharma-AR&D',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 12
    },
    {
      name: 'Kerry Eames',
      tenure: '2+ yr',
      division: 'Gov-IT',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: 34,
      hoursPaid: 45,
      isHighSpread: false,
      color: '#5669EE',
      employeeId: 13
    },
    {
      name: 'Scott Thompson',
      tenure: '0-1 yr',
      division: 'Pharma-RS',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: 32,
      hoursPaid: 90,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 14
    },
    {
      name: 'Kim McKenzie',
      tenure: '2+ yr',
      division: 'Cybersecurity',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: 134,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 15
    },
    {
      name: 'Aaron Stevens',
      tenure: '0-1 yr',
      division: 'Med ST',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: 134,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 16
    },
    {
      name: 'Miguel Fernandez',
      tenure: '1-2 yr',
      division: 'Workday-HCM',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30),
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 17
    },
    {
      name: 'Aaron Houston',
      tenure: '0-1 yr',
      division: 'Infor',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: 36,
      hoursPaid: 150,
      isHighSpread: false,
      color: '#FF628E',
      employeeId: 18
    },
    {
      name: 'Mary Zahn',
      tenure: '1-2 yr',
      division: 'Med QA',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: 36,
      hoursPaid: 211,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 19
    },
    {
      name: 'Raul Gutierrez',
      tenure: '0-1 yr',
      division: 'Gov-IntelEng',
      netSpread: Math.random() * 10000 + 180000,
      weeklyAvg: Math.random() * 10000 + 180000,
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: 30,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48),
      isHighSpread: false,
      color: '#5669EE',
      employeeId: 20
    }
  ],
  totals: {}
}

export const powerRankingAnnualMock = {
  rows: [
    {
      name: 'Bill Cooper',
      tenure: '1-2 yr',
      division: 'SAP',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: 34,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 1
    },
    {
      name: 'Chris Adams',
      tenure: '0-1 yr',
      division: 'Med-CA',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: true,
      color: '#FF8031',
      employeeId: 2
    },
    {
      name: 'Jack Wilson',
      tenure: '1-2 yr',
      division: 'Gov-IntelEng',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#5669EE',
      employeeId: 3
    },
    {
      name: 'Jill Russel',
      tenure: '2+ yr',
      division: 'Med-RA',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 4
    },
    {
      name: 'Emily Cho',
      tenure: '0-1 yr',
      division: 'Explorers',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 5
    },
    {
      name: 'Susan Nelson',
      tenure: '0-1 yr',
      division: 'Med-TE',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: true,
      color: '#5669EE',
      employeeId: 6
    },
    {
      name: 'Mary Álvarez',
      tenure: '0-1 yr',
      division: 'Cerner',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#FF628E',
      employeeId: 7
    },
    {
      name: 'Eric Cunningham',
      tenure: '1-2 yr',
      division: 'BDM',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 8
    },
    {
      name: 'Patricia Lupo',
      tenure: '2+ yr',
      division: 'Kronos',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 9
    },
    {
      name: 'Carol Deleo',
      tenure: '0-1 yr',
      division: 'Data-Science',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 10
    },
    {
      name: 'Chris Washburn',
      tenure: '1-2 yr',
      division: 'Cerner',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 11
    },
    {
      name: 'Sanjay Patel',
      tenure: '2+ yr',
      division: 'Pharma-AR&D',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 12
    },
    {
      name: 'Kerry Eames',
      tenure: '2+ yr',
      division: 'Gov-IT',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#5669EE',
      employeeId: 13
    },
    {
      name: 'Scott Thompson',
      tenure: '0-1 yr',
      division: 'Pharma-RS',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 14
    },
    {
      name: 'Kim McKenzie',
      tenure: '2+ yr',
      division: 'Cybersecurity',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 15
    },
    {
      name: 'Aaron Stevens',
      tenure: '0-1 yr',
      division: 'Med ST',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 16
    },
    {
      name: 'Miguel Fernandez',
      tenure: '1-2 yr',
      division: 'Workday-HCM',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#36B2CF',
      employeeId: 17
    },
    {
      name: 'Aaron Houston',
      tenure: '0-1 yr',
      division: 'Infor',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      color: '#FF628E',
      employeeId: 18
    },
    {
      name: 'Mary Zahn',
      tenure: '1-2 yr',
      division: 'Med QA',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#FF8031',
      employeeId: 19
    },
    {
      name: 'Raul Gutierrez',
      tenure: '0-1 yr',
      division: 'Gov-IntelEng',
      netSpread: Math.round(Math.random() * 10000 + 180000 * 52),
      weeklyAvg: Math.round(Math.random() * 10000 + 180000 * 52),
      spreadHigh: Math.round(Math.random() * 10000 + 280000),
      reachedOn: '2020-10-01',
      gpm: Math.floor(Math.random() * (20 - 30) + 30) * 2,
      hoursPaid: Math.floor(Math.random() * (90 - 48) + 48) * 52,
      isHighSpread: false,
      color: '#5669EE',
      employeeId: 20
    }
  ],
  totals: {}
}
