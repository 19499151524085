import { get, post } from '@/bootstrap/axios'
import { CUSTOM_TF } from '@/shared/constants/dataKeys'
import { DEFAULT_REPORT_OBJ } from '@/shared/constants/local'
import { IItemizedMetricReportResponse, IOneTimeResponse, IPulseByDivisionReportResponse, IReportsRequest,  } from '@/shared/types/swagger'
import { AxiosResponse } from 'axios'

/**
 * Fetches the report response for indicated time range and audience.
 * @param {String} presetRange Value for preset ranges(handled in the BE)
 * @param {String[]} timeRange custom time range
 */
export const getReport = async (timeFrame: string, range: Array<string>, requestObj: IReportsRequest) => {
  const customDateRange = timeFrame === CUSTOM_TF && range && `custom?date-from=${range[0]}&date-until=${range[1]}`
  const url = `/api/reports/pulse/times/${customDateRange || timeFrame || 'Week'}`
  const response = await post<IReportsRequest, AxiosResponse<IPulseByDivisionReportResponse>>(url, requestObj || DEFAULT_REPORT_OBJ)
  return response
}

/**
 * Fetches one time response which contains entity data including current_user.
 */
export const getOneTimeResponse = async () => {
  const url = '/api/one-time'
  const response = await get<AxiosResponse<IOneTimeResponse>>(url)
  return response
}

export const sendGrafanaMetrics = async (data) => {
  const url = 'https://graphite-us-central1.grafana.net/metrics'
  const headers = {
    Authorization:
      'Bearer 58886:',
    'Content-Type': 'application/json'
  }
  return await post(url, data, { headers })
}

export const getItemizedReport = async (timeFrame: string, range: Array<string>, requestObj: IReportsRequest, metric: string) => {
  const customDateRange = timeFrame === CUSTOM_TF && range && `custom?date-from=${range[0]}&date-until=${range[1]}`
  const url = `/api/items/${metric}/times/${customDateRange || timeFrame || 'Week'}`
  const response = await post<IReportsRequest, AxiosResponse<IItemizedMetricReportResponse>>(url, requestObj || DEFAULT_REPORT_OBJ)
  return response
}
