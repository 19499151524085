import { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useAppSelector } from '@/shared/hooks'
import { selectReportResponseLoading, selectBrandContext } from '@/features/shared/redux/sharedSelectors'

export default function useGetLastUpdatedTimeStamp(): Dayjs | null {
  const [timeStamp, setTimeStamp] = useState<Dayjs>(null)
  const reportLoading = useAppSelector(selectReportResponseLoading)
  const data = useAppSelector(selectBrandContext)
  useEffect(() => {
    if (data && !reportLoading) setTimeStamp(dayjs())
  }, [data, reportLoading])
  return timeStamp
}
