export const spreadSummaryMock = {
  rows: [
    {
      name: 'Cerner',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Cerner Federal',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Cybersecurity',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Data Science',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Epic',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Gov DCI',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Gov ES',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Gov IT',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Infor',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Kronos',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Med CA',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Med QA',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Med RA',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Med Science',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Med ST',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Med TE',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Oracle Cloud',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Pharma AR&D',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Pharma Quality',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Pharma RS',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Pharma TE&V',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Privacy and Compliance',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Salesforce',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'SAP',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'UKG Pro',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Workday Financials',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Workday HCM',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    }
  ],
  totals: {}
}

export const spreadSummaryTCMock = {
  rows: [
    {
      name: 'Amherst TC',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Durham TC',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'Harrisonburg TC',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'VTC 1',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'VTC 2',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    },
    {
      name: 'VTC 3',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    }
  ],
  totals: {}
}

export const spreadSummarySeniorsMock = {
  rows: [
    {
      name: 'Seniors',
      weeklySpread: Math.random() * 100000 + 200000,
      ytdAverage: Math.random() * 50000 + 250000,
      ytdRevenue: Math.random() * 10000000 + 5000000
    }
  ],
  totals: {}
}
