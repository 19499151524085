const enrichTargetGroupConfig = (
  config,
  data,
  noneSelected,
  activeTarget,
  setTarget
) => {
  // height in rem
  const maxCardHeight = 23
  const minHeight = 5.6
  // const decrement = 2

  if (config) {
    let currentHeight = maxCardHeight
    const validConfigSteps = data ?
      config.filter((c) => data[c.dataIndex] && !c.detached).length - 1 : 1
    const decrement = (maxCardHeight - minHeight) / (validConfigSteps > 0 ? validConfigSteps : 1)
    return config.map((c) => {
      const onClick = !c.disabled ? () => setTarget(c) : undefined
      const onDeselect = () => setTarget(null)
      const selected = activeTarget && activeTarget.dataIndex === c.dataIndex
      if (c && data && !!data[c.dataIndex]) {
        const height = currentHeight
        currentHeight -= decrement
        return {
          ...c,
          data: !noneSelected && data[c.dataIndex],
          height: c.height || `${height}rem`,
          selected,
          onClick,
          onDeselect
        }
      } else {
        return {
          ...c,
          selected,
          onClick,
          onDeselect
        }
      }
    })
  }
  return []
}

export default enrichTargetGroupConfig