import { createReducer } from '@reduxjs/toolkit'
import { IItemizedMetricReportResponse, IOneTimeResponse, IPulseByDivisionReportResponse } from '@/shared/types/swagger'
import { GrafanaInfoPayload } from '@/shared/components/GrafanaProfiler'
import { CalendarMarker, IPushNotice } from '@/shared/types'
import { addToGrafanaQueue,
  clearGrafanaQueue,
  clearNotices,
  dismissNotice,
  dismissNoticeByDuplicateId,
  fetchEntityContextFail,
  fetchEntityContextSuccess,
  fetchReportBegin,
  fetchReportFail,
  fetchReportSuccess,
  pushNotice,
  setAllReportResponse,
  setFiscalCalendarMarkers,
  setInitialLoad,
  setQsParsing,
  setServiceErrorMessage,
  setShowServiceErrorScreen,
  setSsoError,
  fetchItemizationReportSuccess,
  fetchItemizationReportBegin
} from './sharedActionsPlain'
import { saveMyDivisionsSuccess, saveMyPeopleSuccess } from '@/features/ControlPanel/redux/controlPanelActionsPlain'

type SharedState = {
  reportResponse?: IPulseByDivisionReportResponse
  allReportResponse?: IPulseByDivisionReportResponse
  oneTimeResponse?: IOneTimeResponse
  itemizationResponse?: IItemizedMetricReportResponse
  fiscalCalendarMarkers: Partial<Record<string, CalendarMarker>>
  initialLoad: boolean
  showServiceErrorScreen: boolean
  grafanaQueue: Array<GrafanaInfoPayload>
  notices: Array<Partial<IPushNotice>>
  serviceErrorMessage: string
  meta: {
    reportResponseLoading: boolean
    ssoFailure: boolean
    reportResponseError: boolean
    oneTimeResponseError: boolean
    parsing: boolean
  }
}

const initialState: SharedState = {
  reportResponse: null,
  allReportResponse: null,
  oneTimeResponse: null,
  itemizationResponse: null,
  fiscalCalendarMarkers: {},
  initialLoad: false,
  showServiceErrorScreen: false,
  grafanaQueue: [],
  notices: [],
  serviceErrorMessage: '',
  meta: {
    reportResponseLoading: true,
    ssoFailure: false,
    reportResponseError: false,
    oneTimeResponseError: false,
    parsing: true
  }
}

export default createReducer(initialState, builder => {
  builder.addCase(fetchReportBegin, (state) => {
    state.meta.reportResponseLoading = true
  })
  builder.addCase(fetchReportSuccess, (state, action) => {
    state.reportResponse = action.payload
    state.meta.reportResponseLoading = false
    state.meta.reportResponseError = false
  })
  builder.addCase(fetchReportFail, (state) => {
    state.meta.reportResponseLoading = false
    state.meta.reportResponseError = true
  })
  builder.addCase(fetchEntityContextSuccess, (state, action) => {
    state.oneTimeResponse = action.payload
    state.meta.oneTimeResponseError = false
  })
  builder.addCase(fetchEntityContextFail, (state, action) => {
    state.meta.oneTimeResponseError = true
    state.serviceErrorMessage = action.payload
  })
  builder.addCase(setFiscalCalendarMarkers, (state, action) => {
    state.fiscalCalendarMarkers = action.payload
  })
  builder.addCase(setInitialLoad, (state, action) => {
    state.initialLoad = action.payload
  })
  builder.addCase(addToGrafanaQueue, (state, action) => {
    if (action.payload) {
      if (Array.isArray(action.payload)) {
        state.grafanaQueue = [...state.grafanaQueue, ...action.payload]
      } else {
        state.grafanaQueue.push(action.payload)
      }
    }
  })
  builder.addCase(clearGrafanaQueue, (state) => {
    state.grafanaQueue = []
  })
  builder.addCase(saveMyDivisionsSuccess, (state, action) => {
    state.oneTimeResponse['current-user-context'] = action.payload
  })
  builder.addCase(saveMyPeopleSuccess, (state, action) => {
    state.oneTimeResponse['current-user-context'] = action.payload
  })
  builder.addCase(setSsoError, (state, action) => {
    state.meta.ssoFailure = action.payload
  })
  builder.addCase(pushNotice, (state, action) => {
    const { notice, clearDuplicates, clearOtherNotices } = action.payload
    if (
      clearDuplicates &&
      notice.duplicateId &&
      state.notices.find((v) => v.duplicateId === notice.duplicateId)
    ) {
      const animatedNotice = { animate: true, ...notice }
      if (clearOtherNotices) {
        state.notices = [animatedNotice]
      } else {
        state.notices = state.notices.map((v) => {
          if (v.duplicateId === notice.duplicateId) {
            return animatedNotice
          }
          return v
        })
      }
    } else if (clearOtherNotices) {
      state.notices = [notice]
    } else {
      state.notices.push(notice)
    }
  })
  builder.addCase(dismissNotice, (state, action) => {
    state.notices = state.notices.filter((n) => n.id !== action.payload)
  })
  builder.addCase(clearNotices, state => {
    state.notices = []
  })
  builder.addCase(dismissNoticeByDuplicateId, (state, action) => {
    state.notices = state.notices.filter((n) => n.duplicateId !== action.payload)
  })
  builder.addCase(setShowServiceErrorScreen, (state, action) => {
    state.showServiceErrorScreen = action.payload
  })
  builder.addCase(setAllReportResponse, (state, action) => {
    state.allReportResponse = action.payload
  })
  builder.addCase(setQsParsing, (state, action) => {
    state.meta.parsing = action.payload
  })
  builder.addCase(setServiceErrorMessage, (state, action) => {
    state.serviceErrorMessage = action.payload
  })
  builder.addCase(fetchItemizationReportBegin, state => {
    state.itemizationResponse = null
  })
  builder.addCase(fetchItemizationReportSuccess, (state, action) => {
    state.itemizationResponse = action.payload
  })
})
