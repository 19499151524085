import React, { useCallback ,useRef } from 'react'
import { debounce } from 'lodash'
import { Tree } from 'antd'
import { DataNode } from 'antd/lib/tree'

import { useAppSelector, useAppDispatch } from '@/shared/hooks'
import { GrafanaProfiler, Skeleton } from '@/shared/components'
import {
  selectAtAutoExpandParent,
  selectAtCheckedKeys,
  selectAtExpandedKeys,
  selectAtSearchResultKeys,
  selectAtSelectedKeys,
  selectAudienceTreeData,
  selectAudienceTreeSearch,
  selectEmployeeDictionary,
  selectTimePeriodId,
  selectVisibleAtData
} from '../../redux/controlPanelSelectors'
import {
  setAtAutoExpandParent,
  setAtCheckedKeys,
  setAtExpandedKeys,
  setAtSelectedKeys,
  checkAllRelatedOtherContributors,
  setAtDebouncedCheckedKeys } from '../../redux/controlPanelActions'
import {
  AudienceSave, AudienceSearch, AudienceTreeNode, AudienceTreeShortcuts
} from './components'
import { fetchReport } from '@/features/shared/redux/sharedActions'
import { CUSTOM_TF } from '@/shared/constants/dataKeys'

interface AudienceTreeDataNode extends DataNode {
  id?: string;
}

const AudienceTree = () => {
  const dispatch = useAppDispatch()
  const expandedKeys = useAppSelector(selectAtExpandedKeys)
  const setExpandedKeys = useCallback(val => dispatch(setAtExpandedKeys(val)), [dispatch])
  const checkedKeys = useAppSelector(selectAtCheckedKeys)
  const setCheckedKeys = useCallback(val => dispatch(setAtCheckedKeys(val)), [dispatch])
  const selectedKeys = useAppSelector(selectAtSelectedKeys)
  const setSelectedKeys = useCallback(val => dispatch(setAtSelectedKeys(val)), [dispatch])
  const treeData = useAppSelector(selectAudienceTreeData)
  const autoExpandParent = useAppSelector(selectAtAutoExpandParent)
  const visibleTreeData = useAppSelector(selectVisibleAtData)
  const searchResultKeys = useAppSelector(selectAtSearchResultKeys)
  const search = useAppSelector(selectAudienceTreeSearch)
  const employeeDictionary = useAppSelector(selectEmployeeDictionary)
  const timePeriodId = useAppSelector(selectTimePeriodId)
  const setAutoExpandParent = useCallback(val => dispatch(setAtAutoExpandParent(val)), [dispatch])
  const onOtherContributorsShortcutKeyClick = useCallback((node: AudienceTreeDataNode) => {
    dispatch(checkAllRelatedOtherContributors(node.id))
    dispatch(fetchReport({ skipBuild: true }))
  }, [dispatch])
  const debouncedFetch = useRef(
    debounce((checkedKeysValue: Array<string>) => {
      dispatch(fetchReport({ skipBuild: true }))
      dispatch(setAtDebouncedCheckedKeys(checkedKeysValue))
    }, 1000)
  ).current
  const onCheck = useCallback((checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue)
    debouncedFetch(checkedKeysValue)
  }, [debouncedFetch, setCheckedKeys])

  const onSelect = (selectedKeysValue) => {
    setSelectedKeys(selectedKeysValue)
  }

  const onExpand = (expandedKeysValue) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue)
    setAutoExpandParent(false)
  }

  const searchActiveWithNoResults = search && search.length && !searchResultKeys.length

  return (
    <Skeleton.Tree
      loading={!treeData.length}
      active={true}
      config={Skeleton.Tree.PRESET_1}
    >
      <div className={`c-audience-tree__wrapper${searchActiveWithNoResults ? ' no-results' : ''}`}>
        <GrafanaProfiler id='audience-tree' tags={[`time-period:${timePeriodId || CUSTOM_TF}`]}>
          <Tree
            checkable
            blockNode
            selectable={false}
            className={'c-audience-tree'}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            treeData={visibleTreeData}
            titleRender={(nodeData: AudienceTreeDataNode) => (
              <AudienceTreeNode
                {...nodeData}
                nodeInfo={employeeDictionary[nodeData.id]}
                onShortcutClick={() => onOtherContributorsShortcutKeyClick(nodeData)}
              />)}
          />
        </GrafanaProfiler>
        {searchActiveWithNoResults && (
          <div className='c-audience-tree__no-results'>
            No matching results...
          </div>
        )}
      </div>
      <AudienceSearch />
      <AudienceSave />
    </Skeleton.Tree>
  )
}

AudienceTree.Shortcuts = AudienceTreeShortcuts

export default AudienceTree
