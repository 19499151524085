import * as React from 'react'

export type IconPositionType = 'left' | 'right' | 'right-5' | 'left-5'
export interface IconProps extends React.ComponentProps<'span'> {
  position?: IconPositionType
  margin?: number | string
}

const Icon = ({
  position,
  margin,
  children,
  onClick,
  className = ''
}: IconProps) => {
  return (
    <span
      className={`c-icon ${position ? `c-icon--${position}` : ''} ${className}`}
      style={
        margin
          ? {
              margin: `0 ${position === 'left' ? margin : '0'} 0 ${
                position === 'right' ? margin : '0'
              }`
            }
          : undefined
      }
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
    >
      {children}
    </span>
  )
}

export default Icon
