import React, { useEffect } from 'react'

import { Reports, SpreadHeader, Upload } from '@/features/Spread/components'
import { Route, Switch } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { manageEtlIsProcessing } from '@/features/Spread/redux/spreadActions'
import { selectEtlIsProcessing } from '@/features/Spread/redux/spreadSelectors'
import {
  SpreadReportHomeRoute,
  SpreadUploadFoundersRoute,
  SpreadUploadSpreadRoute
} from '@/features/navigation/components/Routes/routes'

const SpreadReport = () => {
  const dispatch = useAppDispatch()
  const etlIsProcessing = useAppSelector(selectEtlIsProcessing)
  useEffect(() => {
    if (etlIsProcessing) {
      const interval = dispatch(manageEtlIsProcessing())
      return () => {
        clearInterval(interval)
      }
    }
  }, [dispatch, etlIsProcessing])
  return (
    <div className='c-screen c-screen--spread'>
      <SpreadHeader />
      <Switch>
        <Route exact path={SpreadReportHomeRoute} component={Reports} />
        <Route
          exact
          path={SpreadUploadSpreadRoute}
          component={() => <Upload prefix='Import_' title='spread' />}
        />
        <Route
          exact
          path={SpreadUploadFoundersRoute}
          component={() => <Upload prefix='Founders_' title='founders' />}
        />
      </Switch>
    </div>
  )
}

export default SpreadReport
