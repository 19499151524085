import React from 'react'

import { ALL_ALKU_KEY, BRAND_KEY, DIVISION_KEY, EMPLOYEE_KEY, OTHER_CONTRIBUTORS_KEY, SIDE_OF_HOUSE_ENUM } from '@/shared/constants/dataKeys'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import { shouldHideDivision, shouldHideOcs } from '@/bootstrap/configs/sidebar'
import { IDivisionData, IDivisionDataDetails, IOneTimeResponse, IPersonData, IPulseByDivisionReportResponse, ITimeFrame, OtrPeople, TimeFrames } from '@/shared/types/swagger'
import { AlkuTree, AlkuTreeNodeType, EmployeeDictionary, TimeSliderMark, TreeNode } from '@/shared/types'
import { ExpandedKeysObj } from '@/shared/components/Table'

export const NODE_KEY_DELIMITER = '__'

const getDivisionReportData = (
  reportData: IPulseByDivisionReportResponse,
  brandName: string,
  divisionId: string
): Partial<IDivisionDataDetails> => {
  if (brandName && divisionId) {
    return reportData?.['company-data']?.all?.['brand-data']?.[brandName]?.['division-data']?.[divisionId] || {}
  }
  return {}
}

interface DivObj extends IDivisionData {
  divisionId: string
  reportData: Partial<IDivisionDataDetails>
}

/**
 * Fetches the report response for indicated time range.
 * @param {object} entityContext one time response used to generate the tree data
 * @param {object} reportData optional reportData which will generate other contributor nodes if applicable
 * @param {object} employeeDictionary optional object which will be mutated with employee node data
 * @param {String} reportView filters employees by additional rules per tab
 */
export const buildAlkuTreeData = (
  entityContext: IOneTimeResponse,
  reportData: IPulseByDivisionReportResponse,
  employeeDictionary: Partial<EmployeeDictionary>,
  reportView: string
): AlkuTree | [] => {
  try {
    const divisionsByBrand = Object.entries(entityContext?.divisions || []).reduce((brandArray, [divisionId, divisionData]) => {
      const brandName = divisionData['brand-name']
      const indexOfBrand = brandName && brandArray.findIndex(v => v.brandName === brandName)
      const divObj: DivObj = { divisionId, ...divisionData, reportData: getDivisionReportData(reportData, brandName, divisionId) }
      if (indexOfBrand > -1) {
        brandArray[indexOfBrand].divisions.push(divObj)
      } else if (brandName) {
        brandArray.push({
          brandName,
          divisions: [divObj]
        })
      }
      return brandArray
    }, [])
    const peopleContext = entityContext?.people || {}
    let alkuTreeData = []
    if (divisionsByBrand.length) {
      alkuTreeData = divisionsByBrand
        .map(({ brandName, divisions }) => ({
          title: brandName,
          id: brandName,
          key: brandName,
          type: BRAND_KEY,
          children: buildBrandChildNodes(
            brandName,
            divisions,
            peopleContext,
            employeeDictionary,
            reportView
          )
        }))
        .filter((brand) => brand.children.length)
        .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
    }
    const treeData = [
      {
        title: reportView === DASHBOARD_TAB_KEYS.RECRUITING ? 'Recruiting' : 'Account Management',
        key: ALL_ALKU_KEY,
        children: alkuTreeData
      }
    ]
    return treeData
  } catch (e) {
    console.error(e)
    return []
  }
}

const buildBrandChildNodes = (
  brandName: string,
  divisions: Array<DivObj>,
  peopleContext: OtrPeople,
  employeeDictionary: Partial<EmployeeDictionary>,
  reportView: string
) => {
  return divisions
    .filter(({ divisionId }) => {
      return !shouldHideDivision(divisionId)
    })
    .map(({ divisionId, members, name, reportData }) => {
      const { people, otherContributors } = buildDivisionChildNodes(
        brandName,
        divisionId,
        members,
        reportData,
        peopleContext,
        employeeDictionary,
        reportView
      )
      return ({
        title: name || divisionId,
        key: divisionId,
        id: divisionId,
        type: DIVISION_KEY,
        children: people,
        otherContributorIds: otherContributors
      })
    })
    .filter(division => division.children.length)
    .sort((a, b) => (a.title || '').localeCompare((b.title || '')))
}

const getEmployeeName = (employeeInfo: Partial<IPersonData>) => {
  let employeeName = ''
  if (employeeInfo) {
    if (employeeInfo['first-name'])
      employeeName += employeeInfo['first-name'] + ' '
    if (employeeInfo['last-name']) employeeName += employeeInfo['last-name']
  }
  return employeeName
}

const mapPeopleNodes = (
  personId: string,
  brandName: string,
  divisionId: string,
  peopleContext: OtrPeople,
  isOtherContributor = false,
  employeeDictionary: EmployeeDictionary,
  isGovernmentDivision: boolean
) => {
  const personContext = peopleContext?.[personId]
  const employeeName = getEmployeeName(personContext)
  const key = !isOtherContributor
    ? `${divisionId}${NODE_KEY_DELIMITER}${personId}`
    : `${brandName}${NODE_KEY_DELIMITER}${divisionId}${NODE_KEY_DELIMITER}${OTHER_CONTRIBUTORS_KEY}${NODE_KEY_DELIMITER}${personId}`
  if (employeeDictionary && employeeDictionary[personId]) {
    if (isOtherContributor) {
      employeeDictionary[personId].otherContributorKeys.push(key)
    } else {
      employeeDictionary[personId].employeeNodeKeys.push(key)
    }
  } else if (employeeDictionary) {
    employeeDictionary[personId] = {
      id: personId,
      employeeNodeKeys: !isOtherContributor ? [key] : [],
      otherContributorKeys: isOtherContributor ? [key] : []
    }
  }
  return {
    id: personId,
    lastName: personContext?.['last-name'],
    title:  employeeName.length ? employeeName : personId,
    key,
    isInactive: !!personContext?.['is-inactive'],
    isOtherContributor: !!isOtherContributor,
    isGovernmentNode: isGovernmentDivision,
    type: EMPLOYEE_KEY
  }
}

const buildDivisionChildNodes = (
  brandName: string,
  divisionId: string,
  members: Array<string>,
  reportData: Partial<IDivisionDataDetails>,
  peopleContext: OtrPeople,
  employeeDictionary: EmployeeDictionary,
  reportView: string
) => {
  const peopleData = reportData?.['people-data']
  const isGovernmentDivision = brandName === 'Government'
  const showOcs = !shouldHideOcs(divisionId)
  const otherContributors = showOcs && peopleData ? Object.keys(peopleData).reduce(
    (ids, nextId) => {
      if (!members.includes(nextId)) {
        ids.push(nextId)
      }
      return ids
    },
    []
  ) : []
  const people = members
    .reduce((peopleArray, nextPersonId) => {
      const nextPersonContext = peopleContext?.[nextPersonId]
      if (nextPersonContext) {
          // if reportView exists, filters out employees to their side of the house
        if (
          !reportView ||
          (reportView === DASHBOARD_TAB_KEYS.RECRUITING && nextPersonContext['side-of-house'] === SIDE_OF_HOUSE_ENUM.RECRUITING) ||
          (reportView === DASHBOARD_TAB_KEYS.AM && nextPersonContext['side-of-house'] === SIDE_OF_HOUSE_ENUM.AM)
        ) {
          const nextPerson = mapPeopleNodes(
            nextPersonId,
            brandName,
            divisionId,
            peopleContext,
            false,
            employeeDictionary,
            isGovernmentDivision
          )
          return [...peopleArray, nextPerson]
        } else {
          return peopleArray
        }
      }
      return peopleArray
    }, [])
    .sort((a, b) => {
      return (a.lastName || '').localeCompare(b.lastName || '')
    })
  if (otherContributors.length) {
    const otherContributorsNode = {
      key: `${brandName}${NODE_KEY_DELIMITER}${divisionId}${NODE_KEY_DELIMITER}${OTHER_CONTRIBUTORS_KEY}`,
      title: 'Other Contributors',
      children: otherContributors
        .map((personId) =>
          mapPeopleNodes(personId, brandName, divisionId, peopleContext, true, employeeDictionary, isGovernmentDivision)
        )
        .sort((a, b) => {
          if (a.isInactive === b.isInactive) {
            return (a.lastName || '').localeCompare(b.lastName || '')
          }
          return a.isInactive ? 1 : -1
        })
    }
    people.push(otherContributorsNode)
  }
  return { people, otherContributors }
}

const checkChildrenAndPushKeys = (keys: Array<string>, node: TreeNode): void => {
  if (node.key) keys.push(node.key)
  if (node.children) {
    node.children.forEach(childNode => checkChildrenAndPushKeys(keys, childNode))
  }
}

export const extractAllTreeKeys = (treeData: AlkuTree): Array<string> => {
  const keys = []
  if (!treeData) return []
  treeData.forEach(childNode => checkChildrenAndPushKeys(keys, childNode))
  return keys
}

export const buildTimeSliderMarks = (timeData: Record<TimeFrames, ITimeFrame>): Partial<Record<number, TimeSliderMark>> => {
  if (!timeData) return {}
  const markIdTimeSliderMapper = {
    'Day': 0,
    'Week': 20,
    '4Weeks': 40,
    '13Weeks': 60,
    'YTD': 80,
    'TTM': 100
  }
  return Object.values(timeData).reduce((marksObj, { id, from, to }) => {
    const markValue = {
      id,
      from,
      to,
      value: id,
      label: <span className='c-time-slider__mark-label'>{id}</span>
    }
    const timeSliderKey = markIdTimeSliderMapper[id]
    marksObj[timeSliderKey] = markValue
    return marksObj
  }, {})
}

/**
 * Creates a flat array of all tree nodes
 * @param {array} data tree data to generate list from
 * @param {array} dataList empty array
 */
export const generateList = (data: Array<AlkuTreeNodeType>, dataList: Array<AlkuTreeNodeType>): void => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { children, ...rest } = node
    dataList.push({ ...rest })
    if (node.children) {
      generateList(node.children, dataList);
    }
  }
}

export const getParentKey = (key: string, tree: Array<AlkuTreeNodeType>): string | undefined => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

export const buildTableExpandedKeyObjFromTreeExpandedKeysArr = (
  expandedKeys: Array<string>,
  tree: AlkuTree,
  autoExpandParents
): Partial<ExpandedKeysObj> => {
  const keyObj = {}
  expandedKeys.forEach(key => {
    let parentKey = getParentKey(key, tree)
    if (parentKey) {
      keyObj[parentKey] = keyObj[parentKey] ? [...keyObj[parentKey], key] : [key]
      if (autoExpandParents) {
        while (parentKey) {
          const newKey = parentKey
          parentKey = getParentKey(newKey, tree)
          if (parentKey) {
            keyObj[parentKey] = keyObj[parentKey] ? [...keyObj[parentKey], newKey] : [newKey]
          }
        }
      }
    }
  })
  return keyObj
}

/**
 * searches tree for node with specified key
 * @param {array} treeData same as antd treeData
 * @param {string} key node key to start search from
 */
const findNode = (treeData: Array<AlkuTreeNodeType>, key: string): TreeNode => {
  let foundNode
  treeData.every(node => {
    // exit early
    if (foundNode) {
      return false
    }
    if (node.key === key) {
      foundNode = node
    } else if (node.children) {
      foundNode = findNode(node.children, key)
    }
    return true
  })
  return foundNode
}

/**
 * returns true if any descendant node is unchecked
 * @param {array} treeData same as antd treeData
 * @param {array} checkedKeys audience tree currently checkedKeys
 * @param {string} key node key to start search from
 */
export const checkDescendantNodesForUnchecked = (treeData: Array<AlkuTreeNodeType>, checkedKeys: Array<string>, key: string): boolean => {
  const treeNode = findNode(treeData, key)
  if (!treeNode) {
    throw new Error(`Supplied tree data does not contain the following key: ${key}`)
  }
  if (treeNode.children && treeNode.children.length) {
    return treeNode.children.some(node => {
      const unchedked = !checkedKeys.includes(node.key)
      if (unchedked) {
        if (!node.children) {
          return true
        }
        return checkDescendantNodesForUnchecked(treeNode.children, checkedKeys, node.key)
      }
      return false
    })
  }
  return !checkedKeys.includes(treeNode.key)
}

/**
 * Uses the dataList and checkedKeys to return an array of the same checkedKeys in addition to all other contributors
 * for primary division members
 * @param {string[]} checkedKeys
 * @param {AlkuTreeNodeType[]} dataList
 * @return {string[]} array of keys
 */

export const addOcsToCheckedKeys = (checkedKeys: string[], dataList: AlkuTreeNodeType[], employeeDictionary: EmployeeDictionary): string[] => {
  if (Array.isArray(checkedKeys)) {
    if (employeeDictionary) {
      return checkedKeys.reduce((acc, nextKey) => {
        const nodeInfo = dataList.find(v => v.key === nextKey)
        if (nodeInfo?.type === EMPLOYEE_KEY && !nodeInfo.isOtherContributor) {
          const employeeInfo = employeeDictionary[nodeInfo.id]
          if (employeeInfo?.otherContributorKeys.length) {
            return [...acc, nextKey, ...employeeInfo.otherContributorKeys]
          }
        }
        return [...acc, nextKey]
      }, []).filter((key, index, self) => self.indexOf(key) === index)
    }
  } else {
    throw new Error('checkedKeys must be an array of keys')
  }
}
