import React from 'react'

const Icon = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='15.000000pt'
      height='15.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M83 770 c7 -78 31 -140 95 -239 23 -35 47 -79 54 -97 11 -30 16 -34
48 -34 33 0 39 -5 75 -60 l39 -60 86 0 86 0 39 60 c36 55 42 60 75 60 32 0 37
4 48 34 7 18 31 62 54 97 64 99 88 161 95 239 l6 70 -403 0 -403 0 6 -70z
m175 -108 c6 -55 14 -107 18 -118 4 -14 1 -13 -15 6 -29 35 -80 139 -87 178
-6 32 -6 32 33 32 l39 0 12 -98z m367 31 c-11 -111 -33 -202 -64 -270 l-30
-63 -51 0 -51 0 -30 63 c-31 68 -53 159 -64 270 l-7 67 152 0 152 0 -7 -67z
m161 35 c-7 -39 -58 -143 -87 -178 -16 -19 -19 -20 -15 -6 4 10 11 63 18 117
l11 99 40 0 c39 0 39 0 33 -32z'
        />
        <path d='M280 160 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z' />
      </g>
    </svg>
  </div>
)

export default Icon
