import {
  SPREAD_WEEK_KEY,
  SpreadOverTimeReport,
  SpreadOverTimeRow
} from '@/shared/constants/dataKeys'
import { ROW_MOD_KEYS } from '@/shared/constants/local'

const rowTypes = {
  QUARTER: 'quarter',
  MONTH: 'month',
  WEEK: 'week'
}

const rowModMapper = {
  [rowTypes.QUARTER]: [
    ROW_MOD_KEYS.EXPANDABLE,
    ROW_MOD_KEYS.EXPANDABLE_LEFT,
    ROW_MOD_KEYS.OVERTIME
  ],
  [rowTypes.MONTH]: [
    ROW_MOD_KEYS.EXPANDABLE,
    ROW_MOD_KEYS.EXPANDABLE_LEFT,
    ROW_MOD_KEYS.OVERTIME
  ],
  [rowTypes.WEEK]: [ROW_MOD_KEYS.HIGH_SPREAD, ROW_MOD_KEYS.DATA_DISPLAY]
}

const childClassNameMapper = {
  [rowTypes.QUARTER]: 'c-table__row--indent',
  [rowTypes.MONTH]: 'c-table__row--indent',
  [rowTypes.WEEK]: 'c-table__row--indent'
}

const cellClassMapper = {
  [rowTypes.QUARTER]: {
    [SPREAD_WEEK_KEY]: 'c-type--table-data-black',
    default: 'c-type--table-numeric c-type--bold'
  },
  [rowTypes.MONTH]: {
    [SPREAD_WEEK_KEY]: 'c-type--table-data-black',
    default: 'c-type--table-numeric c-type--bold'
  },
  [rowTypes.WEEK]: {
    [SPREAD_WEEK_KEY]: 'c-type--table-data c-type--double-indent',
    default: 'c-type--table-numeric'
  }
}

export interface TableConfig extends SpreadOverTimeRow {
  key?: string
  [SPREAD_WEEK_KEY]?: string
  rowMod?: string[]
  childClassName?: string
  cellClassnames?: {
    spreadWeek: string
    default: string
  }
  childTable?: TableConfig[]
  defaultExpandAllRows?: boolean
  hideExpandArrow?: boolean
}

type nestedTimeFrame = {
  quarters: {
    fiscalQuarter: number
    hours: number
    gpm: number
    timeCardsPaid: number
    weeklySpread: number
    months: {
      fiscalMonth: number
      hours: number
      gpm: number
      timeCardsPaid: number
      weeklySpread: number
      weeks: SpreadOverTimeRow[]
    }[]
  }[]
}

function getMonthName(monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1)
  return date.toLocaleString('en-US', { month: 'long' })
}

/**
 * Returns table configuration for the Week by Week table.
 * @param {array} data pulse-over-time-data from report response
 * @param {string} currentYear the current year, used to sort current year to the top
 */

const createMySpreadOvertimeConfig = (
  overTimeReport?: SpreadOverTimeReport
): TableConfig[] => {
  /**
   * Create nested data structure to help with table config generation
   */
  const nestedTimeFrame: nestedTimeFrame = { quarters: [] }
  let quarterIndex = -1
  let monthIndex = -1
  const orderedOverTimeReport = overTimeReport?.rows

  orderedOverTimeReport?.forEach((week) => {
    const fiscalQuarter = week?.fiscalQuarter
    const fiscalMonth = week?.fiscalMonth

    if (
      fiscalQuarter &&
      !nestedTimeFrame.quarters.some((e) => e.fiscalQuarter === fiscalQuarter)
    ) {
      quarterIndex++ // wont work if the years/quarters are not in order
      monthIndex = -1 // this won't work if the years/quarters are not in order
      nestedTimeFrame.quarters.push({
        fiscalQuarter: fiscalQuarter,
        hours: 0,
        gpm: 0,
        timeCardsPaid: 0,
        weeklySpread: 0,
        months: []
      })
    }

    if (
      fiscalQuarter &&
      !nestedTimeFrame.quarters[quarterIndex].months.some(
        (e) => e.fiscalMonth === fiscalMonth
      )
    ) {
      monthIndex++
      nestedTimeFrame.quarters[quarterIndex].months.push({
        fiscalMonth: fiscalMonth,
        hours: 0,
        gpm: 0,
        timeCardsPaid: 0,
        weeklySpread: 0,
        weeks: []
      })
    }

    nestedTimeFrame.quarters[quarterIndex].months[monthIndex].weeks.push({
      ...week
    })
  })

  try {
    /**
     * Map data to match what the Table config is expecting
     */
    const config = nestedTimeFrame.quarters.reverse().map((quarter) => {
      return {
        ...quarter,
        data: quarter,
        key: `quarter-${quarter.fiscalQuarter}`,
        [SPREAD_WEEK_KEY]: `Total Q${quarter.fiscalQuarter}`,
        rowMod: rowModMapper[rowTypes.QUARTER],
        childClassName: childClassNameMapper[rowTypes.QUARTER],
        cellClassnames: cellClassMapper[rowTypes.QUARTER],
        expandRowByClick: true,
        defaultExpandAllRows: false,
        hideExpandArrow: false,
        childTable: quarter.months.reverse().map((month) => {
          return {
            ...month,
            key: `month-${month.fiscalMonth}`,
            data: month,
            [SPREAD_WEEK_KEY]: `${getMonthName(month.fiscalMonth)}`,
            rowMod: rowModMapper[rowTypes.MONTH],
            childClassName: childClassNameMapper[rowTypes.MONTH],
            cellClassnames: cellClassMapper[rowTypes.MONTH],
            isHighSpread: month.weeks.some((w) => w.isHighSpread === true),
            defaultExpandAllRows: false,
            hideExpandArrow: false,
            childTable: month.weeks.reverse().map((week: SpreadOverTimeRow) => {
              return {
                ...week,
                data: week,
                key: `week-${week.fiscalWeek}`,
                [SPREAD_WEEK_KEY]: `${week.startDate} - ${week.endDate}`,
                rowMod: rowModMapper[rowTypes.WEEK],
                childClassName: childClassNameMapper[rowTypes.WEEK],
                cellClassnames: cellClassMapper[rowTypes.WEEK],
                defaultExpandAllRows: true,
                hideExpandArrow: true,
                isHighSpread: week.isHighSpread
              }
            })
          }
        })
      }
    })

    config.forEach((quarter) => {
      //Declare variables
      let totalQuarterHours = 0
      let totalQuarterGpm = 0
      let totalQuarterTimeCardsPaid = 0
      let totalQuarterSpread = 0
      //Loop through quarter table
      for (let i = 0; i < quarter.childTable.length; i++) {
        let totalMonthsGpm = 0
        let totalMonthsHours = 0
        let totalMonthsTimeCardsPaid = 0
        let totalMonthsSpread = 0
        const month = quarter.childTable[i]
        //Loop through month table
        for (let j = 0; j < month.childTable.length; j++) {
          const week = month.childTable[j]
          totalMonthsHours += week.hours
          totalMonthsGpm += week.gpm
          totalMonthsTimeCardsPaid += week.timeCardsPaid
          totalMonthsSpread += week.weeklySpread
        }
        month.hours = totalMonthsHours
        month.gpm = totalMonthsGpm / month.childTable.length
        month.timeCardsPaid = totalMonthsTimeCardsPaid
        month.weeklySpread = totalMonthsSpread

        totalQuarterGpm += month.gpm
        totalQuarterHours += month.hours
        totalQuarterTimeCardsPaid += month.timeCardsPaid
        totalQuarterSpread += month.weeklySpread
      }
      quarter.hours = totalQuarterHours
      quarter.gpm = totalQuarterGpm / quarter.childTable.length
      quarter.timeCardsPaid = totalQuarterTimeCardsPaid
      quarter.weeklySpread = totalQuarterSpread
    })

    return config
  } catch (e) {
    // console.log(e)
    return []
  }
}

export default createMySpreadOvertimeConfig
