import { createReducer } from '@reduxjs/toolkit'

import { ICurrentUserContext } from '@/shared/types/swagger'
import { setActiveUserAccount } from './authActions'
import { AccountInfo } from '@azure/msal-browser'

type AuthState = {
  user: {
    account?: AccountInfo
    context?: ICurrentUserContext
  }
}

const initialState: AuthState = {
  user: {
    account: null,
    context: null
  }
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setActiveUserAccount, (state, action) => {
    state.user.account = action.payload
  })
})
