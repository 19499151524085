import React from 'react'

const PulseAnimation = () => {

  return (
    <div className='c-pulse-animation'>
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 1000 200'
        // style={{ 'enable-background': 'new 0 0 500 200;' }}
        xmlSpace='preserve'
      >
        <defs>
          <linearGradient
            id='pulse-default-color-gradient'
            x1='0%'
            y1='0%'
            x2='100%'
            y2='0%'
          >
            <stop offset='0%' stopColor='#CA299D' />
            <stop offset='20.15%' stopColor='#310BE6' />
            <stop offset='65.08%' stopColor='#019F60' />
            <stop offset='100%' stopColor='#03DF20' />
          </linearGradient>
        </defs>
        <g>
          <polyline
            className='c-pulse-animation__ekg'
            stroke='url(#pulse-default-color-gradient)'
            points='986.6,113 828.2,113.8 810.3,132.3 796,70.7 746.8,127.4 741.6,120.2 733.9,166.4 727,27.6
          713.2,118.3 711.8,112.3 705.1,126.1 698.2,108.5 694.1,124.4 684.5,92.9 674.1,113 486.6,113.8 328.2,113.8 310.3,132.3 296,70.7 246.8,127.4 241.6,120.2 233.9,166.4 227,27.6
          213.2,118.3 211.8,112.3 205.1,126.1 198.2,108.5 194.1,124.4 184.5,92.9 174.1,113 4.3,113 	'
          />
        </g>
      </svg>
    </div>
  )
}

export default PulseAnimation
