import React from 'react'
import { Tooltip as AntTooltip } from 'antd'

const Tooltip = props => {
  return (
    <AntTooltip
      color='#ffff'
      overlayInnerStyle={!props.color ? { color: '#000000' } : {}}
      title='test'
      {...props}
    />
  )
}

export default Tooltip
