import { getIsPowerUser } from './general'
import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'

const env: string = process.env.REACT_APP_ENVIRONMENT
const useConfigObject = [REACT_APP_ENVIRONMENT.PROD].includes(env)

// These ids are pulled from the pulse prod environment on 2022-12-06
// eslint-disable-next-line no-unused-vars
const DIVISIONS = {
  PHARMA_RS: '026ad33f-6dcb-4b1c-bd32-47b0eb0ff9ca',
  GOV_INFOSOFT: '089b437f-13f5-4410-8079-473c16bae79b',
  BDM: '105317a5-7107-4cf2-883e-2815b9b22e76',
  MED_TE: '120183e2-a08b-4d88-bf26-57681fadebb1',
  GOV_IT: '13640f37-e5d6-47fb-8457-1658aaf09c60',
  ORACLE_CLOUD: '255dcae6-e360-4e33-b938-68e191ccc794',
  WORKDAY_HCM: '368bd3e8-b83c-4590-9b7b-d560af7c841c',
  PHARMA_SCM: '3da42cb4-2820-4c7e-a876-08b9c6eecea0',
  'INSIDE/OUTSIDE': '42bdff54-6047-4bba-ac0c-2a0aa05bd712',
  MED_ST: '4373ed7c-3d0d-4038-b1f6-55a7e78c2d31',
  EXPLORERS: '62d68557-bfa8-40c9-b124-a19bd7d58f68',
  EPIC: '65b5a58e-fed0-4f9a-9d08-f894a3fc1aea',
  SALESFORCE: '748a47ac-756b-4353-a9f2-8fbc59f1b9d5',
  INTERNAL: '75031d01-3c5c-4f7a-b8d5-22dd2f099229',
  GOVERNMENT: '7c68cca2-7a29-4537-af94-bea499bdb065',
  OUTSIDE_SALES: '859004ec-d938-4ef4-af84-595c08457d6f',
  MED_RA: '99694809-d109-4daf-be1c-78919c76487c',
  UNKNOWN: 'a24af077-3713-4221-947d-49c000651c88',
  INSIDE_SALES: 'a9f0dcd2-c4e9-40d4-bfaa-6eecbf469caf',
  MED_CA: 'aa240704-e241-48ee-b258-700f97fa2d46',
  UKG_PRO: 'adad53ce-a56a-46d3-8367-2a35ea8048f6',
  BANKING: 'af5a0e00-82d8-48f0-be34-7876cc999c74',
  PHARMA_AR_D: 'b5df191c-7769-4867-904d-6ba133c2fcd9',
  SAP: 'b9e85d11-75ce-4591-804b-42e5ae465cd6',
  MED_QA: 'befaddd7-800f-431d-aa63-a4de4533037b',
  CERNER: 'cc7febc3-0f1f-4687-b76b-4818ed83e6c3',
  KRONOS: 'd3998400-e6f2-4dab-8874-8ae5e065c260',
  PHARMA_QUALITY: 'da7b37e9-c04c-4a31-a2a8-68816e30d45b',
  CYBERSECURITY: 'f4e9c17e-a9be-4282-84d9-0ff0317bde99',
  DATA_SCIENCE: 'f56fb046-e0fc-46ae-b9dc-b7b65302b26a',
  GOV_INTELENG: 'f62a0bec-d8e8-423f-b511-b155704564ea',
  WORKDAY_FINANCIALS: 'fe815658-e659-4a69-8ac9-86815e6b4cce',
  INFOR: 'a54b0526-52ba-4ad5-b644-3bda3cb7357a',
  AUTOMATION_TESTING: '5843f63b-316a-4afa-a55d-9b36421b057c',
  PHARMA_TE_V: '7966f74c-09a7-4190-85b5-b6b1e4e8731b'
}

const GLOBAL_KEY = 'GLOBAL'
const HIDE_OCS = 'hideOtherContributors'
const HIDE_DIV = 'hideDivision'

type DivisionOptions = {
  [HIDE_DIV]: boolean;
  [HIDE_OCS]: boolean;
}
type DivisionConfig = Partial<DivisionOptions>
type ConfigObject = Record<string, DivisionConfig>

const configObject: ConfigObject = {
  [DIVISIONS.AUTOMATION_TESTING]: {
    [HIDE_DIV]: true
  }
}

const shouldHideDivision = (divisionId) => {
  if (divisionId === DIVISIONS.AUTOMATION_TESTING) {
    console.log({
      divisionId,
      isPowerUser: getIsPowerUser()
    })
  }
  if (!useConfigObject || getIsPowerUser()) {
    return false
  }

  const globalHideSetting = configObject[GLOBAL_KEY]?.[HIDE_DIV]
  const divHideSetting = configObject[divisionId]?.[HIDE_DIV]
  if (globalHideSetting || divHideSetting) {
    if (configObject[divisionId]?.[HIDE_DIV] === false) return false
    return true
  }
  return false
}

const shouldHideOcs = (divisionId) => {
  if (!useConfigObject || getIsPowerUser()) {
    return false
  }

  const globalHideSetting = configObject[GLOBAL_KEY]?.[HIDE_OCS]
  const divHideSetting = configObject[divisionId]?.[HIDE_OCS]
  if (globalHideSetting || divHideSetting) {
    if (configObject[divisionId]?.[HIDE_OCS] === false) return false
    return true
  }
  return false
}

// https://adkgroup.atlassian.net/browse/AST1-24
// Setting this to true until we're no longer hiding anything
// const shouldSkipAllSectorsShortcut = useConfigObject && Object.values(configObject).find(config => config[HIDE_OCS])
const shouldSkipAllSectorsShortcut = true

export { shouldHideOcs, shouldHideDivision, shouldSkipAllSectorsShortcut }
