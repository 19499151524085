import React from 'react'
import Icon from './Icon'

const UpArrow = ({ position }) => (
  <Icon position={position}>
    <svg
      width='13'
      height='14'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M6.067 0L0 6.222h3.792v7c0 .43.34.778.758.778h3.033a.769.769 0 00.759-.778v-7h3.791L6.067 0z'
          id='prefix__a--up-arrow'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b--up-arrow' fill='#fff'>
          <use xlinkHref='#prefix__a--up-arrow' />
        </mask>
        <use fill='#000' fillRule='nonzero' xlinkHref='#prefix__a--up-arrow' />
        <path fill='#45B118' mask='url(#prefix__b--up-arrow)' d='M-2-1h16v16H-2z' />
      </g>
    </svg>
  </Icon>
)

export default UpArrow
