import React from 'react'

import { useAppSelector } from '@/shared/hooks'

import { selectNotices } from '@/features/shared/redux/sharedSelectors'
import Notice from '../Notice'

const NoticeContainer = () => {
  const messages = useAppSelector(selectNotices)

  return (
    <div className='c-notice__container' style={!messages?.length ? { display: 'none' } : {}}>
      {messages.map(m => <Notice key={m.id} {...m}/>)}
    </div>
  )
}

export default NoticeContainer
