import { BDE_KEY, OTHER_CONTRIBUTORS_KEY } from '@/shared/constants/dataKeys'
import { ROW_MOD_KEYS } from '@/shared/constants/local'
import { ItemizedMetric } from '../../../../../shared/types/swagger'

/**
 * Returns table configuration for the main table display based on selected users.
 * @param entityContext data from one time response
 * @param data itemized table data
 * @param {Object[]} selected audience tree data
 */
const createBrandDivisionEmployeeTableConfig = (
  entityContext,
  data: ItemizedMetric[],
  selected
) => {
  try {
    return selected
      .map(({ id, children, key }) => {
        return {
          key,
          [BDE_KEY]: id,
          ...data,
          cellClassnames: {
            [BDE_KEY]: 'c-type--table-data-black',
            default: 'c-type--numeric c-type--bold'
          },
          childClassName: 'c-table__row--indent',
          rowMod: [ROW_MOD_KEYS.EXPANDABLE],
          childTable: createDivisionsEmployeeTableConfig(
            entityContext,
            data,
            children
          )
        }
      })
      .filter((v) => v)
  } catch (e) {
    console.log(e)
  }
}

const createDivisionsEmployeeTableConfig = (
  entityContext,
  data: ItemizedMetric[],
  selected
) => {
  return selected
    .map(({ id, children, key }) => {
      const divisionInfo = entityContext?.divisions?.[id]
      if (divisionInfo) {
        const flattenedChildNodes = spreadOtherContributorsNode(children)
        const itemizedData = data.reduce((acc, nextItemizedData) => {
          if (nextItemizedData.division === id) {
            const foundNode = flattenedChildNodes.find((node) => node.id === nextItemizedData.employee)
            if (foundNode) {
              acc.push({
                ...nextItemizedData,
                ...foundNode,
                id: nextItemizedData.id
              })
            }
          }
          return acc
        }, [])

        return {
          key,
          [BDE_KEY]: divisionInfo.name,
          cellClassnames: {
            [BDE_KEY]: 'c-type--table-data-bold',
            default: 'c-type--table-numeric c-type--bold'
          },
          rowMod: [ROW_MOD_KEYS.EXPANDABLE],
          childClassName: 'c-table__row--quality c-table__row--indent',
          locale: { emptyText: 'No data found for selection.' },
          childTable: createEmployeeTableConfig(entityContext, itemizedData)
        }
      } else {
        return null
      }
    })
    .filter((v) => v)
}

const createEmployeeTableConfig = (
  entityContext,
  peopleData
) => {
  return peopleData
    .map(({ id, isOtherContributor, title, ...rest }, index) => {
      const employeeInfo = entityContext?.people?.[rest.employee]
      if (employeeInfo) {
        return {
          ...employeeInfo,
          ...rest,
          id,
          key: `${index}-${rest.title}-${rest.label}-${id}`,
          [BDE_KEY]: title,
          rowMod: [ROW_MOD_KEYS.AVATAR, ROW_MOD_KEYS.DATA_DISPLAY],
          cellClassnames: {
            [BDE_KEY]: 'c-type--table-data-bold c-table__cell--avatar',
            default: 'c-type--table-numeric'
          },
          isOtherContributor
        }
      } else {
        return null
      }
    })
    .filter((v) => v)
    .sort((a, b) => {
      if (a['last-name'] !== b['last-name']) {
        return a['last-name'].localeCompare(b['last-name'])
      }
      return b.date - a.date
    })
}

const spreadOtherContributorsNode = divisionChildren => {
  // last child node of a division is either an other contributors node or person
  const lastNode = divisionChildren[divisionChildren.length - 1]
  if (lastNode?.key.includes(OTHER_CONTRIBUTORS_KEY)) {
    return [...divisionChildren.slice(0, -1), ...lastNode.children]
  }
  return divisionChildren
}

export default createBrandDivisionEmployeeTableConfig
