import React, { useEffect, useState, useCallback } from 'react'
import { Input } from 'antd'
import classNames from 'classnames'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { setUserSavedTarget } from '../../redux/manageTargetsActions'
import { selectInputRefreshTs, selectTargetsData } from '../../redux/manageTargetsSelectors'
import defaultTargets from '../helpers/defaultTargets'

const NA_VAL = 'N/A'

const shouldShowDefaultBorder = (value, defaultMetric, isDisabledByGovernment) => {
  if (!isDisabledByGovernment) {
    if (defaultMetric === 0 && value === NA_VAL) {
      return true
    }
    return Number(value) === defaultMetric
  }
  return false
}

const ManageTargetInput = ({ record, metric, isGovernmentMetric = false }) => {
  const { id, isGovernmentRecord } = record
  const dispatch = useAppDispatch()
  const [value, setValue] = useState(0)
  const onChange = (e) => setValue(e.currentTarget.value)
  const isDisabledByGovernment = isGovernmentMetric && !isGovernmentRecord
  const targetsData = useAppSelector(selectTargetsData)
  const userTargetData = targetsData.find((v) => v['user-id'] === id) || defaultTargets
  const selectedDefaultMetric = defaultTargets[metric]
  const selectedMetric = userTargetData[metric]
  const inputRefreshTs = useAppSelector(selectInputRefreshTs)
  const onBlur = useCallback(
    (e) => {
      const formattedNumber = Number(e.currentTarget.value)
      const dispatchObject = { id, metric, value: formattedNumber }
      if (!formattedNumber) {
        setValue(NA_VAL)
        dispatchObject.value = 0
      } else {
        setValue(formattedNumber)
      }
      dispatchObject.deleteMetric = formattedNumber ? formattedNumber === selectedMetric : 0 === selectedMetric
      dispatch(setUserSavedTarget(dispatchObject))
    },
    [dispatch, id, metric, selectedMetric]
  )
  useEffect(() => {
    if (selectedMetric && !isDisabledByGovernment) {
      setValue(selectedMetric)
    } else {
      setValue(NA_VAL)
    }
  }, [selectedMetric, isDisabledByGovernment, inputRefreshTs])
  const className = classNames('c-target-input', {
    'c-target-input--default': shouldShowDefaultBorder(value, selectedDefaultMetric, isDisabledByGovernment),
    'c-target-input--disabled': isDisabledByGovernment
  })

  return (
    <div className={className}>
      <Input
        defaultValue={selectedMetric}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabledByGovernment}
      />
    </div>
  )
}

export default ManageTargetInput
