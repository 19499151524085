import * as React from 'react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import ControlPanel from '@/features/ControlPanel'
import { initialDashboardLoad } from '@/features/dashboard/redux/dashboardActions'
import { DashboardTabs, Funnel, MainTableContainer, RatioBoxGroup, WeekByWeekTable } from '@/features/dashboard/components'
import { selectShowDashboardLoader } from '@/features/dashboard/redux/dashboardSelectors'
import { selectQsParsing } from '@/features/shared/redux/sharedSelectors'

const Dashboard = () => {
  const dispatch = useAppDispatch()
  const showLoader = useAppSelector(selectShowDashboardLoader)
  const parsing = useAppSelector(selectQsParsing)
  React.useEffect(() => {
    dispatch(initialDashboardLoad())
  }, [dispatch, parsing])

  return (
    <div className='c-screen'>
      {showLoader && <div className='c-screen__loading-blocker' />}
      <div className='c-sidebar'>
        <ControlPanel />
      </div>
      <div className='c-dashboard'>
        <DashboardTabs />
        <Funnel />
        <RatioBoxGroup />
        <MainTableContainer />
        <WeekByWeekTable />
        {<div className='c-dashboard__loading-blocker' />}
      </div>
    </div>
  );
}

export default Dashboard
