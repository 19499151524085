import { gql } from '@apollo/client'

import { GET_CURRENT_WEEK_TABLE_QUERY, CurrentWeekTableData } from '../CurrentWeekTable/helpers/query'
import { FoundersTableData, GET_ALL_FOUNDERS_QUERY } from '../FoundersReport/helpers/query'
import { GET_TOTALS_TABLE_QUERY, TotalsTableData, GET_DEPARTMENT_TOTALS_TABLE_QUERY, DepartmentsTotalsTableData, GET_ALL_MONTHLY_TARGETS_QUERY, MonthlyTargetsData } from '../TotalsTable/helpers/query'
import { DepartmentMappingsData, GET_DEPARTMENT_MAPPINGS_QUERY } from './departmentMappingsQuery'

export type SpreadExportData = CurrentWeekTableData & TotalsTableData & DepartmentMappingsData & DepartmentsTotalsTableData & FoundersTableData & MonthlyTargetsData

export const GET_SPREAD_EXPORT_QUERY = gql`
  query SpreadExport {
   ${GET_CURRENT_WEEK_TABLE_QUERY}
   ${GET_TOTALS_TABLE_QUERY}
   ${GET_DEPARTMENT_MAPPINGS_QUERY}
   ${GET_DEPARTMENT_TOTALS_TABLE_QUERY}
   ${GET_ALL_FOUNDERS_QUERY}
   ${GET_ALL_MONTHLY_TARGETS_QUERY}
  }
`
