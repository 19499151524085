import { createAction } from '@reduxjs/toolkit'

import controlPanelTypes from './controlPanelTypes'
import { AlkuTree, EmployeeDictionary, TimeSliderMark } from '@/shared/types'
import { ICurrentUserContext } from '@/shared/types/swagger'

export const setTimePeriod = createAction<number>(controlPanelTypes.SET_TIME_PERIOD)
export const setCustomDateRangeEnabled = createAction<boolean>(
  controlPanelTypes.SET_CUSTOM_DATE_RANGE_ENABLED
)
export const setCustomDateRange = createAction<[string, string]>(
  controlPanelTypes.SET_CUSTOM_DATE_RANGE
)
export const setAtSelectedKeys = createAction<Array<string>>(controlPanelTypes.SET_AT_SELECTED_KEYS)
export const setAtExpandedKeys = createAction<Array<string>>(controlPanelTypes.SET_AT_EXPANDED_KEYS)
export const setAtCheckedKeys = createAction<Array<string>>(controlPanelTypes.SET_AT_CHECKED_KEYS)
export const setAtDebouncedCheckedKeys = createAction<Array<string>>(controlPanelTypes.SET_AT_DEBOUNCED_CHECKED_KEYS)
export const setAudienceTreeData = createAction<AlkuTree>(controlPanelTypes.SET_AT_DATA)
export const setTimeSliderMarks = createAction<Record<number, TimeSliderMark>>(controlPanelTypes.SET_TIME_SLIDER_MARKS)
export const setActiveShortcutKey = createAction<string>(controlPanelTypes.SET_ACTIVE_SHORTCUT_KEY)
export const setAtEmployeeDictionary = createAction<EmployeeDictionary>(controlPanelTypes.SET_AT_EMPLOYEE_DICTIONARY)
export const setAudienceTreeSearch = createAction<string>(controlPanelTypes.SET_AUDIENCE_TREE_SEARCH)
export const setAudienceTreeSearchValue = createAction<string>(controlPanelTypes.SET_AUDIENCE_TREE_SEARCH_VALUE)
export const setAtAutoExpandParent = createAction<boolean>(controlPanelTypes.SET_AT_AUTO_EXPAND_PARENT)
export const saveAtState = createAction<Array<string>>(controlPanelTypes.SAVE_AT_STATE)
export const rollbackAtSaveState = createAction(controlPanelTypes.ROLLBACK_AT_SAVE_STATE)
export const setAtSearchResultKeys = createAction<Array<string>>(controlPanelTypes.SET_AT_SEARCH_RESULT_KEYS)
export const addAtCheckedKeys = createAction<Array<string>>(controlPanelTypes.ADD_AT_CHECKED_KEYS)
export const addAtDebouncedCheckedKeys = createAction<Array<string>>(controlPanelTypes.ADD_AT_DEBOUNCED_CHECKED_KEYS)
export const addAtExpandedKeys = createAction<Array<string>>(controlPanelTypes.ADD_AT_EXPANDED_KEYS)
export const clearAtSearch = createAction(controlPanelTypes.CLEAR_AT_SEARCH)
export const setSetSelectedDates = createAction<[string,string] | null>(controlPanelTypes.SET_SELECTED_DATES)

export const saveMyDivisionsBegin = createAction(controlPanelTypes.SAVE_MY_DIVISIONS_BEGIN)
export const saveMyDivisionsSuccess = createAction<ICurrentUserContext>(controlPanelTypes.SAVE_MY_DIVISIONS_SUCCESS)
export const saveMyDivisionsFail = createAction(controlPanelTypes.SAVE_MY_DIVISIONS_FAIL)

export const saveMyPeopleBegin = createAction(controlPanelTypes.SAVE_MY_PEOPLE_BEGIN)
export const saveMyPeopleSuccess = createAction<ICurrentUserContext>(controlPanelTypes.SAVE_MY_PEOPLE_SUCCESS)
export const saveMyPeopleFail = createAction(controlPanelTypes.SAVE_MY_PEOPLE_FAIL)