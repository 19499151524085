import React, { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import {
  selectAtCheckedKeys,
  selectGovernmentDivisionIsChecked,
} from '@/features/ControlPanel/redux/controlPanelSelectors'
import { selectAllCompanyData, selectInitialLoad, selectReportResponseLoading } from '@/features/shared/redux/sharedSelectors'
import { Skeleton } from '@/shared/components'
import TargetGroup from './components/TargetGroup'
import { accountManagementConfig, recruitingConfig } from './helpers/groupConfigs'
import enrichTargetGroupConfig from './helpers/enrichTargetGroupConfigs'
import {
  selectActiveDashboardTabKey,
  selectActiveTarget,
  selectShowDashboardLoader
} from '../../redux/dashboardSelectors'
import { setActiveTarget } from '../../redux/dashboardActions'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import renderNoneSelectedMessage from './helpers/renderNoneSelectedMsg'
import { updateQueryParamsByState } from '@/features/shared/redux/sharedActions'
import { pushEvent }  from '@/bootstrap/gtm/gaEvents'

const FunnelSkeleton = () => {
  return (
    <div className='c-funnel__skeleton'>
      <Skeleton.Input active style={{ width: 200 }} />
    </div>
  )
}

const Funnel = () => {
  const dispatch = useAppDispatch()
  const initialLoad = useAppSelector(selectInitialLoad)
  const reportResponseLoading = useAppSelector(selectReportResponseLoading)
  const showDashboardLoader = useAppSelector(selectShowDashboardLoader)
  const checkedKeys = useAppSelector(selectAtCheckedKeys)
  const data = useAppSelector(selectAllCompanyData)
  const activeKey = useAppSelector(selectActiveDashboardTabKey)
  const activeTarget = useAppSelector(selectActiveTarget)
  const includeGovernment = useAppSelector(selectGovernmentDivisionIsChecked)
  const isLoading = !initialLoad || reportResponseLoading || showDashboardLoader
  const setTarget = useCallback(val => {
    if (activeTarget !== val) {
      dispatch(setActiveTarget(val))
      dispatch(updateQueryParamsByState())
      if (val) {
        pushEvent({
          event: 'ga-select-funnel-stage',
          stage: val.title
        })
      }
    }
  }, [dispatch, activeTarget])

  const noneSelectedMessage = renderNoneSelectedMessage(checkedKeys, data, reportResponseLoading)
  const renderConfig = useCallback(() => {
    const activeConfig = activeKey === DASHBOARD_TAB_KEYS.AM ? accountManagementConfig(includeGovernment) : recruitingConfig(includeGovernment)
    return enrichTargetGroupConfig(activeConfig, data, noneSelectedMessage, activeTarget, setTarget)
  }, [activeKey, data, noneSelectedMessage, activeTarget, setTarget, includeGovernment])
  const config = renderConfig()

  return (
    <div className={`c-funnel ${isLoading ? 'loading' : ''}`}>
      <TargetGroup config={config} data={data}  />
      {(noneSelectedMessage) && (
        <div className='c-funnel__none-selected'>{noneSelectedMessage}</div>
      )}
      {(isLoading) && <FunnelSkeleton />}
    </div>
  )
}

export default Funnel
