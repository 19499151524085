// @ts-strict
import { IAppColumnConfig } from '@/shared/components/Table'
import { formatNumberToPercentage, formatNumberToAccountingNumericalFormat } from '@/utils/tools/format'
import { COLUMN_MOD_KEYS } from '@/shared/constants/local'
import { CurrentWeekRecord } from './query'

export type CurrentWeekStringKeys = Extract<keyof CurrentWeekRecord, string>

const genColCfg = <T extends CurrentWeekStringKeys>(
  title: string,
  dataIndex: T,
  width?: number,
  renderFn?: (value: CurrentWeekRecord[T]) => string
): IAppColumnConfig<CurrentWeekRecord> => ({
    title,
    dataIndex,
    key: dataIndex,
    width,
    columnMod: [COLUMN_MOD_KEYS.PLAIN],
    renderComponent: renderFn ? (record) => renderFn(record[dataIndex]) : undefined,
    shouldCellUpdate: (record, prevRecord) => record.recordId !== prevRecord.recordId
  })

const currentWeekColumnConfigs: Array<IAppColumnConfig> = [
  genColCfg('Record ID', 'recordId', 89),
  genColCfg('Week', 'week', 70),
  genColCfg('Reference Week', 'referenceWeek', 196),
  genColCfg('Client', 'client', 136),
  genColCfg('Consultant', 'consultant', 127),
  genColCfg('Type', 'type', 175),
  genColCfg('Total Billed Hrs', 'totalBilledHours', 139),
  genColCfg<'billRate'>('Bill Rate', 'billRate', 85, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'oTBillRate'>('OT Bill Rate', 'oTBillRate', 109, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'dTBillRate'>('DT Bill Rate', 'dTBillRate', 108, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'totalBilledAmount'>('Total Billed Amt', 'totalBilledAmount', 141, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'totalPaidHours'>('Total Paid Hrs', 'totalPaidHours', 126, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'payRate'>('Pay Rate', 'payRate', 88, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'oTPayRate'>('OT Pay Rate', 'oTPayRate', 112, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'dTPayRate'>('DT Pay Rate', 'dTPayRate', 111, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg<'totalPaidAmount'>('Total Paid Amt', 'totalPaidAmount', 138, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg('Net Spread %', 'netSpreadPct', 123, formatNumberToPercentage),
  genColCfg<'netSpread'>('Net Spread', 'netSpread', 106, (v) => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg('Recruiter', 'recruiter', 122),
  genColCfg('Salesperson', 'salesperson', 144),
  genColCfg('Department', 'department', 125),
  genColCfg('Training Ctr', 'trainingCenter', 124),
  genColCfg('BDM', 'bDM', 140),
  genColCfg('FT/PT', 'pTFT', 121)
]

export default currentWeekColumnConfigs
