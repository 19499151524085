import { get, post } from '@/bootstrap/axios'
import { IManageTargetsResponse, IUserTarget } from '@/shared/types/swagger'
import { AxiosResponse } from 'axios'

export const getTargets = async (): Promise<IManageTargetsResponse> => {
  const url = '/api/user-target'
  return await get<AxiosResponse<IManageTargetsResponse>>(url)
}

export const saveTargets = async (payload: Array<IUserTarget>) => {
  const url = '/api/user-target'

  return await post(url, payload)
}
