import { IAppColumnConfig } from '@/shared/components/Table'
import { EMPLOYEE_KEY } from '@/shared/constants/dataKeys'
import { SpreadPowerRankingMetricKeys } from '@/shared/constants/dataKeys'
import { SpreadSummaryReport } from '@/shared/constants/dataKeys'
import { ER_WIDTH, COLUMN_MOD_KEYS } from '@/shared/constants/local'
import { CompareFn } from 'antd/lib/table/interface'

export type MetricsConfig = {
  title: string
  dataIndex: string[] | string
  key: string
  columnMod: string[]
  sortOrder: number
  align?: string
  ellipsis?: boolean
  width?: string
  rowMod?: string[]
  showSorterTooltip?: boolean
  firstName?: string
  lastName?: string
  sortFn?: CompareFn<SpreadSummaryReport>
}

const generateMetricsConfig = (
  title: string,
  key: string,
  sortOrder: number,
  columnMods = []
): IAppColumnConfig<MetricsConfig> => ({
  title,
  dataIndex: key,
  key: key,
  align: 'right',
  columnMod: columnMods,
  showSorterTooltip: false,
  sorter: {
    compare: (a, b) => {
      return columnMods.includes(COLUMN_MOD_KEYS.PLAIN)
        ? a[key].localeCompare(b[key])
        : a[key] - b[key]
    }
  }
})

/**
 * Creates columnConfig used by antd table component
 * @param {boolean} includeGovernment - whether or not to include government specific colum configs
 * @return {object[]} - Array of column config objects
 */
const spreadPowerRankings: IAppColumnConfig<MetricsConfig>[] = [
  {
    title: 'Name',
    dataIndex: EMPLOYEE_KEY,
    key: EMPLOYEE_KEY,
    width: ER_WIDTH,
    columnMod: [
      COLUMN_MOD_KEYS.TITLE,
      COLUMN_MOD_KEYS.PARTY_ICON,
      COLUMN_MOD_KEYS.EMPLOYEE_NAME,
      COLUMN_MOD_KEYS.STARRED
    ],
    ellipsis: true,
    showSorterTooltip: false,
    className: 'c-type--bold',
    sorter: {
      compare: (a, b) => a.lastName.localeCompare(b.lastName)
    }
  },
  generateMetricsConfig(
    'Tenure',
    SpreadPowerRankingMetricKeys.SPREAD_PR_TENURE as string,
    1,
    [COLUMN_MOD_KEYS.PLAIN]
  ),
  generateMetricsConfig(
    'Division',
    SpreadPowerRankingMetricKeys.SPREAD_PR_DIVISION as string,
    2,
    [COLUMN_MOD_KEYS.PLAIN]
  ),
  generateMetricsConfig(
    'Net Spread',
    SpreadPowerRankingMetricKeys.SPREAD_PR_NET_SPREAD as string,
    3,
    [COLUMN_MOD_KEYS.CURRENCY]
  ),
  generateMetricsConfig(
    'Weekly Avg',
    SpreadPowerRankingMetricKeys.SPREAD_PR_WEEKLY_AVG as string,
    4,
    [COLUMN_MOD_KEYS.CURRENCY]
  ),
  generateMetricsConfig(
    'Spread High',
    SpreadPowerRankingMetricKeys.SPREAD_PR_SPREAD_HIGH as string,
    5,
    [COLUMN_MOD_KEYS.CURRENCY, COLUMN_MOD_KEYS.REACHED_AT]
  ),
  generateMetricsConfig(
    'GPM',
    SpreadPowerRankingMetricKeys.SPREAD_PR_GPM as string,
    6,
    [COLUMN_MOD_KEYS.PERCENT]
  ),
  generateMetricsConfig(
    'Hours Paid',
    SpreadPowerRankingMetricKeys.SPREAD_PR_HOURS_PAID as string,
    7,
    [COLUMN_MOD_KEYS.METRIC]
  )
]

export const generateConfig = () => {
  let config: IAppColumnConfig<MetricsConfig>[] = []
  config = spreadPowerRankings.filter((v) => v)
  return config
}
