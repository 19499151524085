import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import PulseAnimation from '@/shared/components/PulseAnimation'
import { clearNotices, setServiceErrorMessage, setShowServiceErrorScreen } from '@/features/shared/redux/sharedActions'
import { selectServiceErrorMessage } from '@/features/shared/redux/sharedSelectors'
import { Notice } from '@/shared/components'

const ServiceError = () => {
  const dispatch = useAppDispatch()
  const refreshPage = () => window.location.reload()
  const errorMessage = useAppSelector(selectServiceErrorMessage)

  useEffect(() => {
    dispatch(clearNotices())
    return () => {
      dispatch(setShowServiceErrorScreen(false))
      dispatch(setServiceErrorMessage(''))
    }
  }, [dispatch])

  return (
    <div className='c-screen'>
      <div className='c-screen__error'>
        <h1 className='c-type--title-xxl c-type--bold'>
          Oh no! It looks like we lost the Pulse…
        </h1>
        {errorMessage && (
          <Notice
            error
            style={{ marginBottom: '1.1rem', maxWidth: '40rem' }}
          >
            <div className='c-type--table-header-text'>{errorMessage}</div>
          </Notice>
        )}
        <h2 className='c-type--title-xl'>
          <div
            className='c-link c-link--btn'
            role='button'
            tabIndex={0}
            onClick={refreshPage}
          >
            Refresh
          </div>{' '}
          the page or try again later.
        </h2>
        <PulseAnimation />
      </div>
    </div>
  )
}

export default ServiceError
