// @ts-strict
import { IAppColumnConfig } from '@/shared/components/Table'
import { useCallback } from 'react'
import { MetricsConfig, generateConfig } from '../helpers/columnConfigs'
import createConsultantPerformanceConfig, {
  TableConfig
} from '../helpers/createTableConfig'

export default function ConsultantPerformanceData(data) {
  const consultantPerformanceLoading = false
  const consultantPerformanceError = false

  const createTableConfig = useCallback(() => {
    return createConsultantPerformanceConfig(data)
  }, [data])

  const tableConfig: TableConfig[] = createTableConfig()
  const columnConfig: IAppColumnConfig<MetricsConfig>[] = generateConfig()

  return {
    tableConfig,
    columnConfig,
    consultantPerformanceLoading,
    consultantPerformanceError
  }
}
