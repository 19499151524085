import React from 'react'
import Icon, { IconPositionType } from './Icon'

type MultipleDivisionsProps = {
  position?: IconPositionType;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  id?: string | number;
}

const MultipleDivisions = ({ position, onClick, id } : MultipleDivisionsProps) => (
  <Icon position={position} onClick={onClick}>
    <svg
      width='14'
      height='16'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M0 0v9.333h1.556V1.556h6.222V0H0zm3.111 3.111v9.333h1.556V4.667h6.222V3.11H3.11zm3.111 3.111v9.334h1.556V14H12.444v1.556H14V6.222H6.222zm1.556 1.556h1.555v1.555H7.778V7.778zm3.11 0h1.556v1.555H10.89V7.778zm-3.11 3.11h1.555v1.556H7.778V10.89zm3.11 0h1.556v1.556H10.89V10.89z'
          id={`prefix__a--multiplediv${id}`}
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id={`prefix__b--multiplediv${id}`} fill='#fff'>
          <use xlinkHref={`#prefix__a--multiplediv${id}`}/>
        </mask>
        <use fill='#404555' fillRule='nonzero' xlinkHref={`#prefix__a--multiplediv${id}`} />
        <path fill='#404555' mask={`url(#prefix__b--multiplediv${id})`} d='M-3-2h20v20H-3z' />
      </g>
    </svg>
  </Icon>
)

export default MultipleDivisions
