import React from 'react'
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'

import { useAppSelector } from '@/shared/hooks'
import { authenticationParams } from '@/bootstrap/msal'
import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'

import Header from '../Header'
import Routes from '../Routes'
import { USE_TEST_CONFIG } from '@/shared/constants/local'
import { selectSsoFailure } from '@/features/shared/redux/sharedSelectors'
import SsoServiceDown from '@/screens/SsoServiceDown'
import { NoticeContainer } from '@/shared/components'
import QAButton from '@/shared/components/QAButton'

const CURRENT_ENV = process.env.REACT_APP_ENVIRONMENT

const Layout = () => {
  // This hook drives the authentication
  // eslint-disable-next-line no-empty-pattern
  const { /* login, result, error */ } = useMsalAuthentication(!USE_TEST_CONFIG && InteractionType.Redirect, authenticationParams)
  const ssoLoginFailure = useAppSelector(selectSsoFailure)

  return (
    <div>
      <main className='c-layout'>
        <Header />
        {ssoLoginFailure ? (
          <SsoServiceDown />
        ) : !USE_TEST_CONFIG ? (
          <AuthenticatedTemplate>
            <Routes />
          </AuthenticatedTemplate>
        ) : (
          <Routes />
        )}
        <NoticeContainer />
        {[REACT_APP_ENVIRONMENT.DEV, REACT_APP_ENVIRONMENT.UAT].includes(
          CURRENT_ENV
        ) && <QAButton />}
      </main>
    </div>
  )
}

export default Layout