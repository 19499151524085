import React, { useState, useRef, useCallback, useEffect } from 'react'
import axios from 'axios'

import { dismissNoticeByDuplicateId, notifyError } from '@/features/shared/redux/sharedActions'
import { Upload2 } from '../Icons'
import Controls from './components/Controls'
import ProgressTracker from './components/ProgressTracker'
import { useAppDispatch } from '@/shared/hooks'
import Tooltip from '../Tooltip'
import classNames from 'classnames'

type TargetUrlOptions = {
  fileName?: string
}

type UploadButtonProps = {
  className?: string;
  targetUrl: string | ((arg1: File, arg2?: TargetUrlOptions) => string) |  ((arg1: File, arg2?: TargetUrlOptions) => Promise<string>);
  acceptedFileType?: Array<string> | string;
  acceptedFileTypeLabel?: string;
  disabled?: boolean;
  tooltip?: string;
  fileNamePrefix?: string;
  onSuccess?: () => void;
  onFail?: () => void;
  onCancel?: () => void;
}

const DUPLICATE_ID = 'UPLOAD_BUTTON_ERROR'
const STATUSES = {
  EXCEPTION: 'exception',
  NONE: 'none',
  REGULAR: 'regular'
}

/**
 * @param {string} props.className
 * @param {string | function} props.targetUrl - url for upload or function that returns url
 * @param {string | Array[string]} props.acceptedFileType
 * @param {string} props.acceptedFileTypeLabel
 * @param {function} props.onSuccess
 * @param {function} props.onFail
 * @param {function} props.onCancel
 * @return {React.ReactNode}
 */
const UploadButton = (props: UploadButtonProps) => {
  const { className, targetUrl, acceptedFileType, acceptedFileTypeLabel, disabled, tooltip, fileNamePrefix, onSuccess, onFail, onCancel } = props
  const controller = useRef(new AbortController())
  const dispatch = useAppDispatch()
  const hiddenFileInput = useRef(null)
  const [status, setStatus] = useState(STATUSES.NONE)
  const [percent, setPercent] = useState(0)
  const [file, setFile] = useState(null)
  const wrapperClassName = classNames('c-upload-button', className, {
    'c-upload-button--disabled': disabled
  })
  const acceptString = Array.isArray(acceptedFileType)
    ? acceptedFileType.join(', ')
    : acceptedFileType
  const acLabel = acceptedFileTypeLabel || acceptString
  const uploadFile = useCallback(async () => {
    if (file) {
      const fileName = typeof fileNamePrefix === 'string' ? `${fileNamePrefix}${file.name}` : file.name
      setStatus(STATUSES.REGULAR)
      setPercent(0)
      let url = ''
      if (typeof targetUrl === 'string') {
        url = targetUrl
      }
      if (typeof targetUrl === 'function') {
        url = await targetUrl(file, { fileName })
      }
      const formData = new FormData()
      formData.append('file', file)
      formData.append('name', fileName)
      axios
        .put(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            setPercent(percentCompleted)
          },
          signal: controller.current.signal
        })
        .then((response) => {
          if (typeof onSuccess === 'function') {
            onSuccess()
          }
          setFile(null)
          setStatus(STATUSES.NONE)
        })
        .catch((error) => {
          setStatus(STATUSES.EXCEPTION)
          if (typeof onFail === 'function') {
            onFail()
          }
        })
    }
  }, [file, onFail, onSuccess, targetUrl, fileNamePrefix])
  useEffect(() => {
    uploadFile()
  }, [file, uploadFile])

  const _onCancel = useCallback(() => {
    controller.current.abort()
    setFile(null)
    setStatus(STATUSES.NONE)
    if (typeof onCancel === 'function') {
      onCancel()
    }
  }, [onCancel])
  const handleChange = useCallback((event) => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      const name = fileUploaded.name
      const lastDot = name.lastIndexOf('.')
      const ext = name.substring(lastDot)

      if (acceptedFileType === undefined || acceptedFileType.includes(ext)) {
        dispatch(dismissNoticeByDuplicateId(DUPLICATE_ID))
        setFile(fileUploaded)
      } else {
        dispatch(
          notifyError(
            `${name} has an invalid file type. Accepted types are: ${acceptString}`,
            { duplicateId: DUPLICATE_ID }
          )
        )
      }
    }
  }, [dispatch, acceptString, acceptedFileType])
  const handleButtonClick = () => {
    hiddenFileInput.current.click()
  }

  return (
    <div className={wrapperClassName}>
      <input
        type='file'
        name='file'
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        accept={acceptString}
      />
      <Tooltip title={tooltip}>
        <button className='c-upload-button__btn' onClick={handleButtonClick} disabled={disabled}>
          <Upload2 position='left' /> Upload
        </button>
      </Tooltip>
      <div className='c-upload-button__details'>
        {status === 'none' ? (
          <>Accepted Files: {acLabel?.length ? acLabel : 'Any'}</>
        ) : (
          <>
            <Controls
              status={status}
              fileName={file?.name}
              onReset={uploadFile}
              onCancel={_onCancel}
            />
            <ProgressTracker status={status} percent={percent} />
          </>
        )}
      </div>
    </div>
  )
}

export default UploadButton
