import React from 'react'
import { Button, Input, Select } from 'antd'
import { OptionsType } from 'rc-select/lib/interface'
import { consultantPerformanceMock } from '../ConsultantPerformanceTable/hooks/consultantPerformanceMock'
import PropTypes from 'prop-types'
import { initialFilterState } from '@/screens/ConsultantPerformance'
const { Search } = Input

const clientsOptions = consultantPerformanceMock.rows
  .reduce((unique, o) => {
    if (
      !unique.some((obj) => obj.label === o.client && obj.value === o.client)
    ) {
      unique.push({ label: o.client, value: o.client })
    }
    return unique
  }, [])
  .sort((a, b) => a.label.localeCompare(b.label))

const weekOptions = consultantPerformanceMock.rows.reduce((unique, o) => {
  if (!unique.some((obj) => obj.label === o.week && obj.value === o.week)) {
    unique.push({ label: o.week, value: o.week })
  }
  return unique
}, [])

const ConsultantPerformanceFilters = ({ filters, setFilters }) => {
  const clearFilters = () => {
    setFilters(initialFilterState)
  }
  const handleChangeWeek = (value) => {
    setFilters((prev) => ({ ...prev, week: value }))
  }
  const handleChangeClients = (value) => {
    setFilters((prev) => ({ ...prev, client: value }))
  }
  const handleChangeConsultant = (e) => {
    setFilters((prev) => ({ ...prev, consultant: e.target.value }))
  }
  return (
    <div className='c-consultant-performance__grid'>
      <div className='filter'>
        <label>Active Week</label>
        <Select
          allowClear
          style={{
            width: '100%'
          }}
          placeholder='Active Week'
          defaultValue={[]}
          onChange={handleChangeWeek}
          value={filters.week}
          options={new Set(weekOptions) as unknown as OptionsType}
        />
      </div>
      <div className='filter'>
        <label>Filter by Client</label>
        <Select
          allowClear
          style={{
            width: '100%'
          }}
          placeholder='All Clients'
          defaultValue={[]}
          value={filters.client}
          onChange={handleChangeClients}
          options={new Set(clientsOptions) as unknown as OptionsType}
        />
      </div>
      <div className='filter'>
        <label>Search by Name</label>
        <Search
          value={filters.consultant}
          placeholder='Search by name...'
          onChange={handleChangeConsultant}
        />
      </div>
      <div className='filter'>
        <Button onClick={(e) => clearFilters()}>Clear filters</Button>
      </div>
    </div>
  )
}

ConsultantPerformanceFilters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func
}

export default ConsultantPerformanceFilters
