import React from 'react'
import Icon, { IconPositionType } from './Icon'

type DownSolidProps = {
  position?: IconPositionType
  className?: string
  rotate?: number
  scale?: number
}

const DownSolid = ({
  position,
  className,
  rotate = 0,
  scale
}: DownSolidProps) => (
  <Icon position={position} className={className}>
    <svg
      width='9'
      height='9'
      style={{
        transform: `rotate(${rotate}deg)` + scale ? `scale(${scale})` : ''
      }}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.872 7.087l3.377-3.753a.5.5 0 00-.372-.834H1.123a.5.5 0 00-.372.834l3.377 3.753a.5.5 0 00.744 0z'
        fill='#000'
        fillRule='evenodd'
      />
    </svg>
  </Icon>
)

export default DownSolid
