import React from 'react'

const Icon = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='18.000000pt'
      height='18.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M380 850 c-11 -11 -20 -31 -20 -45 l0 -25 -135 0 -135 0 0 -300 0
-300 390 0 390 0 0 300 0 300 -135 0 -135 0 0 25 c0 48 -31 65 -120 65 -67 0
-83 -3 -100 -20z m160 -55 c0 -12 -13 -15 -60 -15 -47 0 -60 3 -60 15 0 12 13
15 60 15 47 0 60 -3 60 -15z m270 -177 c0 -80 -3 -105 -17 -120 -15 -17 -38
-18 -311 -18 -261 0 -298 2 -314 17 -15 14 -18 32 -18 120 l0 103 330 0 330 0
0 -102z m0 -285 l0 -93 -330 0 -330 0 0 93 0 92 330 0 330 0 0 -92z'
        />
        <path
          d='M270 555 c0 -43 1 -45 30 -45 29 0 30 2 30 45 0 43 -1 45 -30 45 -29
0 -30 -2 -30 -45z'
        />
        <path
          d='M630 555 c0 -43 1 -45 30 -45 29 0 30 2 30 45 0 43 -1 45 -30 45 -29
0 -30 -2 -30 -45z'
        />
      </g>
    </svg>
  </div>
)

export default Icon
