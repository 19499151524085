export const consultantPerformanceMock = {
  rows: [
    {
      week: '3/5/23 - 3/11/23',
      client: 'Alcon',
      consultant: 'Abinay Raja',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Krista McDaniels',
      salesperson: 'Michael Adamson'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'Alcon',
      consultant: 'Lynette Campbell',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Keith Larson',
      salesperson: 'Sarah Johnson'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'Alcon',
      consultant: 'Varun Reddy Penna',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'William Kim',
      salesperson: 'James Davis'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'Amneal Pharmaceuticals',
      consultant: 'Nishita Salver',
      totalBilledHours: 40.0,
      totalPaidHours: 40.0,
      netSpreadPercent: 24.5,
      netSpread: 108000,
      recruiter: 'Jane Doe',
      bdm: 'Grace Park',
      salesperson: 'Melanie Cohen'
    },

    {
      week: '3/5/23 - 3/11/23',
      client: 'Asia Society',
      consultant: 'Deborah Ecaruan',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Isabella Diaz',
      salesperson: 'Michael Adamson'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'Crown Equipment Corporation',
      consultant: 'Harold Sleicher',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Krista McDaniels',
      salesperson: 'Sam Johnson'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'Crown Equipment Corporation',
      consultant: 'Rick Flake',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Elena Hernandez',
      salesperson: 'Daniel Woodward'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'DataRobot, Inc.',
      consultant: 'Gopi Chand Kolli',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Krista McDaniels',
      salesperson: 'Michael Adamson'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'Doosan Infacore',
      consultant: 'Darrel Thompson',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Alexander Garcia',
      salesperson: 'Sarah Johnson'
    },
    {
      week: '3/5/23 - 3/11/23',
      client: 'Ferguson',
      consultant: 'Bhanu Chand Somarajpalli',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Lucas Bernard',
      salesperson: 'Chris Patel'
    },
    {
      week: '3/11/23 - 3/18/23',
      client: 'Ferguson',
      consultant: 'Ifeanyi Nwangwu',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Olivia Davis',
      salesperson: 'Chloe Chen'
    },
    {
      week: '3/11/23 - 3/18/23',
      client: 'Ferguson',
      consultant: 'Hardik Oza',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Olivia Davis',
      salesperson: 'Chloe Chen'
    },
    {
      week: '3/11/23 - 3/18/23',
      client: 'Ferguson',
      consultant: 'Venkata Pradeep Tumuluri',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Olivia Davis',
      salesperson: 'Chloe Chen'
    },
    {
      week: '3/11/23 - 3/18/23',
      client: 'Fox Entertainment Group',
      consultant: 'Sandeep Vempati',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Bree Davis',
      salesperson: 'Ava Martinez'
    },
    {
      week: '3/11/23 - 3/18/23',
      client: 'Kawasaki Motors',
      consultant: 'Teja Gantela',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Olivia Davis',
      salesperson: 'Daniel Woodward'
    },
    {
      week: '3/11/23 - 3/18/23',
      client: 'NCR Corporation',
      consultant: 'Kevin Sherman',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Rachel Green',
      salesperson: 'Melanie Cohen'
    },
    {
      week: '3/11/23 - 3/18/23',
      client: 'NCR Corporation',
      consultant: 'Komal Nemichand Sen',
      totalBilledHours: Math.floor(Math.random() * (90 - 48) + 48),
      totalPaidHours: Math.floor(Math.random() * (90 - 48) + 48),
      netSpreadPercent: Math.floor(Math.random() * (90 - 48) + 48),
      netSpread: Math.random() * 10000 + 180000,
      recruiter: 'Jane Doe',
      bdm: 'Isabella Diaz',
      salesperson: 'Michael Adamson'
    }
  ],
  totals: {}
}
