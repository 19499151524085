import { formatNumberToFixedDisplay } from '@/utils/tools/format'
import { CurrentWeekRecord } from './query'

const sumTotalSpread = (data: Array<CurrentWeekRecord>) => {
  const sum = data.reduce((acc, v) => {
    if (v.netSpread) {
      acc+= v.netSpread
    }
    return acc
  }, 0)

  return `$${formatNumberToFixedDisplay(sum)}`
}

export default sumTotalSpread
