import React from 'react'
import Icon from './Icon'

const Attatchment = ({ position }) => (
  <Icon position={position}>
    <svg width='14' height='14' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.808 3.16a3.908 3.908 0 00-2.758-1.132h-.01c-.502 0-.992.094-1.457.278a3.853 3.853 0 00-1.29.842L3.656 6.745c-.42.416-.65.97-.649 1.563a2.19 2.19 0 00.657 1.562c.421.418.98.647 1.578.649h.004c.596 0 1.153-.228 1.572-.64l3.194-3.157a.545.545 0 000-.781.56.56 0 00-.788 0L6.03 9.097a1.107 1.107 0 01-.786.319h-.002a1.128 1.128 0 01-.794-.325 1.098 1.098 0 01-.328-.785 1.075 1.075 0 01.322-.778l3.64-3.598a2.764 2.764 0 011.959-.797h.007c.747.001 1.449.289 1.975.808.525.52.816 1.212.818 1.95a2.702 2.702 0 01-.805 1.942l-3.861 3.82a4.111 4.111 0 01-2.903 1.178H5.26a4.145 4.145 0 01-2.92-1.197 4.037 4.037 0 01-1.21-2.886 3.997 3.997 0 011.194-2.876L7.309.944a.545.545 0 000-.781A.56.56 0 006.52.16L1.536 5.09a5.096 5.096 0 00-1.52 3.66c.001.674.13 1.33.387 1.95a5.224 5.224 0 002.883 2.852c.625.252 1.289.38 1.972.383h.014a5.25 5.25 0 003.687-1.503l3.861-3.819a3.825 3.825 0 001.133-2.728 3.835 3.835 0 00-1.145-2.727z'
        fill='#888'
        fillRule='nonzero'
      />
    </svg>
  </Icon>
)

export default Attatchment
