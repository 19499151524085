import { shouldSkipAllSectorsShortcut } from '@/bootstrap/configs/sidebar'
import { NODE_KEY_DELIMITER } from '@/features/ControlPanel/redux/controlPanelOperations'
import { ALL_ALKU_KEY, CUSTOM_TF, DIVISION_KEY, EMPLOYEE_KEY, SIDE_OF_HOUSE_ENUM } from '@/shared/constants/dataKeys'
import { ALL_API, DEFAULT_REPORT_OBJ } from '@/shared/constants/local'
import { DASHBOARD_TAB_TO_QS } from '@/shared/constants/queryString'
import { AlkuTreeNodeType, CalendarMarker, TimeSliderMark } from '@/shared/types'
import { IDivisionRequest, IOneTimeResponse } from '@/shared/types/swagger'
import { getDateKey, QUERY_STRING_DATE_FORMAT } from '@/utils/tools/dateTime'

const mapMarkersToCalendarObj = (date: string, calendarObj: Record<string, Array<CalendarMarker>>, marker: CalendarMarker) => {
  if (date) {
    const dateKey = getDateKey(date)
    if (calendarObj[dateKey]) {
      calendarObj[dateKey].push(marker)
    }
    calendarObj[dateKey] = [marker]
  }
}

export const buildFiscalCalendarMarkers = (oneTimeResponse: IOneTimeResponse) => {
  const calendarMarkers = {}
  if (oneTimeResponse?.['fiscal-dates']) {
    if (oneTimeResponse['fiscal-dates'].months) {
      oneTimeResponse['fiscal-dates'].months.forEach(date => mapMarkersToCalendarObj(date, calendarMarkers, { preset: 'fiscalMonth' }))
    }
    if (oneTimeResponse['fiscal-dates'].quarters) {
      oneTimeResponse['fiscal-dates'].quarters.forEach(date => mapMarkersToCalendarObj(date, calendarMarkers, { preset: 'quarter' }))
    }
  }
  return calendarMarkers
}

/**
 * 
 * @param {String} divisionId 
 * @param {String} personId - optional, if not included it is assumed all people are requested
 * @param {Object} requestObjDivisions { divisionId: { id: divisionId, people: [personId, ...] }, ... }
 */
const generateDivisionRequestObj = (
  divisionId: string,
  personId?: string,
  requestObjDivisions: Partial<IDivisionRequest> = {}
): IDivisionRequest => {
  let people
  if (!personId) {
    people = [ALL_API]
  } else if (!requestObjDivisions[divisionId]) {
    people = [personId]
  } else {
    people = [...requestObjDivisions[divisionId].people, personId]
  }
  return { id: divisionId, people }
}

// this function is temporary until the All shortcut is fixed and we're no longer hiding divisions
const generateeDivisionRequestObjFromDataList = (divisionId: string, dataList: Array<AlkuTreeNodeType>) => {
  const people = dataList.reduce<Array<string>>((acc, nextNode) => {
    // since the dataList should already have anything undersired filtered
    // we only need to worry about finding employee nodes with keys that include the parent division
    if (nextNode.key.includes(divisionId) && nextNode.type === EMPLOYEE_KEY) {
      acc.push(nextNode.id)
    }
    return acc
  }, [])
  return { id: divisionId, people }
}

export const buildReportRequestObj = (checkedKeys: Array<string> = [], dataList: Array<AlkuTreeNodeType>, sideOfHouse?: number) => {
  // https://adkgroup.atlassian.net/browse/AST1-24
  // To generate an accurate report, if we are hiding OCs in any division we should avoid using the "all" shortcut
  // This bit of logic uses the dataList to create a list of all the available people and builds the new request object based off of it
  if (shouldSkipAllSectorsShortcut && checkedKeys.includes(ALL_ALKU_KEY)) {
    const newCheckedKeys = dataList.reduce((personKeys, nextDataNode) => {
      if (nextDataNode.type === EMPLOYEE_KEY) {
        personKeys.push(nextDataNode.key)
      }
      return personKeys
    }, [])
    return buildReportRequestObj(newCheckedKeys, dataList, sideOfHouse)
  }
  if (checkedKeys.includes(ALL_ALKU_KEY)) {
    const reportObj = {
      'side-of-house': sideOfHouse || 0
    }
    if (sideOfHouse === SIDE_OF_HOUSE_ENUM.ALL) {
      Object.assign(reportObj, DEFAULT_REPORT_OBJ)
    } else {
      reportObj['audience-spec'] = {
        divisions: dataList
          .filter((node) => node.type === DIVISION_KEY)
          .map((node) => ({
            id: node.id,
            people: ['all', ...node.otherContributorIds]
          }))
      }
    }
    return reportObj
  } else {
    const checkedDivsisions = checkedKeys.filter((key) => {
      return dataList.find(
        (node) =>
          node.key === key &&
          node.type === DIVISION_KEY
      )
    })
    const requestObjDivisions = checkedKeys.reduce((accDivs, nextKey) => {
      // employee node keys contain the division id of their parent
      // so if the division key which is the division id is already checked we can skip this as it is included by default
      if (
        checkedDivsisions.some((divisionId) => nextKey.includes(divisionId))
      ) {
        return accDivs
      }
      const nodeInfo = dataList.find((node) => node.key === nextKey)
      if (nodeInfo?.type === EMPLOYEE_KEY) {
        const divisionIdIndex = nodeInfo.isOtherContributor ? 1 : 0
        const divisionId = nextKey.split(NODE_KEY_DELIMITER)[divisionIdIndex]
        const divisionObj = generateDivisionRequestObj(
          divisionId,
          nodeInfo.id,
          accDivs
        )
        return {
          ...accDivs,
          [divisionObj.id]: divisionObj
        }
      } else {
        return accDivs
      }
    }, {})
    return {
      'audience-spec': {
        divisions: [
          ...checkedDivsisions.map((id) => {
            return shouldSkipAllSectorsShortcut ? generateeDivisionRequestObjFromDataList(id, dataList)  : generateDivisionRequestObj(id)
          }),
          ...Object.values(requestObjDivisions)
        ]
      },
      'side-of-house': sideOfHouse || 0
    }
  }
}

/**
 * formats timeslider data for report request
 * @param {*} customDateRange 
 * @param {*} customDateRangeEnabled 
 * @param {*} marks 
 * @param {*} timePeriod 
 * @returns 
 */
export const formatTimeSliderData = (
  customDateRange: [string, string],
  customDateRangeEnabled: boolean,
  marks: Record<string, TimeSliderMark>,
  timePeriod: number
): [string, Array<string>] => {
  const timeFrame =
    customDateRangeEnabled && customDateRange
      ? CUSTOM_TF
      : marks?.[timePeriod]?.id
  const formattedDateRange = customDateRangeEnabled &&
    customDateRange &&
    customDateRange[0] &&
    customDateRange[1] && [
    getDateKey(customDateRange[0]),
    getDateKey(customDateRange[1])
  ]
  return [timeFrame, formattedDateRange]
}

export const handleDashboardQueryParamUpdate = (params, dashboard, controlPanel) => {
  const { activeDashboardTabKey, funnel } = dashboard
  const { timeSlider: {
    timePeriod,
    customDateRangeEnabled,
    customDateRange,
    marks
  },
  audienceTree: { searchValue }
  } = controlPanel
  if (DASHBOARD_TAB_TO_QS[activeDashboardTabKey]) {
    params.r = DASHBOARD_TAB_TO_QS[activeDashboardTabKey]
  }
  if (customDateRange && customDateRangeEnabled) {
    params.tf = 'custom'
    params.sd = customDateRange[0].format(QUERY_STRING_DATE_FORMAT)
    params.ed = customDateRange[1].format(QUERY_STRING_DATE_FORMAT)
  } else if (marks[timePeriod]) {
    params.tf = marks[timePeriod].id.toLowerCase()
  }
  if (funnel.activeTarget) {
    params.m = funnel.activeTarget.title.toLowerCase().replace(' ', '-').replace('.', '')
  }
  if (searchValue) {
    params.search = searchValue.replace(' ', '+')
  }
}
