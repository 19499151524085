import { ROW_MOD_KEYS } from '@/shared/constants/local'
import { FoundersReferenceWeekRecord } from '../../helpers/formatFoundersData'

const createTableConfig = (data: Array<FoundersReferenceWeekRecord>) => {

  try {
    if (data) {
      const config = data.map((record, index) => {
        return {
          ...record,
          rowMod: [
            ROW_MOD_KEYS.DATA_DISPLAY
          ],
          cellClassnames: {
            default: `c-type--table-numeric${record.fiscalQuarter === 99 ? ' c-type--semi-bold' : ''}`
          },
          rowClassName: `c-table__row--bordered${record.fiscalQuarter === 99 ? ' c-table__row--indent c-table__row--white-bg' : ''}`,
          key: `${record.referencedWeek}-${index}`,
          addSpacingNext: record.fiscalMonth === 99
        }
      }).filter(v => v)
      return config
    }
    return []
  } catch (e) {
    console.log(e)
    return []
  }
}

export default createTableConfig
