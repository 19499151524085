import React from 'react'

import MonthlyAverageSpread from '../MonthlyAverageSpread'
import WeeklyNetSpread from '../WeeklyNetSpread'
import FounderStatus from '../FoundersStatus'
import MySpreadOvertime from '../MySpreadOvertime'
import DownloadSpread from '../DownloadSpread'
import { Alert } from '@/shared/components/Icons'
import { useAppSelector } from '@/shared/hooks'
const MySpread = () => {
  const userBullHornId = useAppSelector((state) => state.spread.bullhornId)

  return (
    <div className='c-my_spread'>
      <div className='c-spread__header'>
        <h1 className='c-type c-type--title-xl c-type--bold'>My Spread</h1>
        <div className='c-download-button'>
          <DownloadSpread />
        </div>
      </div>
      {userBullHornId && (
        <>
          <div className='c-my_spread__grid'>
            <WeeklyNetSpread />
            <MonthlyAverageSpread />
            <FounderStatus />
          </div>
          <MySpreadOvertime />
        </>
      )}
      {!userBullHornId && (
        <div role='alert' aria-live='polite' className='c-component-error'>
          <Alert position='left' />
          This user does not have associated with any Bullhorn ID
        </div>
      )}
    </div>
  )
}

export default MySpread
