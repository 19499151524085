
import * as manageTargetsAction from './manageTargetsActionsPlain'
import { initialLoad, notifyError } from '@/features/shared/redux/sharedActions'

import { buildAlkuTreeData } from '@/features/ControlPanel/redux/controlPanelOperations'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import { getTargets, saveTargets } from '@/utils/api/manageTargets'
import { RootState } from '@/bootstrap/redux'
import { IUserTarget } from '@/shared/types/swagger'
import {pushEvent}  from '@/bootstrap/gtm/gaEvents'

export const buildManageTargetsTableData = () => (dispatch, getState) => {
  try {
    const { shared: { oneTimeResponse } } = getState()
    const amTreeData = buildAlkuTreeData(oneTimeResponse, null, null, DASHBOARD_TAB_KEYS.AM)[0].children
    const recruitingTreeData = buildAlkuTreeData(oneTimeResponse, null, null, DASHBOARD_TAB_KEYS.RECRUITING)[0].children
    dispatch(manageTargetsAction.setMtAmTableData(amTreeData))
    dispatch(manageTargetsAction.setMtRecruitingTableData(recruitingTreeData))
  } catch (e) {
      //
  }
}

export const fetchTargets = () => async dispatch => {
  dispatch(manageTargetsAction.fetchTargetsBegin())
  try {
    const response = await getTargets()
    dispatch(manageTargetsAction.fetchTargetsSuccess(response))
  } catch (error) {
    dispatch(notifyError('Error fetching target data'))
    console.error(error)
    dispatch(manageTargetsAction.fetchTargetsFail())
  }
}

export const initiaManageTargetsLoad = () => dispatch => {
  const parallelRequestsFn = () => {
    dispatch(fetchTargets())
  }
  const onSuccesFn = () => {
    dispatch(buildManageTargetsTableData())
  }
  dispatch(initialLoad(parallelRequestsFn, onSuccesFn))
}

export const handleSaveTargetsClick = () => async (dispatch, getState) => {
  try {
    const { manageTargets: { savedTargets, data } }: RootState = getState()
    if (savedTargets) {
      dispatch(manageTargetsAction.saveTargetsBegin())

      const updatedTargets = Object.entries(savedTargets).reduce<Array<IUserTarget>>((acc, [userId, updatedMetrics]) => {
        const targetFromData = data.find(v => v['user-id'] === userId)
        if (targetFromData) {
          acc.push({
            ...targetFromData,
            ...updatedMetrics
          })
        }
        return acc
      }, [])

      await saveTargets(updatedTargets)
      dispatch(manageTargetsAction.saveTargetsSuccess(updatedTargets))
      pushEvent({
        event: 'save_targets'
      })
    }
  } catch (error) {
    dispatch(manageTargetsAction.saveTargetsFail())
    console.error(error)
  }
}

export * from './manageTargetsActionsPlain'
