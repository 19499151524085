import { SortOrder } from 'antd/lib/table/interface'
import { MetricKey } from '../../shared/types'
import { IAggregatedDataItem } from '../../shared/types/swagger'

type Record = { [key in MetricKey]: IAggregatedDataItem } &
  {
    isOtherContributor?: boolean
    [key: string]: unknown
  }

export const sortRecordByLastName = (a: Record, b: Record, sortOrder: SortOrder) => {
  if (a.isOtherContributor !== b.isOtherContributor) {
    return sortOtherContributorsBelow(a, b, sortOrder)
  }
  const nameA = (typeof a['last-name'] === 'string' ? a['last-name'] : '').toUpperCase() // ignore upper and lowercase
  const nameB = (typeof b['last-name'] === 'string' ? b['last-name'] : '').toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  // names must be equal
  return 0
}

export const sortRecordByMetric = (a: Record, b: Record, sortOrder: SortOrder, metricKey: MetricKey) => {
  if (a.isOtherContributor !== b.isOtherContributor) {
    return sortOtherContributorsBelow(a, b, sortOrder)
  }
  const aMetric = a[metricKey]?.actual || 0
  const bMetric = b[metricKey]?.actual || 0
  return aMetric - bMetric
}

export const sortRecordByLocaleCompare = (a: Record, b: Record, sortOrder: SortOrder, stringKey: string) => {
  if (a.isOtherContributor !== b.isOtherContributor) {
    return sortOtherContributorsBelow(a, b, sortOrder)
  }
  const aString = typeof a[stringKey] === 'string' ? a[stringKey] as string : ''
  const bString = typeof b[stringKey] === 'string' ? b[stringKey] as string : ''
  return aString.localeCompare(bString)
}

const sortOtherContributorsBelow = (a: Record, b: Record, sortOrder: SortOrder) => {
  if (a.isOtherContributor === b.isOtherContributor) {
    return 0
  }
  if (sortOrder === 'descend') {
    if (a.isOtherContributor) return -1
    if (b.isOtherContributor) return 1
  }
  if (a.isOtherContributor) return 1
  if (b.isOtherContributor) return -1
}
