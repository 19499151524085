import React from 'react'
import fireEmoji from '@/shared/assets/emojis/fire-emoji.png'
import partyFaceEmoji from '@/shared/assets/emojis/party-face-emoji.png'
import rockOnEmoji from '@/shared/assets/emojis/rock-on-emoji.png'
import partyPopperEmoji from '@/shared/assets/emojis/party-popper-emoji.png'

const emojiDictionary = {
  fire: { src: fireEmoji, alt: 'fire emoji' },
  partyFace: { src: partyFaceEmoji, alt: 'party face emoji' },
  rockOn: { src: rockOnEmoji, alt: 'rock on emoji' },
  partyPopper: { src: partyPopperEmoji, alt: 'party popper emoji' }
}

const Emoji = ({ type, ...props }) => {
  const chosenEmojiProps = emojiDictionary[type]
  if (chosenEmojiProps) {
    // eslint-disable-next-line jsx-a11y/alt-text
    return <img {...props} {...chosenEmojiProps} />
  }
  return null
}

export default Emoji
