import { gql } from '@apollo/client'

export interface IconixxRecord {
  id: string
  week: string
  customerProgramId: string
  client: string
  consultant: string
  netSpread: number
  recruiterId: string
  recruiter: string
  salespersonId: string
  salesperson: string
  department: string
  rTC: string
  bDM: string
  startDate: string
  endDate: string
}

export interface IconixxTableData {
  allIconixx: IconixxRecord[]
}

export const GET_ICONIXX_TABLE_QUERY = `
  allIconixx
  {
    id
    week
    customerProgramId
    client
    consultant
    netSpread
    recruiterId
    recruiter
    salespersonId
    salesperson
    department
    rTC
    bDM
    startDate
    endDate
  } 
`
export const GET_ICONIXX_TABLE_NAMED_QUERY = gql`
  query IconixxTable {
   ${GET_ICONIXX_TABLE_QUERY}
  }
`
