import React, { useEffect } from 'react'
import { Tabs } from 'antd'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import ManageTargetsTable from '@/features/ManageTargets/components/ManageTargetsTable'
import { selectMtAmTableData, selectMtRecruitingTableData } from '@/features/ManageTargets/redux/manageTargetsSelectors'
import {
  accountManagementManageTargetsColumnConfig,
  recruitingManageTargetsColumnConfig
} from '@/features/ManageTargets/components/ManageTargetsTable/helpers/columnConfigs'
import { initiaManageTargetsLoad } from '@/features/ManageTargets/redux/manageTargetsActions'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import ManageTargetsFooter from '@/features/ManageTargets/components/ManageTargetsFooter'
import renderTargetTabBar from '@/features/ManageTargets/components/ManageTargetsTable/helpers/renderTargetTabBar'

const Targets = () => {
  const dispatch = useAppDispatch()
  const recruitingTableData = useAppSelector(selectMtRecruitingTableData)
  const amTableData = useAppSelector(selectMtAmTableData)

  useEffect(() => {
    dispatch(initiaManageTargetsLoad())
  }, [dispatch])

  return (
    <>
      <div className='c-screen'>
        <div className='c-manage-targets'>
          <h1 className='c-manage-targets__title'>
            Manage Weekly Targets
          </h1>
          <p className='c-manage-targets__description'>
            Please set weekly activity targets for each of individuals you
            manage. <br></br>
            All fields must include a number larger than 0. You can use
            decimals. Enter NA into non applicable fields.
          </p>
          <Tabs
            defaultActiveKey={DASHBOARD_TAB_KEYS.AM}
            renderTabBar={renderTargetTabBar}
          >
            <Tabs.TabPane tab='Account Management' key={DASHBOARD_TAB_KEYS.AM}>
              <ManageTargetsTable
                tableData={amTableData}
                columnConfig={accountManagementManageTargetsColumnConfig}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Recruiting' key={DASHBOARD_TAB_KEYS.RECRUITING}>
              <ManageTargetsTable
                tableData={recruitingTableData}
                columnConfig={recruitingManageTargetsColumnConfig}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
      <ManageTargetsFooter />
    </>
  )
}

export default Targets
