import { gql } from '@apollo/client'

export type LastUpdatedData = {
  weeklySpreadLastUpdated: {
    lastUpdated: string
  }
}

export const GET_LAST_UPDATED_QUERY = gql`
  query WeeklySpreadLastUpated {
    weeklySpreadLastUpdated {
      lastUpdated
    }
  }
`

export type FoundersDate = {
  foundersDate: string
}

export const GET_FOUNDERS_DATE = gql`
  query GetFoundersDate {
    foundersDate
  }
`
