import React, { useCallback } from 'react'
import { Skeleton as AntSkeleton } from 'antd'

import { PRESET_1, PRESET_2, PRESET_3 } from './SkeletonTableConfigPresets'
import SkeletonTableHeader from './SkeletonTableHeader'
import SkeletonTableRow from './SkeletonTableRow'
import SkeletonTableSubRow from './SkeletonTableSubrow'

const SkeletonTable = ({ active = false, config, children, loading = false, title = false, hideMainHeader = false, scrollable = false }) => {
  const renderRow = useCallback(({ firstCellInputSize, subrows, titleOnly, hideSubrowAvatars, columnConfig }, index) => {
    const subRowArr = []
    if (subrows) {
      for (let i = 0; i < subrows; i++) {
        subRowArr.push(i)
      }
    }
    return (
      <React.Fragment key={index}>
        <SkeletonTableRow active={active} firstCellInputSize={firstCellInputSize} titleOnly={titleOnly} columnConfig={columnConfig} />
        {subRowArr.map(i => <SkeletonTableSubRow key={i} active={active} hideAvatar={hideSubrowAvatars} columnConfig={columnConfig} />)}
      </React.Fragment>
    )
  }, [active])
  const renderSkeleton = useCallback(() => {
    return (
      <div className={`c-table-skeleton${scrollable ? ' c-table-skeleton--scrollable' : ''}`}>
        {title && (
          <AntSkeleton.Input style={{ width: 379, height: 24, marginBottom: 26 }} size='large' />
        )}
        {config && (
          <>
            {!hideMainHeader && <SkeletonTableHeader active={active} />}
            {config.map(renderRow)}
          </>
        )}
      </div>
    )
  }, [active, config, renderRow, title, hideMainHeader, scrollable])

  return (
    <>
      {loading ? renderSkeleton() : children}
    </>
  )
}

SkeletonTable.PRESET_1 = PRESET_1
SkeletonTable.PRESET_2 = PRESET_2
SkeletonTable.PRESET_3 = PRESET_3

export default SkeletonTable
