import React from 'react'
import {
  SpreadSummaryTableDivisions,
  SpreadSummaryTableSeniors,
  SpreadSummaryTableTrainingCenters
} from '@/features/Spread/components/SpreadSummaryTable'
const SpreadSummary = () => {
  return (
    <div className='c-spread-summary'>
      <div className='c-spread__header'>
        <h1 className='c-type c-type--title-xl c-type--bold'>
          ALKU Spread Summary
        </h1>
      </div>
      <div className='c-spread-summary__grid'>
        <div className='card'>
          <h1>Total Paid Hours</h1>
          <h2>20,687.50</h2>
        </div>
        <div className='card'>
          <h1>Total Net Spread</h1>
          <h2>$902,153</h2>
        </div>
        <div className='card card3'>
          <h1>Company Founders Goal</h1>
          <div className='progress-bar-container'>
            <div className='progress-bar'>
              <div className='progress-bar__fill' style={{ width: `12%` }} />
            </div>
            <div className='progress-bar-caption'>
              <span>12%</span> <b>$902K</b> of <b>95M</b>
            </div>
          </div>
        </div>
      </div>
      <SpreadSummaryTableDivisions />
      <SpreadSummaryTableTrainingCenters />
      <SpreadSummaryTableSeniors />
    </div>
  )
}

export default SpreadSummary
