import * as dashboardActions from './dashboardActionsPlain'
import { clearAtSearch, resetTreeToMyPeople, setAudienceTreeData } from '@/features/ControlPanel/redux/controlPanelActions'
import { initialLoad, updateQueryParamsByState } from '@/features/shared/redux/sharedActions'
import { ALL_ALKU_KEY } from '@/shared/constants/dataKeys'
import { RootState } from '@/bootstrap/redux'

export const prtExpandAllBrandRows = () => (dispatch, getState) => {
  try {
    const { shared: { oneTimeResponse } } = getState()
    if (oneTimeResponse?.divisions) {
      const brandKeys = Object.values(oneTimeResponse.divisions).map(div => div['brand-name']).filter((key, index, self) => key && self.indexOf(key) === index)
      const expandObj = {
        [ALL_ALKU_KEY]: brandKeys
      }
      dispatch(dashboardActions.setPrtExpandedKeysObj(expandObj))
    }
  } catch (e) {
    //
  }
}

export const onDashboardTabChange = (tab: string) => dispatch => {
  dispatch(clearAtSearch())
  dispatch(setAudienceTreeData([]))
  dispatch(dashboardActions.setShowDashboardLoader(true))
  dispatch(dashboardActions.setActiveDashboardTabKey(tab))
  dispatch(resetTreeToMyPeople())
  dispatch(updateQueryParamsByState())
}

export const initialDashboardLoad = () => (dispatch, getState) => {
  const { shared: { initialLoad: firstLoad } }: RootState = getState()
  if (!firstLoad) {
    const onSuccesFn = () => {
      dispatch(resetTreeToMyPeople())
      dispatch(prtExpandAllBrandRows())
    }
    dispatch(initialLoad(null, onSuccesFn))
  }
}

export * from './dashboardActionsPlain'
