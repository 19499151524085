import React from 'react'
import Icon, { IconPositionType } from './Icon'

type DownloadProps = {
  position?: IconPositionType
}

const Download = ({ position }: DownloadProps) => (
  <Icon position={position}>
    <svg
      width='16'
      height='16'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M6.4.4v6.8H3.2L8 12l4.8-4.8H9.6V.4a.4.4 0 00-.4-.4H6.8a.4.4 0 00-.4.4zM0 14.4V16h16v-1.6H0z'
          id='prefix__a--download'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b--download' fill='#fff'>
          <use xlinkHref='#prefix__a--download' />
        </mask>
        <use fill='#404555' fillRule='nonzero' xlinkHref='#prefix__a--download' />
        <path fill='#C2C4C9' mask='url(#prefix__b--download)' d='M0 0h16v16H0z' />
      </g>
    </svg>
  </Icon>
)

export default Download
