import React from 'react'
import classNames from 'classnames'

import CalnderMarkerGroup from './CalnderMarkerGroup'
import presetsConfigObj from './helpers/presets'

const CalendarMarker = ({ text, bgColor = 'red', textColor = 'white', preset }) => {
  const presetObj = presetsConfigObj[preset]
  const calendarMarkerClassname = classNames(
    'c-calendar-marker',
    `u-bg-${presetObj?.bgColor || bgColor}`,
    `u-color-${presetObj?.textColor || textColor}`
  )

  return (
    <div className={calendarMarkerClassname}>
      {presetObj?.text || text}
    </div>
  )
}

CalendarMarker.Group = CalnderMarkerGroup

export default CalendarMarker
