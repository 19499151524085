import { getActionTypes, getMultiActionTypes } from '@/bootstrap/redux/action';

const prefix = 'SHARED';

const unitTypes = getActionTypes(
  [
    'SET_FISCAL_CALENDAR_MARKERS',
    'SET_INITIAL_LOAD',
    'ADD_TO_GRAFANA_QUEUE',
    'CLEAR_GRAFANA_QUEUE',
    'SET_SSO_ERROR',
    'PUSH_NOTICE',
    'DISMISS_NOTICE',
    'SET_SHOW_SERVICE_ERROR_SCREEN',
    'DISMISS_NOTICE_BY_DUPLICATE_ID',
    'CLEAR_NOTICES',
    'SET_ALL_REPORT_RESPONSE',
    'SET_QS_PARSING',
    'SET_SERVICE_ERROR_MESSAGE'
  ],
  prefix
)

const multiTypes = getMultiActionTypes(
  [
    'FETCH_REPORT',
    'FETCH_ENTITY_CONTEXT',
    'FETCH_ITEMIZATION_REPORT'
  ],
  prefix,
)

const sharedTypes = {
  ...unitTypes,
  ...multiTypes,
}

export default sharedTypes
