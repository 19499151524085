import React from 'react'
import CalendarMarker from '../../CalendarMarker'

// eslint-disable-next-line react/display-name
export default function renderCalendarMarkers(markers) {
  return (
    <CalendarMarker.Group>
      {Array.isArray(markers) ? markers.slice().reverse().map((v, i) => <CalendarMarker key={i} {...v} />) : <CalendarMarker {...markers} />}
    </CalendarMarker.Group>
  )
}
