const getValue = (record, dataIndex) => {
  if (Array.isArray(dataIndex)) {
    return dataIndex.reduce((value, nextDataIndex) => {
      if (value && value[nextDataIndex]) {
        return value[nextDataIndex]
      } else {
        return 0
      }
    }, record)
  } else {
    return record?.[dataIndex] || 0
  }
}

export default getValue
