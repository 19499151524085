import React, { useCallback } from 'react'
import { Skeleton as AntSkeleton } from 'antd'
import { PRESET_1 } from './SkeletonTreeConfigPresets'

const SkeletonTree = ({
  active = false,
  loading = false,
  config,
  children
}) => {
  const renderSkeletonTreeNode = useCallback(
    ({ width, children }, key) => (
      <React.Fragment key={key}>
        {children ? (
          <div className='c-tree-skeleton__branch'>
            <div className='c-tree-skeleton__leaf'>
              <AntSkeleton.Input
                style={{ width: width || 100, height: 15 }}
                active={active}
                size={'small'}
              />
              {children && children.map(renderSkeletonTreeNode)}
            </div>
          </div>
        ) : (
          <div className='c-tree-skeleton__leaf'>
            <AntSkeleton.Input
              style={{ width: width || 100, height: 15 }}
              active={active}
              size={'small'}
            />
          </div>
        )}
      </React.Fragment>
    ),
    [active]
  )
  const renderSkeleton = useCallback(() => {
    return (
      <div className='c-tree-skeleton'>
        {config && config.map(renderSkeletonTreeNode)}
      </div>
    )
  }, [config, renderSkeletonTreeNode])
  return (
    <>
      {loading ? renderSkeleton() : children}
    </>
  )
}

SkeletonTree.PRESET_1 = PRESET_1

export default SkeletonTree
