import React from 'react'

const CalendarIcon = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='12.000000pt'
      height='12.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        fill='#000000'
        stroke='none'
      >
        <path
          d='M240 880 c0 -39 -1 -40 -35 -40 -25 0 -44 -8 -60 -25 l-25 -24 0
-311 0 -311 25 -24 24 -25 311 0 311 0 24 25 25 24 0 311 0 311 -25 24 c-16
17 -35 25 -60 25 -34 0 -35 1 -35 40 l0 40 -40 0 -40 0 0 -40 0 -40 -160 0
-160 0 0 40 0 40 -40 0 -40 0 0 -40z m520 -160 l0 -40 -280 0 -280 0 0 40 0
40 280 0 280 0 0 -40z m0 -320 l0 -200 -280 0 -280 0 0 200 0 200 280 0 280 0
0 -200z'
        />
      </g>
    </svg>
  </div>
)

export default CalendarIcon
