export type SpreadDepartmentMapping = {
  brand: string
  department: string
  splitBy: string
  subBrand: string
  enable: boolean
}

export type DepartmentMappingsData = {
  spreadDivisionMappings: Array<SpreadDepartmentMapping>
}

export const GET_DEPARTMENT_MAPPINGS_QUERY = `
  spreadDivisionMappings {
    brand
    department
    splitBy
    subBrand
    enable
  }
`