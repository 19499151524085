import { SPREAD_WEEK_KEY, SpreadMetricKeys } from '@/shared/constants/dataKeys'
import { BDE_WIDTH, COLUMN_MOD_KEYS } from '@/shared/constants/local'

export type MetricsConfig = {
  title: string
  dataIndex: string[] | string
  key: string
  columnMod: string[]
  sortOrder: number
  align?: string
  ellipsis?: boolean
  width?: string
}

const generateMetricsConfig = (
  title: string,
  key: string,
  sortOrder: number,
  columnMods = []
): MetricsConfig => ({
  title,
  dataIndex: key,
  key: key,
  align: 'right',
  columnMod: columnMods,
  sortOrder: sortOrder
})

/**
 * Creates columnConfig used by antd table component
 * @param {boolean} includeGovernment - whether or not to include government specific colum configs
 * @return {object[]} - Array of column config objects
 */
const spreadOverTime: MetricsConfig[] = [
  {
    title: 'Timeframe',
    dataIndex: SPREAD_WEEK_KEY,
    key: SPREAD_WEEK_KEY,
    width: BDE_WIDTH,
    columnMod: [COLUMN_MOD_KEYS.TITLE, COLUMN_MOD_KEYS.PARTY_ICON],
    sortOrder: 0
  },
  generateMetricsConfig(
    'Weekly Spread',
    SpreadMetricKeys.SPREAD_WEEKLY_SPREAD as string,
    1,
    [COLUMN_MOD_KEYS.CURRENCY]
  ),
  generateMetricsConfig('GPM', SpreadMetricKeys.SPREAD_GPM as string, 2, [
    COLUMN_MOD_KEYS.PERCENT
  ]),
  generateMetricsConfig('Hours', SpreadMetricKeys.SPREAD_HOURS as string, 3, [
    COLUMN_MOD_KEYS.METRIC
  ]),
  generateMetricsConfig(
    'Time Cards Paid',
    SpreadMetricKeys.SPREAD_TIME_CARDS_PAID as string,
    4,
    [COLUMN_MOD_KEYS.METRIC]
  )
]

export const generateConfig = () => {
  let config: MetricsConfig[] = []
  config = spreadOverTime
  return config
}
