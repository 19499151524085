// @ts-strict

import React, { useState, useCallback, useEffect } from 'react'
import { Tabs } from 'antd'

import createTableConfig from './helpers/createTableConfig'
import onDataLoad, { getColumnDataFromSource } from './helpers/onDataLoad'
import { GET_FOUNDERS_TABLE_NAMED_QUERY } from './helpers/query'
import TableHandler from '../TableHandler'
import { Select } from '@/shared/components'
import createColumnConfig from './helpers/createColumnConfigs'
import { useAppDispatch } from '@/shared/hooks'
import { setReportHeader } from '@/features/Spread/redux/spreadSlice'

type Props = {
  isActive: boolean
}

const TAB_KEYS = {
  SALES: 'sales',
  RECRUITING: 'recruiting'
}

type InitialDataFromLoadType = { sales: Record<string, unknown>, recruiting: Record<string, unknown> }

const FoundersTable = ({ isActive }: Props) => {
  const dispatch = useAppDispatch()
  const [dataFromLoad, setDataFromLoad] = useState<ReturnType<typeof onDataLoad> | InitialDataFromLoadType>({ sales: {}, recruiting: {} })
  const [data, setData] = useState(null)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [columnDataSource, setColumnDataSource] = useState({})

  const setHeaderTimeRange = useCallback((range) => {
    const header = <span>{'Founder\'s Report Totals for:'} <span className='c-type--semi-bold'>{range}</span></span>
    dispatch(setReportHeader(header))
  }, [dispatch])

  useEffect(() => {
    if (isActive && dataFromLoad?.sales?.headerDate) {
      setHeaderTimeRange(dataFromLoad.sales.headerDate)
    }
  }, [setHeaderTimeRange, isActive, dataFromLoad])

  const onLoad = useCallback((data) => {
    const { recruiting, sales }  = onDataLoad(data)
    setDataFromLoad({ sales, recruiting })
    setData(sales.data)
    setColumnDataSource(sales.columnDataSource)
    setOptions(sales.options)
    setSelectedOption(sales.options[0].value)
    setHeaderTimeRange(sales.headerDate)
  }, [setHeaderTimeRange])

  const onTabChange = useCallback(activeKey => {
    setData(dataFromLoad[activeKey].data)
    setColumnDataSource(dataFromLoad[activeKey].columnDataSource)
    setOptions(dataFromLoad[activeKey].options)
    setSelectedOption(dataFromLoad[activeKey].options[0].value)
    setHeaderTimeRange(dataFromLoad[activeKey].headerDate)
  }, [dataFromLoad, setHeaderTimeRange])

  const tableConfig = createTableConfig(data)
  const columnConfig = createColumnConfig(getColumnDataFromSource(selectedOption, columnDataSource))

  return (
    <>
      {
        options.length ? (
          <div className='c-spread__table-filters'>
            <Select defaultValue={options[0].value} onChange={setSelectedOption} style={{ width: 214 }} options={options} value={selectedOption} />
            <div className='c-spread__soh-tabs c-audience-tree__shortcuts'>
              <Tabs defaultActiveKey={TAB_KEYS.SALES} onChange={onTabChange} centered>
                <Tabs.TabPane tab='Sales' key={TAB_KEYS.SALES} />
                <Tabs.TabPane tab='Recruiting' key={TAB_KEYS.RECRUITING} />
              </Tabs>
            </div>
          </div>
        ) : null
      }
      <TableHandler
        isActive={isActive}
        columnConfig={columnConfig}
        onDataLoad={onLoad}
        queryString={GET_FOUNDERS_TABLE_NAMED_QUERY}
        tableConfig={tableConfig}
        errorMessage={'Error fetching Founder\'s report.'}
        dataPath={['allFounders']}
      />
    </>
  )
}

export default FoundersTable
