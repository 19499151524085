// @ts-strict

import React, { useState, useCallback, useEffect } from 'react'
import columnConfig from './helpers/columnConfig'

import createTableConfig from './helpers/createTableConfig'
import onDataLoad, {
  OnDataLoadReturn,
  getDataFromOptionValue,
  getTargetDataFromOptionValue
} from './helpers/onDataLoad'
import { GET_TOTALS_TABLE_NAMED_QUERY } from './helpers/query'
import TableHandler from '../TableHandler'
import { Select } from '@/shared/components'
import { useAppDispatch } from '@/shared/hooks'
import { setReportHeader } from '../../redux/spreadSlice'
import { formatTableDate } from '@/utils/tools/dateTime'

type Props = {
  isActive: boolean
}

const TotalsTable = ({ isActive }: Props) => {
  const dispatch = useAppDispatch()
  const [data, setData] = useState(null)
  const [targetsData, setTargetsData] = useState(null)
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null)
  const [reportHeaderText, setReportHeaderText] = useState(null)

  useEffect(() => {
    if (isActive && reportHeaderText) {
      dispatch(setReportHeader(reportHeaderText))
    }
  }, [isActive, dispatch, reportHeaderText])

  const formatReportHeaderData = useCallback(
    ({
      endDate,
      startDate,
      firstWeek,
      lastWeek
    }: OnDataLoadReturn['reportHeaderData']) => {
      const timeRange = `${formatTableDate(startDate)} - ${formatTableDate(
        endDate
      )}`
      const week = `${firstWeek}${lastWeek ? `-${lastWeek}` : ''}`
      return (
        <span>
          Spread Totals for:{' '}
          <span className='c-type--semi-bold'>
            Week {week}: {timeRange}
          </span>
        </span>
      )
    },
    []
  )

  const onLoad = useCallback(
    (data) => {
      const {
        configData,
        options: _options,
        targetData,
        reportHeaderData
      }: OnDataLoadReturn = onDataLoad(data)
      setData(configData)
      setTargetsData(targetData)
      setOptions(_options)
      setSelectedOption(_options[0].value)
      const reportHeader = formatReportHeaderData(reportHeaderData)
      setReportHeaderText(reportHeader)
    },
    [formatReportHeaderData, setReportHeaderText]
  )

  const tableConfig = createTableConfig(
    getDataFromOptionValue(selectedOption, data),
    getTargetDataFromOptionValue(selectedOption, targetsData)
  )

  return (
    <>
      {options.length ? (
        <div>
          <Select
            defaultValue={options[0].value}
            onChange={setSelectedOption}
            style={{ width: 214 }}
            options={options}
          />
        </div>
      ) : null}
      <TableHandler
        isActive={isActive}
        columnConfig={columnConfig}
        onDataLoad={onLoad}
        queryString={GET_TOTALS_TABLE_NAMED_QUERY}
        tableConfig={tableConfig}
        errorMessage='Error fetching Totals report.'
        dataPath={['totalSummary']}
      />
    </>
  )
}

export default TotalsTable
