import React, { useCallback } from 'react'
import { Tabs } from 'antd'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { selectActiveDashboardTabKey } from '@/features/dashboard/redux/dashboardSelectors'
import { onDashboardTabChange } from '@/features/dashboard/redux/dashboardActions'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import LastUpdated from '../LastUpdated'

const DashboardTabs = () => {
  const dispatch = useAppDispatch()
  const activeKey = useAppSelector(selectActiveDashboardTabKey)
  const onTabChange = useCallback(v => {
    dispatch(onDashboardTabChange(v))
  }, [dispatch])
  return (
    <div className='c-dashboard__tabs'>
      <Tabs defaultActiveKey={DASHBOARD_TAB_KEYS.AM} activeKey={activeKey} onChange={onTabChange}>
        <Tabs.TabPane tab='Account Management' key={DASHBOARD_TAB_KEYS.AM} />
        <Tabs.TabPane tab='Recruiting' key={DASHBOARD_TAB_KEYS.RECRUITING} />
      </Tabs>
      <LastUpdated />
    </div>
  )
}

export default DashboardTabs
