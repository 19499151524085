import React, { useCallback, useState } from 'react'
import DownloadOutlined from '@/shared/components/Icons/DownloadOutlined'

import saveSpreadFile from '@/features/Spread/components/helpers/generateSpreadWorkbook'

import { GET_SPREAD_EXPORT_QUERY } from '../helpers/spreadQuery'
import { useAppDispatch } from '@/shared/hooks'
import { notify } from '@/features/shared/redux/sharedActions'
import { useLazyQuery } from '@apollo/client/react'
import { Button } from 'antd'

const DownloadSpread = () => {
  const dispatch = useAppDispatch()
  const [downloadProcessing, setDownloadProcessing] = useState(false)

  const onComplete = useCallback(() => {
    setDownloadProcessing(false)
    dispatch(
      notify('Your file has been successfully downloaded.', {
        success: true,
        style: { width: 460 },
        clearOtherNotices: true,
        clearDuplicates: true,
        timeout: 5000
      })
    )
  }, [dispatch])

  const onError = useCallback(
    (error) => {
      console.error(error)
      setDownloadProcessing(false)
      dispatch(
        notify('There was an error downloading your file.', {
          error: true,
          style: { width: 460 },
          clearOtherNotices: true,
          clearDuplicates: true
        })
      )
    },
    [setDownloadProcessing, dispatch]
  )

  const onSaveSpreadFile = (data) => {
    setDownloadProcessing(true)
    saveSpreadFile(data, {
      onComplete,
      onError
    })
  }

  const [
    getSpreadExportData,
    { called: spreadExportCalled, loading: spreadExportLoading }
  ] = useLazyQuery(GET_SPREAD_EXPORT_QUERY, {
    onCompleted: onSaveSpreadFile,
    onError
  })

  const queryExecuted = spreadExportCalled && spreadExportLoading
  const disabledDownload = false
  const btnLoading = downloadProcessing || queryExecuted

  return (
    <Button
      onClick={(e) => getSpreadExportData()}
      disabled={disabledDownload}
      loading={btnLoading}
    >
      <>
        {!btnLoading && <DownloadOutlined fill='dark-grey' />}
        <span>Download Full Spread</span>
      </>
    </Button>
  )
}

export default DownloadSpread
