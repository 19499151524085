import React from 'react'
import { Skeleton as AntSkeleton } from 'antd'

const SkeletonTableHeader = ({ active }) => {
  return (
    <div className='c-table-skeleton__header'>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--header'>
        <AntSkeleton.Input style={{width: 206}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--header'>
        <AntSkeleton.Input style={{width: 75}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--header'>
        <AntSkeleton.Input style={{width: 75}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--header'>
        <AntSkeleton.Input style={{width: 75}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--header'>
        <AntSkeleton.Input style={{width: 75}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--header'>
        <AntSkeleton.Input style={{width: 75}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--header'>
        <AntSkeleton.Input style={{width: 75}} active={active} size={'small'} />
      </div>
    </div>
  );
};

export default SkeletonTableHeader
