import React from 'react'
import { Progress } from 'antd';

const ProgressTracker = ({ percent, status }) => {
  const strokeColor = status === 'exception' ? '#A00E20' : '#1890FF'

  return (
    <div className='c-upload-button__progress'>
      <Progress
        trailColor='#CCCCCC'
        strokeColor={strokeColor}
        strokeWidth={2}
        strokeLinecap='square'
        showInfo={false}
        percent={percent}
      />
    </div>
  )
}

export default ProgressTracker
