import React from 'react';
import { Skeleton as AntSkeleton, Space } from 'antd'

const SkeletonTableSubrow = ({ active, hideAvatar = false, columnConfig }) => {
  if (columnConfig) {
    return (
      <div className='c-table-skeleton__sub-row'>
        {columnConfig.map(({ inputSize, headerInputSize }, index) => (
          <div
            key={index}
            className='c-table-skeleton__cell c-table-skeleton__cell--sub-row'
            style={{ width: Math.max(inputSize, headerInputSize || inputSize) }}
          >
            {index === 0 && !hideAvatar && (
              <AntSkeleton.Avatar active={active} />
            )}
            <AntSkeleton.Input
              style={{ width: inputSize }}
              active={active}
              size={'small'}
            />
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className='c-table-skeleton__sub-row'>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--sub-row' style={{paddingLeft: '4rem', flexBasis: '25%'}}>
        <Space>
          {!hideAvatar && <AntSkeleton.Avatar active={active} />}
          <AntSkeleton.Input style={{width: 130}} active={active} size={'small'} />
        </Space>
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--sub-row'>
        <AntSkeleton.Input style={{width: 65}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--sub-row'>
        <AntSkeleton.Input style={{width: 65}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--sub-row'>
        <AntSkeleton.Input style={{width: 65}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--sub-row'>
        <AntSkeleton.Input style={{width: 65}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--sub-row'>
        <AntSkeleton.Input style={{width: 65}} active={active} size={'small'} />
      </div>
      <div className='c-table-skeleton__cell c-table-skeleton__cell--sub-row'>
        <AntSkeleton.Input style={{width: 65}} active={active} size={'small'} />
      </div>
    </div>
  );
};

export default SkeletonTableSubrow
