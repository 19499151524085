// @ts-strict
import { OptionProps } from 'antd/lib/select'

import groupDepartmentBrands, { BrandDepartmentDataRecord } from '../../helpers/groupDepartmentBrands'
import groupMonthlyTargetData, { FiscalMonthTargets } from '../../helpers/groupMonthlyTargetData'
import { TotalsTableData } from './query'

const DELIMITER = '____'

interface Option extends Partial<OptionProps> {
  selectLabel?: string
}

type ReportHeaderData = {
  firstWeek: number
  lastWeek: number
  startDate: string
  endDate: string
}

export type OnDataLoadReturn = {
  configData: Record<string, BrandDepartmentDataRecord>
  options: Option[]
  targetData: Record<string, FiscalMonthTargets>
  reportHeaderData: ReportHeaderData
}

export const getDataFromOptionValue = (value: string, data) => {
  if (data && value) {
    const indexArray = value.split(DELIMITER)

    let selectedData

    indexArray.forEach(val => {
      if (!selectedData) {
        selectedData = data[val]
      } else {
        selectedData = selectedData[val]
      }
    })

    return selectedData
  }

  return []
}

export const getTargetDataFromOptionValue = (value: string, data) => {
  if (data && value) {
    const indexArray = value.split(DELIMITER).filter(v => v !== 'mergedData')

    return data[indexArray[indexArray.length - 1]]
  }

  return {}
}

const onDataLoad = (data: TotalsTableData): OnDataLoadReturn => {
  const {
    totalSummary,
    allDepartmentSummary,
    allMonthlyTargets,
    spreadDivisionMappings
  } = data
  const groupedMonthlyTargets = groupMonthlyTargetData(spreadDivisionMappings, allMonthlyTargets)
  const groupedDepartmentBrands = groupDepartmentBrands(spreadDivisionMappings, allDepartmentSummary)
  const configData =  {
    'All ALKU': {
      mergedData: totalSummary
    },
    ...groupedDepartmentBrands
  }

  const reportHeaderData = totalSummary.reduce((acc, nextRecord) => {
    if (!acc.endDate && nextRecord.endDate) {
      acc.endDate = nextRecord.endDate
    }
    if (!acc.startDate && nextRecord.startDate) {
      acc.startDate = nextRecord.startDate
    }
    if ((!acc.firstWeek && nextRecord.fiscalWeek) || (Number(nextRecord.fiscalWeek) !== 0 && Number(nextRecord.fiscalWeek) < acc.firstWeek)) {
      acc.firstWeek = Number(nextRecord.fiscalWeek)
      acc.startDate = nextRecord.startDate
    }
    if ((!acc.firstWeek && nextRecord.fiscalWeek) || (Number(nextRecord.fiscalWeek) > acc.lastWeek)) {
      acc.lastWeek = Number(nextRecord.fiscalWeek)
      acc.endDate = nextRecord.endDate
    }
    return acc
  }, { firstWeek: 0, lastWeek: 0, endDate: '', startDate: '' })

  const optionsFromConfigData = Object.entries(configData).reduce<Option[]>((acc, [brand, brandData]) => {
    if (brand && brandData.mergedData) {
      acc.push({
        label: brand,
        value: `${brand}${DELIMITER}mergedData`,
        selectLabel: brand,
        className: 'c-type--bold'
      })
    }

    Object.entries(brandData).forEach(([department, data]) => {
      if (department !== 'mergedData') {
        acc.push({
          label: department,
          value: `${brand}${DELIMITER}${department}`,
          selectLabel: `${brand ? `${brand} / ` : ''}${department}`,
          className: 'c-option--indent'
        })
      }
    })

    return acc
  }, [])

  return {
    configData,
    options: optionsFromConfigData,
    targetData: groupedMonthlyTargets,
    reportHeaderData
  }
}

export default onDataLoad