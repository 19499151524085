import React from 'react'
import { Select as AntdSelect } from 'antd'
import { OptionProps as AntdOptionProps, SelectProps, SelectValue } from 'antd/lib/select'
import DownCarat from '../Icons/DownCarat'

const { Option } = AntdSelect

interface OptionProps extends AntdOptionProps {
  selectLabel?: string
}

interface Props<T extends SelectValue> extends SelectProps<T> {
  options: OptionProps[]
}

const renderOptions = (options: OptionProps[]) => {
  if (!options.length) return null
  return options.map(({ label, value, className, selectLabel }) => {
    return (
      <Option key={value} value={value} className={`c-option${className ? ` ${className}` : ''}`} label={selectLabel || label}>
        {label}
      </Option>
    )
  })
}

const Select = <T extends SelectValue,>(props: Props<T>) => {
  const { options, ...restProps } = props

  return (
    <div className='c-select'>
      <AntdSelect suffixIcon={DownCarat} optionLabelProp='label' {...restProps} dropdownClassName={'c-select__dropdown'}>
        {renderOptions(options)}
      </AntdSelect>
    </div>
  )
}

export default Select
