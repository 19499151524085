import { EMPLOYEE_KEY } from '@/shared/constants/dataKeys'
import { ROW_MOD_KEYS } from '@/shared/constants/local'

const rowTypes = {
  employee: 'employee'
}

const rowModMapper = {
  [rowTypes.employee]: [
    ROW_MOD_KEYS.HIGH_SPREAD,
    ROW_MOD_KEYS.DATA_DISPLAY,
    ROW_MOD_KEYS.AVATAR
  ]
}

const cellClassMapper = {
  [rowTypes.employee]: {
    default: 'c-type--table-data c-table__cell--avatar'
  }
}

export interface TableConfig {
  key?: string
  [EMPLOYEE_KEY]?: string
  rowMod?: string[]
  childClassName?: string
  cellClassnames?: {
    default: string
  }
  childTable?: TableConfig[]
  defaultExpandAllRows?: boolean
  hideExpandArrow?: boolean
}

/**
 * Returns table configuration for the Week by Week table.
 * @param {array} data pulse-over-time-data from report response
 * @param {string} currentYear the current year, used to sort current year to the top
 */

const createPowerRankingConfig = (data?): TableConfig[] => {
  const config = data
    .map((employee) => {
      return {
        ...employee,
        data: employee,
        firstName: employee.name.split(' ')[0],
        lastName: employee.name.split(' ')[1],
        title: employee.name.split(' ')[1],
        key: `employee-${employee.name.replace(' ', '-')}`,
        [EMPLOYEE_KEY]: `${employee.name}`,
        rowMod: rowModMapper[rowTypes.employee],
        cellClassnames: cellClassMapper[rowTypes.employee],
        rowClassName: 'c-table__row--bordered',
        isHighSpread: employee.isHighSpread
      }
    })
    .filter((v) => v)

  return config
}

export default createPowerRankingConfig
