export const PRESET_1 = [
  { firstCellInputSize: 165 },
  { firstCellInputSize: 165, subrows: 8 },
  { firstCellInputSize: 165, subrows: 5 }
]

export const PRESET_2 = [
  { firstCellInputSize: 165, titleOnly: true, subrows: 8, hideSubrowAvatars: true }
]

export const PRESET_3 = [
  {
    hideSubrowAvatars: true,
    subrows: 25,
    columnConfig: [
      { inputSize: 42, headerInputSize: 16 },
      { inputSize: 162, headerInputSize: 118 },
      { inputSize: 114, headerInputSize: 44 },
      { inputSize: 95, headerInputSize: 80 },
      { inputSize: 157, headerInputSize: 75 },
      { inputSize: 20, headerInputSize: 111 },
      { inputSize: 45, headerInputSize: 59 },
      { inputSize: 30, headerInputSize: 84 },
      { inputSize: 82 },
      { inputSize: 59, headerInputSize: 117 },
      { inputSize: 40, headerInputSize: 101 },
      { inputSize: 38, headerInputSize: 63 },
      { inputSize: 30, headerInputSize: 86 },
      { inputSize: 58, headerInputSize: 107 },
      { inputSize: 40, headerInputSize: 98 },
      { inputSize: 58, headerInputSize: 80 },
      { inputSize: 88, headerInputSize: 67 },
      { inputSize: 112, headerInputSize: 88 },
      { inputSize: 91, headerInputSize: 89 },
      { inputSize: 87 },
      { inputSize: 111, headerInputSize: 38 },
      { inputSize: 59, headerInputSize: 39 },
      { inputSize: 24, headerInputSize: 160 }
    ]
  }
]