import React from 'react'
import store from '@/bootstrap/redux'
import { DownSolid, RightSolid } from '../../Icons'
import {
  formatNumberDisplay,
  formatNumberToPercentage,
  getMonthNameFromFiscalMonth
} from '@/utils/tools/format'
import { COLUMN_MOD_KEYS, ROW_MOD_KEYS } from '@/shared/constants/local'
import { Avatar, Emoji, Tooltip } from '@/shared/components'
import COLOR_KEYS from '@/shared/constants/colors'
import PulseLink from '../../PulseLink'
import { formatTableDate } from '@/utils/tools/dateTime'
import classNames from 'classnames'
import { IAppColumnConfig } from '..'
import { formatCurrency } from '@/features/Spread/components/MySpread/helpers/helper'
import Star from '../../Icons/Star'
import StarSolid from '../../Icons/StarSolid'
import {
  addStarred,
  removeStarred
} from '@/features/Spread/redux/spreadSlice'
import { useDispatch } from 'react-redux'
import CalendarIcon from '../../Icons/CalendarIcon'

const renderArrow = (
  rowMod,
  columnMod,
  childTable,
  hideExpandArrow,
  expanded
) => {
  if (rowMod && columnMod) {
    if (
      rowMod.includes(ROW_MOD_KEYS.EXPANDABLE) ||
      rowMod.includes(ROW_MOD_KEYS.EXPANDABLE_LEFT)
    ) {
      if (childTable && !hideExpandArrow) {
        const position = rowMod.includes(ROW_MOD_KEYS.EXPANDABLE_LEFT)
          ? ('left-5' as const)
          : ('right-5' as const)
        const className = classNames('c-table__expand-arrow', {
          'c-table__expand-arrow--right': !rowMod.includes(
            ROW_MOD_KEYS.EXPANDABLE_LEFT
          )
        })

        if (rowMod.includes(ROW_MOD_KEYS.EXPANDABLE_CARAT)) {
          return !expanded ? (
            <RightSolid position={position} className={className} />
          ) : (
            <DownSolid position={position} className={className} />
          )
        }
        return !expanded ? (
          <RightSolid position={position} className={className} />
        ) : (
          <DownSolid position={position} className={className} />
        )
      }
    }
  }

  return null
}

type OptionalRecordType = {
  rowMod?: string[]
  childTable?: unknown[]
  hideExpandArrow?: boolean
  firstName?: string
  lastName?: string
  isHighSpread?: boolean
  color?: string
  starred?: boolean
  employeeId?: number
  reachedOn?: string
}

type PulseCellProps<T> = {
  text?: string | number
  record?: T & OptionalRecordType
  expanded?: boolean
  config?: IAppColumnConfig<T>
}

const PulseCell = <T extends OptionalRecordType>({
  text,
  record,
  config,
  expanded
}: PulseCellProps<T>) => {
  const dispatch = useDispatch()
  const starredUsers = store.getState().spread.starredUsers

  const { columnMod = [], renderComponent } = config
  const { rowMod = [], childTable, hideExpandArrow } = record

  if (
    !rowMod.includes(ROW_MOD_KEYS.DATA_DISPLAY) &&
    !columnMod.includes(COLUMN_MOD_KEYS.TITLE) &&
    !rowMod.includes(ROW_MOD_KEYS.OVERTIME)
  ) {
    return null
  }
  if (renderComponent) {
    return <>{renderComponent(record)}</>
  }
  if (
    columnMod?.includes(COLUMN_MOD_KEYS.PLAIN) ||
    columnMod?.includes(COLUMN_MOD_KEYS.METRIC)
  ) {
    return <>{formatNumberDisplay(text as number)}</>
  }
  if (columnMod?.includes(COLUMN_MOD_KEYS.CURRENCY)){
    return (
      <>
        {formatCurrency(text as number)}
        {columnMod?.includes(COLUMN_MOD_KEYS.REACHED_AT) &&
          record.reachedOn && (
            <Tooltip placement='top' title={`Reached on: ` + record.reachedOn}>
              <span className='c-high_spread__reached-at'>
                <CalendarIcon />
              </span>
            </Tooltip>
          )}
      </>
    )
  }
  if (columnMod?.includes(COLUMN_MOD_KEYS.LINK)) {
    return (
      <PulseLink className='c-link--default' to='/'>
        {text}
      </PulseLink>
    )
  }
  if (columnMod?.includes(COLUMN_MOD_KEYS.DATE)) {
    return <>{formatTableDate(text)}</>
  }
  if (columnMod?.includes(COLUMN_MOD_KEYS.MONTH)) {
    return <>{getMonthNameFromFiscalMonth(text as number)} A</>
  }
  if (columnMod?.includes(COLUMN_MOD_KEYS.PERCENT)) {
    return <>{formatNumberToPercentage(text as number)}</>
  }
  if (columnMod?.includes(COLUMN_MOD_KEYS.PARTY_ICON)) {
    const spreadArrow = renderArrow(
      rowMod,
      columnMod,
      childTable,
      hideExpandArrow,
      expanded
    )

    const addToStarred = (employeeId) => {
      dispatch(addStarred(employeeId))
    }

    const removeFromStarred = (employeeId) => {
      dispatch(removeStarred(employeeId))
    }

    return (
      <>
        {rowMod?.includes(ROW_MOD_KEYS.AVATAR) && (
          <>
            <div className='c-high_spread__flex__avatar'>
              {columnMod?.includes(COLUMN_MOD_KEYS.STARRED) && (
                <span className='c-high_spread__star'>
                  {!starredUsers.includes(record?.employeeId) && (
                    <span
                      onClick={(e) => {
                        addToStarred(record?.employeeId)
                      }}
                    >
                      <Star />
                    </span>
                  )}
                  {starredUsers.includes(record?.employeeId) && (
                    <span
                      onClick={(e) => removeFromStarred(record?.employeeId)}
                    >
                      <StarSolid />
                    </span>
                  )}
                </span>
              )}
              <Avatar
                size={30}
                border={record?.color ? record?.color : COLOR_KEYS.gray}
              >
                {`${
                  record?.firstName
                    ? record?.firstName?.[0]
                    : record?.['first-name']?.[0]
                }/${
                  record?.lastName
                    ? record?.lastName?.[0]
                    : record?.['last-name']?.[0]
                }`}
              </Avatar>
              {text}
              {record?.isHighSpread && (
                <Emoji
                  className='c-high_spread__emoji'
                  style={{ height: '2rem', width: '2rem' }}
                  type={'partyFace'}
                />
              )}
            </div>
          </>
        )}
        {!rowMod?.includes(ROW_MOD_KEYS.AVATAR) && (
          <>
            <div className='c-high_spread__flex'>
              <span>{text}</span>
              {!record.hideExpandArrow && spreadArrow}
              {record?.isHighSpread && (
                <Emoji
                  className='c-high_spread__emoji'
                  style={{ height: '2rem', width: '2rem' }}
                  type={'partyFace'}
                />
              )}
            </div>
          </>
        )}
      </>
    )
  }

  const arrow = renderArrow(
    rowMod,
    columnMod,
    childTable,
    hideExpandArrow,
    expanded
  )

  return (
    <>
      {rowMod?.includes(ROW_MOD_KEYS.EXPANDABLE_LEFT) && arrow}
      <span className='c-table-cell__text' title={text?.toString()}>
        {columnMod?.includes(COLUMN_MOD_KEYS.TITLE) &&
          rowMod?.includes(ROW_MOD_KEYS.AVATAR) && (
            <Avatar size={30} border={COLOR_KEYS.gray}>
              {`${
                record?.firstName
                  ? record?.firstName?.[0]
                  : record?.['first-name']?.[0]
              }/${
                record?.lastName
                  ? record?.lastName?.[0]
                  : record?.['last-name']?.[0]
              }`}
            </Avatar>
          )}
        {text}
      </span>
      {rowMod &&
        rowMod.includes(ROW_MOD_KEYS.EXPANDABLE) &&
        !rowMod.includes(ROW_MOD_KEYS.EXPANDABLE_LEFT) &&
        arrow}
    </>
  )
}

export default PulseCell
