// @ts-strict
import React, { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import saveIconixxFile from '@/features/Spread/components/helpers/generateIconixxWorkbook'
import saveSpreadFile from '@/features/Spread/components/helpers/generateSpreadWorkbook'
import { Dropdown, Button, PulseLink } from '@/shared/components'
import { Upload, Download } from '@/shared/components/Icons'
import { GET_ICONIXX_TABLE_NAMED_QUERY } from '../IconixxTable/helpers/query'
import { GET_SPREAD_EXPORT_QUERY } from '../helpers/spreadQuery'
import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { selectEtlIsProcessing } from '@/features/Spread/redux/spreadSelectors'
import { notify } from '@/features/shared/redux/sharedActions'
import {
  FinancialTeamRoutes,
  SpreadUploadFoundersRoute,
  SpreadUploadSpreadRoute
} from '@/features/navigation/components/Routes/routes'

const renderBreadCrumbs = (pathname) => {
  const pathSnippets = pathname.split('/').filter((v) => v.length)
  return pathSnippets.map((path, index) => {
    const label = path[0].toUpperCase() + path.toLowerCase().slice(1)
    return (
      <Breadcrumb.Item key={index} className='c-type--body-text'>
        {index === pathSnippets.length - 1 ? (
          label
        ) : (
          <PulseLink to={'/' + pathSnippets.slice(0, index + 1).join('/')}>
            {label}
          </PulseLink>
        )}
      </Breadcrumb.Item>
    )
  })
}

const SpreadHeader = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [downloadProcessing, setDownloadProcessing] = useState(false)
  const onComplete = useCallback(() => {
    setDownloadProcessing(false)
    dispatch(
      notify('Your file has been successfully downloaded.', {
        success: true,
        style: { width: 460 },
        clearOtherNotices: true,
        clearDuplicates: true,
        timeout: 5000
      })
    )
  }, [setDownloadProcessing, dispatch])
  const onError = useCallback(
    (error) => {
      console.error(error)
      setDownloadProcessing(false)
      dispatch(
        notify('There was an error downloading your file.', {
          error: true,
          style: { width: 460 },
          clearOtherNotices: true,
          clearDuplicates: true
        })
      )
    },
    [setDownloadProcessing, dispatch]
  )
  const onSaveIconixxFile = (data) => {
    setDownloadProcessing(true)
    saveIconixxFile(data, {
      onComplete,
      onError
    })
  }
  const onSaveSpreadFile = (data) => {
    setDownloadProcessing(true)
    saveSpreadFile(data, {
      onComplete,
      onError
    })
  }
  const [getIconixxData, { called: iconixxCalled, loading: iconixxLoading }] =
    useLazyQuery(GET_ICONIXX_TABLE_NAMED_QUERY, {
      onCompleted: onSaveIconixxFile
    })
  const [
    getSpreadExportData,
    { called: spreadExportCalled, loading: spreadExportLoading }
  ] = useLazyQuery(GET_SPREAD_EXPORT_QUERY, {
    onCompleted: onSaveSpreadFile,
    onError
  })
  const etlIsProcessing = useAppSelector(selectEtlIsProcessing)
  const queryExecuted =
    (iconixxCalled && iconixxLoading) ||
    (spreadExportCalled && spreadExportLoading)
  const disabledDownload = etlIsProcessing || queryExecuted
  const disabledUpload = etlIsProcessing || queryExecuted
  const btnDownloadLoading = downloadProcessing || queryExecuted
  const btnUploadLoading = etlIsProcessing || queryExecuted
  const parentUploadComponent: React.ReactNode = (
    <Button
      className='c-btn--icon c-spread__upload-btn'
      disabled={disabledUpload}
      loading={btnUploadLoading}
    >
      Upload <Upload position={'right'} />
    </Button>
  )
  const parentDownloadComponent: React.ReactNode = (
    <Button
      className='c-btn--icon c-spread__download-btn'
      disabled={disabledDownload}
      loading={btnDownloadLoading}
    >
      Download <Download />
    </Button>
  )
  const history = useHistory()

  const menuDownloadConfig = [
    {
      text: 'Spread',
      onClick: () => getSpreadExportData()
    },
    {
      text: 'Iconixx',
      onClick: () => getIconixxData()
    }
  ]
  const menuUploadConfig = [
    {
      text: 'Spread data',
      onClick: () => history.push(SpreadUploadSpreadRoute)
    },
    {
      text: 'Founders target data',
      onClick: () => history.push(SpreadUploadFoundersRoute)
    }
  ]

  if (FinancialTeamRoutes.includes(location.pathname.replace(/\/$/, ''))) {
    return (
      <div className='c-spread__header'>
        <h1
          className='c-type c-type--title-xl c-type--bold'
          style={{ marginTop: '0.8rem' }}
        >
          Spread
        </h1>
        <div className='u-align-center'>
          <Dropdown
            className='c-spread__dropdown-container'
            overlayClassName='c-spread__upload-menu'
            parentComponent={parentUploadComponent}
            menuConfig={menuUploadConfig}
            showArrow={false}
            getPopupContainer={(node) => node}
            disabled={disabledUpload}
          />
          <Dropdown
            className='c-spread__dropdown-container'
            overlayClassName='c-spread__download-menu'
            parentComponent={parentDownloadComponent}
            menuConfig={menuDownloadConfig}
            showArrow={false}
            getPopupContainer={(node) => node}
            disabled={disabledDownload}
          />
        </div>
      </div>
    )
  }

  const items = renderBreadCrumbs(location.pathname)

  return <Breadcrumb className='c-spread__bread-crumbs'>{items}</Breadcrumb>
}

export default SpreadHeader
