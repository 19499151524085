// @ts-strict
import { FoundersRecord } from '../FoundersReport/helpers/query'

type FoundersDepartmentMapping = Record<string, FoundersRecord[]>
export type FoundersBrandDepartmentMapping = Record<string, FoundersDepartmentMapping>

export type FoundersReferenceWeekRecord = {
  referenceWeek: string
  fiscalMonth: number
  fiscalQuarter: number
  fiscalWeek: string
  fiscalYear: string
  [key: string]: number | string
}

export type FoundersGroupedByMonth = {
  fiscalMonth: number
  records: FoundersReferenceWeekRecord[]
}

export type FoundersGroupedByQuarter = {
  fiscalQuarter: number
  months: FoundersGroupedByMonth[]
}

type ExtrapolatedData = {
  uniqueUserRecords: FoundersRecord[]
  uniqueRefWeeks: FoundersRecord[]
  highestFiscalWeekValue: number
  lowestFiscalWeekValue: number
  startDate: string
  endDate: string
}

export type FormatFoundersDataReturnType = {
    brandDepartmentMapping: FoundersBrandDepartmentMapping
    departmentRowValues: Record<string, string>
    nameRowValues: Record<string, string>
    dataByReferenceWeek: FoundersGroupedByQuarter[]
    uniqueUserRecords: FoundersRecord[]
    highestFiscalWeekValue: number
    lowestFiscalWeekValue: number
    startDate: string
    endDate: string
}

const getSideOfHouseIdAndDivisionName = (sideOfHouse: string) => {
  if (['Sales', 'Recruiters'].includes(sideOfHouse)) {
    switch (sideOfHouse) {
    case 'Sales':
      return ['bdmId', 'BDM']
    case 'Recruiters':
      return ['rdmId', 'RDM']
    default:
      break
    }
  }
  return []
}

const formatFoundersData = (dataSource: FoundersRecord[], sideOfHouse: string): FormatFoundersDataReturnType => {
  const [sideOfHouseId, bdmName] = getSideOfHouseIdAndDivisionName(sideOfHouse)
  const {
    uniqueRefWeeks, uniqueUserRecords, highestFiscalWeekValue, lowestFiscalWeekValue, startDate, endDate
  } = dataSource.reduce<ExtrapolatedData>((acc, nextRecord) => {
    if (!acc.uniqueUserRecords.find(r => r.repId === nextRecord.repId)) {
      acc.uniqueUserRecords = [...acc.uniqueUserRecords, nextRecord]
    }
    if (!acc.uniqueRefWeeks.find(r => r.referenceWeek === nextRecord.referenceWeek)) {
      acc.uniqueRefWeeks = [...acc.uniqueRefWeeks, nextRecord]
    }
    if (Number(nextRecord.fiscalWeek) > acc.highestFiscalWeekValue) {
      acc.highestFiscalWeekValue = Number(nextRecord.fiscalWeek)
      acc.endDate = nextRecord.endDate
    }

    if (Number(nextRecord.fiscalWeek) < acc.lowestFiscalWeekValue) {
      acc.lowestFiscalWeekValue = Number(nextRecord.fiscalWeek)
      acc.startDate = nextRecord.startDate
    } else if (acc.lowestFiscalWeekValue === 0 && Number(nextRecord.fiscalWeek)) {
      acc.lowestFiscalWeekValue = Number(nextRecord.fiscalWeek)
      acc.startDate = nextRecord.startDate
    }

    return acc
  }, { uniqueUserRecords: [], uniqueRefWeeks: [], highestFiscalWeekValue: 0, lowestFiscalWeekValue: 0, endDate: '', startDate: '' })
  uniqueRefWeeks.sort((aRecord, bRecord) => (Number(aRecord.fiscalWeek) - Number(bRecord.fiscalWeek)))
  const bdmExists = sideOfHouseId ? !!dataSource.find(v => v[sideOfHouseId]) : false
  const initialObject =  bdmExists ? { [bdmName]: { [bdmName]: [] } } : {}
  const brandDepartmentMapping = uniqueUserRecords.reduce<FoundersBrandDepartmentMapping>((acc, nextRecord) => {
    const { brand, department } = nextRecord
    if (sideOfHouseId && nextRecord[sideOfHouseId]) {
      acc[bdmName][bdmName].push(nextRecord)
    } else if (acc[brand]) {
      if (acc[brand][department]) {
        acc[brand][department] = [...acc[brand][department], nextRecord]
      } else {
        acc[brand][department] = [nextRecord]
      }
    } else {
      acc[brand] = { [department]: [nextRecord] }
    }
    return acc
  }, initialObject)

  const departmentRowValues = uniqueUserRecords.reduce<Record<string, string>>((acc, nextRecord) => {
    if (sideOfHouseId && nextRecord[sideOfHouseId]) {
      acc[nextRecord.repId] = bdmName
    } else {
      acc[nextRecord.repId] = nextRecord.department
    }
    return acc
  }, {})
  departmentRowValues.referenceWeek = `Current Week ${highestFiscalWeekValue}`
  const nameRowValues = uniqueUserRecords.reduce<Record<string, string>>((acc, nextRecord) => {
    acc[nextRecord.repId] = nextRecord.employee
    return acc
  }, {})
  nameRowValues.referenceWeek = `${dataSource[dataSource.length - 1].fiscalYear} Founder's Club Tracker - ${sideOfHouse.toUpperCase()}`

  const dataByReferenceWeek = uniqueRefWeeks.reduce<FoundersGroupedByQuarter[]>((acc, refWeekRecord) => {
    const { fiscalMonth, fiscalQuarter, fiscalWeek, fiscalYear } = refWeekRecord
    const dataRecord = {
      referenceWeek: refWeekRecord.referenceWeek,
      fiscalMonth: fiscalMonth,
      fiscalQuarter: fiscalQuarter,
      fiscalWeek: fiscalWeek,
      fiscalYear: fiscalYear
    }
    uniqueUserRecords.forEach(userRecord => {
      const relevantRecord = dataSource.find(r => r.referenceWeek === refWeekRecord.referenceWeek && r.repId === userRecord.repId)
      if (relevantRecord) {
        dataRecord[userRecord.repId] = relevantRecord.spread
      }
    })
    const indexOfQuarter = acc.findIndex(v => v.fiscalQuarter === fiscalQuarter)
    if (indexOfQuarter < 0) {
      acc.push({
        fiscalQuarter,
        months: [{
          fiscalMonth,
          records: [dataRecord]
        }]
      })
    } else {
      const indexOfMonth = acc[indexOfQuarter].months.findIndex(v => v.fiscalMonth === dataRecord.fiscalMonth)
      if (indexOfMonth < 0) {
        acc[indexOfQuarter].months.push({
          fiscalMonth,
          records: [dataRecord]
        })
      } else {
        acc[indexOfQuarter].months[indexOfMonth].records.push(dataRecord)
      }
    }

    return acc
  }, [])

  return {
    brandDepartmentMapping,
    departmentRowValues,
    nameRowValues,
    dataByReferenceWeek,
    uniqueUserRecords,
    highestFiscalWeekValue,
    lowestFiscalWeekValue,
    startDate,
    endDate
  }
}

export default formatFoundersData
