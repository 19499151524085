/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-strict
import React, { useRef, useCallback, useEffect, useState } from 'react'
import { Table as AntTable } from 'antd'
import { ColumnType, TableProps } from 'antd/lib/table'

import PulseCell from './components/PulseCell'
import addSpacingElements from './helpers/addSpacingElements'
import renderCellProps from './helpers/renderCellProps'
import TotalsRow from './components/TotalsRow'
import { CompareFn, SortOrder } from 'antd/lib/table/interface'

export type ExpandedKeysObj = Record<string, Array<string>>

export interface IAppColumnConfig<RecordType = unknown>
  extends ColumnType<RecordType> {
  columnMod?: Array<string>
  renderComponent?: (record: RecordType) => React.ReactNode | string
  sorter?:
    | boolean
    | CompareFn<any>
    | { compare?: CompareFn<any>; multiple?: number }
  sortOrder?: SortOrder
  key: string
  sortFn?: CompareFn<RecordType>
}

export interface ITableProps<RecordType = unknown>
  extends TableProps<RecordType> {
  tableConfig: any[]
  columnConfig: any // TODO: figure out how to get this IAppColumnConfig<RecordType>[] and the MetricConfig type to work
  sortTargetIndex?: number
  index?: number
  parentSortObj?: { columnKey?: string; order?: SortOrder }
  expandedKeysObj?: ExpandedKeysObj
  setExpandedKeysObj?: (expandedKeysObj: ExpandedKeysObj) => void
  parentKey?: number | string
  includeTotalsRow?: boolean
  totalsData?: RecordType | Record<string | number, unknown>
  onScroll?: (event: Event) => void
  disableSpacing?: boolean
  internalRef?: React.MutableRefObject<HTMLDivElement>
}

const renderExpandedRow = (row, columns, extraConfig) => {
  if (row && row.childTable) {
    const {
      childTable,
      childClassName,
      locale,
      defaultExpandAllRows,
      expandRowByClick = true,
      data
    } = row

    return (
      <>
        <Table
          tableConfig={childTable}
          columnConfig={columns}
          className={childClassName || ''}
          showHeader={false}
          locale={locale}
          defaultExpandAllRows={defaultExpandAllRows}
          expandRowByClick={expandRowByClick}
          data={data}
          {...extraConfig}
        />
      </>
    )
  }
  return null
}

// includeTotalsRow without totalsData will infer total from top level table
// includeTotalsRow with totalsData will use totalsData value as is
const Table = <RecordType,>({
  tableConfig = [],
  columnConfig,
  className = 'c-table',
  sortTargetIndex = 2,
  index = 0,
  parentSortObj,
  expandedKeysObj,
  setExpandedKeysObj,
  parentKey = 0,
  defaultExpandAllRows = false,
  includeTotalsRow = false,
  totalsData,
  tableLayout = 'fixed',
  pagination = false,
  onScroll,
  disableSpacing,
  internalRef,
  expandIconColumnIndex = -1,
  expandRowByClick = true,
  ...restProps
}: ITableProps<RecordType>) => {
  const nextIndex = index + 1
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [sortObj, setSortObj] = useState({})
  const withSpacing = disableSpacing
    ? tableConfig
    : addSpacingElements(tableConfig)
  const renderFn = useCallback(
    (text, record, config) => {
      if (record.isSpacing) return null
      return (
        <PulseCell
          text={text}
          record={record}
          config={config}
          expanded={(expandedKeysObj?.[parentKey] || expandedRowKeys).includes(
            record.key
          )}
        />
      )
    },
    [expandedKeysObj, expandedRowKeys, parentKey]
  )
  useEffect(() => {
    if (defaultExpandAllRows) {
      const keys = tableConfig.map((v) => v.key)
      setExpandedRowKeys(keys)
    }
  }, [defaultExpandAllRows, setExpandedRowKeys, tableConfig])

  useEffect(() => {
    if (onScroll && (restProps.scroll?.x || restProps.scroll?.y) && onScroll) {
      if (internalRef?.current) {
        const scrollableEl =
          internalRef.current.getElementsByClassName('ant-table-body')[0]
        if (scrollableEl) {
          scrollableEl.addEventListener('scroll', onScroll)

          return () => {
            scrollableEl.removeEventListener('scroll', onScroll)
          }
        }
      }
    }
  }, [internalRef, onScroll, restProps.scroll])

  const enrichedConfig = columnConfig.map((config) => {
    const enriched = {
      ...config,
      // eslint-disable-next-line react/display-name
      onCell: (record, rowIndex) => renderCellProps(record, rowIndex, config),
      // eslint-disable-next-line react/display-name
      render: (text, record) => renderFn(text, record, config)
    }
    if (config.sortFn) {
      // sets sort arrows on outer table
      if (sortTargetIndex && index === 0) {
        enriched.sorter = true
      }
      // sets sort function supplied in columnConfig to column at targeted table
      if (sortTargetIndex === index) {
        enriched.sorter = (a, b) => enriched.sortFn(a, b, parentSortObj?.order)
        // uses sort object generated at top level to sort all nested tables at targeted level
        if (parentSortObj?.columnKey === enriched.key) {
          enriched.sortOrder = parentSortObj.order
        }
      }
    }
    return enriched
  })
  const onChange = useCallback(
    (pagination, filters, sorter, extra) => {
      if (sortTargetIndex && index === 0 && extra.action === 'sort') {
        setSortObj(sorter)
      }
    },
    [sortTargetIndex, index, setSortObj]
  )
  const onExpandedRowsChange = useCallback(
    (expandedRows) => {
      if (expandedKeysObj && setExpandedKeysObj) {
        setExpandedKeysObj({
          ...expandedKeysObj,
          [parentKey]: expandedRows
        })
      } else {
        setExpandedRowKeys(expandedRows)
      }
    },
    [setExpandedRowKeys, expandedKeysObj, parentKey, setExpandedKeysObj]
  )

  return (
    <AntTable
      tableLayout={tableLayout}
      className={className}
      columns={enrichedConfig}
      dataSource={withSpacing}
      rowClassName={(row) =>
        row.rowClassName
          ? row.rowClassName
          : row.childTable
          ? 'c-table__expandable'
          : ''
      }
      expandable={{
        expandedRowKeys: expandedKeysObj?.[parentKey] || expandedRowKeys,
        expandedRowRender: (row) =>
          renderExpandedRow(row, columnConfig, {
            sortTargetIndex,
            index: nextIndex,
            parentSortObj: index === 0 ? sortObj : parentSortObj, // all nested tables need access to the first level sort order
            expandedKeysObj,
            setExpandedKeysObj,
            parentKey: row.key,
            expandIconColumnIndex: expandIconColumnIndex,
            expandRowByClick: row.expandRowByClick
          }),
        rowExpandable: (row) => !!row.childTable,
        expandRowByClick: expandRowByClick,
        expandIconColumnIndex: expandIconColumnIndex,
        onExpandedRowsChange,
        defaultExpandAllRows
      }}
      pagination={pagination}
      onChange={onChange}
      summary={() => {
        if (includeTotalsRow) {
          return (
            <TotalsRow
              data={tableConfig}
              columnConfig={columnConfig}
              totalsData={totalsData}
            />
          )
        }
      }}
      {...restProps}
    />
  )
}

const TableWrapper = <RecordType,>(
  props: Omit<ITableProps<RecordType>, 'internalRef'>
) => {
  const ref = useRef(null)

  return (
    <div ref={ref}>
      <Table internalRef={ref} {...props} />
    </div>
  )
}

export default TableWrapper
