import { BDE_KEY } from '@/shared/constants/dataKeys'
import { ROW_MOD_KEYS } from '@/shared/constants/local'

/**
 * Returns table configuration for the main table display based on selected users.
 * @param selected array of nested objects {array}
 */
const createBrandDivisionEmployeeTableConfig = (selected, governmentDivisionIds) => {
  try {
    return selected.map(({ id, children, key }) => {
      return {
        key,
        [BDE_KEY]: id,
        cellClassnames: {
          [BDE_KEY]: 'c-type--table-data-black',
          default: 'c-type--table-numeric'
        },
        childClassName: 'c-table__row--indent',
        rowMod: [ROW_MOD_KEYS.EXPANDABLE_LEFT],
        childTable: createDivisionsEmployeeTableConfig(
          children,
          governmentDivisionIds
        )
      }
    })
  } catch (e) { console.log(e) }
}

const createDivisionsEmployeeTableConfig = (divisions, governmentDivisionIds) => {
  return divisions.map(({ children, key, title, id }) => {
    const isGovernmentDivision = governmentDivisionIds.includes(id)
    return {
      key,
      [BDE_KEY]: title,
      cellClassnames: {
        [BDE_KEY]: 'c-type--table-data-bold',
        default: 'c-type--table-numeric'
      },
      childClassName: 'c-table__row--quality c-table__row--indent',
      rowMod: [ROW_MOD_KEYS.EXPANDABLE_LEFT],
      locale: { emptyText: 'Nothing to see here...' },
      childTable: createEmployeeTableConfig(
        children,
        isGovernmentDivision
      )
    }
  })
}

const createEmployeeTableConfig = (employees, isGovernmentDivision) => {
  return employees.map(({ id, key, title }) => {
    return {
      key,
      id,
      isGovernmentRecord: isGovernmentDivision,
      [BDE_KEY]: title,
      rowMod: [ROW_MOD_KEYS.DATA_DISPLAY],
      cellClassnames: {
        [BDE_KEY]: '',
        default: 'c-type--table-numeric'
      }
    }
  })
}

export default createBrandDivisionEmployeeTableConfig
