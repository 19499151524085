import { getEtlIsProcessing } from '@/utils/api/spread'
import { dismissNoticeByDuplicateId, notify } from '@/features/shared/redux/sharedActions'
import { setEtlIsProcessing } from './spreadSlice'

export const manageEtlIsProcessing = () => (dispatch) => {
  const duplicateId = 'ETL_IS_RUNNING'
  let apiCallCount = 0
  const manager = setInterval(async () => {
    const response = await getEtlIsProcessing()
    apiCallCount++
    if (typeof response === 'boolean' || typeof response.etlInProcess === 'boolean') {
      const isProcessing = typeof response === 'boolean' ? response : response.etlInProcess
      if (apiCallCount === 1 && isProcessing) {
        dispatch(
          notify('ETL is currently running. Expect longer than usual load times.', {
            warning: true,
            style: { width: 460 },
            clearDuplicates: true,
            timeout: 10000,
            duplicateId
          })
        )
      }
      if (!isProcessing) {
        clearInterval(manager)
        dispatch(setEtlIsProcessing(isProcessing))
        dispatch(dismissNoticeByDuplicateId(duplicateId))

        if (apiCallCount > 1 && typeof response !== 'boolean' && response.etlError) {
          dispatch(
            notify('There was an error during the most recent ETL. The current data may not reflect the lastest upload.', {
              error: true,
              style: { width: 460 }
            })
          )
        }
      }
    } else {
      console.error('Invalid etlIsProcessing response.')
      clearInterval(manager)
    }
  }, 1500)

  return manager
}
