import { configureStore, ThunkAction } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { persistConfig } from './persist'
import { createBrowserHistory } from 'history'
import { createReduxEnhancer } from '@sentry/react'
import { AnyAction } from 'redux'
import createRootReducer from './createRootReducer'
import { routerMiddleware } from 'connected-react-router'

const sentryReduxEnhancer = createReduxEnhancer({})

export const history = createBrowserHistory()
const rootReducer = createRootReducer(history)
const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureAppStore = (preloadedState) => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(routerMiddleware(history)),
    preloadedState,
    enhancers: [sentryReduxEnhancer]
  })

  return store
}

const store = configureAppStore({})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>

export const persistor = persistStore(store)
// process.env.NODE_ENV !== 'production' && persistor.purge();
export default store