import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Button } from '@/shared/components'
import { Refresh2 } from '@/shared/components/Icons'
import useGetLastUpdatedTimeStamp from './hooks/useGetLastUpdatedTimeStamp'
import refetchData from './helpers/refetchData'

type AppearanceState = 'none' | 'calm' | 'suggest' | 'yell'
const SUGGEST_TIMER = 10 * 60 * 1000 // 10 minutes in milliseconds
const YELL_TIMER = 15 * 60 * 1000 - SUGGEST_TIMER // 15 minutess in milliseconds 

const RefreshButton = () => (
  <Button
    styleAsLink
    style={{ paddingTop: '0', paddingBottom: 0, fontWeight: 'normal' }}
    onClick={refetchData}
  >
    <Refresh2 position='left' />Refresh
  </Button>
)

const LastUpdated = () => {
  const [appearanceState, setAppearanceState] = useState<AppearanceState>('none')
  const timer = useRef<NodeJS.Timeout>()
  const timestamp = useGetLastUpdatedTimeStamp()
  const setAppearanceTimer = useCallback(() => {
    setAppearanceState('calm')
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      setAppearanceState('suggest')
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setAppearanceState('yell')
      }, YELL_TIMER)
    }, SUGGEST_TIMER)
  }, [setAppearanceState])
  useEffect(() => {
    if (timestamp) {
      setAppearanceTimer()
    }
  },[timestamp, setAppearanceTimer])
  return (
    <div className={`c-dashboard__last-updated ${appearanceState}`}>
      <div className='c-dot' /> <span className='c-dashboard__last-updated-timestamp'>Last updated {timestamp?.format('MM/DD/YY @ hh:mm A z')}</span> <RefreshButton />
    </div>
  )
}

export default LastUpdated
