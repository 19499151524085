import { createReducer } from '@reduxjs/toolkit'

import { ALL_ALKU_KEY } from '@/shared/constants/dataKeys'
import { generateList } from './controlPanelOperations'
import { AT_SHORTCUT_KEYS, AT_SHORTCUT_KEYS_TYPE } from '@/shared/constants/local'
import { AlkuTree, AlkuTreeNodeType, EmployeeDictionary, TimeSliderMark } from '@/shared/types'
import {
  addAtCheckedKeys,
  addAtDebouncedCheckedKeys,
  addAtExpandedKeys,
  clearAtSearch,
  rollbackAtSaveState,
  saveAtState,
  saveMyDivisionsBegin,
  saveMyDivisionsFail,
  saveMyDivisionsSuccess,
  saveMyPeopleBegin,
  saveMyPeopleFail,
  saveMyPeopleSuccess,
  setActiveShortcutKey,
  setAtAutoExpandParent,
  setAtCheckedKeys,
  setAtDebouncedCheckedKeys,
  setAtEmployeeDictionary,
  setAtExpandedKeys,
  setAtSearchResultKeys,
  setAtSelectedKeys,
  setAudienceTreeData,
  setAudienceTreeSearch,
  setAudienceTreeSearchValue,
  setCustomDateRange,
  setCustomDateRangeEnabled,
  setSetSelectedDates,
  setTimePeriod,
  setTimeSliderMarks } from './controlPanelActionsPlain'
import {
  fetchReportFail,
  fetchReportSuccess
} from '@/features/shared/redux/sharedActionsPlain'

type AudienceTree = {
  data: AlkuTree,
  dataList: Array<AlkuTreeNodeType>
  expandedKeys: Array<string>
  checkedKeys: Array<string>
  debouncedCheckedKeys: Array<string>
  selectedKeys: Array<string>
  searchResultKeys: Array<string>
  activeShortcutKey: AT_SHORTCUT_KEYS_TYPE
  employeeDictionary: EmployeeDictionary
  search: string
  searchValue: string
  autoExpandParent: boolean
  saveStates: Array<Partial<AudienceTree>>
  meta: {
    myDivisionsSaving: boolean
    myPeopleSaving: boolean
  }
}

type ControlPanelState = {
  timeSlider: {
    timePeriod: number
    customDateRangeEnabled: boolean
    customDateRange?: [string, string]
    marks?: Record<string, TimeSliderMark>
    selectedDates?: [string, string]
    meta: {
      rollbackTimePeriod: number
    }
  },
  audienceTree: AudienceTree
}

const initialState: ControlPanelState = {
  timeSlider: {
    timePeriod: 20,
    customDateRangeEnabled: false,
    customDateRange: null,
    marks: null,
    selectedDates: null,
    meta: {
      rollbackTimePeriod: 20
    }
  },
  audienceTree: {
    data: [],
    dataList: [],
    expandedKeys: [ALL_ALKU_KEY],
    checkedKeys: [],
    debouncedCheckedKeys: [],
    selectedKeys: [],
    searchResultKeys: [],
    activeShortcutKey: AT_SHORTCUT_KEYS.PEOPLE,
    employeeDictionary: {},
    search: '',
    searchValue: '',
    autoExpandParent: true,
    saveStates: [],
    meta: {
      myDivisionsSaving: false,
      myPeopleSaving: false
    }
  }
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setTimePeriod, (state, action) => {
    state.timeSlider.timePeriod = action.payload
  })
  builder.addCase(setCustomDateRangeEnabled, (state, action) => {
    state.timeSlider.customDateRangeEnabled = action.payload
  })
  builder.addCase(setCustomDateRange, (state, action) => {
    state.timeSlider.customDateRange = action.payload
  })
  builder.addCase(setAtCheckedKeys, (state, action) => {
    state.audienceTree.checkedKeys = action.payload
  })
  builder.addCase(setAtDebouncedCheckedKeys, (state, action) => {
    state.audienceTree.debouncedCheckedKeys = action.payload
  })
  builder.addCase(addAtCheckedKeys, (state, action) => {
    state.audienceTree.checkedKeys = [
      ...state.audienceTree.checkedKeys,
      ...action.payload
    ].filter((key, index, self) => key && self.indexOf(key) === index)
  })
  builder.addCase(addAtDebouncedCheckedKeys, (state, action) => {
    state.audienceTree.debouncedCheckedKeys = [
      ...state.audienceTree.debouncedCheckedKeys,
      ...action.payload
    ].filter((key, index, self) => key && self.indexOf(key) === index)
  })
  builder.addCase(setAtSelectedKeys, (state, action) => {
    state.audienceTree.selectedKeys = action.payload
  })
  builder.addCase(setAtExpandedKeys, (state, action) => {
    state.audienceTree.expandedKeys = action.payload
  })
  builder.addCase(addAtExpandedKeys, (state, action) => {
    state.audienceTree.expandedKeys = [
      ...state.audienceTree.expandedKeys,
      ...action.payload
    ].filter((key, index, self) => key && self.indexOf(key) === index)
  })
  builder.addCase(setAudienceTreeData, (state, action) => {
    state.audienceTree.data = action.payload
    const dataList = []
    generateList(action.payload, dataList)
    state.audienceTree.dataList = dataList
  })
  builder.addCase(setTimeSliderMarks, (state, action) => {
    state.timeSlider.marks = action.payload
  })
  builder.addCase(setActiveShortcutKey, (state, action) => {
    state.audienceTree.activeShortcutKey = action.payload
  })
  builder.addCase(setAtEmployeeDictionary, (state, action) => {
    state.audienceTree.employeeDictionary = action.payload
  })
  builder.addCase(setAudienceTreeSearch, (state, action) => {
    state.audienceTree.search = action.payload
  })
  builder.addCase(setAudienceTreeSearchValue, (state, action) => {
    state.audienceTree.searchValue = action.payload
  })
  builder.addCase(clearAtSearch,(state) => {
    state.audienceTree.searchValue = ''
    state.audienceTree.search = ''
    state.audienceTree.searchResultKeys = []
    // after clearing search we rollback to pre-search tree state using rollbackAtSave logic
    const lastSaveState = state.audienceTree.saveStates.pop() || {}
    state.audienceTree = {
      ...state.audienceTree,
      ...lastSaveState
    }
  })
  builder.addCase(setAtAutoExpandParent, (state, action) => {
    state.audienceTree.autoExpandParent = action.payload
  })
  builder.addCase(saveAtState, (state, action) => {
    const saveKeys = action.payload
    const savedEntries = saveKeys.reduce((entriesObj, nextKey) => {
      if (state.audienceTree[nextKey]) {
        entriesObj[nextKey] = state.audienceTree[nextKey]
      }
      return entriesObj
    }, {})
    state.audienceTree.saveStates.push({
      ...savedEntries
    })
  })
  builder.addCase(rollbackAtSaveState, (state) => {
    const lastSaveState = state.audienceTree.saveStates.pop() || {}
    state.audienceTree = {
      ...state.audienceTree,
      ...lastSaveState
    }
  })
  builder.addCase(setAtSearchResultKeys, (state, action) => {
    state.audienceTree.searchResultKeys = action.payload
  })
  builder.addCase(saveMyDivisionsBegin, (state) => {
    state.audienceTree.meta.myDivisionsSaving = true
  })
  builder.addCase(saveMyDivisionsSuccess, (state) => {
    state.audienceTree.meta.myDivisionsSaving = false
  })
  builder.addCase(saveMyDivisionsFail, (state) => {
    state.audienceTree.meta.myDivisionsSaving = false
  })
  builder.addCase(saveMyPeopleBegin, (state) => {
    state.audienceTree.meta.myPeopleSaving = true
  })
  builder.addCase(saveMyPeopleSuccess, (state) => {
    state.audienceTree.meta.myPeopleSaving = false
  })
  builder.addCase(saveMyPeopleFail, (state) => {
    state.audienceTree.meta.myPeopleSaving = false
  })
  builder.addCase(fetchReportSuccess, (state) => {
    state.timeSlider.meta.rollbackTimePeriod = state.timeSlider.timePeriod
  })
  builder.addCase(fetchReportFail, (state) => {
    state.timeSlider.timePeriod = state.timeSlider.meta.rollbackTimePeriod
  })
  builder.addCase(setSetSelectedDates, (state, action) => {
    state.timeSlider.selectedDates = action.payload
  })
})
