import { BDE_KEY } from '@/shared/constants/dataKeys'
import { ROW_MOD_KEYS } from '@/shared/constants/local'
import { sortPulseOverTimeConfig } from './sortConfigs'

const rowTypes = {
  YEAR: 'year',
  QUARTER: 'quarter',
  MONTH: 'month',
  WEEK: 'week'
}

const rowModMapper = {
  [rowTypes.YEAR]: [ROW_MOD_KEYS.EXPANDABLE],
  [rowTypes.MONTH]: [ROW_MOD_KEYS.EXPANDABLE],
  [rowTypes.WEEK]: [ROW_MOD_KEYS.QUALITY, ROW_MOD_KEYS.DATA_DISPLAY]
}

const childTableMapper = {
  [rowTypes.YEAR]: 'quarters',
  [rowTypes.QUARTER]: 'months',
  [rowTypes.MONTH]: 'weeks'
}

const childClassNameMapper = {
  [rowTypes.QUARTER]: 'c-table__row--indent',
  [rowTypes.MONTH]: 'c-table__row--indent c-table__row--quality'
}

const cellClassMapper = {
  [rowTypes.YEAR]: {
    [BDE_KEY]: 'c-type--table-data-black',
    default: 'c-type--table-numeric c-type--bold'
  },
  [rowTypes.QUARTER]: {
    [BDE_KEY]: 'c-type--table-data-bold',
    default: 'c-type--table-numeric c-type--bold'
  },
  [rowTypes.MONTH]: {
    [BDE_KEY]: 'c-type--table-data-bold',
    default: 'c-type--table-numeric'
  },
  [rowTypes.WEEK]: {
    [BDE_KEY]: 'c-type--table-data',
    default: 'c-type--table-numeric'
  }
}

/**
 * Returns table configuration for the Week by Week table.
 * @param {array} data pulse-over-time-data from report response
 * @param {string} currentYear the current year, used to sort current year to the top
 */
const createTableConfig = (data, type) => {
  try {
    const config = data.map(record => {
      const type = getType(record)
      let children = record[childTableMapper[type]]
      const { timeframe, data } = record

      // in order to skip the month layer we iterate through month records and flatten weeks
      if (type === rowTypes.QUARTER) {
        children = record[childTableMapper[type]].reduce(
          (acc, nextMonthRecords) => {
            acc = [...acc, ...nextMonthRecords.weeks]
            return acc
          }, [])
      }

      return {
        ...data,
        dataFrom: record['data-from'],
        key: record.timeframe,
        [BDE_KEY]: timeframe,
        rowMod: rowModMapper[type],
        childClassName: childClassNameMapper[type],
        cellClassnames: cellClassMapper[type],
        childTable: children && createTableConfig(children),
        defaultExpandAllRows: true,
        hideExpandArrow: true
      }
    }).filter(v => v)
    config.sort(sortPulseOverTimeConfig)
    return config
  } catch (e) {
    console.log(e)
    return []
  }
}

const getType = record => {
  let type = rowTypes.WEEK
  if (record) {
    if (record.quarters) type = rowTypes.YEAR
    if (record.months) type = rowTypes.QUARTER
    if (record.weeks) type = rowTypes.MONTH
  }
  return type
}

export default createTableConfig
