export const formatCurrency = (value: number) => {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
}
export const getMonthName = (date) => {
  return date.toLocaleString('en-US', { month: 'short' })
}
