import React from 'react'
import Icon from './Icon'

const RightSolid = ({ scale, position, className }) => (
  <Icon position={position} className={className}>
    <svg
      width='9'
      height='9'
      xmlns='http://www.w3.org/2000/svg'
      style={scale ? { transform: `scale(${scale})` } : {}}
    >
      <path
        d='M6.787 4.128L3.034.751a.5.5 0 00-.834.372v6.754a.5.5 0 00.834.372l3.753-3.377a.5.5 0 000-.744z'
        fill='#000'
        fillRule='evenodd'
      />
    </svg>
  </Icon>
)

export default RightSolid;
