import {
  ApolloClient,
  ApolloClientOptions,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { createSubscriptionHandshakeLink as awsCreateSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { getAccessToken } from '../msal'
import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'
import { notifyError } from '@/features/shared/redux/sharedActions'
import store from '@/bootstrap/redux'

const env: string = process.env.REACT_APP_ENVIRONMENT || 'dev'

const {
  REACT_APP_API_SPREAD
} = process.env

// TODO: Remove when deprecated by new clients
export const getDomoBaseHost = (): string => {
  switch (env) {
  case REACT_APP_ENVIRONMENT.PROD:
    return 'https://alkueverywhere.com'
  case REACT_APP_ENVIRONMENT.UAT:
    return 'https://ae-uat.alku-dev.com'
  default:
    return 'https://ae.alku-dev.com'
  }
}

type ClientKey =
  | 'spread'

// shared config object for multiple clients
const config: ApolloClientOptions<NormalizedCacheObject> = {
  cache: new InMemoryCache({
    addTypename: false //this is for removing the __typename field from the response
  })
}

/**
 * Helper to manage the env variables
 * TODO: This will simplify after the JWT switch; won't need apiKey
 * @param key {ClientKey} internal key reference
 * @returns Object {baseUrl: string; apiKey: string}
 */
const getBaseUrl = (key?: ClientKey): string => {
  switch (key) {
  case 'spread':
    return REACT_APP_API_SPREAD
  default:
    // backwards compat w old host
    return `${getDomoBaseHost()}/domo/api/graphql`
  }
}

/**
 * Creates HttpLink Apollo Link
 * https://www.apollographql.com/docs/react/api/link/apollo-link-http/
 * @param key
 * @returns ApolloLink
 */
const generateHttpLink = (key?: ClientKey) => {
  const baseUrl = getBaseUrl(key)
  return createHttpLink({
    uri: baseUrl
  })
}
/**
 * Creates the Apollo Auth Link
 * https://www.apollographql.com/docs/react/networking/authentication/
 *
 * Headers are compatible with API Key and JWT
 * @param key
 * @returns ApolloLink
 */
const createAuthLink = (key?: ClientKey) =>
  setContext(async (request, { headers }) => {
    const baseUrl = getBaseUrl(key)
    const token: string = await getAccessToken()
    // set operationName if it exists to distinguish it in the dev console
    return {
      uri: `${baseUrl}${
        request.operationName ? `?${request.operationName}` : ''
      }`,
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

/**
 * Error handler for the network layer.
 * https://www.apollographql.com/docs/react/data/error-handling/
 */
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  // syntax or validation errors
  if (graphQLErrors) {
    const errors = graphQLErrors.map(
      ({ message }) => `${message} on ${operation.operationName}`
    )
    store.dispatch(notifyError(errors.join('\n')))
  }
  // network layer errors
  // statusCode Type: https://github.com/apollographql/apollo-link/issues/300
  if (networkError && 'statusCode' in networkError) {
    switch (networkError.statusCode) {
    case 401:
      // TODO: Likely will replace this with unauthorized handler. I don't see anything that
      // does a logout for the user - MSAL Logout?
      store.dispatch(
        notifyError(`${networkError.message} on ${operation.operationName}`)
      )
      break
    default:
      store.dispatch(
        notifyError(`${networkError.message} on ${operation.operationName}`)
      )
      break
    }
  }
})
/**
 * Apollo Link for retry operations.
 * The delay object is the default for avoiding the 'thundering herd' of network requests if
 * the server goes down - so that the client won't crash it again by distributing the load.
 * https://www.apollographql.com/docs/react/api/link/apollo-link-retry
 */
const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error
  }
})

/**
 * Creates the Apollo Auth Link
 * https://github.com/awslabs/aws-mobile-appsync-sdk-js
 *
 * @param key
 * @returns ApolloLink
 */
const createSubscriptionHandshakeLink = (key?: ClientKey) => {
  const url = getBaseUrl(key)
  const region = 'us-east-2'
  const auth = {
    type: 'OPENID_CONNECT' as const,
    jwtToken: getAccessToken
  }
  const httpLink = generateHttpLink(key)
  return awsCreateSubscriptionHandshakeLink({ url, region, auth }, httpLink)
}

const getApolloLinks = (key?: ClientKey) =>
  from([
    errorLink,
    retryLink,
    createAuthLink(key),
    createSubscriptionHandshakeLink(key)
  ])

export const client = new ApolloClient({
  ...config,
  link: getApolloLinks()
})

export const spreadClient = new ApolloClient({
  ...config,
  link: getApolloLinks('spread')
})
