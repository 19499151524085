import React from 'react'

const Icon = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='18.000000pt'
      height='18.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M386 870 c-63 -16 -153 -70 -197 -117 -22 -24 -55 -74 -72 -111 -29
-61 -32 -76 -32 -163 0 -90 2 -99 37 -171 45 -91 103 -147 196 -191 61 -29 76
-32 162 -32 86 0 101 3 162 32 93 44 151 100 196 191 35 72 37 81 37 172 0 91
-2 100 -37 172 -68 136 -188 217 -336 224 -42 2 -94 -1 -116 -6z m229 -101
c63 -30 124 -90 155 -154 34 -72 34 -198 0 -270 -31 -64 -91 -124 -155 -155
-72 -34 -198 -34 -270 0 -260 126 -238 495 35 594 63 23 170 16 235 -15z'
        />
        <path
          d='M450 745 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z'
        />
        <path
          d='M290 665 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z'
        />
        <path
          d='M560 614 c-36 -36 -79 -72 -97 -79 -36 -15 -51 -52 -34 -84 23 -43
92 -35 106 11 4 13 39 56 78 95 l71 72 -24 26 c-13 14 -26 25 -30 25 -3 0 -35
-30 -70 -66z'
        />
        <path
          d='M210 505 c-15 -18 -10 -45 13 -59 34 -22 73 27 47 59 -16 19 -44 19
-60 0z'
        />
        <path
          d='M690 505 c-26 -32 13 -81 48 -59 22 14 27 41 12 59 -16 19 -44 19
-60 0z'
        />
        <path
          d='M290 345 c-15 -18 -10 -45 13 -59 34 -22 73 27 47 59 -16 19 -44 19
-60 0z'
        />
        <path
          d='M610 345 c-15 -18 -10 -45 13 -59 34 -22 73 27 47 59 -16 19 -44 19
-60 0z'
        />
      </g>
    </svg>
  </div>
)

export default Icon
