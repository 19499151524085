import React from 'react'

import {
  CLIENT_SUBS_KEY,
  CLIENT_VISITS_KEY,
  IN_HOUSE_INTERVIEWS_KEY,
  JOB_ORDERS_KEY,
  LEADS_KEY,
  PIPELINES_KEY,
  RECRUITING_DBAS_KEY,
  RECRUITING_DEALS_KEY,
  RECRUITING_DIALS_KEY,
  RECRUITING_SENDOUTS_KEY,
  SALES_DBAS_KEY,
  SALES_DEALS_KEY,
  SALES_DIALS_KEY,
  SALES_SENDOUTS_KEY,
  SCHEDULED_CALLS_KEY,
  SUBS_KEY
} from '@/shared/constants/dataKeys'
import { COLUMN_MOD_KEYS } from '@/shared/constants/local'
import { IAppColumnConfig } from '@/shared/components/Table'
import { MyPeopleTableRecord } from './formatData'
import { SpacingRow } from '@/shared/components/Table/helpers/addSpacingElements'

// TODO: Factor in empty spacing rows for sorting
// Spacing has been disabled for this table for the moment
const handleSortSpacing = <RecordType,>(
  a: RecordType | SpacingRow<RecordType>,
  b: RecordType | SpacingRow<RecordType>,
  sortFn: (_a: RecordType, _b: RecordType) => number
) => {
  if ('isSpacing' in a && a.isSpacing) {
    const sort = 'key' in b ? a.sort?.[b.key] : undefined
    if (typeof sort === 'number') {
      return sort
    }
    return !('isSpacing' in b) ? sortFn(a.referenceRow, b) : 0
  } else if ('isSpacing' in b && b.isSpacing) {
    const sort = 'key' in a ? b.sort?.[a.key] : undefined
    if (typeof sort === 'number') {
      return 0 - sort
    }
    return !('isSpacing' in a) ? sortFn(a, b.referenceRow) : 0
  } else if (!('isSpacing' in a) && !('isSpacing' in b)) {
    return sortFn(a, b)
  }
  return 0
}

const generateMetricsConfig = (title: string, key: string): IAppColumnConfig<MyPeopleTableRecord | SpacingRow<MyPeopleTableRecord>> => ({
  title,
  dataIndex: [key, 'actual'],
  key: key,
  align: 'right',
  sortFn: (a, b) => {
    return handleSortSpacing(a, b, (_a: typeof a, _b: typeof b) => _b[key]?.actual - _a[key]?.actual)
  },
  showSorterTooltip: false,
  columnMod: [COLUMN_MOD_KEYS.METRIC],
  renderComponent: record => (record[key].actual || 0)
})

const firstTwoColumns: IAppColumnConfig<MyPeopleTableRecord | SpacingRow<MyPeopleTableRecord>>[] = [
  {
    title: 'Employee',
    dataIndex: 'employeeName',
    key: 'employeeName',
    ellipsis: true,
    width: '20%',
    sortFn: (a, b) => {
      const fn = (_a: typeof a, _b: typeof b) => 'lastName' in a && 'lastName' in b ? a.lastName?.localeCompare(b.lastName) : 0
      return handleSortSpacing(a, b, fn)
    },
    sortDirections: ['ascend' as const, 'descend' as const],
    showSorterTooltip: false,
    columnMod: [COLUMN_MOD_KEYS.TITLE]
  },
  {
    title: 'Division',
    dataIndex: 'divisionName',
    key: 'division',
    ellipsis: true,
    width: '15%',
    align: 'left' as const,
    sortFn: (a, b) => {
      const fn = (_a: typeof a, _b: typeof b) => 'divisionName' in a && 'divisionName' in b ? a.divisionName?.localeCompare(b.divisionName) : 0
      return handleSortSpacing(a, b, fn)
    },
    sortDirections: ['ascend' as const, 'descend' as const],
    showSorterTooltip: false,
    columnMod: [COLUMN_MOD_KEYS.TITLE],
    renderComponent: record => {
      const otherText = 'childTable' in record && record.childTable?.length > 2 ? 'Others' : 'Other'
      const text =`${'divisionName' in record ? record.divisionName : ''}${'childTable' in record && record.childTable?.length > 1 ? ` & ${record.childTable.length - 1} ${otherText}` : ''}`
      return (
        <span title={text}>
          {text}
        </span>
      )
    }
  }
]

const accountManagementPulseByDivisionConfig = (includeGovernment): IAppColumnConfig<MyPeopleTableRecord>[] =>
  [
    ...firstTwoColumns,
    generateMetricsConfig('Dials', SALES_DIALS_KEY),
    generateMetricsConfig('DBAs', SALES_DBAS_KEY),
    includeGovernment &&
      generateMetricsConfig('Client Visits', CLIENT_VISITS_KEY),
    generateMetricsConfig('Pipelines', PIPELINES_KEY),
    generateMetricsConfig('Jobs', JOB_ORDERS_KEY),
    generateMetricsConfig('Send Outs', SALES_SENDOUTS_KEY),
    generateMetricsConfig('Deals', SALES_DEALS_KEY)
  ].filter((v) => v)

const recruitingPulseByDivisionConfig = (includeGovernment): IAppColumnConfig<MyPeopleTableRecord>[] =>
  [
    ...firstTwoColumns,
    generateMetricsConfig('Dials', RECRUITING_DIALS_KEY),
    includeGovernment &&
      generateMetricsConfig('Sched. Calls', SCHEDULED_CALLS_KEY),
    generateMetricsConfig('DBAs', RECRUITING_DBAS_KEY),
    generateMetricsConfig('Subs', SUBS_KEY),
    generateMetricsConfig('Client Subs', CLIENT_SUBS_KEY),
    includeGovernment &&
      generateMetricsConfig('In-Houses', IN_HOUSE_INTERVIEWS_KEY),
    generateMetricsConfig('Send Outs', RECRUITING_SENDOUTS_KEY),
    generateMetricsConfig('Deals', RECRUITING_DEALS_KEY),
    generateMetricsConfig('Leads', LEADS_KEY)
  ].filter((v) => v)

export {
  accountManagementPulseByDivisionConfig,
  recruitingPulseByDivisionConfig
}
