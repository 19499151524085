import React from 'react'
import { Skeleton } from 'antd'

import { useAppSelector } from '@/shared/hooks'
import RatioBox from './components/RatioBox'
import { selectActiveDashboardTabKey, selectShowDashboardLoader } from '../../redux/dashboardSelectors'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import { AM_RATIO_BOXES, RECRUITING_RATIO_BOXES, AWO_BOXES } from './helpers/configs'
import { selectCompanyDataCalculations, selectInitialLoad, selectReportResponseLoading } from '@/features/shared/redux/sharedSelectors'
import { hideAwoBoxes } from '@/bootstrap/configs/dashboard'

const RatioSkeleton = () => {
  return (
    <div className='c-ratio-box__group-skeleton'>
      <Skeleton.Input active style={{ width: 200, marginBottom: 20 }} />
    </div>
  )
}

const AwoRow = ({ calculations }) => {
  if (hideAwoBoxes) return null

  return (
    <div className='c-ratio-box__row'>
      {AWO_BOXES.map((c) => (
        <RatioBox key={c.key} {...c} value={calculations?.[c.dataIndex]} />
      ))}
    </div>
  )
}

const RatioBoxGroup = () => {
  const dashboardTab = useAppSelector(selectActiveDashboardTabKey)
  const calculations = useAppSelector(selectCompanyDataCalculations)
  const showDashboardLoader = useAppSelector(selectShowDashboardLoader)
  const initialLoad = useAppSelector(selectInitialLoad)
  const fetchingReport = useAppSelector(selectReportResponseLoading)
  const config = dashboardTab === DASHBOARD_TAB_KEYS.AM ? AM_RATIO_BOXES : RECRUITING_RATIO_BOXES

  if (!initialLoad || fetchingReport || showDashboardLoader) {
    return <RatioSkeleton />
  }

  return (
    <div className='c-ratio-box__group'>
      <div className='c-ratio-box__row'>
        {config.map(({ key, title, dataIndex, variant }) => (
          <RatioBox
            key={key}
            title={title}
            variant={variant}
            {...(calculations || {})[dataIndex]}
          />
        ))}
      </div>
      <AwoRow calculations={calculations} />
    </div>
  )
}

export default RatioBoxGroup
