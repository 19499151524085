// @ts-strict
import React, { useEffect } from 'react'
import { Table, Skeleton } from '@/shared/components'
import useOverTimeData from './hooks/useOverTimeData'
import withApolloProvider from '@/shared/hoc/withApolloProvider'
import { spreadClient } from '@/bootstrap/apollo'
import { Alert } from '@/shared/components/Icons'
import { useGetMySpreadOvertimeLazyQuery } from '@/__generated__/gql-types'
import { useAppSelector } from '@/shared/hooks'

// AKA "Spread Over Time"
const MySpreadOvertimeTable = () => {
  const [getData, { data, loading, error }] = useGetMySpreadOvertimeLazyQuery()
  const { tableConfig, columnConfig } = useOverTimeData(data?.spreadOverTime)
  const userBullHornId = useAppSelector(
    (state) => state.spread.bullhornId
  )
  useEffect(() => {
    if (userBullHornId)
    getData({ variables: { bullHornId: userBullHornId }})
  }, [getData, userBullHornId])

  return (
    <>
      {error && (
        <div role='alert' aria-live='polite' className='c-component-error'>
          <Alert position='left' />
          The data table could not be loaded at this time
        </div>
      )}
      {!error && (
        <Skeleton.Table
          active
          title
          loading={loading || !tableConfig.length}
          config={Skeleton.Table.PRESET_1}
        >
          <h3 className='c-type c-type--table-header-text'>
            My Spread Over Time
          </h3>
          <Table
            sticky
            tableConfig={tableConfig}
            columnConfig={columnConfig as []}
            defaultExpandAllRows={false}
            includeTotalsRow
          ></Table>
        </Skeleton.Table>
      )}
    </>
  )
}

export default withApolloProvider({ client: spreadClient })(
  MySpreadOvertimeTable
)
