// @ts-strict
import { ROW_MOD_KEYS } from '@/shared/constants/local'

const defaultClassname = 'c-table__cell--data'

/**
 * Creates props to pass to antd cell
 * @param {Object} record - row data
 * @param {String[]} record.rowMod - array of row modifiers
 * @param {Object} record.cellClassnames - Object where each key is the cell's dataIndex and value is class to apply to the cell
 * @return {Object[]} - Array of column config objects
 */
const renderCellProps = (record, rowIndex, config) => {
  const { rowMod, cellClassnames = {} } = record
  const { dataIndex } = config
  let className =
    cellClassnames[dataIndex] || cellClassnames.default || defaultClassname
  if (rowMod?.includes(ROW_MOD_KEYS.QUALITY)) {
    const numObj = record[dataIndex[0]]
    const quality = numObj?.quality
    if (quality) {
      if (quality === -2) className += ' u-bg-pale-red'
      if (quality === -1) className += ' u-bg-pale-yellow'
      if (quality >= 1) className += ' u-bg-pale-green'
    }
  }
  if (rowMod?.includes(ROW_MOD_KEYS.HIGH_SPREAD)) {
    if (record.isHighSpread) className += ' c-high_spread u-bg-pale-green'
  }

  return {
    className: `c-table__cell ${className}`
  }
}

export default renderCellProps
