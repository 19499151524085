import React from 'react'

import { BDE_KEY } from '@/shared/constants/dataKeys'
import { BDE_WIDTH, COLUMN_MOD_KEYS } from '@/shared/constants/local'
import { IAppColumnConfig } from '@/shared/components/Table'
import { ItemizedMetric } from '@/shared/types/swagger'
import {
  CLIENT_SUBS_KEY,
  CLIENT_VISITS_KEY,
  IN_HOUSE_INTERVIEWS_KEY,
  JOB_ORDERS_KEY,
  LEADS_KEY,
  PIPELINES_KEY,
  RECRUITING_DBAS_KEY,
  RECRUITING_DEALS_KEY,
  RECRUITING_SENDOUTS_KEY,
  SALES_DBAS_KEY,
  SALES_DEALS_KEY,
  SALES_SENDOUTS_KEY,
  SCHEDULED_CALLS_KEY,
  SUBS_KEY
} from '@/shared/constants/dataKeys'

const getLabelName = (metric): string => {
  const metricToLabel = {
    [CLIENT_SUBS_KEY]: 'Candidate',
    [CLIENT_VISITS_KEY]: 'Contact',
    [IN_HOUSE_INTERVIEWS_KEY]: 'Candidate',
    [JOB_ORDERS_KEY]: 'Job Title',
    [LEADS_KEY]: 'Lead',
    [PIPELINES_KEY]: 'Opportunity Title',
    [RECRUITING_DBAS_KEY]: 'Candidate',
    [RECRUITING_DEALS_KEY]: 'Job Title',
    [RECRUITING_SENDOUTS_KEY]: 'Candidate',
    [SALES_DBAS_KEY]: 'Contact',
    [SALES_DEALS_KEY]: 'Job Title',
    [SALES_SENDOUTS_KEY]: 'Candidate',
    [SCHEDULED_CALLS_KEY]: 'Candidate',
    [SUBS_KEY]: 'Candidate'
  }

  return metricToLabel[metric] || 'Candidate'
}

// lastName is added into record in createTableConfig function
const itemizedColumnConfig = (metric: string): IAppColumnConfig<ItemizedMetric & { lastName: string }>[] => [
  {
    title: 'Sector/Division/Employee',
    dataIndex: BDE_KEY,
    key: BDE_KEY,
    ellipsis: true,
    width: BDE_WIDTH,
    sortFn: (a, b) => a.lastName.localeCompare(b.lastName),
    sortDirections: ['ascend' as const, 'descend' as const],
    showSorterTooltip: false,
    columnMod: [COLUMN_MOD_KEYS.TITLE]
  },
  {
    title: getLabelName(metric),
    dataIndex: 'label',
    key: 'label',
    sortFn: (a, b,) => a.sort.localeCompare(b.sort),
    showSorterTooltip: false
  },
  {
    title: 'Date Added',
    dataIndex: 'date',
    key: 'date',
    sortFn: (a, b) => {
      return a.date - b.date
    },
    renderComponent: (record) => {
      if (record.date) {
        const year = record.date.toString().substring(2,4)
        const month = record.date.toString().substring(4,6)
        const day = record.date.toString().substring(6,8)
        return `${month}/${day}/${year}`
      }
      return record.date
    },
    showSorterTooltip: false,
    columnMod: [COLUMN_MOD_KEYS.DATE]
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sortFn: (a, b) => a.id.localeCompare(b.id),
    showSorterTooltip: false,
    renderComponent: (record) => {
      return (
        <a href={record.bullhornUrl} target='_blank' rel='noopener noreferrer' className='c-link c-link--default'>
          {record.id}
        </a>
      )
    }
  }
]

export default itemizedColumnConfig
