import React from 'react'
import { Popover, Button, Input, Switch } from 'antd'
import {
  setServiceErrorMessage,
  setShowServiceErrorScreen,
  setSsoError
} from '@/features/shared/redux/sharedActions'
import {
  selectShowServiceErrorScreen,
  selectSsoFailure
} from '@/features/shared/redux/sharedSelectors'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { setBullhornId } from '@/features/Spread/redux/spreadSlice'

const QAMenu = () => {
  const dispatch = useAppDispatch()
  const ssoError = useAppSelector(selectSsoFailure)
  const showServiceError = useAppSelector(selectShowServiceErrorScreen)
  const setErrorMessage = (e) => {
    dispatch(setServiceErrorMessage(e.target.value))
  }
  const onSssoSliderChange = () => {
    dispatch(setSsoError(!ssoError))
  }

  const onServiceErrorSliderChange = () => {
    dispatch(setShowServiceErrorScreen(!showServiceError))
  }

  const setSpecificBullhornId = (e) => {
    dispatch(setBullhornId(e.target.value))
  }

  return (
    <div>
      <div className='c-qa-btn__menu-item' key='2'>
        <span className='c-type--bold'>
          ALK-744 SSO Outage (404 variant) (Toggle to enable/disable page)
        </span>
        <Switch checked={ssoError} onClick={onSssoSliderChange} />
      </div>
      <div className='c-qa-btn__menu-item' key='3'>
        <span className='c-type--bold'>
          ALK-891 Service Error Screen (Toggle to enable/disable page)
        </span>
        <Switch
          checked={showServiceError}
          onClick={onServiceErrorSliderChange}
        />
      </div>
      <div className='c-qa-btn__menu-item' key='4'>
        <span className='c-type--bold'>
          ALK-1118 Set Service Error Message (Type message then press enter)
        </span>
        <Input
          placeholder='Enter error message to display'
          onPressEnter={setErrorMessage}
        />
      </div>
      <div className='c-qa-btn__menu-item' key='5'>
        <span className='c-type--bold'>
          (Spread) Force specific Bullhorn ID (Type the ID then press enter)
        </span>
        <Input
          type='number'
          placeholder='Enter Bullhorn ID'
          onPressEnter={setSpecificBullhornId}
        />
      </div>
    </div>
  )
}

const QAButton = () => {
  return (
    <div className='c-qa-btn'>
      <Popover
        placement='bottomRight'
        title={'QA Actions'}
        content={<QAMenu />}
        trigger='click'
      >
        <Button shape='circle'>QA</Button>
      </Popover>
    </div>
  )
}

export default QAButton
