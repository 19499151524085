import React from 'react'
import Icon, { IconPositionType } from './Icon'

type CheckProps = {
  position?: IconPositionType;
}

const Check = ({ position }: CheckProps) => (
  <Icon position={position}>
    <svg
      width='18'
      height='18'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M9 .9a8.1 8.1 0 100 16.2A8.1 8.1 0 009 .9zM7.38 13.385L3.567 9.573l1.146-1.146 2.667 2.668 5.907-5.908 1.146 1.146-7.053 7.052z'
          id='prefix__a--check'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b--check' fill='#fff'>
          <use xlinkHref='#prefix__a--check' />
        </mask>
        <use fill='#000' fillRule='nonzero' xlinkHref='#prefix__a--check' />
        <path fill='#45B118' mask='url(#prefix__b--check)' d='M0 0h18v18H0z' />
      </g>
    </svg>
  </Icon>
)

export default Check
