import React from 'react'
import Icon from './Icon'

const Dash = ({ position }) => (
  <Icon position={position}>
    <svg
      width='16'
      height='4'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <rect id='prefix__a' x='0' y='0' width='16' height='3.8' rx='.5' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b' fill='#fff'>
          <use xlinkHref='#prefix__a' />
        </mask>
        <use fill='#D8D8D8' xlinkHref='#prefix__a' />
        <path fill='#13215B' mask='url(#prefix__b)' d='M0-7h16V9H0z' />
      </g>
    </svg>
  </Icon>
)

export default Dash
