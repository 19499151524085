import Excel, { BorderStyle, Fill } from 'exceljs'
import { numFmtOptions } from '@/shared/constants/excel'
import { CurrentWeekStringKeys } from '../CurrentWeekTable/helpers/columConfigs'

interface CurrentWeekColumnHeader extends Partial<Excel.Column> {
  header: string
  key: CurrentWeekStringKeys
  width: number
}

const borderStyle = { style: 'thin' as BorderStyle, color: { argb: '000' } }

const headerStyle = {
  fill: {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'ffffe0'}
  } as Fill,
  border: {
    top: borderStyle,
    left: borderStyle,
    bottom: borderStyle,
    right: borderStyle
  },
  font: {
    name: 'Calibri',
    size: 11,
    bold: true
  }
}

const currentWeekColumnHeaders: Array<CurrentWeekColumnHeader> = [
  { header: 'Record ID', key: 'recordId', width: 13.2 },
  { header: 'Week', key: 'week', width: 7 },
  { header: 'Reference Week', key: 'referenceWeek', width: 21 },
  { header: 'Client', key: 'client', width: 43 },
  { header: 'Consultant', key: 'consultant', width: 32.4 },
  { header: 'Type', key: 'type', width: 61.8 },
  { header: 'Total Billed Hours', key: 'totalBilledHours', width: 16.8 },
  { header: 'Bill Rate', key: 'billRate', width: 11 },
  { header: 'OT Bill Rate', key: 'oTBillRate', width: 11.6 },
  { header: 'DT Bill Rate', key: 'dTBillRate', width: 11.6 },
  { header: 'Total Billed Amount', key: 'totalBilledAmount', width: 20.4 },
  { header: 'Total Paid Hours', key: 'totalPaidHours', width: 16 },
  { header: 'Pay Rate', key: 'payRate', width: 11 },
  { header: 'OT Pay Rate', key: 'oTPayRate', width: 12.4 },
  { header: 'DT Pay Rate', key: 'dTPayRate', width: 12.2 },
  { header: 'Total Paid Amount', key: 'totalPaidAmount', width: 19.4 },
  { header: 'Net Spread %', key: 'netSpreadPct', width: 13.4 },
  { header: 'Net Spread', key: 'netSpread', width: 12.8 },
  { header: 'Recruiter', key: 'recruiter', width: 22.4 },
  { header: 'Salesperson', key: 'salesperson', width: 17.4 },
  { header: 'Department', key: 'department', width: 15.8 },
  { header: 'Training Center', key: 'trainingCenter', width: 15.2 },
  { header: 'BDM', key: 'bDM', width: 17.2 },
  { header: 'FT/PT', key: 'pTFT', width: 8 },
]

const currentWeekHeaderCells = [
  'A1',
  'B1',
  'C1',
  'D1',
  'E1',
  'F1',
  'G1',
  'H1',
  'I1',
  'J1',
  'K1',
  'L1',
  'M1',
  'N1',
  'O1',
  'P1',
  'Q1',
  'R1',
  'S1',
  'T1',
  'U1',
  'V1',
  'W1',
  'X1'
]

const currentWeekCurrencyCellKeys: Array<CurrentWeekStringKeys> = [
  'totalPaidAmount',
  'netSpread',
  'totalBilledAmount'
]

const currentWeekNumberCellKeys: Array<CurrentWeekStringKeys> = [
  'totalBilledHours',
  'billRate',
  'oTBillRate',
  'dTBillRate',
  'totalPaidHours',
  'payRate',
  'oTPayRate',
  'dTPayRate'
]

const generateCwWorksheet = (workbook: Excel.Workbook) => {
  const cwWorksheet = workbook.addWorksheet('Current Week', {views: [{showGridLines: false}]})
  cwWorksheet.columns = currentWeekColumnHeaders
  currentWeekHeaderCells
    .forEach(key => {
      cwWorksheet.getCell(key).border = headerStyle.border
      cwWorksheet.getCell(key).fill = headerStyle.fill
      cwWorksheet.getCell(key).font = headerStyle.font
    })
  currentWeekCurrencyCellKeys
    .forEach(key => {
      cwWorksheet.getColumn(key).numFmt = numFmtOptions.CURRENCY
    })
  currentWeekNumberCellKeys
    .forEach(key => {
      cwWorksheet.getColumn(key).numFmt = numFmtOptions.NUMBER
    })
  cwWorksheet.getColumn('netSpreadPct').numFmt = numFmtOptions.PERCENT
  cwWorksheet.getColumn('netSpreadPct').alignment = { horizontal: 'right' }
  return cwWorksheet
}

export default generateCwWorksheet
