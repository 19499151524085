import React from 'react'
import Icon from './Icon'

const TimeAndDate = ({ position, margin, scale, height = 18, width = 18 }) => {
  return (
    <Icon position={position} margin={margin}>
      <svg
        width={`${scale}rem` || width}
        height={`${scale}rem` || height}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        x='0px'
        y='0px'
        viewBox='0 0 18 18'
      >
        <defs>
          <path
            d='M1.714 0C.767 0 0 .767 0 1.714v12c0 .947.767 1.715 1.714 1.715h4.79a6.781 6.781 0 01-.445-1.715H1.714V5.143h12v.916c.6.074 1.174.225 1.715.445v-4.79C15.429.767 14.66 0 13.714 0h-12zm0 1.714h12V3.43h-12V1.714zM3.43 6.857v1.714h1.714V6.857H3.429zm3.428 0v1.714h.651a6.893 6.893 0 012.033-1.714H6.857zm6 .857V9.43a3.439 3.439 0 013.429 3.428 3.439 3.439 0 01-3.429 3.429c-1.807 0-3.282-1.421-3.405-3.198l-1.557-1.557a5.13 5.13 0 00-.18 1.326c0 2.829 2.314 5.143 5.142 5.143C15.686 18 18 15.686 18 12.857c0-2.828-2.314-5.143-5.143-5.143zm-2.986.944L8.658 9.871l3.593 3.592a.855.855 0 001.212 0 .856.856 0 000-1.212L9.871 8.658zm-6.442 1.628V12h1.714v-1.714H3.429z'
            id='prefix__a--time-and-date'
          />
        </defs>
        <g fill='none' fillRule='evenodd'>
          <mask id='prefix__b--time-and-date' fill='#fff'>
            <use xlinkHref='#prefix__a--time-and-date' />
          </mask>
          <use
            fill='#000'
            fillRule='nonzero'
            xlinkHref='#prefix__a--time-and-date'
          />
          <path
            fill='#404555'
            mask='url(#prefix__b--time-and-date)'
            d='M-1-1h20v20H-1z'
          />
        </g>
      </svg>
    </Icon>
  )
}

export default TimeAndDate
