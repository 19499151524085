import {
  CLIENT_SUBS_KEY,
  CLIENT_VISITS_KEY,
  IN_HOUSE_INTERVIEWS_KEY,
  JOB_ORDERS_KEY,
  LEADS_KEY,
  PIPELINES_KEY,
  RECRUITING_DBAS_KEY,
  RECRUITING_DEALS_KEY,
  RECRUITING_DIALS_KEY,
  RECRUITING_SENDOUTS_KEY,
  SALES_DBAS_KEY,
  SALES_DEALS_KEY,
  SALES_DIALS_KEY,
  SALES_SENDOUTS_KEY,
  SCHEDULED_CALLS_KEY,
  SUBS_KEY
} from '@/shared/constants/dataKeys'
import { FunnelTarget } from '@/shared/types'

export const accountManagementConfig = (includeGovernment: boolean): FunnelTarget[] => [
  { title: 'Dials', dataIndex: SALES_DIALS_KEY, disabled: true, apiName: 'SalesDba' as const },
  { title: 'DBAs', dataIndex: SALES_DBAS_KEY, apiName: 'SalesDba' as const },
  includeGovernment && {
    title: 'Client Visits',
    dataIndex: CLIENT_VISITS_KEY,
    apiName: 'ClientVisit' as const
  },
  { title: 'Pipelines', dataIndex: PIPELINES_KEY, apiName: 'Pipeline' as const },
  { title: 'Jobs', dataIndex: JOB_ORDERS_KEY, apiName: 'JobOrder' as const },
  { title: 'Send Outs', dataIndex: SALES_SENDOUTS_KEY, apiName: 'SalesSendout' as const },
  { title: 'Deals', dataIndex: SALES_DEALS_KEY, apiName: 'SalesDeal' as const }
].filter(v => v)

export const recruitingConfig = (includeGovernment: boolean): FunnelTarget[] =>
  [
    { title: 'Dials', dataIndex: RECRUITING_DIALS_KEY, disabled: true, apiName: 'RecruiterDba' as const },
    includeGovernment && {
      title: 'Sched. Calls',
      dataIndex: SCHEDULED_CALLS_KEY,
      apiName: 'ScheduledCall' as const
    },
    { title: 'DBAs', dataIndex: RECRUITING_DBAS_KEY, apiName: 'RecruiterDba' as const },
    { title: 'Subs', dataIndex: SUBS_KEY, apiName: 'Submission' as const },
    { title: 'Client Subs', dataIndex: CLIENT_SUBS_KEY, apiName: 'ClientSubmission' as const },
    includeGovernment && {
      title: 'In-Houses',
      dataIndex: IN_HOUSE_INTERVIEWS_KEY,
      apiName: 'InhouseInterview' as const
    },
    { title: 'Send Outs', dataIndex: RECRUITING_SENDOUTS_KEY, apiName: 'RecruiterSendout' as const },
    { title: 'Deals', dataIndex: RECRUITING_DEALS_KEY, isLastTarget: true, apiName: 'RecruiterDeal' as const },
    { title: 'Leads', dataIndex: LEADS_KEY, isGroupTarget: false, height: '6.6rem', apiName: 'Lead' as const }
  ].filter((v) => v)

export const allTargetGroupOptions = [
  ...accountManagementConfig(true),
  ...recruitingConfig(true)
]
