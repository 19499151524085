import React from 'react'
import { SPREAD_PR_VIEW_KEYS } from '@/shared/constants/local'
import { Button } from 'antd'
import PropTypes from 'prop-types'

const PowerRankingViewSelector = ({ activeView, setActiveView }) => {
  const changeView = (v) => {
    setActiveView(v)
  }
  return (
    <div className='c-spread__header__view-selector'>
      <Button
        onClick={(e) => changeView(SPREAD_PR_VIEW_KEYS.CURRENT_WEEK_VIEW)}
        className={
          activeView === SPREAD_PR_VIEW_KEYS.CURRENT_WEEK_VIEW
            ? 'activeBtn'
            : ''
        }
      >
        {SPREAD_PR_VIEW_KEYS.CURRENT_WEEK_VIEW}
      </Button>
      <Button
        onClick={(e) => changeView(SPREAD_PR_VIEW_KEYS.ANUAL_VIEW)}
        className={
          activeView === SPREAD_PR_VIEW_KEYS.ANUAL_VIEW ? 'activeBtn' : ''
        }
      >
        {SPREAD_PR_VIEW_KEYS.ANUAL_VIEW}
      </Button>
    </div>
  )
}

PowerRankingViewSelector.propTypes = {
  activeView: PropTypes.string,
  setActiveView: PropTypes.func
}

export default PowerRankingViewSelector
