import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getAccessToken } from '../msal'

const API_HOST = process.env.REACT_APP_API_HOST
const options = { baseURL: API_HOST }
const axiosInstance = axios.create(options)

axiosInstance.interceptors.response.use(
  (response) => response.data
  // error => logoutAdal(error)
)

export const request = async <R extends AxiosResponse>(
  options: AxiosRequestConfig
): Promise<R['data']> => {
  const token = await getAccessToken()
  const headers = {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
    ...(options.headers || {})
  }
  if (token && !headers.Authorization) {
    headers.Authorization = `Bearer ${token}`
  }

  return axiosInstance.request<unknown, R>({ ...options, headers })
}

export function get<R extends AxiosResponse>(
  url: string,
  config: AxiosRequestConfig = {}
) {
  return request<R>({ method: 'get', url, ...config })
}

export function post<P, R extends AxiosResponse>(
  url: string,
  data: P,
  config: AxiosRequestConfig = {}
) {
  return request<R>({ method: 'post', url, data, ...config })
}

export function patch<P, R extends AxiosResponse>(
  url: string,
  data: P,
  config: AxiosRequestConfig = {}
) {
  return request<R>({ method: 'patch', url, data, ...config })
}

export function del(url: string, config: AxiosRequestConfig = {}) {
  return request({ method: 'delete', url, ...config })
}

export function put<P, R extends AxiosResponse>(
  url: string,
  data: P,
  config: AxiosRequestConfig = {}
) {
  return request<R>({ method: 'put', url, data, ...config })
}

export default axiosInstance
