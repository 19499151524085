import Excel, { BorderStyle } from 'exceljs'

import { getMonthNameFromFiscalMonth } from '@/utils/tools/format'
import { numFmtOptions } from '@/shared/constants/excel'
import { FoundersRecord } from '../FoundersReport/helpers/query'
import formatFoundersData, { FoundersBrandDepartmentMapping, FoundersGroupedByMonth, FoundersGroupedByQuarter } from './formatFoundersData'

interface FoundersColumnHeader extends Partial<Excel.Column> {
  header?: string
  key: string
  width: number
}

type GenerateColumnDataReturn = {
  columns: FoundersColumnHeader[]
  brandEndKeys: [string, string][]
  departmentEndKeys: [string, string][]
  userColKeys: string[]
  spacingColKeys: string[]
}

const firstColKey = 'referenceWeek'

const thinBorderStyle = { style: 'thin' as BorderStyle, color: { argb: '000' } }
const medBorderStyle = { style: 'medium' as BorderStyle, color: { argb: '000' } }
const doubleBorderStyle = { style: 'double' as BorderStyle, color: { argb: '000' } }

const generateColumnData = (brandDepartmentMapping: FoundersBrandDepartmentMapping): GenerateColumnDataReturn => {
  const userColWidth = 13.40
  const firstCol: FoundersColumnHeader = { key: firstColKey, width: 22 }
  const brandEndKeys: [string, string][] = []
  const departmentEndKeys: [string, string][] = []
  const userColKeys: string[] = []
  const spacingColKeys: string[] = []
  const restCols = Object.entries(brandDepartmentMapping)
    .reduce<FoundersColumnHeader[]>((acc, [brand, departments], index) => {
      const brandStartColKey = `${brand}-start`
      const brandEndColKey = `${brand}-end`
      brandEndKeys.push([brandStartColKey, brandEndColKey])
      acc.push({
        header: brand,
        key: brandStartColKey,
        width: index === 0 ? 8 : userColWidth
      })
      spacingColKeys.push(brandStartColKey, brandEndColKey)
      Object.entries(departments)
        .forEach(([department, userRecords], departmentIndex, self) => {
          let departmentStartColKey = ''
          let departmentEndColKey = ''
          userRecords.forEach((user, userIndex) => {
            if (userIndex === 0) {
              departmentStartColKey = user.repId
            }
            if (userIndex === userRecords.length - 1) {
              departmentEndColKey = user.repId
            }
            acc.push({
              key: user.repId,
              width: userColWidth
            })
            userColKeys.push(user.repId)
          })
          if (departmentIndex !== self.length - 1) {
            const depEndKey = `${department}-end`
            acc.push({
              key: depEndKey,
              width: userColWidth
            })
            spacingColKeys.push(depEndKey)
          }
          departmentEndKeys.push([departmentStartColKey, departmentEndColKey])
        })
      acc.push({
        key: brandEndColKey,
        width: userColWidth
      })

      return acc
    }, [])
  const columns = [
    firstCol,
    ...restCols
  ]
  return {
    columns,
    brandEndKeys,
    departmentEndKeys,
    userColKeys,
    spacingColKeys
  }
}

const addReferenceWeeks = (worksheet: Excel.Worksheet, data: FoundersGroupedByMonth, userColKeys: GenerateColumnDataReturn['userColKeys']) => {
  const topSpacing = worksheet.addRow({})
  topSpacing.height = 25
  topSpacing.getCell(firstColKey).fill =  {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'CCCCCC'}
  }
  topSpacing.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle, top: thinBorderStyle }

  const weekRows = data.records.map(weekData => {
    const row = worksheet.addRow(weekData)
    row.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle }
    row.height = 21
    row.numFmt = numFmtOptions.ACCOUNTING
    return row
  })

  const bottomSpacing = worksheet.addRow({})
  bottomSpacing.height = 21
  bottomSpacing.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle, bottom: doubleBorderStyle }

  const totalMonthData = {
    referenceWeek: `${getMonthNameFromFiscalMonth(data.fiscalMonth)} Totals`
  }
  const monthRow = worksheet.addRow(totalMonthData)
  monthRow.height = 21
  monthRow.font = { name: 'Calibri', size: 11, bold: true }
  monthRow.numFmt = numFmtOptions.ACCOUNTING
  monthRow.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle, bottom: medBorderStyle }
  monthRow.getCell(firstColKey).alignment = { horizontal: 'right' }
  userColKeys.forEach(key => {
    bottomSpacing.getCell(key).border = { bottom: doubleBorderStyle }
    monthRow.getCell(key).border = { bottom: medBorderStyle }

    const startCell = weekRows[0].getCell(key).$col$row
    if (weekRows.length > 1) {
      const endCell = weekRows[weekRows.length - 1].getCell(key).$col$row
      monthRow.getCell(key).value = { formula: `SUM(${startCell}:${endCell})`, date1904: false }
    } else {
      monthRow.getCell(key).value = { formula: `${startCell}`, date1904: false }
    }
  })

  return monthRow
}

const addMonthTables = (worksheet: Excel.Worksheet, data: FoundersGroupedByQuarter, userColKeys: GenerateColumnDataReturn['userColKeys']) => {
  const monthRows = data.months.map(monthData => {
    return addReferenceWeeks(worksheet, monthData, userColKeys)
  })

  const topSpacing = worksheet.addRow({})
  topSpacing.height = 25
  topSpacing.getCell(firstColKey).fill =  {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'CCCCCC'}
  }
  topSpacing.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle, top: medBorderStyle, bottom: doubleBorderStyle }

  const totalQuarterData = {
    referenceWeek: `Quarter - ${data.fiscalQuarter} Totals`
  }
  const quarterRow = worksheet.addRow(totalQuarterData)
  quarterRow.numFmt = numFmtOptions.ACCOUNTING
  quarterRow.font = { name: 'Calibri', size: 11, bold: true }
  quarterRow.getCell(firstColKey).alignment = { horizontal: 'right' }

  userColKeys.forEach(key => {
    const sumCells = monthRows.map(r => {
      return r.getCell(key).$col$row
    })
    quarterRow.getCell(key).value = { formula: `${sumCells.join('+')}`, date1904: false }
  })

  const bottomSpacing = worksheet.addRow({})
  bottomSpacing.height = 5
  bottomSpacing.getCell(firstColKey).fill = { type: 'pattern', pattern:'solid', fgColor:{ argb: '000' } }

  userColKeys.forEach(key => {
    const sumCells = monthRows.map(r => {
      return r.getCell(key).$col$row
    })
    quarterRow.getCell(key).value = { formula: `${sumCells.join('+')}`, date1904: false }
    topSpacing.getCell(key).border = { top: medBorderStyle, bottom: doubleBorderStyle }
    bottomSpacing.getCell(key).border = { top: medBorderStyle }
  })

  return quarterRow
}

const addQuarterTables = (worksheet: Excel.Worksheet, data: FoundersGroupedByQuarter[], userColKeys: GenerateColumnDataReturn['userColKeys']) => {
  const quarterRows = data.map(quarterData => {
    return addMonthTables(worksheet, quarterData, userColKeys)
  })
  const topSpacing = worksheet.addRow({})
  topSpacing.height = 25
  topSpacing.getCell(firstColKey).fill =  {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'CCCCCC'}
  }
  topSpacing.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle, top: medBorderStyle, bottom: doubleBorderStyle }

  const yearTotalData = {
    referenceWeek: 'YTD Total'
  }
  const yearRow = worksheet.addRow(yearTotalData)
  yearRow.numFmt = numFmtOptions.ACCOUNTING
  yearRow.getCell(firstColKey).alignment = { horizontal: 'right' }
  yearRow.font = { name: 'Calibri', size: 12, bold: true }
  userColKeys.forEach(key => {
    const sumCells = quarterRows.map(r => {
      return r.getCell(key).$col$row
    })
    yearRow.getCell(key).value = { formula: `${sumCells.join('+')}`, date1904: false }
    yearRow.getCell(key).border = { top: doubleBorderStyle, bottom: doubleBorderStyle }
  })
}

const generateFoundersWorksheet = (workbook: Excel.Workbook, allData: FoundersRecord[], sideOfHouse: 'Recruiters' | 'Sales') => {
  const sheetName = `Founders - ${sideOfHouse}`
  const foundersWorksheet = workbook.addWorksheet(sheetName, {views: [{state: 'frozen', ySplit: 7, xSplit: 1, showGridLines: false}]})
  const filterSideOfHouse = sideOfHouse === 'Recruiters' ? 'Recruiting' : sideOfHouse
  const data = allData.filter(v => v.sideOfHouse === filterSideOfHouse)
  const { brandDepartmentMapping, departmentRowValues, nameRowValues, dataByReferenceWeek } = formatFoundersData(data, sideOfHouse)
  const {
    columns,
    brandEndKeys,
    departmentEndKeys,
    userColKeys,
    spacingColKeys
  } = generateColumnData(brandDepartmentMapping)
  foundersWorksheet.columns = columns

  const headerRow = foundersWorksheet.getRow(1)
  headerRow.alignment = { horizontal: 'center', vertical: 'middle' }
  headerRow.font = { name: 'Microsoft Sans Serif', size: 14, bold: true }
  brandEndKeys.forEach(([startKey, endKey]) => {
    const startCell = headerRow.getCell(startKey)
    const endCell = headerRow.getCell(endKey)
    startCell.border = { left: thinBorderStyle, right: thinBorderStyle, top: thinBorderStyle, bottom: thinBorderStyle }
    foundersWorksheet.mergeCells(Number(startCell.row), Number(startCell.col), Number(endCell.row), Number(endCell.col))
  })
  headerRow.getCell(firstColKey).fill =  {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'CCCCCC'}
  }
  headerRow.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle, top: thinBorderStyle }

  const departmentRow = foundersWorksheet.addRow(departmentRowValues)
  departmentRow.font = { name: 'Microsoft Sans Serif', size: 12, bold: true }
  departmentRow.alignment = { horizontal: 'center', vertical: 'middle' }
  departmentEndKeys.forEach(([startKey, endKey]) => {
    const startCell = departmentRow.getCell(startKey)
    const endCell = departmentRow.getCell(endKey)
    startCell.border = { left: thinBorderStyle, right: thinBorderStyle, top: thinBorderStyle, bottom: thinBorderStyle }
    foundersWorksheet.mergeCells(Number(startCell.row), Number(startCell.col), Number(endCell.row), Number(endCell.col))
  })
  departmentRow.getCell(firstColKey).font = { name: 'Calibri', size: 12, bold: true }
  departmentRow.getCell(firstColKey).border = { left: thinBorderStyle, right: thinBorderStyle, bottom: thinBorderStyle }

  const nameRow = foundersWorksheet.addRow(nameRowValues)
  nameRow.height = 42
  nameRow.font = { name: 'Microsoft Sans Serif', size: 9, bold: true }
  nameRow.alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
  userColKeys.forEach(key => {
    nameRow.getCell(key).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }
  })
  nameRow.getCell(firstColKey).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
  nameRow.getCell(firstColKey).font = { name: 'Calibri', size: 12, bold: true, color: { argb: '4FAF58' } }
  nameRow.getCell(firstColKey).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }

  const weeklyFoundersNeededRow = foundersWorksheet.addRow({ referenceWeek: 'Weekly Founders Needed' })
  weeklyFoundersNeededRow.height = 27
  weeklyFoundersNeededRow.font = { name: 'Calibri', size: 11, bold: true, color: { argb: 'FD0200' } }
  weeklyFoundersNeededRow.alignment = { horizontal: 'center', vertical: 'bottom' }
  weeklyFoundersNeededRow.getCell(firstColKey).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }

  const averageWeeklySpreadRow = foundersWorksheet.addRow({ referenceWeek: 'Avg. Weekly Spread' })
  averageWeeklySpreadRow.height = 24
  averageWeeklySpreadRow.font = { name: 'Calibri', size: 11, bold: true, color: { argb: '4FAF58' } }
  averageWeeklySpreadRow.alignment = { horizontal: 'center', vertical: 'bottom' }
  averageWeeklySpreadRow.getCell(firstColKey).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }

  const onTargetRow = foundersWorksheet.addRow({ referenceWeek: 'On Target' })
  onTargetRow.height = 23
  onTargetRow.font = { name: 'Calibri', size: 11, bold: true, color: { argb: 'F59A40' } }
  onTargetRow.alignment = { horizontal: 'center', vertical: 'bottom' }
  onTargetRow.getCell(firstColKey).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }

  const topWeekRow = foundersWorksheet.addRow({ referenceWeek: 'Top Week' })
  topWeekRow.height = 23
  topWeekRow.font = { name: 'Calibri', size: 11, bold: true }
  topWeekRow.alignment = { horizontal: 'center', vertical: 'bottom' }
  topWeekRow.getCell(firstColKey).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }

  spacingColKeys.forEach(key => {
    topWeekRow.getCell(key).border = { bottom: thinBorderStyle }
  })

  userColKeys.forEach(key => {
    weeklyFoundersNeededRow.getCell(key).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }
    averageWeeklySpreadRow.getCell(key).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }
    onTargetRow.getCell(key).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }
    topWeekRow.getCell(key).border = { left: medBorderStyle, right: medBorderStyle, top: medBorderStyle, bottom: medBorderStyle }
  })

  addQuarterTables(foundersWorksheet, dataByReferenceWeek, userColKeys)

  return foundersWorksheet
}

export default generateFoundersWorksheet
