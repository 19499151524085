import React from 'react'
import { Menu, Dropdown as AntDropdown, DropDownProps as AntDropDownProps, MenuItemProps } from 'antd'
import { DownSolid } from '../Icons';
import { Link } from 'react-router-dom';

type MenuConfig = {
  icon?: React.ReactNode | HTMLElement
  text?: string
  to?: string
  onClick?: MenuItemProps['onClick']
  href?: HTMLAnchorElement['href']
}

type DropdownProps = {
  menuConfig: Array<MenuConfig>
  parentComponent: React.ReactNode | HTMLElement
  showArrow?: boolean
  className?: string
  overlayClassName?: string
  getPopupContainer?: AntDropDownProps['getPopupContainer'],
  disabled?: boolean
}

const Dropdown = ({
  menuConfig = [],
  parentComponent,
  showArrow = true,
  className = '',
  overlayClassName = '',
  getPopupContainer,
  disabled
} : DropdownProps) => {
  const menu = (
    <Menu>
      {menuConfig && menuConfig.map((c) => {
        if (c) {
          const {icon, text, onClick, to, href} = c
          return (
            <Menu.Item key={text} onClick={onClick}>
              {to ? (
                <Link
                  to={to}
                  className='c-dropdown__text c-dropdown__menu-item'
                >
                  <>
                    {icon}
                    {text}
                  </>
                </Link>
              ) : href ? (
                <a
                  href={href}
                  target='_blank' rel='noopener noreferrer'
                  className='c-dropdown__text c-dropdown__menu-item'
                >
                  {icon}
                  {text}
                </a>
              ) : (
                <span
                  className='c-dropdown__text c-dropdown__menu-item'
                >
                  {icon}
                  {text}
                </span>
              )}
            </Menu.Item>
          )
        } else {
          return null;
        }
      })}
    </Menu>
  )

  const _disabled = disabled || (!menuConfig || !menuConfig.filter(v => v).length)

  return (
    <AntDropdown
      overlay={menu}
      disabled={_disabled}
      className={className}
      overlayClassName={overlayClassName}
      getPopupContainer={getPopupContainer}
    >
      <div className={`c-dropdown c-dropdown__text${disabled ? ' disabled' : ''}`}>
        {parentComponent}
        {!disabled && !!showArrow && <DownSolid scale={1.4} position='right' />}
      </div>
    </AntDropdown>
  );
}

export default Dropdown
