import React from 'react'
import Button from '../../Button'
import { Attatchment, Close, Refresh } from '../../Icons'

const Controls = ({ onCancel, onReset, fileName, status }) => {
  return (
    <div className='c-upload-button__controls'>
      <div className='c-upload-button__file-name-container'>
        <Attatchment position='left' />{' '}
        <span className='c-upload-button__file-name'>{fileName}</span>
      </div>
      <div className='c-upload-button__actions'>
        {status === 'exception' ? (
          <Button styleAsLink onClick={onReset}>
            <Refresh />
          </Button>
        ) : null}
        <Button styleAsLink onClick={onCancel}>
          <Close />
        </Button>
      </div>
    </div>
  )
}

export default Controls
