import React, { useEffect } from 'react'

import Compass from '@/shared/components/Icons/Compass'
import TargetSpread from '@/shared/components/Icons/TargetSpread'
import { formatCurrency } from '../MySpread/helpers/helper'
import { useAppSelector } from '@/shared/hooks'
import { useGetFounderStatusLazyQuery } from '@/__generated__/gql-types'
import { Skeleton } from 'antd'
import withApolloProvider from '@/shared/hoc/withApolloProvider'
import { spreadClient } from '@/bootstrap/apollo'
import { FOUNDER_STATUS } from '@/shared/constants/dataKeys'
import COLOR_KEYS from '@/shared/constants/colors'

const FounderStatus = () => {
  const [getData, { data, loading, error }] = useGetFounderStatusLazyQuery()
  const userBullHornId = useAppSelector(
    (state) => state.spread.bullhornId
  )
  useEffect(() => {
    getData({ variables: { bullHornId: userBullHornId } })
  }, [getData, userBullHornId])

  const founderStatus = data?.founderRanks?.founderStatus
  const powerRanks = data?.founderRanks?.powerRanks
  const alkuRanking = data?.founderRanks?.powerRanks?.alkuRanking

  return (
    <div className='card card3'>
      <Skeleton loading={loading && !data && !error}>
        {founderStatus?.status && (
          <>
            <h1>Founder Status</h1>
            <h2 style={{ textTransform: 'capitalize' }}>
              <TargetSpread color={COLOR_KEYS['blue']} />
              <p>{founderStatus?.status?.replace(/_/g, ' ').toLowerCase()}</p>
            </h2>
            <div className='progress-bar'>
              <div
                className='progress-bar__fill'
                style={{
                  width: `${
                    founderStatus?.percentage > 100
                      ? 100
                      : founderStatus?.percentage
                  }%`,
                  backgroundColor:
                    founderStatus?.status === FOUNDER_STATUS.OFF_TARGET
                      ? COLOR_KEYS['orange']
                      : COLOR_KEYS['green']
                }}
              />
            </div>
            <p className='progress-bar-caption'>
              {founderStatus?.status === FOUNDER_STATUS.ACHIVED && (
                <>
                  You&apos;ve achieved Founder Status for{' '}
                  {founderStatus?.fiscalYear}!
                </>
              )}
              {founderStatus?.status !== FOUNDER_STATUS.ACHIVED && (
                <>
                  You need to average {formatCurrency(founderStatus?.average)}
                  /wk for {founderStatus?.fiscalYear}
                </>
              )}
            </p>
          </>
        )}
        {powerRanks && (
          <>
            <h1>Weekly Power Ranking</h1>
            <div className='founderRanking'>
              <Compass color={COLOR_KEYS['blue']} />
              <h3>#{powerRanks?.tenureRanking?.rank}</h3>
              <span>of {powerRanks?.tenureRanking?.total}</span>
              <span>
                Tenure
                {powerRanks?.tenure === 0 && <> 0-1 years</>}
                {powerRanks?.tenure === 1 && <> 1-2 years</>}
                {powerRanks?.tenure === 2 && <> 2-3 years</>}
                {powerRanks?.tenure > 2 && <> 3+ years</>}
              </span>
            </div>
            <div className='founderRanking'>
              <Compass color={COLOR_KEYS['blue']} />
              <h3>#{alkuRanking?.rank}</h3>
              <span>of {alkuRanking?.total}</span>
              <span>ALL Alku</span>
            </div>
          </>
        )}
      </Skeleton>
    </div>
  )
}

export default withApolloProvider({ client: spreadClient })(FounderStatus)
