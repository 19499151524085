import { CurrentWeekRecord } from './query'
import { ROW_MOD_KEYS } from '@/shared/constants/local'

const createTableConfig = (data: Array<CurrentWeekRecord>) => {
  try {
    const config = data.map(record => {

      return {
        ...record,
        rowMod: [
          ROW_MOD_KEYS.DATA_DISPLAY
        ],
        cellClassnames: {
          default: 'c-type--table-numeric'
        },
        key: record.recordId
      }
    }).filter(v => v)
    return config
  } catch (e) {
    console.log(e)
    return []
  }
}

export default createTableConfig
