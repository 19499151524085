import React from 'react'
import COLOR_KEYS from '@/shared/constants/colors'

type DownloadOutlinedProps = {
  fill?: string
}

const DownloadOutlined = ({ fill }: DownloadOutlinedProps) => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='18px'
      height='18px'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        fill={fill ? COLOR_KEYS[fill] || fill : '#404555'}
        stroke='none'
      >
        <path
          d='M380 860 c-18 -18 -20 -33 -20 -160 l0 -140 -87 0 -88 0 148 -147
147 -148 147 148 148 147 -88 0 -87 0 0 140 c0 177 -2 180 -120 180 -67 0 -83
-3 -100 -20z m140 -220 l0 -160 32 0 33 0 -53 -52 -52 -53 -52 53 -53 52 33 0
32 0 0 160 0 160 40 0 40 0 0 -160z'
        />
        <path d='M80 120 l0 -40 400 0 400 0 0 40 0 40 -400 0 -400 0 0 -40z' />
      </g>
    </svg>
  </div>
)

export default DownloadOutlined
