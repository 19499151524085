// @ts-strict
import React, { useCallback, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { UploadButton } from '@/shared/components'
import {
  clearNotices,
  dismissNoticeByDuplicateId,
  notify,
  notifyError
} from '@/features/shared/redux/sharedActions'
import { getPresignedUrl, setEtlIsProcessingTrue } from '@/utils/api/spread'
import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { GET_FOUNDERS_DATE, GET_LAST_UPDATED_QUERY } from './helpers/query'
import { formatLastUpdatedDate } from '@/utils/tools/dateTime'
import { selectEtlIsProcessing } from '@/features/Spread/redux/spreadSelectors'
import { setEtlIsProcessing, setSpreadStatus } from '@/features/Spread/redux/spreadSlice'
import { SpreadReportHomeRoute } from '@/features/navigation/components/Routes/routes'
import { client, spreadClient } from '@/bootstrap/apollo'
import { SPREAD_STATUS } from '@/shared/constants/dataKeys'

const Upload = ({ prefix, title }) => {
  const [redirect, setRedirect] = useState(false)
  /* 
    TODO: This needs to be refactored to use the new spreadClient.
    (Still using the old client for now to avoid breaking changes)
  */
  const { data, loading } = useQuery(
    prefix === 'Founders_' ? GET_FOUNDERS_DATE : GET_LAST_UPDATED_QUERY,
    { client: prefix === 'Founders_' ? spreadClient : client }
  )

  const dispatch = useAppDispatch()
  const isProcessing = useAppSelector(selectEtlIsProcessing)
  const onSuccess = useCallback(async () => {
    await setEtlIsProcessingTrue()
    dispatch(setEtlIsProcessing(true))
    dispatch(
      notify('Your file has been successfully uploaded.', {
        success: true,
        style: { width: 460 },
        clearOtherNotices: true,
        clearDuplicates: true,
        duplicateId: 'Your file has been successfully uploaded.'
      })
    )
    setTimeout(() => {
      dispatch(clearNotices())
      setRedirect(true)
    }, 2000)
  }, [dispatch])
  const onFail = () => {
    dispatch(
      notifyError('Your file could not be uploaded. Please try again.', {
        style: { width: 460 }
      })
    )
  }
  const onCancel = () => {
    dispatch(
      dismissNoticeByDuplicateId(
        'Your file could not be uploaded. Please try again.'
      )
    )
  }
  const getTargetUrl = async (
    file: File,
    { fileName }: { fileName?: string }
  ) => {
    try {
      const data = await getPresignedUrl(
        typeof fileName === 'string' ? fileName : file.name,
        10
      )
      return data.url
    } catch {
      onFail()
    }
  }
  const updatedDate =
    prefix === 'Founders_'
      ? data?.foundersDate
      : data?.weeklySpreadLastUpdated.lastUpdated
  const lastUpdatedDate =
    loading || (!loading && !data) ? '...' : formatLastUpdatedDate(updatedDate)

  if (redirect) {
    return <Redirect to={SpreadReportHomeRoute} />
  }
  const report_title = `${title}`
  return (
    <div>
      <br />
      <h1 className='c-type c-type--title-xl c-type--bold'>
        Upload {report_title} data
      </h1>
      <div className='c-spread__last-uploaded'>
        <div>Upload your spreadsheet below to update {report_title} data.</div>
        <div>
          Last upload:{' '}
          <span className='c-type--semi-bold'>{lastUpdatedDate}</span>
        </div>
      </div>
      <UploadButton
        fileNamePrefix={prefix}
        acceptedFileTypeLabel='Excel Spreadsheet'
        acceptedFileType={['.xlsx', '.xls']}
        onSuccess={() => {
          onSuccess()
          dispatch(setSpreadStatus(SPREAD_STATUS.NOT_APPROVED))
        }}
        onFail={onFail}
        onCancel={onCancel}
        targetUrl={getTargetUrl}
        disabled={isProcessing}
        tooltip={
          isProcessing ? 'Upload disabled while ETL is running.' : undefined
        }
      />
      <br/>
      <a href={SpreadReportHomeRoute}>
        Return
      </a>
    </div>
  )
}

Upload.propTypes = {
  prefix: PropTypes.oneOf(['Import_', 'Founders_']),
  title: PropTypes.string.isRequired
}

export default Upload
