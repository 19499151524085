// @ts-strict
import Excel, { BorderStyle, Fill } from 'exceljs'
import { numFmtOptions } from '@/shared/constants/excel'
import { TotalsStringKeys } from '../TotalsTable/helpers/columnConfig'
import { BaseTotalsRecord } from '../TotalsTable/helpers/query'
import groupTotalRecords, { TotalsRecordGroupedMonth, TotalsRecordGroupedQuarters } from './groupTotalRecords'
import { getMonthNameFromFiscalMonth } from '@/utils/tools/format'
import { FiscalMonthTargets } from './groupMonthlyTargetData'

interface SpreadColumnHeader extends Partial<Excel.Column> {
  header: string
  key?: TotalsStringKeys
  width?: number
}

const borderStyle = { style: 'medium' as BorderStyle, color: { argb: '000' } }

const headerStyle = {
  fill: {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'111111'}
  } as Fill,
  border: {
    top: borderStyle,
    left: borderStyle,
    bottom: borderStyle,
    right: borderStyle
  },
  font: {
    name: 'Calibri',
    size: 11,
    bold: true,
    color: { argb: 'FFFFFF' }
  }
}

const secondaryHeaderStyle = {
  fill: {
    type: 'pattern',
    pattern:'solid',
    fgColor:{argb:'e23e29'}
  } as Fill,
  border: {
    top: borderStyle,
    left: borderStyle,
    bottom: borderStyle,
    right: borderStyle
  },
  font: {
    name: 'Calibri',
    size: 11,
    bold: true,
    color: { argb: 'FFFFFF' }
  }
}

const generateSpreadColumnHeaders = (worksheetName: string): Array<SpreadColumnHeader> => ([
  { header: '' },
  { header: '' },
  { header: `Department - ${worksheetName}`, key: 'referencedWeek', width: 21.4 },
  { header: '', key: 'totalBillAmount', width: 14.2 },
  { header: 'Recruiter - All Recruiters', key: 'totalPayAmount', width: 12.8 },
  { header: '', key: 'spread', width: 12.6 },
  { header: 'Sales - All Salespeople', key: 'gPM', width: 11.6 },
  { header: '', key: 'averageHourlyBill', width: 11.2 },
  { header: 'Client - All', key: 'averageHourlyPay', width: 11.8 },
  { header: '', key: 'averageHourlySpread', width: 11.4 },
  { header: 'Consultant All', key: 'timeCardsPaidNet', width: 11.4 },
  { header: '', key: 'totalHoursBilled', width: 13.4 },
  { header: 'RTC - All', key: 'averageHoursPerCandidate', width: 10.6 },
  { header: '', key: 'totalHoursPaid', width: 14 }
])

const secondayHeader: Partial<Record<TotalsStringKeys, string>> = {
  referencedWeek: 'All Summary',
  totalBillAmount: 'Total Bill Amount',
  totalPayAmount: 'Total Pay Amount',
  spread: 'Spread',
  gPM: 'GPM',
  averageHourlyBill: 'Avg Hourly Bill',
  averageHourlyPay: 'Avg Hourly Pay',
  averageHourlySpread: 'Avg Hourly Spread',
  timeCardsPaidNet: 'Time Cards Paid, Net',
  totalHoursBilled: 'Total Hours Billed',
  averageHoursPerCandidate: 'Avg Hours Per Candidate',
  totalHoursPaid: 'Total Hours Paid'
}

const currencyKeys: Array<TotalsStringKeys> = [
  'totalBillAmount',
  'totalPayAmount',
  'spread',
  'averageHourlyBill',
  'averageHourlyPay',
  'averageHourlySpread'
]

const numberKeys: Array<TotalsStringKeys> = [
  'totalHoursBilled',
  'averageHoursPerCandidate',
  'totalHoursPaid'
]

const columns = ['C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N']
const getRowRange = (rowNum: number): Array<string> => columns.map(col => `${col}${rowNum}`)
const getTotalSumColumnKeys = (rowNum: number): Array<string> => [
  `D${rowNum}`,
  `E${rowNum}`,
  `F${rowNum}`,
  `L${rowNum}`,
  `N${rowNum}`
]
const getTotalAverageColumnKeys = (rowNum: number): Array<string> => [
  `D${rowNum}`,
  `E${rowNum}`,
  `F${rowNum}`,
  `H${rowNum}`,
  `I${rowNum}`,
  `J${rowNum}`,
  `K${rowNum}`,
  `L${rowNum}`,
  `M${rowNum}`,
  `N${rowNum}`
]
const spreadHeaderCells = getRowRange(1)
const middleAlignColumns: Array<TotalsStringKeys> = [
  'gPM', 'averageHourlyBill', 'averageHourlyPay', 'averageHourlySpread', 'timeCardsPaidNet', 'averageHoursPerCandidate'
]

const addTotalsFormulas = (
  worksheet: Excel.Worksheet,
  row: number,
  startRow: number,
  endRow: number,
  fiscalMonth: number
) => {
  const rowRange = getRowRange(row)
  const totalSumColumnKeys = getTotalSumColumnKeys(row)
  // apply styling
  rowRange
    .forEach(key => {
      worksheet.getCell(key).border = { left: borderStyle, right: borderStyle, top: borderStyle, bottom: borderStyle }
      worksheet.getCell(key).font = { name: 'Calibri', size: 11, bold: true }
      worksheet.getCell(key).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'efefef'} }
    })
  totalSumColumnKeys
    .forEach(key => {
      const column = key[0]
      if (startRow !== endRow) {
        worksheet.getCell(key).value = { formula: `SUM(${column}${startRow}:${column}${endRow})`, date1904: false }
      } else {
        worksheet.getCell(key).value = { formula: `${column}${startRow}`, date1904: false }
      }
    })
  const monthName = getMonthNameFromFiscalMonth(fiscalMonth)
  worksheet.getCell(rowRange[4]).value = { formula: `F${row}/D${row}`, date1904: false }
  worksheet.getCell(rowRange[0]).value = `${monthName} Totals`
  worksheet.getCell(rowRange[0]).font = { name: 'Calibri', size: 12, bold: true }
}
const addAveragesFormulas = (
  worksheet: Excel.Worksheet,
  row: number,
  startRow: number,
  endRow: number
) => {
  const rowRange = getRowRange(row)
  const totalAverageColumnKeys = getTotalAverageColumnKeys(row)
  // apply styling
  rowRange
    .forEach(key => {
      worksheet.getCell(key).border = { left: borderStyle, right: borderStyle, top: borderStyle, bottom: borderStyle }
      worksheet.getCell(key).font = { name: 'Calibri', size: 11, bold: true }
      worksheet.getCell(key).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'efefef'} }
    })
  totalAverageColumnKeys
    .forEach(key => {
      const column = key[0]
      worksheet.getCell(key).value = { formula: `AVERAGE(${column}${startRow}:${column}${endRow})`, date1904: false }
    })
  worksheet.getCell(rowRange[0]).value = 'Weekly Averages'
}

const addBlackBottomBorder = (worksheet: Excel.Worksheet) => {
  worksheet.addRow({ referencedWeek: '' })
  worksheet.getRow(worksheet.rowCount).height = 7
  getRowRange(worksheet.rowCount)
    .forEach(key => {
      worksheet.getCell(key).fill = { type: 'pattern', pattern:'solid', fgColor:{ argb: '000' } }
    })
}

const getFiscalMonth = (data: TotalsRecordGroupedMonth): number => {
  return data?.[0]?.fiscalMonth || 0
}

const generateMonthTable = (worksheet: Excel.Worksheet, data: TotalsRecordGroupedMonth, targetData?: FiscalMonthTargets) => {
  const startRow = worksheet.rowCount + 1
  worksheet.addRows(data)
  const endRow = worksheet.rowCount

  //style month rows rows
  for (let i = startRow; i <= endRow; i++) {
    const rowRange = getRowRange(i)
    rowRange
      .forEach(key => {
        worksheet.getCell(key).border = { left: borderStyle, right: borderStyle }
      })
    worksheet.getCell(rowRange[0]).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'efefef'} }
    worksheet.getCell(rowRange[0]).font = { name: 'Calibri', size: 10 }
  }

  const totalRow = worksheet.rowCount + 1
  const fiscalMonth = getFiscalMonth(data)
  addTotalsFormulas(worksheet, totalRow, startRow, endRow, fiscalMonth)
  addAveragesFormulas(worksheet, worksheet.rowCount + 1, startRow, endRow)

  // add monthly targets + styling
  let monthlyTargetsRowData = {
    referencedWeek: 'Monthly Target'
  }
  if (targetData && targetData[fiscalMonth]) {
    monthlyTargetsRowData = {
      ...monthlyTargetsRowData,
      ...targetData[fiscalMonth]
    }
  }
  const monthlyTargetsRow = worksheet.addRow(monthlyTargetsRowData)
  monthlyTargetsRow.getCell('totalBillAmount').numFmt = numFmtOptions.SPREAD_CURRENCY
  monthlyTargetsRow.getCell('totalPayAmount').numFmt = numFmtOptions.SPREAD_CURRENCY
  monthlyTargetsRow.getCell('spread').numFmt = numFmtOptions.SPREAD_CURRENCY
  const totalBillAmountCell = monthlyTargetsRow.getCell('totalBillAmount').$col$row
  const spreadCell = monthlyTargetsRow.getCell('spread').$col$row
  monthlyTargetsRow.getCell('gPM').value = { formula: `=IFERROR(${spreadCell}/${totalBillAmountCell},"")`, date1904: false }
  monthlyTargetsRow.getCell('timeCardsPaidNet').numFmt = numFmtOptions.SPREAD_NUMBER

  getRowRange(worksheet.rowCount)
    .forEach(key => {
      worksheet.getCell(key).border = { left: borderStyle, right: borderStyle, top: borderStyle, bottom: { style: 'double', color: { argb: '000' } } }
      worksheet.getCell(key).font = { name: 'Calibri', size: 11, bold: true }
      worksheet.getCell(key).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'aaaaaa'} }
    })

  return totalRow
}

const getFiscalQuarter = (quarterData: TotalsRecordGroupedQuarters): number | string => {
  return quarterData?.[0]?.[0]?.fiscalQuarter || '?'
}

const generateQuarterTable = (worksheet: Excel.Worksheet, quarterData: TotalsRecordGroupedQuarters, targetData: FiscalMonthTargets, skipBorder = false) => {
  const startRow = worksheet.rowCount + 1
  const fiscalQuarter = getFiscalQuarter(quarterData)
  const monthRows = quarterData.map(data => {
    const monthRowValue = generateMonthTable(worksheet, data, targetData)
    return monthRowValue
  })

  // Add Totals Quarter Row
  worksheet.addRow({ referencedWeek: `Quarter ${fiscalQuarter} - Totals` })
  const quarterTotalRow = worksheet.rowCount
  const quarterSumRowKeys = getTotalSumColumnKeys(quarterTotalRow)
  // apply styling
  getRowRange(quarterTotalRow)
    .forEach(key => {
      worksheet.getCell(key).border = { left: borderStyle, right: borderStyle }
      worksheet.getCell(key).fill = secondaryHeaderStyle.fill
      worksheet.getCell(key).font = secondaryHeaderStyle.font
    })
  quarterSumRowKeys
    .forEach(key => {
      const column = key[0]
      worksheet.getCell(key).value = { formula: `${column}${monthRows.join(`+${column}`)}`, date1904: false }
    })
  worksheet.getRow(quarterTotalRow).getCell('gPM').value = { formula: `F${quarterTotalRow}/D${quarterTotalRow}`, date1904: false }

  // Add Monthly Averages Row
  worksheet.addRow({ referencedWeek: 'Monthly Averages' })
  const monthlyAveragesRow = worksheet.rowCount
  getTotalSumColumnKeys(monthlyAveragesRow)
    .forEach(key => {
      const column = key[0]
      worksheet.getCell(key).value = { formula: `${column}${quarterTotalRow}/${quarterData.length}`, date1904: false }
    })
  // apply styling
  getRowRange(monthlyAveragesRow)
    .forEach(key => {
      worksheet.getCell(key).border = { left: borderStyle, right: borderStyle }
      worksheet.getCell(key).fill = secondaryHeaderStyle.fill
      worksheet.getCell(key).font = secondaryHeaderStyle.font
    })
  const endRow = worksheet.rowCount
  // apply general styling
  worksheet.getRows(startRow, endRow - startRow)
    .forEach(row => {
      row.height = 18
    })

  // add black thick black border separator row
  if (!skipBorder) {
    addBlackBottomBorder(worksheet)
  }

  return quarterTotalRow
}

const handleData = (worksheet: Excel.Worksheet, totalsData: Array<BaseTotalsRecord>, targetData?: FiscalMonthTargets) => {
  const groupedData = groupTotalRecords(totalsData)
  const quarterRows = groupedData.map((data, index) => {
    const isLast = (groupedData.length - 1) === index
    const quarterRowValue = generateQuarterTable(worksheet, data, targetData, isLast)
    return quarterRowValue
  })

  worksheet.addRow({ referencedWeek: 'YTD Total' })
  const ytdRow = worksheet.lastRow
  const ytdRowTotalSumColumnKeys = getTotalSumColumnKeys(worksheet.rowCount)
  ytdRowTotalSumColumnKeys
    .forEach(key => {
      const column = key[0]
      worksheet.getCell(key).value = { formula: `${column}${quarterRows.join(`+${column}`)}`, date1904: false }
    })
  ytdRow.getCell('gPM').value = { formula: `F${worksheet.rowCount}/D${worksheet.rowCount}`, date1904: false }
  getRowRange(worksheet.rowCount)
    .forEach(key => {
      worksheet.getCell(key).border = { left: borderStyle, right: borderStyle }
      worksheet.getCell(key).fill = secondaryHeaderStyle.fill
      worksheet.getCell(key).font = secondaryHeaderStyle.font
    })
  addBlackBottomBorder(worksheet)
}

const generateSpreadWorksheet = (workbook: Excel.Workbook, totalsData: Array<BaseTotalsRecord>, name: string, targetData?: FiscalMonthTargets) => {
  // add worksheet
  const spreadWorksheet = workbook.addWorksheet(name, {views: [{showGridLines: false}]})

  // generate columns with worksheet name in first cell
  spreadWorksheet.columns = generateSpreadColumnHeaders(name)
  spreadWorksheet.mergeCells('C1:D1')
  spreadWorksheet.mergeCells('E1:F1')
  spreadWorksheet.mergeCells('G1:H1')
  spreadWorksheet.mergeCells('I1:J1')
  spreadWorksheet.mergeCells('K1:L1')
  spreadWorksheet.mergeCells('M1:N1')
  // fix width
  spreadWorksheet.getColumn('totalBillAmount').width = 14.2
  // vertically align cells
  columns.forEach(key => {
    spreadWorksheet.getColumn(key).alignment = { vertical: 'middle' }
  })
  // align non header columns
  middleAlignColumns
    .forEach(key => {
      spreadWorksheet.getColumn(key).alignment = { horizontal: 'center', vertical: 'middle' }
    })
  spreadWorksheet.getColumn('referencedWeek').alignment = { horizontal: 'right', vertical: 'middle' }

  // format numbers
  spreadWorksheet.getColumn('gPM').numFmt = numFmtOptions.PERCENT
  currencyKeys.forEach(key => {
    spreadWorksheet.getColumn(key).numFmt = numFmtOptions.SPREAD_CURRENCY
  })
  numberKeys.forEach(key => {
    spreadWorksheet.getColumn(key).numFmt = numFmtOptions.SPREAD_NUMBER
  })

  // add secondary header + styling
  spreadWorksheet.addRow(secondayHeader)
  getRowRange(2)
    .forEach(key => {
      spreadWorksheet.getCell(key).border = secondaryHeaderStyle.border
      spreadWorksheet.getCell(key).fill = secondaryHeaderStyle.fill
      spreadWorksheet.getCell(key).font = secondaryHeaderStyle.font
      spreadWorksheet.getCell(key).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
    })
  spreadWorksheet.getRow(2).height = 42

  // style primary header
  spreadHeaderCells
    .forEach(key => {
      spreadWorksheet.getCell(key).border = headerStyle.border
      spreadWorksheet.getCell(key).fill = headerStyle.fill
      spreadWorksheet.getCell(key).font = headerStyle.font
      spreadWorksheet.getCell(key).alignment = { horizontal: 'left' }
    })
  spreadWorksheet.getCell('C1').font = {
    name: 'Calibri',
    size: 14,
    bold: true,
    color: { argb: 'FFFFFF' }
  }
  spreadWorksheet.getCell('K1').alignment = { horizontal: 'center' }

  // hide first two columns as per example export
  spreadWorksheet.getColumn('A').hidden = true
  spreadWorksheet.getColumn('B').hidden = true

  handleData(spreadWorksheet, totalsData, targetData)

  return spreadWorksheet
}

export default generateSpreadWorksheet
