import React from 'react'
import classNames from 'classnames'

import { Card, ProgressBar, Emoji } from '@/shared/components'
import { Close } from '@/shared/components/Icons'
import { formatNumberDisplay } from '@/utils/tools/format'

const qualityToEmoji = { 0: 'partyFace', 1: 'rockOn', 2: 'fire' }

const Target = ({ isLastTarget, isFirstTarget, title, data, height, selected = false, onDeselect, onClick, isGroupTarget = false }) => {
  const { target, actual, percent, quality } = data || {}
  const showProgress = percent !== undefined && percent >= 0
  const cardStyle = {
    height: data && height ? height : undefined,
    borderColor: quality !== undefined && quality >= 0 ? '#45B118' : undefined
  }
  const progressBackground = quality !== undefined && (quality === -1 ? 'red-yellow' : quality >= 0 ? 'green-grad' : '')
  const emoji = <Emoji className='c-target__emoji' style={{ height: '2rem', width: '2rem' }} type={qualityToEmoji[quality]} />
  const targetClassName = classNames({
    selectable: onClick,
    [`c-target--${isFirstTarget ? 'start' : 'end'}`]: isFirstTarget || isLastTarget,
    'c-target--w-progress': showProgress,
    selected
  })
  const calculatedPercent = percent >= 1 ? 100 : percent * 100

  return (
    <div className={`c-target ${targetClassName}`}>
      {isGroupTarget && !isFirstTarget && <span className='c-target__divisor c-target__divisor--w-arrow' />}
      <Card
        style={cardStyle}
        className={`${quality !== undefined && quality >= 0 ? 'u-bg-pale-green' : ''}`}
        onClick={onClick}
      >
        <span className='c-type--funnel-stage'>{title}</span>
        {emoji}
        {selected && (
          <Close className='c-target__close' onClick={e => {
            e.stopPropagation()
            onDeselect()
          }}
          />
        )}
        {showProgress && (
          <ProgressBar
            width='100%'
            percent={calculatedPercent}
            text={formatNumberDisplay(actual)}
            className={`c-target__progressbar${progressBackground ? ` c-progress--${progressBackground}` : ''}`}
          />
        )}
        {target !== undefined && (
          <div className='c-target__footer'>
            <div className='c-target__footer-text'>
              <span>Target:</span>
              <span>{formatNumberDisplay(target)}</span>
            </div>
          </div>
        )}
      </Card>
      {isGroupTarget && !isLastTarget && <span className='c-target__divisor' />}
    </div>
  )
}

export default Target
