import React, { useCallback } from 'react'

import { useAppSelector } from '@/shared/hooks'
import { Table, Skeleton} from '@/shared/components'
import {
  accountManagementPulseByDivisionConfig,
  recruitingPulseByDivisionConfig
} from './helpers/columnConfigs'
import createWeekByWeekConfig from './helpers/createTableConfig'
import { selectReportResponseLoading, selectWeekByWeekReport } from '@/features/shared/redux/sharedSelectors'
import {
  selectGovernmentDivisionIsChecked, selectShouldShowWeekByWeek
} from '@/features/ControlPanel/redux/controlPanelSelectors'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import { selectActiveDashboardTabKey } from '../../redux/dashboardSelectors'
import { hideTotalsRow } from '@/bootstrap/configs/dashboard'

// AKA "Pulse Over Time"
const WeekByWeekTable = () => {
  const data = useAppSelector(selectWeekByWeekReport)
  const shouldShowWeekByWeek = useAppSelector(selectShouldShowWeekByWeek)
  const fetchingReport = useAppSelector(selectReportResponseLoading)
  const accountManagementTabActive =
    useAppSelector(selectActiveDashboardTabKey) === DASHBOARD_TAB_KEYS.AM
  const includeGovernment = useAppSelector(selectGovernmentDivisionIsChecked)
  const createTableConfig = useCallback(() => {
    if (data?.years) {
      return createWeekByWeekConfig(data.years)
    } else {
      return []
    }
  }, [data])
  const createColumnConfig = useCallback(() => {
    if (accountManagementTabActive) {
      return accountManagementPulseByDivisionConfig(includeGovernment)
    } else {
      return recruitingPulseByDivisionConfig(includeGovernment)
    }
  }, [includeGovernment, accountManagementTabActive])

  const tableConfig = createTableConfig()
  const columnConfig = createColumnConfig()

  if (!shouldShowWeekByWeek) {
    return null
  }

  return (
    <Skeleton.Table
      active
      title
      loading={!tableConfig.length || fetchingReport}
      config={Skeleton.Table.PRESET_1}
    >
      <h3 className='c-type c-type--table-header-text'>
        {`${accountManagementTabActive ? 'Account Management' : 'Recruiting'} Pulse over time`}
      </h3>
      <Table
        sticky
        tableConfig={tableConfig}
        columnConfig={columnConfig}
        defaultExpandAllRows
        includeTotalsRow={!hideTotalsRow}
        totalsData={data?.data}
      />
    </Skeleton.Table>
  )
}

export default WeekByWeekTable
