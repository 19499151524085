import { IAppColumnConfig } from '@/shared/components/Table'
import { EMPLOYEE_KEY } from '@/shared/constants/dataKeys'
import { SpreadConsultantPerformanceMetricKeys } from '@/shared/constants/dataKeys'
import { SpreadSummaryReport } from '@/shared/constants/dataKeys'
import { COLUMN_MOD_KEYS, CP_WIDTH } from '@/shared/constants/local'
import { CompareFn } from 'antd/lib/table/interface'

export type MetricsConfig = {
  title: string
  dataIndex: string[] | string
  key: string
  columnMod: string[]
  sortOrder: number
  align?: string
  ellipsis?: boolean
  width?: string
  rowMod?: string[]
  showSorterTooltip?: boolean
  firstName?: string
  lastName?: string
  sortFn?: CompareFn<SpreadSummaryReport>
}

const generateMetricsConfig = (
  title: string,
  key: string,
  sortOrder: number,
  columnMods = []
): IAppColumnConfig<MetricsConfig> => ({
  title,
  dataIndex: key,
  key: key,
  columnMod: columnMods,
  showSorterTooltip: false,
  sorter: {
    compare: (a, b) => {
      return columnMods.includes(COLUMN_MOD_KEYS.PLAIN)
        ? a[key].localeCompare(b[key])
        : a[key] - b[key]
    }
  }
})

/**
 * Creates columnConfig used by antd table component
 * @param {boolean} includeGovernment - whether or not to include government specific colum configs
 * @return {object[]} - Array of column config objects
 */
const spreadConsultantPerformance: IAppColumnConfig<MetricsConfig>[] = [
  {
    title: 'Week',
    dataIndex: EMPLOYEE_KEY,
    key: EMPLOYEE_KEY,
    width: CP_WIDTH,
    columnMod: [COLUMN_MOD_KEYS.TITLE],
    ellipsis: true,
    showSorterTooltip: false,
    className: 'c-type--display-data',
    sorter: {
      compare: (a, b) => a.week.localeCompare(b.week)
    }
  },
  {
    title: 'Client',
    dataIndex: SpreadConsultantPerformanceMetricKeys.SPREAD_CP_CLIENT as string,
    key: SpreadConsultantPerformanceMetricKeys.SPREAD_CP_CLIENT as string,
    width: CP_WIDTH,
    columnMod: [COLUMN_MOD_KEYS.PLAIN],
    ellipsis: true,
    showSorterTooltip: false,
    className: 'c-type--display-data',
    sorter: {
      compare: (a, b) => a.client.localeCompare(b.client)
    }
  },
  {
    title: 'Consultant',
    dataIndex:
      SpreadConsultantPerformanceMetricKeys.SPREAD_CP_CONSULTANT as string,
    key: SpreadConsultantPerformanceMetricKeys.SPREAD_CP_CONSULTANT as string,
    width: CP_WIDTH,
    columnMod: [COLUMN_MOD_KEYS.PLAIN],
    ellipsis: true,
    showSorterTooltip: false,
    className: 'c-type--display-data',
    sorter: {
      compare: (a, b) => a.consultant.localeCompare(b.consultant)
    }
  },
  generateMetricsConfig(
    'Total Billed Hrs',
    SpreadConsultantPerformanceMetricKeys.SPREAD_CP_TOTAL_BILLED_HOURS as string,
    3,
    [COLUMN_MOD_KEYS.METRIC]
  ),
  generateMetricsConfig(
    'Net Spread',
    SpreadConsultantPerformanceMetricKeys.SPREAD_CP_NET_SPREAD as string,
    5,
    [COLUMN_MOD_KEYS.CURRENCY]
  ),
  generateMetricsConfig(
    'Net Spread',
    SpreadConsultantPerformanceMetricKeys.SPREAD_CP_NET_SPREAD_PERCENT as string,
    6,
    [COLUMN_MOD_KEYS.PERCENT]
  ),
  generateMetricsConfig(
    'Recruiter',
    SpreadConsultantPerformanceMetricKeys.SPREAD_CP_RECRUITER as string,
    7,
    [COLUMN_MOD_KEYS.PLAIN]
  ),
  generateMetricsConfig(
    'BDM',
    SpreadConsultantPerformanceMetricKeys.SPREAD_CP_BDM as string,
    8,
    [COLUMN_MOD_KEYS.PLAIN]
  ),
  generateMetricsConfig(
    'Salesperson',
    SpreadConsultantPerformanceMetricKeys.SPREAD_CP_SALESPERSON as string,
    9,
    [COLUMN_MOD_KEYS.PLAIN]
  )
]

export const generateConfig = () => {
  let config: IAppColumnConfig<MetricsConfig>[] = []
  config = spreadConsultantPerformance.filter((v) => v)
  return config
}
