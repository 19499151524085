import React from 'react'
import { CALCULATIONS_KEYS } from '@/shared/constants/dataKeys'
import RatioBox from '../components/RatioBox'
import { Dash } from '@/shared/components/Icons'

export const AM_RATIO_BOXES = [
  {
    key: CALCULATIONS_KEYS.AM_PIPE_DEAL,
    dataIndex: CALCULATIONS_KEYS.AM_PIPE_DEAL,
    title: 'Pipelines to Deals',
    variant: RatioBox.VARIANTS.RATIO
  },
  {
    key: CALCULATIONS_KEYS.AM_JOB_SENDOUT,
    dataIndex: CALCULATIONS_KEYS.AM_JOB_SENDOUT,
    title: 'Job Orders to Send Outs',
    variant: RatioBox.VARIANTS.RATIO
  },
  {
    key: CALCULATIONS_KEYS.AM_JOB_DEAL,
    dataIndex: CALCULATIONS_KEYS.AM_JOB_DEAL,
    title: 'Job Orders to Deals',
    variant: RatioBox.VARIANTS.RATIO
  },
  {
    key: CALCULATIONS_KEYS.AM_SENDOUT_DEAL,
    dataIndex: CALCULATIONS_KEYS.AM_SENDOUT_DEAL,
    title: 'Send Outs to Deals',
    variant: RatioBox.VARIANTS.RATIO
  }
]

export const RECRUITING_RATIO_BOXES = [
  {
    key: CALCULATIONS_KEYS.RC_SUB_DEAL,
    dataIndex: CALCULATIONS_KEYS.RC_SUB_DEAL,
    title: 'Subs to Deals',
    variant: RatioBox.VARIANTS.RATIO
  },
  {
    key: CALCULATIONS_KEYS.RC_SENDOUT_DEAL,
    dataIndex: CALCULATIONS_KEYS.RC_SENDOUT_DEAL,
    title: 'Send Outs to Deals',
    variant: RatioBox.VARIANTS.RATIO
  },
  {
    key: CALCULATIONS_KEYS.RC_SUB_CLIENT_SUB,
    dataIndex: CALCULATIONS_KEYS.RC_SUB_CLIENT_SUB,
    title: 'Subs to Client Subs'
  },
  {
    key: CALCULATIONS_KEYS.RC_CLIENT_SUB_SENDOUT,
    dataIndex: CALCULATIONS_KEYS.RC_CLIENT_SUB_SENDOUT,
    title: 'Client Subs to Send Outs',
    variant: RatioBox.VARIANTS.RATIO
  }
]

export const AWO_BOXES = [
  {
    key: CALCULATIONS_KEYS.STARTS,
    dataIndex: CALCULATIONS_KEYS.STARTS,
    title: 'Starts'
  },
  {
    key: CALCULATIONS_KEYS.YELLOW,
    dataIndex: CALCULATIONS_KEYS.YELLOW,
    title: 'Yellow Zone',
    tooltip: 'Yellow Zones calculated for current week only',
    // eslint-disable-next-line react/display-name
    textRenderFn: value => value < 0 ? <Dash /> : value
  },
  { key: CALCULATIONS_KEYS.FINISHES, dataIndex: CALCULATIONS_KEYS.FINISHES, title: 'Finishes' },
  { key: CALCULATIONS_KEYS.NET_STARTS, dataIndex: CALCULATIONS_KEYS.NET_STARTS, title: 'Net Starts' }
]
