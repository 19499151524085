import store from '@/bootstrap/redux'
import { fetchItemizationResponse, fetchReport } from '@/features/shared/redux/sharedActions'

export default function refetchData() {
  const { dashboard: {
    funnel: { activeTarget }
  } } = store.getState()
  store.dispatch(fetchReport({ skipBuild: true }))
  if (activeTarget) {
    store.dispatch(fetchItemizationResponse())
  }
}
