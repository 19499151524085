import React from 'react'
import Icon from './Icon'
import COLOR_KEYS from '@/shared/constants/colors'

const Info = ({ position, fill }) => (
  <Icon position={position}>
    <svg
      width='20'
      height='20'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M10 2c-4.409 0-8 3.591-8 8s3.591 8 8 8 8-3.591 8-8-3.591-8-8-8zm0 1.6c3.544 0 6.4 2.856 6.4 6.4 0 3.544-2.856 6.4-6.4 6.4A6.388 6.388 0 013.6 10c0-3.544 2.856-6.4 6.4-6.4zM9.2 6v1.6h1.6V6H9.2zm0 3.2V14h1.6V9.2H9.2z'
          id='prefix__a--info-ico'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b--info-ico' fill='#fff'>
          <use xlinkHref='#prefix__a--info-ico' />
        </mask>
        <use fill='#000' fillRule='nonzero' xlinkHref='#prefix__a--info-ico' />
        <path fill={fill ? COLOR_KEYS[fill] || fill : '#404555'} mask='url(#prefix__b--info-ico)' d='M0 0h20v20H0z' />
      </g>
    </svg>
  </Icon>
)

export default Info
