import React, { useEffect } from 'react'
import Money from '@/shared/components/Icons/Money'
import Trophy from '@/shared/components/Icons/Trophy'
import { DownArrow, UpArrow } from '@/shared/components/Icons'
import { formatCurrency } from '../MySpread/helpers/helper'
import { useGetWeeklyNetSpreadLazyQuery } from '@/__generated__/gql-types'
import { Skeleton } from 'antd'
import { spreadClient } from '@/bootstrap/apollo'
import withApolloProvider from '@/shared/hoc/withApolloProvider'
import Emoji from '@/shared/components/Emoji'
import { useAppSelector } from '@/shared/hooks'

const WeeklyNetSpread = () => {
  const userBullHornId = useAppSelector(
    (state) => state.spread.bullhornId
  )
  const [getData, { data, loading }] = useGetWeeklyNetSpreadLazyQuery()

  useEffect(() => {
    if (userBullHornId)
    getData({
      variables: { bullHornId: userBullHornId }
    })
  }, [data, getData, userBullHornId])

  const weeklyNetSpread = data?.weeklyNetSpread

  return (
    <div
      className={
        weeklyNetSpread?.isSpreadHigh
          ? 'card card1 animateHighSpread'
          : 'card card1'
      }
    >
      <Skeleton loading={loading && !data}>
        {weeklyNetSpread && (
          <>
            {weeklyNetSpread.isSpreadHigh && (
              <>
                <Emoji
                  className='c-high_spread__emoji'
                  style={{ height: '2rem', width: '2rem' }}
                  type={'partyFace'}
                />
                <div className='highSpread'>
                  <Trophy />
                  <h1 className='highSpreadReached'>
                    You have a new Spread High!
                  </h1>
                  <Emoji
                    type={'partyPopper'}
                    style={{ height: '2rem', width: '2rem' }}
                  />
                </div>
              </>
            )}
            <h1>Weekly Net Spread</h1>
            <h2 className='money'>
              <Money fill='green-money' />
              {formatCurrency(weeklyNetSpread.netSpread)}
              <span className='weeklyPerformance'>
                {weeklyNetSpread.isGreaterThanLastWeek === 1 && (
                  <UpArrow />
                )}
                {weeklyNetSpread.isGreaterThanLastWeek === -1 && (
                  <DownArrow />
                )}
              </span>
              <span className='caption'>From last week</span>
            </h2>
            <h1>Weekly Spread High</h1>
            <h2 className='weeklySpreadHigh money'>
              <Money fill='green-money' />
              {formatCurrency(weeklyNetSpread.weeklySpreadHigh)}
            </h2>
            <p>Reached: {weeklyNetSpread.reachedOn}</p>
          </>
        )}
      </Skeleton>
    </div>
  )
}
export default withApolloProvider({ client: spreadClient })(WeeklyNetSpread)
