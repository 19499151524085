import TagManager from 'react-gtm-module'
import { tagManagerArgs, useGTM } from '.'

const pushEvent = (eventName) => {
  if(eventName && useGTM){
    const newTagManagerArgs = {
      dataLayer: {
        ...eventName
      }
    }
    TagManager.initialize({
      ...tagManagerArgs,
      ...newTagManagerArgs
    })
  }
}

export {pushEvent}