import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router'
import { MsalProvider } from '@azure/msal-react'
import { ApolloProvider } from '@apollo/client'
import TagManager from 'react-gtm-module'
import { clarity } from 'react-microsoft-clarity'

import store, { persistor, history } from '@/bootstrap/redux'
import { msalInstance } from '@/bootstrap/msal'
import { client } from '@/bootstrap/apollo'

import './utils/iota/reset.scss'
import App from './App';
import { USE_TEST_CONFIG } from './shared/constants/local';
import { tagManagerArgs, useGTM, CLARITY_PROJECT_ID } from './bootstrap/gtm';

const NODE = 'root'

if (useGTM) {
  TagManager.initialize(tagManagerArgs)
  clarity.init(CLARITY_PROJECT_ID)
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <PersistGate loading={<div>loading!</div>} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </MsalProvider>
  </ApolloProvider>,
  document.getElementById(NODE),
);

if (USE_TEST_CONFIG) {
  window.store = store
}