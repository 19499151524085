import React from 'react'

const Card = ({ children, className = '', ...rest }) => {
  return (
    <div className={`c-card ${className}`} {...rest}>
      {children}
    </div>
  )
}

export default Card
