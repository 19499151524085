import { combineReducers } from '@reduxjs/toolkit'
import { connectRouter } from 'connected-react-router'

// reducers
import auth from '@/features/auth/redux/authReducer'
import shared from '@/features/shared/redux/sharedReducer'
import controlPanel from '@/features/ControlPanel/redux/controlPanelReducer'
import dashboard from '@/features/dashboard/redux/dashboardReducer'
import manageTargets from '@/features/ManageTargets/redux/manageTargetsReducer'
import spread from '@/features/Spread/redux/spreadSlice'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    controlPanel,
    dashboard,
    manageTargets,
    shared,
    spread,
  })

export default createRootReducer

