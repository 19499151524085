import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'

const env: string = process.env.REACT_APP_ENVIRONMENT
const useGTM = [REACT_APP_ENVIRONMENT.DEV, REACT_APP_ENVIRONMENT.PROD, REACT_APP_ENVIRONMENT.UAT].includes(env)

const getTagManagerArgs = () => {
  const args = {
    gtmId: 'GTM-WC97NQ7',
    auth: '',
    preview: ''
  }
  switch (env) {
  case REACT_APP_ENVIRONMENT.PROD:
    args.auth = '***REMOVED***'
    args.preview = 'env-1'
    break
  case REACT_APP_ENVIRONMENT.UAT:
  case REACT_APP_ENVIRONMENT.DEV:
    args.auth = '***REMOVED***'
    args.preview = 'env-3'
    break
  default:
    console.warn('GTM is not set up for this environment.')
    break
  }
  return args
}

const tagManagerArgs = getTagManagerArgs()

const CLARITY_PROJECT_ID = 'ftmlewzrpp'

export { tagManagerArgs, useGTM, CLARITY_PROJECT_ID }
