import React, { useState } from 'react'
import PowerRankingTable from '@/features/Spread/components/PowerRankingTable'
import PowerRankingsTabs from '@/features/Spread/components/PowerRankingsTabs'
import PowerRankingFilters from '@/features/Spread/components/PowerRankingFilters'
import PowerRankingViewSelector from '@/features/Spread/components/PowerRankingViewSelector'
import {
  powerRankingWeeklyMock,
  powerRankingAnnualMock
} from '@/features/Spread/components/PowerRankingTable/hooks/powerRankingMock'
import { useAppSelector } from '@/shared/hooks'
import { SPREAD_PR_VIEW_KEYS } from '@/shared/constants/local'

export const initialFilterState = {
  divisions: [],
  tenure: null,
  name: null,
  onlyHigh: false,
  onlyStarred: false
}

const PowerRankings = () => {
  const starredUsers = useAppSelector((state) => state.spread.starredUsers)

  const [filters, setFilters] = useState(initialFilterState)

  const [activeView, setActiveView] = useState(
    SPREAD_PR_VIEW_KEYS.CURRENT_WEEK_VIEW
  )

  const unfilterData =
    activeView === SPREAD_PR_VIEW_KEYS.CURRENT_WEEK_VIEW
      ? powerRankingWeeklyMock
      : powerRankingAnnualMock

  const data = unfilterData.rows.filter((row) => {
    if (filters) {
      if (
        filters.divisions.length &&
        !filters.divisions.includes(row.division)
      ) {
        return false
      }
      if (filters.tenure && filters.tenure !== row.tenure) {
        return false
      }
      if (
        filters.name &&
        !row.name.toLocaleLowerCase().includes(filters.name.toLocaleLowerCase())
      ) {
        return false
      }
      if (filters.onlyHigh && row.isHighSpread !== filters.onlyHigh) {
        return false
      }
      if (filters.onlyStarred && !starredUsers.includes(row.employeeId)) {
        return false
      }
    }
    return true
  })

  return (
    <div className='c-power-ranking'>
      <div className='c-spread__header'>
        <h1 className='c-type c-type--title-xl c-type--bold'>Power Rankings</h1>
        <PowerRankingViewSelector
          activeView={activeView}
          setActiveView={setActiveView}
        />
      </div>
      <PowerRankingsTabs setFilters={setFilters} />
      {!filters.onlyStarred && (
        <PowerRankingFilters
          setFilters={setFilters}
          filters={filters}
          data={unfilterData.rows}
        />
      )}
      <PowerRankingTable data={data} />
    </div>
  )
}

export default PowerRankings
