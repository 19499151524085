import React, { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { Button } from '@/shared/components'
import { selectSavedTargets, selectSaveTargetsState } from '../../redux/manageTargetsSelectors'
import { discardSavedTargets, handleSaveTargetsClick, setSaveTargetsState } from '../../redux/manageTargetsActions'
import { RequestState } from '@/shared/types'
import GreenCircleCheck from '@/shared/components/Icons/GreenCircleCheck'

const savedButtonDisabledStates: RequestState[] = ['success', 'inProgress']

const ManageTargetsFooter = () => {
  const dispatch = useAppDispatch()
  const savedTargets = useAppSelector(selectSavedTargets)
  const saveTargetsState = useAppSelector(selectSaveTargetsState)
  const onDiscard = useCallback(() => {
    dispatch(discardSavedTargets())
  }, [dispatch])
  const onSave = useCallback(() => {
    dispatch(handleSaveTargetsClick())
  }, [dispatch])
  const saveButtonDisabled = savedButtonDisabledStates.includes(saveTargetsState)
  useEffect(() => {
    if (saveTargetsState === 'success') {
      setTimeout(() => {
        dispatch(setSaveTargetsState('none'))
      }, 1500)
    }
  }, [saveTargetsState, dispatch])
  if (!savedTargets && saveTargetsState === 'none') {
    return null
  }
  return (
    <div className='c-manage-targets__footer'>
      <div className='c-manage-targets__footer-content'>
        <div>
          <Button loading={saveTargetsState === 'inProgress'} onClick={onSave} disabled={saveButtonDisabled}>
            Save Targets
          </Button>
          {saveTargetsState === 'success' ? (
            <span className='c-manage-targets__targets-saved'>
              <GreenCircleCheck size={16} position='left' />Targets Saved
            </span>
          ) : null}
        </div>
        <Button loading={false} styleAsLink onClick={onDiscard}>
          Discard Changes
        </Button>
      </div>
    </div>
  )
}

export default ManageTargetsFooter
