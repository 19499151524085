import React, { useEffect } from 'react'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { Avatar, Dropdown } from '@/shared/components'
import {  /* SignOut, */ Target, StyleGuide } from '@/shared/components/Icons'
import { selectActiveUserName } from '@/features/auth/redux/authSelectors'
import { setActiveUserAccount } from '@/features/auth/redux/authActions'
import { selectUserContext } from '@/features/shared/redux/sharedSelectors'
import { hideManageTargetsScreen } from '@/bootstrap/configs/routes'
import { getStyleGuideLink } from '@/bootstrap/configs/general'

const UserDropdown = () => {
  const dispatch = useAppDispatch()
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const name = useAppSelector(selectActiveUserName)
  const currentUserContext = useAppSelector(selectUserContext)
  useEffect(() => {
    if (isAuthenticated && !name && accounts.length) {
      const userAccounts = instance.getAllAccounts()
      instance.setActiveAccount(userAccounts[0])
      dispatch(setActiveUserAccount(userAccounts[0]))
    }
  }, [dispatch, instance, accounts, isAuthenticated, name])
  const parentComponent: React.ReactNode = (
    <>
      <Avatar src={currentUserContext?.['image-url']} size={28} border='red' />
      <span>{name}</span>
    </>
  )

  const menuConfig = [
    !hideManageTargetsScreen && {
      text: 'Manage Targets',
      icon: <Target position='left' />,
      to: '/manage-targets'
    },
    {
      text: 'User Guide',
      icon: <StyleGuide height={16} width={16} position='left' />,
      href: getStyleGuideLink()
    },
    // {
    //   text: 'Sign Out',
    //   icon: <SignOut position='left' />,
    //   onClick: () => instance.logoutRedirect()
    // }
  ]
  if (!isAuthenticated) return null

  return (
    <Dropdown
      parentComponent={parentComponent}
      menuConfig={menuConfig}
    />
  )
}

export default UserDropdown
