import React from 'react'
import Icon, { IconPositionType } from './Icon'

type OldUserProps = {
  position?: IconPositionType;
}

const OldUser = ({ position }: OldUserProps) => (
  <Icon position={position}>
    <svg
      width='12'
      height='12'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M8.5 5.643a2.936 2.936 0 012.929 2.928A2.936 2.936 0 018.5 11.5a2.936 2.936 0 01-2.929-2.929A2.936 2.936 0 018.5 5.643zm0 1c-1.06 0-1.929.869-1.929 1.928 0 1.06.87 1.929 1.929 1.929 1.06 0 1.929-.869 1.929-1.929S9.559 6.643 8.5 6.643zm-3.357-.357c.046 0 .097.003.146.004a3.966 3.966 0 00-.554 1.153c-1.766.118-3.592.953-3.592 1.414v.286h3.474c.058.404.176.787.345 1.143H0V8.857C0 7.17 3.348 6.286 5.143 6.286zM9.05 7.4l.55.55-.55.55.55.55-.55.55-.55-.55-.55.55-.551-.55.55-.55-.55-.55.55-.55.55.55.55-.55zM5.143 0a2.288 2.288 0 012.286 2.286A2.288 2.288 0 015.143 4.57a2.288 2.288 0 01-2.286-2.285A2.288 2.288 0 015.143 0zm0 1.143a1.144 1.144 0 000 2.286 1.144 1.144 0 000-2.286z'
          id='prefix__a--old-user'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b--olduser' fill='#fff'>
          <use xlinkHref='#prefix__a--old-user' />
        </mask>
        <use fill='#82858D' fillRule='nonzero' xlinkHref='#prefix__a--old-user' />
        <path fill='#90949E' mask='url(#prefix__b--olduser)' d='M-2-2h16v16H-2z' />
      </g>
    </svg>
  </Icon>
)

export default OldUser
