import { COLUMN_MOD_KEYS } from '@/shared/constants/local'
import {
  formatNumberDisplay,
  formatNumberToPercentage
} from '@/utils/tools/format'
import React from 'react'
import getValue from '../helpers/getValue'
import sumMetrics from '../helpers/sumMetrics'
import { formatCurrency } from '@/features/Spread/components/MySpread/helpers/helper'

const TotalsRow = ({ data = [], columnConfig = [], totalsData }) => {
  // Assumes first column is not a metric key and is used for the "Total" text
  const metricsConfigTotaled = columnConfig.reduce(
    (config, nextColumConfig, currentIndex) => {
      if (
        nextColumConfig.columnMod.includes(COLUMN_MOD_KEYS.METRIC) ||
        nextColumConfig.columnMod.includes(COLUMN_MOD_KEYS.PERCENT) ||
        nextColumConfig.columnMod.includes(COLUMN_MOD_KEYS.CURRENCY)
      ) {
        const value = totalsData
          ? getValue(totalsData, nextColumConfig.dataIndex)
          : sumMetrics(data, nextColumConfig.dataIndex)
        if (nextColumConfig.columnMod.includes(COLUMN_MOD_KEYS.CURRENCY)) {
          config.push({
            ...nextColumConfig,
            key: `total-${nextColumConfig.key}`,
            value: `$${formatCurrency(value)}`
          })
        } else if (
          nextColumConfig.columnMod.includes(COLUMN_MOD_KEYS.PERCENT)
        ) {
          config.push({
            ...nextColumConfig,
            key: `total-${nextColumConfig.key}`,
            value: formatNumberToPercentage(value / data.length)
          })
        } else {
          config.push({
            ...nextColumConfig,
            key: `total-${nextColumConfig.key}`,
            value: value ? formatNumberDisplay(value) : '0'
          })
        }
      } else if (currentIndex !== 0) {
        config.push({
          ...nextColumConfig,
          key: `total-${nextColumConfig.key}`,
          value: ' '
        })
      }
      return config
    },
    []
  )

  return (
    <>
      <tr
        data-row-key='totals-spacing'
        className='ant-table-row ant-table-row-level-0 c-table__spacing'
      >
        <td />
      </tr>
      <tr
        data-row-key='totals-row'
        className='ant-table-row ant-table-row-level-0 c-table__expandable'
      >
        <td className='ant-table-cell ant-table-cell-ellipsis c-table__cell c-type--table-data-black'>
          <div className='u-align-center'>Totals</div>
        </td>
        {metricsConfigTotaled.map((config) => (
          <>
            {config.key !== 'total-spreadWeek' && (
              <td
                key={config.key}
                className='ant-table-cell c-table__cell c-type--table-numeric c-type--bold'
                style={config.align ? { textAlign: config.align } : {}}
              >
                <div>{config.value}</div>
              </td>
            )}
          </>
        ))}
      </tr>
    </>
  )
}

export default TotalsRow
