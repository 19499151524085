import { BaseTotalsRecord } from '../TotalsTable/helpers/query'

export type TotalsRecordGroupedMonth = Array<BaseTotalsRecord>
export type TotalsRecordGroupedQuarters = Array<TotalsRecordGroupedMonth>

const groupTotalRecords = (records: Array<BaseTotalsRecord>): Array<TotalsRecordGroupedQuarters> => {
  const groupedByMonths = records.slice().sort((aRecord, bRecord) => {
    return bRecord.fiscalMonth - aRecord.fiscalMonth
  }).reduce<Record<number, TotalsRecordGroupedMonth>>((acc, nextRecord) => {
    if (acc[nextRecord.fiscalMonth]) {
      acc[nextRecord.fiscalMonth] = [...acc[nextRecord.fiscalMonth], nextRecord]
    } else {
      acc[nextRecord.fiscalMonth] = [nextRecord]
    }
    return acc
  }, {})
  const groupedByQuarters = Object.values(groupedByMonths).reduce<Record<number, TotalsRecordGroupedQuarters>>((acc, nextGroupedMonth) => {
    const fiscalQuarter = nextGroupedMonth.find(v => v)?.fiscalQuarter
    if (fiscalQuarter) {
      if (acc[fiscalQuarter]) {
        acc[fiscalQuarter] = [...acc[fiscalQuarter], nextGroupedMonth]
      } else {
        acc[fiscalQuarter] = [nextGroupedMonth]
      }
    }
    return acc
  }, {})

  return Object.entries(groupedByQuarters).sort((aQuarterEntries, bQuarterEntries) => {
    return  Number(aQuarterEntries[0]) - Number(bQuarterEntries[0])
  }).map(quarterEntries => quarterEntries[1])
}

export default groupTotalRecords
