import React from 'react'

type SliderMark = {
  label: React.ReactNode
  value: string
}

const marks: Record<number, SliderMark> = {
  0: {
    label: <span className='c-time-slider__mark-label'>Day</span>,
    value: 'Day'
  },
  20: {
    label: <span className='c-time-slider__mark-label'>Week</span>,
    value: 'Week'
  },
  40: {
    label: <span className='c-time-slider__mark-label'>4wks</span>,
    value: '4wks'
  },
  60: {
    label: <span className='c-time-slider__mark-label'>13wks</span>,
    value: '13wks'
  },
  80: {
    label: <span className='c-time-slider__mark-label'>YTD</span>,
    value: 'YTD'
  },
  100: {
    label: <span className='c-time-slider__mark-label'>TTM</span>,
    value: 'TTM'
  }
}

export default marks
