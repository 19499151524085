import React, { useEffect } from 'react'
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer
} from 'recharts'
import { formatCurrency, getMonthName } from '../MySpread/helpers/helper'
import { useGetMonthlyAverageSpreadLazyQuery } from '@/__generated__/gql-types'
import { spreadClient } from '@/bootstrap/apollo'
import { Skeleton } from 'antd'
import { useAppSelector } from '@/shared/hooks'
import Money from '@/shared/components/Icons/Money'
import COLOR_KEYS from '@/shared/constants/colors'
import withApolloProvider from '@/shared/hoc/withApolloProvider'

const GRAPH_COLOR = 'purple'
const GRAPH_LIGHT_COLOR = 'light-purple'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  notation: 'compact',
  compactDisplay: 'short'
})

const monthLabel = (item) => {
  const date = new Date()
  const month = item.fiscalMonth
  return (
    getMonthName(new Date(date.setMonth(month - 1))) +
    ' ' +
    item.fiscalYear.toString().slice(-2)
  )
}

const MonthlyAverageSpread = () => {
  const userBullHornId = useAppSelector(
    (state) => state.spread.bullhornId
  )
  const [getData, { data, loading }] = useGetMonthlyAverageSpreadLazyQuery()
  const chart = []

  useEffect(() => {
    if (userBullHornId)
    getData({
      variables: { bullHornId: userBullHornId }
    })
  }, [data, getData, userBullHornId])

  const averageSpread = data?.monthlyAverageSpread
  let average = 0;
  averageSpread?.monthsValue.forEach((item, index) => {
    /* INCLUDE ONLY LAST 6 MONTH */
    if(index < 6) {
      chart.push({
        name: monthLabel(item),
        spread: item.spreadValue,
        fiscalYear: item.fiscalYear,
        fiscalMonth: item.fiscalMonth,
        fill: COLOR_KEYS[GRAPH_LIGHT_COLOR]
      })
      average += item.spreadValue
    }
  })

  average = average / chart.length

  const displayChart = chart.sort((a, b) => {
    const dateA = new Date(a.fiscalYear, a.fiscalMonth - 1).getTime()
    const dateB = new Date(b.fiscalYear, b.fiscalMonth - 1).getTime()
    return dateA - dateB
  })

  if (displayChart.length > 0) {
    displayChart[displayChart.length - 1].fill = COLOR_KEYS[GRAPH_COLOR]
  }

  return (
    <div className='card card2'>
      <Skeleton loading={loading && !data}>
        <h1>Monthly Average Spread</h1>
        <h2>
          <Money fill={COLOR_KEYS[GRAPH_COLOR]} />
          {formatCurrency(average)}
        </h2>
        <div
          style={{
            position: 'relative',
            width: '100%',
            paddingBottom: '210px'
          }}
        >
          <div
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              top: 0
            }}
          >
            <ResponsiveContainer height='80%' width='100%'>
              <ComposedChart
                data={displayChart}
                margin={{
                  top: 10,
                  right: 10,
                  left: 10,
                  bottom: 10
                }}
              >
                <CartesianGrid stroke={COLOR_KEYS['gray']} vertical={false} />
                <XAxis
                  stroke={COLOR_KEYS['black']}
                  dataKey='name'
                  tickLine={false}
                  tickMargin={5}
                  type='category'
                  tick={{}}
                />
                <YAxis
                  tickMargin={5}
                  tickLine={false}
                  stroke={COLOR_KEYS['black']}
                  tickFormatter={(value) => formatter.format(value)}
                />
                <Bar
                  dataKey='spread'
                  fill={COLOR_KEYS[GRAPH_COLOR]}
                  radius={[10, 10, 0, 0]}
                  barSize={40}
                />
                <ReferenceLine
                  y={average}
                  stroke='red'
                  yAxisId={0}
                  ifOverflow='extendDomain'
                  strokeDasharray='5 5'
                ></ReferenceLine>
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      </Skeleton>
    </div>
  )
}

export default withApolloProvider({ client: spreadClient })(
  MonthlyAverageSpread
)
