import { getActionTypes, getMultiActionTypes } from '@/bootstrap/redux/action';

const prefix = 'MANAGE_TARGETS';

// CLEAR_TOKEN: AUTH/CLEAR_TOKEN
const unitTypes = getActionTypes(
  [
    'SET_AM_TABLE_DATA',
    'SET_RECRUITING_TABLE_DATA',
    'DISCARD_SAVED_TARGETS',
    'SET_USER_SAVED_TARGET',
    'SET_SAVE_TARGETS_STATE'
  ],
  prefix
)

/**
 * REFRESH_TOKEN: AUTH/REFRESH_TOKEN
 * REFRESH_TOKEN_BEGIN: AUTH/REFRESH_TOKEN_BEGIN
 * REFRESH_TOKEN_SUCCESS: AUTH/REFRESH_TOKEN_SUCCESS
 * REFRESH_TOKEN_FAIL: AUTH/REFRESH_TOKEN_FAIL
 **/
const multiTypes = getMultiActionTypes(
  [
    'FETCH_TARGETS',
    'SAVE_TARGETS'
  ],
  prefix,
);

const manageTargetsTypes = {
  ...unitTypes,
  ...multiTypes,
}

export default manageTargetsTypes
