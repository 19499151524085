import React, { useState, useCallback, useEffect } from 'react'
import { Tabs } from 'antd'
import { Button } from '@/shared/components'
import { Waiting } from '@/shared/components/Icons'
import CurrentWeekTable from '../CurrentWeekTable'
import TotalsTable from '../TotalsTable'
import IconixxTable from '../IconixxTable'
import FoundersReport from '../FoundersReport'
import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import {
  selectEtlIsProcessing,
  selectReportHeader
} from '@/features/Spread/redux/spreadSelectors'
import { setReportHeader } from '../../redux/spreadSlice'
import {
  useApproveSpreadMutation,
  useGetStatusSpreadLazyQuery
} from '@/__generated__/gql-types'
import { spreadClient } from '@/bootstrap/apollo'
import { SPREAD_STATUS } from '@/shared/constants/dataKeys'
import { setSpreadStatus } from '@/features/Spread/redux/spreadSlice'

const REPORTS = {
  CURRENT_WEEK: 'current-week',
  TOTALS: 'totals',
  ICONIXX: 'iconixx',
  FOUNDERS: 'founders'
}

const defaultActive = REPORTS.CURRENT_WEEK

const Reports = () => {
  const dispatch = useAppDispatch()
  const [activeKey, setActiveKey] = useState(defaultActive)
  const etlIsProcessing = useAppSelector(selectEtlIsProcessing)
  const reportHeader = useAppSelector(selectReportHeader)
  const spreadStatus = useAppSelector((state) => state.spread.spreadStatus)
  const onChange = useCallback(
    (key) => {
      if (key !== activeKey) {
        dispatch(setReportHeader('Loading report...'))
        setActiveKey(key)
      }
    },
    [dispatch, activeKey]
  )


  const [getSpreadStatus, { data, loading: spreadStatusLoading, error }] = useGetStatusSpreadLazyQuery({ client: spreadClient })

  useEffect(() => {
    if(!etlIsProcessing && activeKey === REPORTS.CURRENT_WEEK) {
      if(!spreadStatus.status) getSpreadStatus({ fetchPolicy: 'no-cache' })
      if (data && data.statusSpread && !spreadStatusLoading && !error) {
        dispatch(setSpreadStatus(data.statusSpread))
      }else if(error){
        dispatch(setSpreadStatus(SPREAD_STATUS.UNKNOWN))
      }
    }
  }, [etlIsProcessing, activeKey, error, data, dispatch, getSpreadStatus, spreadStatus.status, spreadStatusLoading])

  const approveSpread = () => {
    if(window.confirm('Are you sure you want to approve this data?') === false) return
    approveSpreadMutation()
  }

  const [approveSpreadMutation, { loading: spreadMutationLoading }] =
    useApproveSpreadMutation({
      client: spreadClient,
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        getSpreadStatus({ fetchPolicy: 'no-cache' })
        if (
          data &&
          data.statusSpread &&
          !spreadStatusLoading &&
          !spreadMutationLoading &&
          !error
        ) {
          dispatch(setSpreadStatus(data.statusSpread))
        } else if (error) {
          dispatch(setSpreadStatus(SPREAD_STATUS.UNKNOWN))
        }
      }
    })

  return (
    <div>
      <div className='c-spread__report-info'>
        <span className='c-type--spread-header-text'>
          {!etlIsProcessing && reportHeader ? (
            <>
              {reportHeader}
              {!spreadStatusLoading && !spreadMutationLoading && activeKey === REPORTS.CURRENT_WEEK && (
                <>
                  <span className='c-spread-status'>
                    <span className={spreadStatus.class}>
                      {spreadStatus.status}
                    </span>
                  </span>
                  {spreadStatus.status === SPREAD_STATUS.NOT_APPROVED && (
                    <Button
                      className='c-btn'
                      onClick={(e) => approveSpread()}
                      disabled={
                        etlIsProcessing ||
                        spreadStatusLoading ||
                        spreadMutationLoading
                      }
                    >
                      Approve Spread
                    </Button>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Waiting position='left' />
              The Spread Report is being processed, this screen will refresh
              shortly…
            </>
          )}
        </span>
      </div>
      <Tabs
        defaultActiveKey={defaultActive}
        activeKey={activeKey}
        onChange={onChange}
        animated={false}
        className='c-tabs'
        prefixCls='c-tabs ant-tabs'
      >
        <Tabs.TabPane tab='Current Week' key={REPORTS.CURRENT_WEEK}>
          <CurrentWeekTable isActive={activeKey === REPORTS.CURRENT_WEEK} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Totals' key={REPORTS.TOTALS}>
          <TotalsTable isActive={activeKey === REPORTS.TOTALS} />
        </Tabs.TabPane>
        <Tabs.TabPane tab='Iconixx' key={REPORTS.ICONIXX}>
          <IconixxTable isActive={activeKey === REPORTS.ICONIXX} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Founder's Report"} key={REPORTS.FOUNDERS}>
          <FoundersReport isActive={activeKey === REPORTS.FOUNDERS} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

export default Reports
