import { createReducer } from '@reduxjs/toolkit'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import { ExpandedKeysObj } from '@/shared/components/Table'
import { addPrtExpandedKeysObj, setActiveDashboardTabKey, setActiveTarget, setPrtExpandedKeysObj, setShowDashboardLoader } from './dashboardActionsPlain'
import { fetchReportFail, fetchReportSuccess } from '@/features/shared/redux/sharedActionsPlain'
import { FunnelTarget } from '@/shared/types'

type DashboardReducer = {
  funnel: {
    activeTarget: FunnelTarget
  }
  primaryReportTable: {
    expandedKeysObj: ExpandedKeysObj
  }
  activeDashboardTabKey: string
  showLoader: boolean
}

const initialState: DashboardReducer = {
  funnel: {
    activeTarget: null
  },
  primaryReportTable: {
    expandedKeysObj: {}
  },
  activeDashboardTabKey: DASHBOARD_TAB_KEYS.AM,
  showLoader: false
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setActiveTarget, (state, action) => {
    state.funnel.activeTarget = action.payload
  })
  builder.addCase(setActiveDashboardTabKey, (state, action) => {
    state.funnel.activeTarget = null
    state.activeDashboardTabKey = action.payload
  })
  builder.addCase(setPrtExpandedKeysObj, (state, action) => {
    state.primaryReportTable.expandedKeysObj = action.payload
  })
  builder.addCase(addPrtExpandedKeysObj, (state, action) => {
    if (action.payload) {
      const newKeyOb = {
        ...state.primaryReportTable.expandedKeysObj
      }
      Object.entries(action.payload).forEach(([parentKey, expandedKeys]) => {
        if (newKeyOb[parentKey]) {
          newKeyOb[parentKey] = [
            ...newKeyOb[parentKey],
            ...expandedKeys
          ].filter((key, index, self) => key && self.indexOf(key) === index)
        } else {
          newKeyOb[parentKey] = expandedKeys
        }
      })
      state.primaryReportTable.expandedKeysObj = newKeyOb
    }
  })
  builder.addCase(setShowDashboardLoader, (state, action) => {
    state.showLoader = action.payload
  })
  builder.addCase(fetchReportSuccess, state => {
    state.showLoader = false
  })
  builder.addCase(fetchReportFail, state => {
    state.showLoader = false
  })
})
