import React, { Profiler, useCallback, useRef } from 'react'
import { debounce } from 'lodash'
import pkg from '../../../../package.json'

import { getCurrentTimestampInSeconds } from '@/utils/tools/dateTime'
import { useAppDispatch } from '@/shared/hooks'
import { addToGrafanaQueue } from '@/features/shared/redux/sharedActions'
import { REACT_APP_ENVIRONMENT } from '../../constants/local'

const APP_NAME = pkg.name
const VERSION = pkg.version
const ENV = process.env.REACT_APP_ENVIRONMENT || 'local'

export type GrafanaInfoPayload = {
  name: string
  actualDuration: number
  baseDuration: number
  startTime: number
  commitTime: number
  time: number
  tags: Array<string>
}

type GrafanaProfilerProps = {
  children: React.ReactNode
  id: string
  active?: boolean
  tags: Array<string>
}

const createRenderCallback = dispatchAction => (
  id, // the "id" prop of the Profiler tree that has just committed
  phase, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
  actualDuration, // time spent rendering the committed update
  baseDuration, // estimated time to render the entire subtree without memoization
  startTime, // when React began rendering this update
  commitTime, // when React committed this update
  interactions // the Set of interactions belonging to this update
) => {
  const time = getCurrentTimestampInSeconds()
  // Aggregate or log render timings...
  const payload: GrafanaInfoPayload = {
    name: `components.${id}.profiler`,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
    time,
    tags: [`app=${APP_NAME}`, `version=${VERSION}`, `env=${ENV}`]
  }
  if (dispatchAction) dispatchAction(payload, phase)
}

const GrafanaProfiler = ({ children, id, active = true, tags = [] }: GrafanaProfilerProps) => {
  const dispatch = useAppDispatch()
  const debouncedAddToGrafanaQueue = useRef(debounce((payload: GrafanaInfoPayload) => {
    console.log(payload)
    dispatch(addToGrafanaQueue(payload))
  }, 500)).current
  const addToQueue = useCallback((payload: GrafanaInfoPayload, phase: string) => {
    if (ENV && phase === 'update') {
      if (tags) {
        payload.tags = [...payload.tags, ...tags]
      }
      debouncedAddToGrafanaQueue(payload)
    }
  }, [debouncedAddToGrafanaQueue, tags])
  const onRenderCallback = createRenderCallback(addToQueue)

  useCallback(() => {
    return () => debouncedAddToGrafanaQueue?.cancel()
  }, [debouncedAddToGrafanaQueue])
  return (
    <>
      {active && ENV === REACT_APP_ENVIRONMENT.PROD ? (
        <Profiler id={id} onRender={onRenderCallback}>
          {children}
        </Profiler>
      ) : children}
    </>
  )
}

export default GrafanaProfiler
