import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { Tabs } from 'antd'
import { SPREAD_PR_TAB_KEYS } from '@/shared/constants/local'

const PowerRankingsTabs = ({ setFilters }) => {
  const [activeKey, setActiveKey] = useState(SPREAD_PR_TAB_KEYS.COMPANY)
  const onTabChange = useCallback(
    (v) => {
      setActiveKey(v)
      setFilters((prev) => ({
        ...prev,
        onlyStarred: v === SPREAD_PR_TAB_KEYS.STARRED
      }))
    },
    [setFilters]
  )

  return (
    <div className='c-dashboard__tabs'>
      <Tabs
        defaultActiveKey={SPREAD_PR_TAB_KEYS.COMPANY}
        activeKey={activeKey}
        onChange={onTabChange}
      >
        <Tabs.TabPane
          tab={SPREAD_PR_TAB_KEYS.COMPANY}
          key={SPREAD_PR_TAB_KEYS.COMPANY}
        />
        <Tabs.TabPane
          tab={SPREAD_PR_TAB_KEYS.STARRED}
          key={SPREAD_PR_TAB_KEYS.STARRED}
        />
      </Tabs>
    </div>
  )
}

PowerRankingsTabs.propTypes = {
  setFilters: PropTypes.func
}

export default PowerRankingsTabs
