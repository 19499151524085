import React from 'react'
import Icon from './Icon'

const DownArrow = ({ position }) => (
  <Icon position={position}>
    <svg
      width='13'
      height='14'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M6.067 0L0 6.222h3.792v7c0 .43.34.778.758.778h3.033a.769.769 0 00.759-.778v-7h3.791L6.067 0z'
          id='prefix__a--down-arrow'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b--down-arrow' fill='#fff'>
          <use xlinkHref='#prefix__a--down-arrow' />
        </mask>
        <use
          fill='#D0021B'
          fillRule='nonzero'
          transform='rotate(-180 6.067 7)'
          xlinkHref='#prefix__a--down-arrow'
        />
      </g>
    </svg>
  </Icon>
)

export default DownArrow
