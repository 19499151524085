import { gql } from '@apollo/client'

export interface CurrentWeekRecord {
  recordId: string
  week: string
  referenceWeek: string
  client: string
  consultant: string
  type: string
  totalBilledHours: number
  billRate: number
  oTBillRate: number
  dTBillRate: number
  totalBilledAmount: number
  totalPaidHours: number
  payRate: number
  oTPayRate: number
  dTPayRate: number
  totalPaidAmount: number
  netSpreadPct: number
  netSpread: number
  recruiter: string
  salesperson: string
  department: string
  trainingCenter: string
  bDM: string
  pTFT: string
  endDate: string
  startDate: string
}

export interface CurrentWeekTableData {
  allWeeklySpread: CurrentWeekRecord[]
}

export const GET_CURRENT_WEEK_TABLE_QUERY = `
allWeeklySpread
  {
    recordId
    week
    referenceWeek
    client
    consultant
    type
    totalBilledHours
    billRate
    oTBillRate
    dTBillRate
    totalBilledAmount
    totalPaidHours
    payRate
    oTPayRate
    dTPayRate
    totalPaidAmount
    netSpreadPct
    netSpread
    recruiter
    salesperson
    department
    trainingCenter
    bDM
    pTFT
    endDate
    startDate
  } 
`

export const GET_CURRENT_WEEEK_TABLE_NAMED_QUERY = gql`
  query CurrentWeekTable {
   ${GET_CURRENT_WEEK_TABLE_QUERY}
  }
`