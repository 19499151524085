// @ts-strict
import { DepartmentTotalsRecord, BaseTotalsRecord } from '../TotalsTable/helpers/query';
import { SpreadDepartmentMapping } from './departmentMappingsQuery';

type ModifiedSpreadDepartmentMapping = SpreadDepartmentMapping & { isDepartment?: boolean, childDepartments?: string[] }
type RefObj = Record<string, Array<ModifiedSpreadDepartmentMapping>>

type DepObj = Record<string, DepartmentTotalsRecord[] | BaseTotalsRecord[]>

export type BrandDepartmentDataRecord = {
  mergedData?: BaseTotalsRecord[]
} & DepObj

type GroupedDepartmentBrands = Record<string, BrandDepartmentDataRecord>

const sumByKey = (data: Array<unknown>, key: string | number) => {
  return data.reduce<number>((result, nextSet) => {
    if (typeof nextSet[key] === 'number') {
      result+= nextSet[key]
    }
    return result
  }, 0)
}

const mergeDepartmentTotalsRecords = (data: DepObj) => {
  const mergedData: BaseTotalsRecord[] = []
  const departments = Object.keys(data)
  if (Object.values(data).length) {
    const departmentWithMostRecords = Object.values(data).reduce<BaseTotalsRecord[]>((acc, nextDepartmentRecords) => {
      if (nextDepartmentRecords.length > acc.length) return nextDepartmentRecords
      return acc
    }, [])
    const refWeeks = departmentWithMostRecords.map(r => r.referencedWeek)
    refWeeks.forEach(referencedWeek => {
      const relaventRecords = departments.reduce<BaseTotalsRecord[]>((acc, nextDepartment) => {
        const foundDep = data[nextDepartment].find(v => v.referencedWeek === referencedWeek)
        if (foundDep) {
          acc.push(foundDep)
        }

        return acc
      }, [])
      if (relaventRecords.length) {
        // values not summed from data are calculated according to https://adkgroup.atlassian.net/wiki/spaces/ALKU/pages/3300720647/Spread+Excel+file+exploration.
        const totalBillAmount = sumByKey(relaventRecords, 'totalBillAmount')
        const totalHoursPaid = sumByKey(relaventRecords, 'totalHoursPaid')
        const timeCardsPaidNet = sumByKey(relaventRecords, 'timeCardsPaidNet')
        const spread = sumByKey(relaventRecords, 'spread')
        const totalHoursBilled = sumByKey(relaventRecords, 'totalHoursBilled')
        const totalPayAmount = sumByKey(relaventRecords, 'totalPayAmount')
        const averageHourlyBill = totalBillAmount / totalHoursBilled
        const averageHourlyPay = totalPayAmount / totalHoursPaid
        const averageHourlySpread = averageHourlyBill - averageHourlyPay
        const averageHoursPerCandidate = totalHoursBilled / timeCardsPaidNet
        const gPM = spread / totalBillAmount
        const record = {
          averageHourlyBill,
          averageHourlyPay,
          averageHourlySpread,
          averageHoursPerCandidate,
          currentYear: relaventRecords[0].currentYear,
          fiscalMonth: relaventRecords[0].fiscalMonth,
          fiscalQuarter: relaventRecords[0].fiscalQuarter,
          fiscalWeek: relaventRecords[0].fiscalWeek,
          fiscalYear: relaventRecords[0].fiscalYear,
          fiscalYearWeek: relaventRecords[0].fiscalYearWeek,
          gPM,
          referencedWeek,
          spread,
          timeCardsPaidNet,
          totalBillAmount,
          totalHoursPaid,
          totalHoursBilled,
          totalPayAmount,
          week: relaventRecords[0].week,
          yearWeek: relaventRecords[0].yearWeek
        }
        mergedData.push(record)
      }
    })
  }
  return mergedData
}

// ensure mapping is sorted correctly to create refObj
const sortSpreadDepartmentMapping = (aMapping: SpreadDepartmentMapping, bMapping: SpreadDepartmentMapping) => {
  if (aMapping.brand !== bMapping.brand) {
    return aMapping.brand.localeCompare(bMapping.brand)
  } else if (aMapping.subBrand !== bMapping.subBrand) {
    return aMapping.subBrand.localeCompare(bMapping.subBrand)
  } else if (aMapping.splitBy !== bMapping.splitBy) {
    return aMapping.splitBy.localeCompare(bMapping.splitBy)
  }

  return aMapping.department.localeCompare(bMapping.department)
}

// create an object to group departments under brands
const createRefObj = (data: SpreadDepartmentMapping[]) => {
  const createAggregatedItem = (name: string) => {
    const item: ModifiedSpreadDepartmentMapping = {
      department: name,
      isDepartment: false,
      splitBy: '',
      subBrand: '',
      brand: '',
      enable: true
    }

    return item
  }

  const refObj = data.slice().sort(sortSpreadDepartmentMapping).reduce<RefObj>((acc, nextMapping) => {
    if (nextMapping.enable) {
      if (!acc[nextMapping.brand]) {
        acc[nextMapping.brand] = [{ ...nextMapping, isDepartment: true }]
      } else if (acc[nextMapping.brand] && !acc[nextMapping.brand].find(v => v.department === nextMapping.department)) {
        // if subBrand exists and hasn't already been added, we add subBrand into list before department
        if (nextMapping.subBrand && !acc[nextMapping.brand].find(v => v.department === nextMapping.subBrand)) {
          const brandItem = createAggregatedItem(nextMapping.subBrand)
          acc[nextMapping.brand] = [...acc[nextMapping.brand], brandItem]
        }
        // if splitBy exists and hasn't already been added, we add splitBy into list before department but after brand if that also exists
        if (nextMapping.splitBy && !acc[nextMapping.brand].find(v => v.department === nextMapping.splitBy)) {
          const splitByItem = createAggregatedItem(nextMapping.splitBy)
          acc[nextMapping.brand] = [...acc[nextMapping.brand], splitByItem]
        }
        acc[nextMapping.brand] = [...acc[nextMapping.brand], { ...nextMapping, isDepartment: true }]
      }
    }
    return acc
  }, {})
  return refObj
}

// This function adds "Splity By" and "Sub-brands" to the DepObj by merging the data of their
// respective departments
const addSupplementaryInfoToDepObj = (depObj: DepObj, departmentMappings: SpreadDepartmentMapping[]) => {
  const aggregate = (mapping: SpreadDepartmentMapping, field: 'subBrand' | 'splitBy') => {
    const group = {}
    const departments = departmentMappings.filter(v => v[field] === mapping[field])
    departments.forEach(m => {
      const departmentData = depObj[m.department.toLowerCase()]
      if (departmentData) {
        group[m.department] = depObj[m.department.toLowerCase()]
      }
    })
    depObj[mapping[field].toLowerCase()] = mergeDepartmentTotalsRecords(group)
  }

  departmentMappings.forEach(mapping => {
    if (!depObj[mapping.subBrand.toLowerCase()]) {
      aggregate(mapping, 'subBrand')
    }
    if (!depObj[mapping.splitBy.toLowerCase()]) {
      aggregate(mapping, 'splitBy')
    }
  })
}

// Using RefObj, group data under brands and create merged data set for brands
const groupDepartmentBrands = (departmentMappingsData: SpreadDepartmentMapping[], departmentTotalsData: DepartmentTotalsRecord[]): GroupedDepartmentBrands => {
  const refObj = createRefObj(departmentMappingsData)

  const depObj = departmentTotalsData.reduce<DepObj>((acc, nextRecord) => {
    const depName = nextRecord.department.toLowerCase()
    if (acc[depName]) {
      acc[depName].push(nextRecord)
    } else {
      acc[depName] = [nextRecord]
    }
    return acc
  }, {})

  addSupplementaryInfoToDepObj(depObj, departmentMappingsData)

  const groupedDepartmentBrands = {}
  Object.entries(refObj).forEach(([brand, departments]) => {
    const group = {}
    const depGroup = {}
    departments.forEach(mapping => {
      if (depObj[mapping.department.toLowerCase()]) {
        group[mapping.department] = depObj[mapping.department.toLowerCase()]
        if (mapping.isDepartment) {
          depGroup[mapping.department] = depObj[mapping.department.toLowerCase()]
        }
      }
    })
    // create brand total records by merging data
    /**
     * https://alkutech.atlassian.net/browse/RP-771
     * Previous condition of brand && !group[brand] was causing a brand with a single division of the same name
     * to not display properly on the dropdown so the added OR statement will create mergedData now if it exists
     * as a department even if it has the same name
     * */ 
    if (brand && (!group[brand] || (
      departments.find(v => v.department === brand && v.isDepartment)
    ))) {
      group['mergedData'] = mergeDepartmentTotalsRecords(depGroup)
    }
    groupedDepartmentBrands[brand] = group
  })
  return groupedDepartmentBrands
}

export default groupDepartmentBrands
