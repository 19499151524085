import React, { useCallback } from 'react'
import { Tabs } from 'antd'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { selectActiveShortcutKey } from '@/features/ControlPanel/redux/controlPanelSelectors'
import { onAudienceShortcutTabChange } from '@/features/ControlPanel/redux/controlPanelActions'
import { AT_SHORTCUT_KEYS } from '@/shared/constants/local'

const AudienceTreeShortcuts = () => {
  const dispatch = useAppDispatch()
  const activeKey = useAppSelector(selectActiveShortcutKey)
  const onTabChange = useCallback((val: string) => {
    dispatch(onAudienceShortcutTabChange(val))
  }, [dispatch])
  return (
    <div className='c-audience-tree__shortcuts'>
      <Tabs defaultActiveKey={AT_SHORTCUT_KEYS.PEOPLE} onChange={onTabChange} activeKey={activeKey} centered>
        <Tabs.TabPane tab='My People' key={AT_SHORTCUT_KEYS.PEOPLE} />
        <Tabs.TabPane tab='My Divisions' key={AT_SHORTCUT_KEYS.DIVISIONS} />
        <Tabs.TabPane tab='All' key={AT_SHORTCUT_KEYS.ALL} />
      </Tabs>
    </div>
  )
}

export default AudienceTreeShortcuts
