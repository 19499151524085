import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color }) => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='32.000000pt'
      height='32.000000pt'
      viewBox='0 0 90.000000 90.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,90.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M427 864 c-4 -4 -7 -17 -7 -29 0 -18 -9 -25 -46 -34 -139 -35 -240
-136 -275 -274 -9 -39 -15 -46 -38 -49 -20 -2 -26 -8 -26 -28 0 -20 6 -26 26
-28 23 -3 29 -10 38 -49 35 -138 136 -239 274 -274 39 -9 46 -15 49 -38 2 -20
8 -26 28 -26 20 0 26 6 28 26 3 23 10 29 49 38 138 35 239 136 274 274 9 39
15 46 38 49 20 2 26 8 26 28 0 20 -6 26 -26 28 -23 3 -29 10 -38 49 -35 138
-136 239 -274 274 -39 9 -46 15 -49 38 -3 26 -35 41 -51 25z m-7 -144 c0 -26
-5 -31 -42 -42 -58 -17 -139 -98 -156 -155 -11 -38 -16 -43 -43 -43 -27 0 -29
2 -23 28 27 112 93 187 197 223 68 24 67 24 67 -11z m167 -6 c78 -37 135 -112
157 -206 6 -26 4 -28 -23 -28 -27 0 -32 5 -43 43 -17 57 -98 138 -155 155 -38
11 -43 16 -43 43 0 27 2 29 28 23 15 -3 50 -17 79 -30z m-136 -114 c11 0 22 7
25 15 5 13 10 13 42 -1 49 -20 72 -42 93 -89 15 -34 16 -41 3 -48 -18 -10 -18
-44 0 -54 13 -7 12 -14 -3 -48 -21 -47 -44 -69 -93 -89 -32 -14 -37 -14 -42
-1 -7 19 -45 19 -52 0 -5 -13 -10 -13 -42 1 -49 20 -72 42 -93 89 -15 34 -16
41 -3 48 18 10 18 46 -1 53 -13 5 -13 10 1 42 20 49 42 72 89 93 34 15 41 16
48 3 4 -7 17 -14 28 -14z m-229 -222 c17 -58 98 -139 156 -156 37 -11 42 -16
42 -43 0 -27 -2 -29 -27 -23 -51 12 -109 41 -146 73 -36 32 -79 110 -91 164
-6 25 -4 27 23 27 27 0 32 -5 43 -42z m522 15 c-12 -54 -55 -132 -91 -164 -37
-32 -95 -61 -145 -73 -26 -6 -28 -4 -28 23 0 27 5 32 43 43 57 17 138 98 155
156 11 37 16 42 43 42 27 0 29 -2 23 -27z'
        />
        <path
          d='M410 490 c-11 -11 -20 -29 -20 -40 0 -11 9 -29 20 -40 11 -11 29 -20
40 -20 26 0 60 34 60 60 0 11 -9 29 -20 40 -11 11 -29 20 -40 20 -11 0 -29 -9
-40 -20z'
        />
      </g>
    </svg>
  </div>
)

Icon.propTypes = {
  color: PropTypes.string.isRequired
}

export default Icon
