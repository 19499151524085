// @ts-strict
export type SpacingRow<T> = {
  key: string
  rowClassName: string,
  isSpacing: boolean
  referenceRow: T
  sort: Record<string, number>
}

type BaseRecordType<T> = {
  key: string
  childTable?: T[]
  addSpacingNext?: boolean
}

const generateEmptyRow = <RecordType>(nextConfig: RecordType & { key: string }, modifier?: string, sort?: Record<string, number>): SpacingRow<RecordType> => ({
  key: `${nextConfig.key}-spacing`,
  rowClassName: `c-table__spacing${modifier ? `--${modifier}` : ''}`,
  isSpacing: true,
  referenceRow: nextConfig,
  sort
})

const addSpacingElements = <RecordType extends BaseRecordType<RecordType>>(config: RecordType[], modifier?: string) => {
  if (!config) return []
  return config.reduce((acc, nextConfig) => {
    if ((nextConfig && nextConfig.childTable)) {
      acc = [
        ...acc,
        generateEmptyRow(nextConfig, modifier, { [nextConfig.key]: -1 }),
        nextConfig
      ]
    } else if (nextConfig?.addSpacingNext) {
      acc = [
        ...acc,
        nextConfig,
        generateEmptyRow(nextConfig, modifier, { [nextConfig.key]: 1 })
      ]
    } else {
      acc.push(nextConfig)
    }
    return acc
  }, [])
}

export default addSpacingElements
