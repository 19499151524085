import React from 'react'

const Star = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='16.000000pt'
      height='16.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        fill={'#404555'}
        stroke={'#404555'}
      >
        <path
          d='M410 795 c-53 -125 -69 -155 -85 -155 -53 -2 -306 -28 -311 -32 -3
-3 52 -54 121 -114 69 -59 125 -110 125 -113 0 -3 -16 -73 -35 -155 -19 -82
-35 -155 -35 -162 0 -8 60 22 141 71 77 47 144 85 149 85 5 0 72 -38 149 -85
81 -48 141 -79 141 -71 0 7 -16 82 -36 166 -34 148 -35 154 -17 170 10 9 67
59 127 110 127 111 133 103 -84 120 l-145 12 -65 154 c-36 85 -67 154 -70 154
-3 0 -34 -70 -70 -155z m110 -132 c17 -43 37 -84 42 -90 6 -7 34 -13 62 -14
28 0 71 -4 95 -7 l43 -7 -63 -55 c-34 -30 -69 -60 -76 -65 -11 -9 -10 -25 7
-98 12 -49 20 -93 18 -98 -2 -5 -40 13 -85 41 l-83 49 -82 -49 c-46 -28 -84
-46 -86 -41 -2 5 6 49 18 97 l21 88 -48 41 c-26 22 -61 52 -78 66 l-29 27 89
6 c50 4 97 10 104 15 8 4 30 44 49 89 19 45 38 82 42 82 4 0 22 -35 40 -77z'
        />
      </g>
    </svg>
  </div>
)

export default Star
