import { Maybe, Scalars } from '@/__generated__/gql-types'
import { OmitStrict } from '@/utils/typescript'

export const BDE_KEY = 'brandDivisionEmployee' // used for the first column of tables, generally the "name/title"
export const CLIENT_SUBS_KEY = 'client-subs'
export const CLIENT_VISITS_KEY = 'client-visits'
export const IN_HOUSE_INTERVIEWS_KEY = 'in-house-interviews'
export const JOB_ORDERS_KEY = 'jobs'
export const LEADS_KEY = 'leads'
export const PIPELINES_KEY = 'pipelines'
export const RECRUITING_DBAS_KEY = 'recruiting-dbas'
export const RECRUITING_DEALS_KEY = 'recruiting-deals'
export const RECRUITING_DIALS_KEY = 'recruiting-dials'
export const RECRUITING_SENDOUTS_KEY = 'recuriting-send-outs'
export const SALES_DBAS_KEY = 'sales-dbas'
export const SALES_DEALS_KEY = 'sales-deals'
export const SALES_DIALS_KEY = 'sales-dials'
export const SALES_SENDOUTS_KEY = 'sales-send-outs'
export const SCHEDULED_CALLS_KEY = 'scheduled-calls'
export const SUBS_KEY = 'subs'
export const SPREAD_WEEK_KEY = 'spreadWeek'

export const OTHER_CONTRIBUTORS_KEY = 'ocs'
export const ALL_ALKU_KEY = '__ALKU__'
export const BRAND_KEY = 'brand'
export const DIVISION_KEY = 'division'
export const EMPLOYEE_KEY = 'employee'

export const CUSTOM_TF = 'custom'

export type SIDE_OF_HOUSE_VAL = 0 | 1 | 2 | 3

export const SIDE_OF_HOUSE_ENUM: Record<string, SIDE_OF_HOUSE_VAL> = {
  UNKNOWN: 0,
  RECRUITING: 1,
  AM: 2,
  ALL: 3
} as const

export const FOUNDER_STATUS = {
  OFF_TARGET: 'OFF_TARGET',
  ON_TARGET: 'ON_TARGET',
  ACHIVED: 'ACHIEVED'
}

export type SPREAD_STATUS_TYPE = keyof typeof SPREAD_STATUS

export const SPREAD_STATUS: Record<string, string> = {
  APPROVED: 'Approved',
  NOT_APPROVED: 'Not Approved',
  PUBLISHED: 'Published',
  UNKNOWN: 'Unknown'
} as const


export type CALCULATIONS_KEYS_TYPE = keyof typeof CALCULATIONS_KEYS

export const CALCULATIONS_KEYS: Record<string, string> = {
  AM_PIPE_DEAL: 'sales-pipe-deal',
  AM_JOB_SENDOUT: 'sales-job-sendout',
  AM_JOB_DEAL: 'sales-job-deal',
  AM_CLIENT_SUB_SENDOUT: 'sales-client-sub-sendout',
  AM_SENDOUT_DEAL: 'sales-sendout-deal',
  RC_SUB_CLIENT_SUB: 'recruiting-sub-client-sub',
  RC_SUB_DEAL: 'recruiting-sub-deal',
  RC_CLIENT_SUB_SENDOUT: 'recruiting-client-sub-sendout',
  RC_JOB_DEAL: 'recruiting-job-deal',
  RC_SENDOUT_DEAL: 'recruiting-sendout-deal',
  STARTS: 'starts',
  FINISHES: 'finishes',
  YELLOW: 'yellow',
  NET_STARTS: 'netstarts'
} as const

type SpreadDataKey =
  | 'SPREAD_WEEKLY_SPREAD'
  | 'SPREAD_GPM'
  | 'SPREAD_HOURS'
  | 'SPREAD_TIME_CARDS_PAID'

export const SpreadMetricKeys: Record<SpreadDataKey, SpreadKey> = {
  SPREAD_WEEKLY_SPREAD: 'weeklySpread',
  SPREAD_GPM: 'gpm',
  SPREAD_HOURS: 'hours',
  SPREAD_TIME_CARDS_PAID: 'timeCardsPaid'
} as const

export type SpreadKey = keyof OmitStrict<SpreadTotal, 'label'>

export type SpreadSummaryReport = {
  weeklySpread?: Maybe<number>
  ytdAverage?: Maybe<number>
  ytdRevenue?: Maybe<number>
  name?: Maybe<string>
  label?: Maybe<string>
}

export type SpreadPowerRankingKey = keyof OmitStrict<
  SpreadPowerRankingReport,
  'label'
>

type SpreadPowerRankingDataKey =
  | 'SPREAD_PR_NAME'
  | 'SPREAD_PR_TENURE'
  | 'SPREAD_PR_DIVISION'
  | 'SPREAD_PR_NET_SPREAD'
  | 'SPREAD_PR_REACHED_AT'
  | 'SPREAD_PR_WEEKLY_AVG'
  | 'SPREAD_PR_SPREAD_HIGH'
  | 'SPREAD_PR_GPM'
  | 'SPREAD_PR_HOURS_PAID'
  | 'SPREAD_PR_IS_HIGH_SPREAD'

export const SpreadPowerRankingMetricKeys: Record<
  SpreadPowerRankingDataKey,
  SpreadPowerRankingKey
> = {
  SPREAD_PR_NAME: 'name',
  SPREAD_PR_TENURE: 'tenure',
  SPREAD_PR_DIVISION: 'division',
  SPREAD_PR_NET_SPREAD: 'netSpread',
  SPREAD_PR_REACHED_AT: 'reachedOn',
  SPREAD_PR_WEEKLY_AVG: 'weeklyAvg',
  SPREAD_PR_SPREAD_HIGH: 'spreadHigh',
  SPREAD_PR_GPM: 'gpm',
  SPREAD_PR_HOURS_PAID: 'hoursPaid',
  SPREAD_PR_IS_HIGH_SPREAD: 'isHighSpread'
} as const

export type SpreadPowerRankingReport = {
  name?: Maybe<string>
  tenure?: Maybe<string>
  division?: Maybe<string>
  netSpread?: Maybe<number>
  reachedOn?: Maybe<string>
  weeklyAvg?: Maybe<number>
  spreadHigh?: Maybe<number>
  gpm?: Maybe<number>
  hoursPaid?: Maybe<number>
  isHighSpread?: Maybe<boolean>
  label?: Maybe<string>
}

type SpreadConsultantPerformanceDataKey =
  | 'SPREAD_CP_WEEK'
  | 'SPREAD_CP_CLIENT'
  | 'SPREAD_CP_CONSULTANT'
  | 'SPREAD_CP_TOTAL_BILLED_HOURS'
  | 'SPREAD_CP_TOTAL_PAID_HOURS'
  | 'SPREAD_CP_NET_SPREAD'
  | 'SPREAD_CP_NET_SPREAD_PERCENT'
  | 'SPREAD_CP_RECRUITER'
  | 'SPREAD_CP_BDM'
  | 'SPREAD_CP_SALESPERSON'

export type SpreadConsultantPerformanceKey = keyof OmitStrict<
  SpreadConsultantPerformanceReport,
  'label'
>

export const SpreadConsultantPerformanceMetricKeys: Record<
  SpreadConsultantPerformanceDataKey,
  SpreadConsultantPerformanceKey
> = {
  SPREAD_CP_WEEK: 'week',
  SPREAD_CP_CLIENT: 'client',
  SPREAD_CP_CONSULTANT: 'consultant',
  SPREAD_CP_TOTAL_BILLED_HOURS: 'totalBilledHours',
  SPREAD_CP_TOTAL_PAID_HOURS: 'totalPaidHours',
  SPREAD_CP_NET_SPREAD: 'netSpread',
  SPREAD_CP_NET_SPREAD_PERCENT: 'netSpreadPercent',
  SPREAD_CP_RECRUITER: 'recruiter',
  SPREAD_CP_BDM: 'bdm',
  SPREAD_CP_SALESPERSON: 'salesperson'
} as const

export type SpreadConsultantPerformanceReport = {
  week?: Maybe<string>
  client?: Maybe<string>
  consultant?: Maybe<string>
  totalBilledHours?: Maybe<number>
  totalPaidHours?: Maybe<number>
  netSpread?: Maybe<number>
  netSpreadPercent?: Maybe<number>
  recruiter?: Maybe<string>
  bdm?: Maybe<string>
  salesperson?: Maybe<string>
  label?: Maybe<string>
}

export type SpreadOverTimeRow = {
  weeklySpread?: Maybe<number>
  gpm?: Maybe<Scalars['Float']>
  hours?: Maybe<Scalars['Float']>
  timeCardsPaid?: Maybe<number>
  fiscalQuarter?: Maybe<Scalars['Int']>
  fiscalMonth?: Maybe<Scalars['Int']>
  fiscalWeek?: Maybe<Scalars['Int']>
  isHighSpread?: Maybe<boolean>
  startDate?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
}

export type SpreadTotal = {
  weeklySpread?: Maybe<number>
  gpm?: Maybe<number>
  hours?: Maybe<number>
  timeCardsPaid?: Maybe<number>
  fiscalQuarter?: Maybe<Scalars['Int']>
  fiscalMonth?: Maybe<Scalars['Int']>
  fiscalWeek?: Maybe<Scalars['Int']>
  label?: Maybe<string>
}

export type SpreadOverTimeReport = {
  rows?: Maybe<Array<Maybe<SpreadOverTimeRow>>>
  totals: SpreadTotal
}

export type SpreadSummaryKey = keyof OmitStrict<SpreadSummaryReport, 'label'>

type SpreadSummaryDataKey =
  | 'SPREAD_YTD_AVERAGE'
  | 'SPREAD_YTD_REVENUE'
  | 'SPREAD_WEEKLY_SPREAD'

export const SpreadSummaryMetricKeys: Record<
  SpreadSummaryDataKey,
  SpreadSummaryKey
> = {
  SPREAD_WEEKLY_SPREAD: 'weeklySpread',
  SPREAD_YTD_AVERAGE: 'ytdAverage',
  SPREAD_YTD_REVENUE: 'ytdRevenue'
} as const
