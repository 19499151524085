import React from 'react'
import RatioBox from './RatioBox'

const RatioText = ({ variant, value }) => {
  return (
    <div className='c-ratio-box__text'>
      {variant === RatioBox.VARIANTS.RATIO ? (
        <>
          {value ? (
            <>
              {value.split(':')[0]}
              <span className='c-ratio-box__text--colon'>:</span>
              {value.split(':')[1]}
            </>
          ) : (
            <span className='c-ratio-box__text--colon' style={{ marginLeft: '53px' }}>:</span>
          )}
        </>
      ) : (
        value
      )}
    </div>
  )
}

export default RatioText
