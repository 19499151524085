import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'

import store from '../redux'

const env: string = process.env.REACT_APP_ENVIRONMENT
const authId = typeof process.env.REACT_APP_AUTH_ID === 'string' ? process.env.REACT_APP_AUTH_ID.split(',') : []

const getIsPowerUser = () => {
  const { auth: { user: { account } } } = store.getState()
  if (account) {
    return authId.includes(account.username)
  }
  return false
}

const getStyleGuideLink = () => {
  switch (env) {
  case REACT_APP_ENVIRONMENT.PROD:
    return 'https://alku-ae-prod.s3.us-east-2.amazonaws.com/documents/pulse/ALKU_Pulse_User_Guide.pdf'
  case REACT_APP_ENVIRONMENT.UAT:
    return 'https://alku-ae-uat.s3.us-east-2.amazonaws.com/documents/pulse/ALKU_Pulse_User_Guide.pdf'
  case REACT_APP_ENVIRONMENT.DEV:
  default:
    return 'https://alku-ae-dev.s3.us-east-2.amazonaws.com/documents/pulse/ALKU_Pulse_User_Guide.pdf'
  }
}

export { getIsPowerUser, getStyleGuideLink }
