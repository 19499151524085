/* Define all the routes for the application here. */

/* Components */
import MySpreadScreen from '@/screens/MySpread'
import SpreadSummaryScreen from '@/screens/SpreadSummary'
import ConsultantPerformanceScreen from '@/screens/ConsultantPerformance'
import PowerRankingsScreen from '@/screens/PowerRankings'

/* Icons */
import DashboardIcon from '@/shared/components/Icons/Dashboard'
import Business from '@/shared/components/Icons/Business'
import UserIcon from '@/shared/components/Icons/User'
import UsersIcon from '@/shared/components/Icons/Users'
import Dashboard from '@/screens/Dashboard'
import MySpread from '@/features/Spread/components/MySpread'
import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'

/* Public routes */
export const PulseHomeRoute = '/'
export const ManageTargetsRoute = '/manage-targets'
export const SpreadHomeRoute = '/spread'
export const SpreadSummaryRoute = `${SpreadHomeRoute}/summary`
export const SpreadConsultantPerformanceRoute = `${SpreadHomeRoute}/consultant-performance`
export const SpreadPowerRankingsRoute = `${SpreadHomeRoute}/power-rankings`

/* Financial team routes */
export const SpreadReportHomeRoute = '/accounting-spread'
export const SpreadUploadSpreadRoute = `${SpreadReportHomeRoute}/upload/spread`
export const SpreadUploadFoundersRoute = `${SpreadReportHomeRoute}/upload/founders`

const subRoutes = [
  {
    path: SpreadHomeRoute, //Route: /spread
    icon: UserIcon,
    component: MySpread,
    label: 'My Spread',
    showOnNavigationSidebar: true,
    showOnNavigationHeader: false
  }
]

const env = process.env.REACT_APP_ENVIRONMENT || 'dev'

/* Todo: Only show these routes if the user is in the dev environment */
if (env === REACT_APP_ENVIRONMENT.DEV) {
  subRoutes.push(
    {
      path: SpreadPowerRankingsRoute, //Route: /spread/power-rankings
      icon: UsersIcon,
      component: PowerRankingsScreen,
      label: 'Power Rankings',
      showOnNavigationSidebar: true,
      showOnNavigationHeader: false
    },
    {
      path: SpreadConsultantPerformanceRoute, //Route: /spread/consultant-performance
      icon: Business,
      component: ConsultantPerformanceScreen,
      label: 'Consultant Performance',
      showOnNavigationSidebar: true,
      showOnNavigationHeader: false
    },
    {
      path: SpreadSummaryRoute, //Route: /spread/summary
      icon: DashboardIcon,
      component: SpreadSummaryScreen,
      label: 'ALKU Spread Summary',
      showOnNavigationSidebar: true,
      showOnNavigationHeader: false
    }
  )
}

export const PublicRoutes = [
  {
    path: PulseHomeRoute, //Route: /
    component: Dashboard, //Child component
    icon: DashboardIcon, //Icon for the route
    label: 'Activity', //Label for the route
    showOnNavigationSidebar: false, // If true, the route will be included in the navigation sidebar
    showOnNavigationHeader: true, // If true, the route will be included in the navigation header
    childs: [] //Route subpaths (if any)
  },
  {
    path: SpreadHomeRoute,
    icon: UserIcon,
    component: MySpreadScreen,
    label: 'Spread',
    showOnNavigationSidebar: true,
    showOnNavigationHeader: true,
    /* Subpaths */
    childs: subRoutes
  }
]

export const FinancialTeamRoutes = [
  SpreadReportHomeRoute,
  SpreadUploadSpreadRoute,
  SpreadUploadFoundersRoute
]
