// @ts-strict
import React, { useCallback } from 'react'

import columnConfig from './helpers/columConfigs'

import createTableConfig from './helpers/createTableConfig'
import { GET_ICONIXX_TABLE_NAMED_QUERY, IconixxRecord } from './helpers/query'
import TableHandler from '../TableHandler'
import { formatTableDate } from '@/utils/tools/dateTime'

type Props = {
  isActive: boolean
}

const IconixxTable = ({ isActive }: Props) => {
  const setHeaderOnLoad = useCallback((record: IconixxRecord) => {
    const timeRange = `${formatTableDate(record.startDate)} - ${formatTableDate(record.endDate)}`
    const week = record.week.replace(/[^0-9.]/,'')
    return <span>Iconixx Report for: <span className='c-type--semi-bold'>Week {week}: {timeRange}</span></span>
  }, [])

  return (
    <TableHandler
      isActive={isActive}
      createTableConfig={createTableConfig}
      columnConfig={columnConfig}
      setHeaderOnLoad={setHeaderOnLoad}
      queryString={GET_ICONIXX_TABLE_NAMED_QUERY}
      errorMessage='Error fetching Iconixx report.'
      dataPath={['allIconixx']}
    />
  )
}

export default IconixxTable
