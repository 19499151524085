import { getActionTypes, getMultiActionTypes } from '@/bootstrap/redux/action';

const prefix = 'CONTROL_PANEL';

// CLEAR_TOKEN: AUTH/CLEAR_TOKEN
const unitTypes = getActionTypes(
  [
    'SET_TIME_PERIOD',
    'SET_CUSTOM_DATE_RANGE_ENABLED',
    'SET_CUSTOM_DATE_RANGE',
    'SET_AT_CHECKED_KEYS',
    'SET_AT_DEBOUNCED_CHECKED_KEYS',
    'SET_AT_EXPANDED_KEYS',
    'SET_AT_SELECTED_KEYS',
    'SET_AT_DATA',
    'SET_TIME_SLIDER_MARKS',
    'SET_ACTIVE_SHORTCUT_KEY',
    'SET_AT_EMPLOYEE_DICTIONARY',
    'SET_AUDIENCE_TREE_SEARCH',
    'SET_AUDIENCE_TREE_SEARCH_VALUE',
    'SET_AT_AUTO_EXPAND_PARENT',
    'SAVE_AT_STATE',
    'ROLLBACK_AT_SAVE_STATE',
    'SET_AT_SEARCH_RESULT_KEYS',
    'ADD_AT_CHECKED_KEYS',
    'ADD_AT_EXPANDED_KEYS',
    'CLEAR_AT_SEARCH',
    'ADD_AT_DEBOUNCED_CHECKED_KEYS',
    'SET_SELECTED_DATES'
  ],
  prefix
)

/**
 * REFRESH_TOKEN: AUTH/REFRESH_TOKEN
 * REFRESH_TOKEN_BEGIN: AUTH/REFRESH_TOKEN_BEGIN
 * REFRESH_TOKEN_SUCCESS: AUTH/REFRESH_TOKEN_SUCCESS
 * REFRESH_TOKEN_FAIL: AUTH/REFRESH_TOKEN_FAIL
 **/
const multiTypes = getMultiActionTypes(
  [
    'SAVE_MY_DIVISIONS',
    'SAVE_MY_PEOPLE'
  ],
  prefix,
);

const controlPanelTypes = {
  ...unitTypes,
  ...multiTypes,
}

export default controlPanelTypes
