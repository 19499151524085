import { createAction } from '@reduxjs/toolkit'

import sharedTypes from './sharedTypes'
import { IItemizedMetricReportResponse, IOneTimeResponse, IPulseByDivisionReportResponse } from '@/shared/types/swagger'
import { CalendarMarker, IPushNotice } from '@/shared/types'
import { GrafanaInfoPayload } from '@/shared/components/GrafanaProfiler'

export const setFiscalCalendarMarkers = createAction<Partial<Record<string, CalendarMarker>>>(sharedTypes.SET_FISCAL_CALENDAR_MARKERS)
export const setInitialLoad = createAction<boolean>(sharedTypes.SET_INITIAL_LOAD)
export const addToGrafanaQueue = createAction<GrafanaInfoPayload>(sharedTypes.ADD_TO_GRAFANA_QUEUE)
export const clearGrafanaQueue = createAction(sharedTypes.CLEAR_TO_GRAFANA_QUEUE)
export const setSsoError = createAction<boolean>(sharedTypes.SET_SSO_ERROR)
export const pushNotice = createAction<IPushNotice>(sharedTypes.PUSH_NOTICE)
export const dismissNotice = createAction<string>(sharedTypes.DISMISS_NOTICE)
export const dismissNoticeByDuplicateId = createAction<string>(sharedTypes.DISMISS_NOTICE_BY_DUPLICATE_ID)
export const setShowServiceErrorScreen = createAction<boolean>(sharedTypes.SET_SHOW_SERVICE_ERROR_SCREEN)
export const clearNotices = createAction(sharedTypes.CLEAR_NOTICES)
export const setAllReportResponse = createAction<IPulseByDivisionReportResponse>(sharedTypes.SET_ALL_REPORT_RESPONSE)
export const setQsParsing = createAction<boolean>(sharedTypes.SET_QS_PARSING)
export const setServiceErrorMessage = createAction<string>(sharedTypes.SET_SERVICE_ERROR_MESSAGE)

export const fetchReportBegin = createAction(sharedTypes.FETCH_REPORT_BEGIN)
export const fetchReportSuccess = createAction<IPulseByDivisionReportResponse>(sharedTypes.FETCH_REPORT_SUCCESS)
export const fetchReportFail = createAction(sharedTypes.FETCH_REPORT_FAIL)

export const fetchEntityContextBegin = createAction(sharedTypes.FETCH_ENTITY_CONTEXT_BEGIN)
export const fetchEntityContextSuccess = createAction<IOneTimeResponse>(sharedTypes.FETCH_ENTITY_CONTEXT_SUCCESS)
export const fetchEntityContextFail = createAction<string>(sharedTypes.FETCH_ENTITY_CONTEXT_FAIL)

export const fetchItemizationReportBegin = createAction(sharedTypes.FETCH_ITEMIZATION_REPORT_BEGIN)
export const fetchItemizationReportSuccess = createAction<IItemizedMetricReportResponse>(sharedTypes.FETCH_ITEMIZATION_REPORT_SUCCESS)
export const fetchItemizationReportFail = createAction(sharedTypes.FETCH_ITEMIZATION_REPORT_FAIL)