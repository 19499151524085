import { createReducer } from '@reduxjs/toolkit'

import manageTargetsTypes from './manageTargetsTypes'
import { RequestState, TreeNode } from '@/shared/types'
import { IManageTargetsResponse, Target } from '@/shared/types/swagger'
import {
  discardSavedTargets,
  fetchTargetsBegin,
  fetchTargetsFail,
  fetchTargetsSuccess,
  saveTargetsBegin,
  saveTargetsFail,
  saveTargetsSuccess,
  setMtAmTableData,
  setMtRecruitingTableData,
  setSaveTargetsState,
  setUserSavedTarget
} from './manageTargetsActionsPlain'

type SavedTargets = Record<string, Partial<Target>>

type ManageTargetsState = {
  amTableData: Array<TreeNode>
  recruitingTableData: Array<TreeNode>
  data: Partial<IManageTargetsResponse>
  savedTargets?: SavedTargets
  meta: {
    inputRefreshTs: string | number
    fetchTargetsError: boolean
    saveTargetsState: RequestState
  }
}

const initialState: ManageTargetsState = {
  amTableData: null,
  recruitingTableData: null,
  savedTargets: null,
  data: [],
  meta: {
    inputRefreshTs: 'init',
    fetchTargetsError: false,
    saveTargetsState: 'none'
  }
}

export default createReducer(initialState, (builder) => {
  builder.addCase(setMtAmTableData, (state, action) => {
    state.amTableData = action.payload
  })
  builder.addCase(setMtRecruitingTableData, (state, action) => {
    state.recruitingTableData = action.payload
  })
  builder.addCase(discardSavedTargets, state => {
    state.savedTargets = null
    state.meta.inputRefreshTs = Date.now()
  })
  builder.addCase(setUserSavedTarget, (state, action) => {
    const { id, metric, value, deleteMetric } = action.payload
    if (id && metric && !isNaN(value)) {
      if (!deleteMetric) {
        if (state.savedTargets) {
          state.savedTargets[id] = { ...(state.savedTargets[id] || {}), [metric]: value }
        } else {
          state.savedTargets = { [id]: { [metric]: value } }
        }
      } else if (
        state.savedTargets?.[id]?.[metric] ||
        state.savedTargets?.[id]?.[metric] === 0
      ) {
        delete state.savedTargets[id][metric]
        if (!Object.keys(state.savedTargets[id]).length) {
          delete state.savedTargets[id]
          if (!Object.keys(state.savedTargets).length) {
            state.savedTargets = null
            state.meta.inputRefreshTs = Date.now()
          }
        }
      }
    } else {
      console.error(
        `Invalid ${manageTargetsTypes.SET_USER_SAVED_TARGET} payload.`
      )
    }
  })
  builder.addCase(fetchTargetsBegin, state => {
    state.meta.fetchTargetsError = false
  })
  builder.addCase(fetchTargetsFail, state => {
    state.meta.fetchTargetsError = true
  })
  builder.addCase(fetchTargetsSuccess, (state, action) => {
    state.data = action.payload
  })
  builder.addCase(saveTargetsBegin, state => {
    state.meta.saveTargetsState = 'inProgress'
  })
  builder.addCase(saveTargetsSuccess, (state, action) => {
    state.data = state.data.map(v => {
      const updatedTarget = action.payload.find(target => target['user-id'] === v['user-id'])
      if (updatedTarget) {
        return updatedTarget
      }
      return v
    })
    state.savedTargets = null
    state.meta.inputRefreshTs = Date.now()
    state.meta.saveTargetsState = 'success'
  })
  builder.addCase(saveTargetsFail, state => {
    state.meta.saveTargetsState = 'error'
  })
  builder.addCase(setSaveTargetsState, (state, action) => {
    state.meta.saveTargetsState = action.payload
  })
})
