import { createAction } from '@reduxjs/toolkit'

import { ExpandedKeysObj } from '@/shared/components/Table'
import { FunnelTarget } from '@/shared/types'

import dashboardTypes from './dashboardTypes'

export const setActiveTarget = createAction<FunnelTarget>(dashboardTypes.SET_ACTIVE_TARGET)
export const setActiveDashboardTabKey = createAction<string>(dashboardTypes.SET_ACTIVE_DASHBOARD_TAB_KEY)
export const setShowDashboardLoader = createAction<boolean>(dashboardTypes.SET_SHOW_LOADER)

// primary report table actions
export const setPrtExpandedKeysObj = createAction<Partial<ExpandedKeysObj>>(dashboardTypes.SET_PRT_EXPANDED_KEYS_OBJ)
export const addPrtExpandedKeysObj = createAction<Partial<ExpandedKeysObj>>(dashboardTypes.ADD_PRT_EXPANDED_KEYS_OBJ)
