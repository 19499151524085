// @ts-strict
import { useCallback } from 'react'
import { MetricsConfig, generateConfig } from '../helpers/columnConfigs'
import createMySpreadOvertimeConfig, {
  TableConfig
} from '../helpers/createTableConfig'

export default function useOverTimeData(data) {
  const overTimeLoading = false
  const overTimeError = false

  const createTableConfig = useCallback(() => {
    return createMySpreadOvertimeConfig(data)
  }, [data])

  const tableConfig: TableConfig[] = createTableConfig()
  const columnConfig: MetricsConfig[] = generateConfig()

  return {
    tableConfig,
    columnConfig,
    overTimeLoading,
    overTimeError
  }
}
