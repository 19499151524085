// @ts-strict
import { MonthlyTargetRecord } from '../TotalsTable/helpers/query'
import { SpreadDepartmentMapping } from './departmentMappingsQuery'

type MonthlyTargets = {
  gPM?: number
  spread?: number
  timeCardsPaidNet?: number,
  totalBillAmount?: number
  totalPayAmount?: number
}

export type FiscalMonthTargets = Record<number, MonthlyTargets>
type DepartmentsFiscalMonthTargets = Record<string, FiscalMonthTargets>

type TargetFieldMapper = {
  keyFrom: keyof MonthlyTargetRecord
  keyTo: keyof MonthlyTargets | string
}
const targetFields: TargetFieldMapper[] = [
  { keyFrom: 'timecardTarget', keyTo: 'timeCardsPaidNet' },
  { keyFrom: 'billTarget', keyTo: 'totalBillAmount' },
  { keyFrom: 'payTarget', keyTo: 'totalPayAmount' },
  { keyFrom: 'spreadTarget', keyTo: 'spread' }
]

const mergeData = (
  name: string,
  monthlyTargetData: MonthlyTargetRecord[],
  type: 'brand' | 'department' | 'splitBy' | 'subBrand'
): FiscalMonthTargets => {
  const data: FiscalMonthTargets = {}
  const records = monthlyTargetData.filter(v => v[type] === name)
  addData(records, data)

  return data
}
const addData = (records: MonthlyTargetRecord[], dataObj: FiscalMonthTargets) => {
  records.forEach((record) => {
    const { fiscalMonth } = record
    targetFields.forEach(({ keyFrom, keyTo }) => {
      if (dataObj[fiscalMonth]) {
        if (dataObj[fiscalMonth][keyTo]) {
          dataObj[fiscalMonth][keyTo] = dataObj[fiscalMonth][keyTo] + record[keyFrom]
        } else {
          dataObj[fiscalMonth][keyTo] = record[keyFrom]
        }
      } else {
        dataObj[fiscalMonth] = { [keyTo]: record[keyFrom] }
      }
    })
  })
}

const aggregateBrandTotals = (brandDepartmentMapping: SpreadDepartmentMapping[], groupedTargets: DepartmentsFiscalMonthTargets): FiscalMonthTargets => {
  const data: FiscalMonthTargets = {}
  const allBrands = brandDepartmentMapping.reduce<string[]>((acc, nextRec) => {
    if (nextRec.brand && !acc.includes(nextRec.brand)) {
      acc.push(nextRec.brand)
    }
    return acc
  }, [])

  allBrands.forEach(brand => {
    const targetData = groupedTargets[brand]
    if (targetData) {
      Object.entries(targetData).forEach(([fiscalMonth, targets]) => {
        Object.entries(targets).forEach(([targetKey, targetValue]) => {
          if (data[fiscalMonth]) {
            if (data[fiscalMonth][targetKey]) {
              data[fiscalMonth][targetKey] = data[fiscalMonth][targetKey] + targetValue
            } else {
              data[fiscalMonth][targetKey] = targetValue
            }
          } else {
            data[fiscalMonth] = { [targetKey]: targetValue }
          }
        })
      })
    }
  })

  return data
}

const groupMonthlyTargetData = (
  brandDepartmentMapping: SpreadDepartmentMapping[],
  monthlyTargetData: MonthlyTargetRecord[]
) => {
  const aggregateFields: Array<keyof Omit<SpreadDepartmentMapping, 'enable'>> = ['brand', 'department', 'splitBy', 'subBrand']

  const groupedData = brandDepartmentMapping.reduce<DepartmentsFiscalMonthTargets>((acc, nextRecord) => {
    aggregateFields.forEach(field => {
      if (!acc[nextRecord[field]]) {
        acc[nextRecord[field]] = mergeData(nextRecord[field], monthlyTargetData, field)
      }
    })

    return acc
  }, {})

  groupedData['All ALKU'] = aggregateBrandTotals(brandDepartmentMapping, groupedData)

  return groupedData
}

export default groupMonthlyTargetData
