import { get, post } from '@/bootstrap/axios'
import { AxiosResponse } from 'axios'
import { EtlInProcessResponse, SignedUrlResponse } from '@/shared/types/swagger'
import { getDomoBaseHost } from '../../../bootstrap/apollo'

export const getPresignedUrl = async (name: string, expiry: number) => {
  const host = getDomoBaseHost()
  const url = `${host}/domo/api/signed-urls?fileName=${name}&expiry=${expiry}`
  const data = await get<AxiosResponse<SignedUrlResponse>>(url)
  return data
}

export const getEtlIsProcessing = async () => {
  const host = getDomoBaseHost()
  const url = `${host}/domo/api/notifications/etl_in_process`
  return get<AxiosResponse<EtlInProcessResponse>>(url)
}

export const setEtlIsProcessingTrue = async () => {
  const host = getDomoBaseHost()
  const url = `${host}/domo/api/Notifications/etl_in_process_true`
  await post(url, null)
}
