import React from 'react'
import Icon from './Icon'

const AvatarPlaceholder = ({ position = 'left', size = 32 }) => {
  return (
    <Icon position={position}>
      <svg
        width={`${size}px`}
        height={`${size}px`}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 32 32'
        preserveAspectRatio='xMidYMid meet'
      >
        <path
          d='M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm0 4.4a5.2 5.2 0 110 10.4 5.2 5.2 0 010-10.4zm0 24.4a12.785 12.785 0 01-10.634-5.675C7.16 20.517 12.874 19.2 16 19.2c3.126 0 8.84 1.317 10.634 3.925A12.785 12.785 0 0116 28.8z'
          fill='#000'
          fillRule='nonzero'
        />
      </svg>
    </Icon>
  );
}

export default AvatarPlaceholder
