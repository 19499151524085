import { ICompanyData, IAggregatedData, IOneTimeResponse } from '@/shared/types/swagger'

export type MyPeopleTableRecord = {
  data: IAggregatedData
  firstName: string
  lastName: string
  isPrimaryDivision: boolean
  divisionId: string
  divisionName: string
  employeeId: string
  employeeName: string
  children?: MyPeopleTableRecord[]
  childTable?: unknown[]
}

type GroupedUserRecord = {
  data: IAggregatedData
  divisionId: string
}

// drills through company data object to retrieve all user records in a top level object
const groupUserRecords = (brandData: ICompanyData['brand-data']): Record<string, GroupedUserRecord[]> => {
  if (brandData) {
    return Object.values(brandData).reduce<Record<string, GroupedUserRecord[]>>((acc, nextBrand) => {
      if (nextBrand['division-data']) {
        Object.entries(nextBrand['division-data']).forEach(([divisionId, divisionDataDetails]) => {
          Object.entries(divisionDataDetails['people-data']).forEach(([personId, { data }]) => {
            const groupedUserRecord = { divisionId, data }
            if (acc[personId] && !acc[personId].find(v => v.divisionId === divisionId)) {
              acc[personId].push(groupedUserRecord)
            } else if (!acc[personId]) {
              acc[personId] = [groupedUserRecord]
            }
          })
        })
      }
      return acc
    }, {})
  } else {
    return {}
  }
}

const sumAggregateDataFromRecords = (records: MyPeopleTableRecord[], primaryRecord: MyPeopleTableRecord): IAggregatedData => {
  return records.reduce<IAggregatedData>((acc, nextRecord) => {

    if (nextRecord.divisionId !== primaryRecord.divisionId) {
      Object.entries(nextRecord.data).forEach(([dataKey, aggregateData]) => {
        if (dataKey !== 'calculations' && typeof aggregateData.actual === 'number') {
          const updatedAggregatedData = { ...acc[dataKey], actual: acc[dataKey].actual + aggregateData.actual }
          acc[dataKey] = updatedAggregatedData
        }
      })
    }
    return acc
  }, { ...primaryRecord.data })
}

const formatDataForMyPeopleTable = (brandData: ICompanyData['brand-data'], entityContext: IOneTimeResponse): MyPeopleTableRecord[] => {
  const groupedRecords = groupUserRecords(brandData)
  const { people, divisions } = entityContext || {}
  return Object.entries(groupedRecords).reduce<MyPeopleTableRecord[]>((acc, [personId, records]) => {
    const personContext = people?.[personId]
    const firstName = personContext?.['first-name']?.trim()
    const lastName = personContext?.['last-name']?.trim()
    const employeeName = `${firstName} ${lastName}`
    const enrichedRecords: MyPeopleTableRecord[] = records.map(record => {
      const divisionContext = divisions?.[record.divisionId]
      const isPrimaryDivision = divisionContext.members.includes(personId)
      const divisionName = divisionContext?.name
      return {
        ...record,
        isPrimaryDivision,
        divisionName,
        firstName,
        lastName,
        employeeId: personId,
        employeeName
      }
    }).sort((a, b) => {
      if (a.isPrimaryDivision) return -1
      if (b.isPrimaryDivision) return 1
      return a.divisionName.localeCompare(b.divisionName)
    })
    if (records.length > 1) {
      const primaryRecord = enrichedRecords[0]
      const mergedData = sumAggregateDataFromRecords(enrichedRecords, primaryRecord)
      return [
        ...acc,
        {
          ...primaryRecord,
          data: mergedData,
          children: enrichedRecords
        }
      ]
    }

    return [...acc, ...enrichedRecords]
  }, []).sort((a, b) => a.lastName.localeCompare(b.lastName))
}

export default formatDataForMyPeopleTable
