import React from 'react';
import { Skeleton as AntSkeleton } from 'antd'

const SkeletonTableRow = ({ firstCellInputSize = 206, active, titleOnly = false, columnConfig }) => {
  if (columnConfig) {
    return (
      <div className='c-table-skeleton__row'>
        {columnConfig.map(({ inputSize, headerInputSize }, index) => (
          <div className='c-table-skeleton__cell' key={index} style={{ width: Math.max(inputSize, headerInputSize || inputSize) }}>
            {!titleOnly && (
              <AntSkeleton.Input
                style={{ width: headerInputSize || inputSize }}
                active={active}
                size={'small'}
              />
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className='c-table-skeleton__row'>
      <div className='c-table-skeleton__cell' style={{ flexBasis: '25%' }}>
        <AntSkeleton.Input
          style={{ width: firstCellInputSize }}
          active={active}
          size={'small'}
        />
      </div>
      <div className='c-table-skeleton__cell'>
        {!titleOnly && (
          <AntSkeleton.Input
            style={{ width: 65 }}
            active={active}
            size={'small'}
          />
        )}
      </div>
      <div className='c-table-skeleton__cell'>
        {!titleOnly && (
          <AntSkeleton.Input
            style={{ width: 65 }}
            active={active}
            size={'small'}
          />
        )}
      </div>
      <div className='c-table-skeleton__cell'>
        {!titleOnly && (
          <AntSkeleton.Input
            style={{ width: 65 }}
            active={active}
            size={'small'}
          />
        )}
      </div>
      <div className='c-table-skeleton__cell'>
        {!titleOnly && (
          <AntSkeleton.Input
            style={{ width: 65 }}
            active={active}
            size={'small'}
          />
        )}
      </div>
      <div className='c-table-skeleton__cell'>
        {!titleOnly && (
          <AntSkeleton.Input
            style={{ width: 65 }}
            active={active}
            size={'small'}
          />
        )}
      </div>
      <div className='c-table-skeleton__cell'>
        {!titleOnly && (
          <AntSkeleton.Input
            style={{ width: 65 }}
            active={active}
            size={'small'}
          />
        )}
      </div>
    </div>
  )
};

export default SkeletonTableRow;
