export const PRESET_1 = [
  {
    width: 99,
    children: [
      {
        width: 100,
        children: [
          {
            width: 150,
            children: [
              { width: 150 },
              { width: 130 },
              { width: 150 }
            ]
          },
          { width: 150 },
          {
            width: 150,
            children: [{ width: 150 }, { width: 150 }]
          },
          { width: 150 }
        ]
      },
      {
        width: 150,
        children: [
          { width: 150 },
          { width: 150 },
          { width: 150 },
          { width: 150 }
        ]
      },
      {
        width: 150,
        children: [{ width: 150 }]
      }
    ]
  }
]
