import React from 'react'

import { MultipleDivisions, OldUser } from '@/shared/components/Icons'
import { Tooltip } from '@/shared/components'

type AudienceTreeIconProps = {
  isInactive?: boolean;
  showOtherContributorShortcut?: boolean;
  onShortcutClick: () => void
  id: string;
}

const AudienceTreeIcon = ({
  isInactive,
  showOtherContributorShortcut,
  onShortcutClick,
  id
} : AudienceTreeIconProps) => {
  if (isInactive) {
    return (
      <Tooltip title='No Longer Active'>
        {''}
        <OldUser />
      </Tooltip>
    )
  }
  if (showOtherContributorShortcut) {
    return (
      <Tooltip title='Select for all activity'>
        {''}
        <MultipleDivisions
          id={id}
          onClick={e => {
            e.stopPropagation()
            onShortcutClick()
          }}
        />
      </Tooltip>
    )
  }
  return null
}

export default AudienceTreeIcon
