import React from 'react'

const Icon = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='18.000000pt'
      height='18.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M412 824 c-18 -10 -45 -33 -60 -52 -22 -29 -27 -46 -27 -92 0 -47 5
-63 29 -94 87 -115 267 -67 283 76 7 57 -15 108 -61 143 -46 35 -116 43 -164
19z m123 -89 c50 -49 15 -135 -55 -135 -19 0 -40 9 -55 25 -50 49 -15 135 55
135 19 0 40 -9 55 -25z'
        />
        <path
          d='M355 385 c-89 -19 -166 -57 -203 -99 -29 -33 -32 -43 -32 -101 l0
-65 360 0 360 0 0 65 c0 58 -3 68 -32 101 -79 90 -288 135 -453 99z m286 -94
c71 -22 119 -53 119 -77 0 -12 -44 -14 -280 -14 -228 0 -280 3 -280 13 0 51
172 110 300 103 41 -2 105 -14 141 -25z'
        />
      </g>
    </svg>
  </div>
)

export default Icon
