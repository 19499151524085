import React, { useState } from 'react'
import ConsultantPerformanceFilters from '@/features/Spread/components/ConsultantPerformanceFilters'
import ConsultantPerformanceSummary from '@/features/Spread/components/ConsultantPerformanceSummary'
import { ConsultantPerformanceTable } from '@/features/Spread/components/ConsultantPerformanceTable'
import { consultantPerformanceMock } from '@/features/Spread/components/ConsultantPerformanceTable/hooks/consultantPerformanceMock'

export const initialFilterState = {
  week: null,
  client: null,
  consultant: null
}

const ConsultantPerformance = () => {
  const [filters, setFilters] = useState(initialFilterState)
  const data = consultantPerformanceMock.rows.filter((row) => {
    if (filters) {
      if (filters.week && filters.week !== row.week) {
        return false
      }
      if (filters.client && filters.client !== row.client) {
        return false
      }
      if (
        filters.consultant &&
        !row.consultant
          .toLocaleLowerCase()
          .includes(filters.consultant.toLocaleLowerCase())
      ) {
        return false
      }
    }
    return true
  })
  return (
    <div className='c-power-ranking'>
      <div className='c-spread__header'>
        <h1 className='c-type c-type--title-xl c-type--bold'>
          Consultant Performance
        </h1>
      </div>
      <ConsultantPerformanceSummary />
      <ConsultantPerformanceFilters setFilters={setFilters} filters={filters} />
      <ConsultantPerformanceTable data={data} />
    </div>
  )
}

export default ConsultantPerformance
