import React from 'react'
import PropTypes from 'prop-types'
import COLOR_KEYS from '@/shared/constants/colors'

const Icon = ({ fill }) => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='32.000000pt'
      height='28.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        fill={fill ? COLOR_KEYS[fill] || fill : '#404555'}
        stroke='none'
      >
        <path
          d='M467 801 l-289 -76 151 -3 c140 -2 158 -1 263 28 97 26 116 28 140
      17 16 -6 31 -20 34 -29 4 -13 16 -18 45 -18 21 0 39 2 39 5 0 16 -35 132 -43
      143 -5 6 -19 11 -31 11 -11 -1 -151 -36 -309 -78z'
        />
        <path
          d='M92 628 c-17 -17 -17 -439 0 -456 17 -17 759 -17 776 0 17 17 17 439
      0 456 -17 17 -759 17 -776 0z m688 -88 c18 -18 20 -33 20 -140 0 -173 26 -160
      -320 -160 -346 0 -320 -13 -320 160 0 100 3 123 18 139 16 18 34 19 300 20
      269 1 283 0 302 -19z'
        />
        <path
          d='M435 511 c-45 -20 -70 -60 -70 -112 0 -42 5 -53 33 -81 47 -48 117
      -48 164 0 28 28 33 39 33 82 0 42 -5 54 -31 81 -33 33 -92 46 -129 30z'
        />
        <path
          d='M250 425 c-15 -18 -10 -45 13 -59 34 -22 73 27 47 59 -16 19 -44 19
      -60 0z'
        />
        <path
          d='M650 425 c-15 -18 -10 -45 13 -59 34 -22 73 27 47 59 -16 19 -44 19
      -60 0z'
        />
      </g>
    </svg>
  </div>
)

Icon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string
}

export default Icon
