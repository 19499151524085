import React, { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { Table, Skeleton, GrafanaProfiler } from '@/shared/components'
import { accountManagementPulseByDivisionConfig, recruitingPulseByDivisionConfig } from './helpers/columnConfigs'
import createBrandDivisionEmployeeTableConfig from './helpers/createTableConfig'
import { selectBrandContext, selectCompanyTotals, selectEntityContext, selectReportResponseLoading } from '@/features/shared/redux/sharedSelectors'
import { selectBrandTreeData, selectCheckedBrandTreeData, selectGovernmentDivisionIsChecked, selectTimePeriodId } from '@/features/ControlPanel/redux/controlPanelSelectors'
import {
  selectActiveDashboardTabKey,
  selectPrtExpandedKeysObj
} from '@/features/dashboard/redux/dashboardSelectors'
import { setPrtExpandedKeysObj } from '@/features/dashboard/redux/dashboardActions'
import { ALL_ALKU_KEY, CUSTOM_TF } from '@/shared/constants/dataKeys'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'

const PrimaryReportTable = () => {
  const dispatch = useAppDispatch()
  const keysObj = useAppSelector(selectPrtExpandedKeysObj)
  const fetchingReport = useAppSelector(selectReportResponseLoading)
  const setKeysObj = useCallback(val => { dispatch(setPrtExpandedKeysObj(val)) }, [dispatch])
  const context = useAppSelector(selectBrandContext)
  const selected = useAppSelector(selectCheckedBrandTreeData)
  const brandTreeData = useAppSelector(selectBrandTreeData)
  const entityContext = useAppSelector(selectEntityContext)
  const accountManagementTabActive = useAppSelector(selectActiveDashboardTabKey) === DASHBOARD_TAB_KEYS.AM
  const includeGovernment = useAppSelector(selectGovernmentDivisionIsChecked)
  const timePeriodId = useAppSelector(selectTimePeriodId)
  const reportData = useAppSelector(selectBrandContext)
  const totalsData = useAppSelector(selectCompanyTotals)
  const createTableConfig = useCallback(() => {
    if (context && selected) {
      return createBrandDivisionEmployeeTableConfig(entityContext, context, selected)
    } else {
      return []
    }
  }, [context, entityContext, selected])
  const createColumnConfig = useCallback(() => {
    if (accountManagementTabActive) {
      return accountManagementPulseByDivisionConfig(includeGovernment)
    } else {
      return recruitingPulseByDivisionConfig(includeGovernment)
    }
  }, [includeGovernment, accountManagementTabActive])
  const tableConfig = createTableConfig()
  const columnConfig = createColumnConfig()
  const locale = brandTreeData && brandTreeData.length && { emptyText: reportData ? 'Nothing is currently selected.' : 'Data Not Found' }

  return (
    <Skeleton.Table
      active
      title
      loading={(!tableConfig.length && !brandTreeData) || fetchingReport}
      config={Skeleton.Table.PRESET_1}
    >
      <h3 className='c-type c-type--table-header-text'>
        {`${accountManagementTabActive ? 'Account Management' : 'Recruiting'} Pulse by Division`}
      </h3>
      <GrafanaProfiler id='primary-report-table' tags={[`time-period:${timePeriodId || CUSTOM_TF}`]}>
        <Table
          sticky
          tableConfig={tableConfig}
          columnConfig={columnConfig}
          locale={locale}
          expandedKeysObj={keysObj}
          setExpandedKeysObj={setKeysObj}
          parentKey={ALL_ALKU_KEY}
          includeTotalsRow={selected.length}
          totalsData={totalsData}
        />
      </GrafanaProfiler>
    </Skeleton.Table>
  )
}

export default PrimaryReportTable
