import React from 'react'
import Icon, { IconProps } from './Icon'

type CloseProps = Pick<IconProps, 'onClick' | 'position' | 'className'>;

const Close = ({ position, onClick, className } : CloseProps) => (
  <Icon position={position} onClick={onClick} className={className}>
    <svg
      width='12'
      height='12'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          id='prefix__a--close'
          d='M.943 0L0 .943l4.862 4.862L0 10.667l.943.942 4.862-4.862 4.862 4.862.942-.942-4.862-4.862L11.609.943 10.667 0 5.805 4.862z'
        />
      </defs>
      <g transform='translate(.195 .195)' fill='none' fillRule='evenodd'>
        <mask id='prefix__b--close' fill='#fff'>
          <use xlinkHref='#prefix__a--close' />
        </mask>
        <use fill='#000' fillRule='nonzero' xlinkHref='#prefix__a--close' />
        <path
          fill='#404555'
          mask='url(#prefix__b--close)'
          d='M-2.195-2.195h16v16h-16z'
        />
      </g>
    </svg>
  </Icon>
)

export default Close
