import React from 'react';
import Icon from './Icon'

const Target = ({ position }) => (
  <Icon position={position}>
    <svg
      width='16'
      height='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <path
          d='M8 0a8 8 0 00-8 8 8 8 0 008 8 8 8 0 006.825-12.175l-1.175 1.2A6.29 6.29 0 0114.4 8 6.4 6.4 0 118 1.6c1.09 0 2.103.294 3 .775L12.175 1.2A7.926 7.926 0 008 0zm6.8.075L8.4 6.45A1.6 1.6 0 108 9.6a1.6 1.6 0 001.55-2l6.375-6.4L14.8.075zM8 3.2a4.801 4.801 0 000 9.6c2.65 0 4.8-2.15 4.8-4.8 0-.634-.125-1.222-.35-1.775L11.15 7.5c.028.16.05.331.05.5 0 1.769-1.431 3.2-3.2 3.2A3.198 3.198 0 014.8 8c0-1.769 1.431-3.2 3.2-3.2.178 0 .356.019.525.05L9.8 3.575A4.67 4.67 0 008 3.2z'
          id='prefix__a-icon-target'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b-icon-target' fill='#fff'>
          <use xlinkHref='#prefix__a-icon-target' />
        </mask>
        <use fill='#000' fillRule='nonzero' xlinkHref='#prefix__a-icon-target' />
        <path fill='#404555' mask='url(#prefix__b-icon-target)' d='M-2-2h20v20H-2z' />
      </g>
    </svg>
  </Icon>
)

export default Target
