import React from 'react'

import Target from '../Target'

const TargetGroup = ({ config }) => {
  return (
    <div className='c-target__group'>
      {config.map((c, index) => (
        <Target
          key={c.key || c.dataIndex || c.title || index}
          isFirstTarget={index === 0}
          isLastTarget={c.isLastTarget || config.length - 1 === index}
          isGroupTarget
          {...c}
        />
      ))}
    </div>
  )
}

export default TargetGroup
