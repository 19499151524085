import { gql } from '@apollo/client'
import { DepartmentMappingsData, GET_DEPARTMENT_MAPPINGS_QUERY } from '../../helpers/departmentMappingsQuery'

export interface MonthlyTargetRecord {
  billTarget: number
  brand: string
  department: string
  fiscalMonth: number
  payTarget: number
  spreadTarget: number
  timecardTarget: number
  splitBy: string
  subBrand: string
}

export interface MonthlyTargetsData {
  allMonthlyTargets: MonthlyTargetRecord[]
}

export interface BaseTotalsRecord {
  averageHourlyBill: number
  averageHourlyPay: number
  averageHourlySpread: number
  averageHoursPerCandidate: number
  currentYear: string
  fiscalMonth: number
  fiscalQuarter: number
  fiscalWeek: string
  fiscalYear: number
  fiscalYearWeek: string
  gPM: number
  referencedWeek: string
  spread: number
  timeCardsPaidNet: number,
  totalBillAmount: number
  totalHoursPaid: number
  totalHoursBilled: number
  totalPayAmount: number
  week: string
  yearWeek: string
}

export interface TotalsRecord extends BaseTotalsRecord {
  startDate: string
  endDate: string
}

export interface DepartmentTotalsRecord extends BaseTotalsRecord {
  department: string
  brand: string
  splitBy: string
  subBrand: string
}

export interface TotalsSummaryTableData {
  totalSummary: TotalsRecord[]
}

export interface DepartmentsTotalsTableData {
  allDepartmentSummary: DepartmentTotalsRecord[]
}

export type TotalsTableData = TotalsSummaryTableData & DepartmentsTotalsTableData & MonthlyTargetsData & DepartmentMappingsData

const sharedFields = `
  averageHourlyBill
  averageHourlyPay
  averageHourlySpread
  averageHoursPerCandidate
  currentYear
  fiscalMonth
  fiscalQuarter
  fiscalWeek
  fiscalYear
  fiscalYearWeek
  gPM
  referencedWeek
  spread
  timeCardsPaidNet
  totalBillAmount
  totalHoursPaid
  totalHoursBilled
  totalPayAmount
  week
  yearWeek
`

export const GET_ALL_MONTHLY_TARGETS_QUERY = `
  allMonthlyTargets {
    billTarget
    brand
    department
    fiscalMonth
    payTarget
    spreadTarget
    timecardTarget
    splitBy
    subBrand
  }
`

export const GET_TOTALS_TABLE_QUERY = `
  totalSummary {
    ${sharedFields}
    startDate
    endDate
  }
`

export const GET_DEPARTMENT_TOTALS_TABLE_QUERY = `
  allDepartmentSummary {
    ${sharedFields}
    department
    brand
    splitBy
    subBrand
  }
`

export const GET_TOTALS_TABLE_NAMED_QUERY = gql`
  query TotalsTable {
    ${GET_TOTALS_TABLE_QUERY}
    ${GET_DEPARTMENT_TOTALS_TABLE_QUERY}
    ${GET_DEPARTMENT_MAPPINGS_QUERY}
    ${GET_ALL_MONTHLY_TARGETS_QUERY}
  }
`
