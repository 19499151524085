import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: any
  AWSDateTime: any
}

export type BullHornId = {
  value?: Maybe<Scalars['Int']>
}

export type DateRange = {
  from: Scalars['AWSDate']
  to: Scalars['AWSDate']
}

export type FounderRanks = {
  founderStatus?: Maybe<FounderStatus>
  powerRanks?: Maybe<PowerRanks>
}

export type FounderStatus = {
  average?: Maybe<Scalars['Float']>
  fiscalYear?: Maybe<Scalars['Int']>
  percentage?: Maybe<Scalars['Float']>
  status?: Maybe<Status>
}

export type MonthlyAverageSpread = {
  monthsValue?: Maybe<Array<Maybe<MonthValue>>>
  spreadHigh?: Maybe<Scalars['Float']>
}

export type Mutation = {
  approveSpread?: Maybe<Scalars['Boolean']>
  forceApprove?: Maybe<Scalars['Boolean']>
}

export type PowerRanks = {
  alkuRanking?: Maybe<Ranking>
  tenure?: Maybe<Scalars['Int']>
  tenureRanking?: Maybe<Ranking>
}

export type Query = {
  bullHornId?: Maybe<BullHornId>
  founderRanks: FounderRanks
  foundersDate?: Maybe<Scalars['AWSDateTime']>
  monthlyAverageSpread: MonthlyAverageSpread
  spreadOverTime: SpreadOverTime
  statusSpread?: Maybe<Scalars['String']>
  weeklyNetSpread: WeeklyNetSpread
}

export type QueryFounderRanksArgs = {
  bullHornId?: InputMaybe<Scalars['Int']>
}

export type QueryMonthlyAverageSpreadArgs = {
  bullHornId?: InputMaybe<Scalars['Int']>
}

export type QuerySpreadOverTimeArgs = {
  bullHornId?: InputMaybe<Scalars['Int']>
}

export type QueryWeeklyNetSpreadArgs = {
  bullHornId?: InputMaybe<Scalars['Int']>
}

export type Ranking = {
  rank?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
}

export enum SideOfHouse {
  AcctMgmt = 'ACCT_MGMT',
  Recruiting = 'RECRUITING'
}

export type SpreadOverTime = {
  rows?: Maybe<Array<Maybe<SpreadRow>>>
  total: SpreadTotal
}

export type SpreadRow = {
  endDate?: Maybe<Scalars['AWSDate']>
  fiscalMonth?: Maybe<Scalars['Int']>
  fiscalQuarter?: Maybe<Scalars['Int']>
  fiscalWeek?: Maybe<Scalars['Int']>
  fiscalYear?: Maybe<Scalars['Int']>
  gpm?: Maybe<Scalars['Float']>
  hours?: Maybe<Scalars['Float']>
  isHighSpread?: Maybe<Scalars['Boolean']>
  startDate?: Maybe<Scalars['AWSDate']>
  timeCardsPaid?: Maybe<Scalars['Int']>
  weeklySpread?: Maybe<Scalars['Float']>
}

export type SpreadTotal = {
  gpm?: Maybe<Scalars['Float']>
  hours?: Maybe<Scalars['Float']>
  timeCardsPaid?: Maybe<Scalars['Int']>
  weeklySpread?: Maybe<Scalars['Float']>
}

export enum Status {
  Achieved = 'ACHIEVED',
  OffTarget = 'OFF_TARGET',
  OnTarget = 'ON_TARGET'
}

export type WeeklyNetSpread = {
  isGreaterThanLastWeek?: Maybe<Scalars['Int']>
  isSpreadHigh?: Maybe<Scalars['Boolean']>
  netSpread?: Maybe<Scalars['Float']>
  reachedOn?: Maybe<Scalars['AWSDate']>
  weeklySpreadHigh?: Maybe<Scalars['Float']>
}

export type MonthValue = {
  fiscalMonth?: Maybe<Scalars['Int']>
  fiscalYear?: Maybe<Scalars['Int']>
  spreadValue?: Maybe<Scalars['Float']>
}

export type GetBullhornIdQueryVariables = Exact<{ [key: string]: never }>

export type GetBullhornIdQuery = {
  bullHornId?: { value?: number | null } | null
}

export type ApproveSpreadMutationVariables = Exact<{ [key: string]: never }>

export type ApproveSpreadMutation = { approveSpread?: boolean | null }

export type GetStatusSpreadQueryVariables = Exact<{ [key: string]: never }>

export type GetStatusSpreadQuery = { statusSpread?: string | null }

export type GetFounderStatusQueryVariables = Exact<{
  bullHornId: Scalars['Int']
}>

export type GetFounderStatusQuery = {
  founderRanks: {
    founderStatus?: {
      status?: Status | null
      percentage?: number | null
      average?: number | null
      fiscalYear?: number | null
    } | null
    powerRanks?: {
      tenure?: number | null
      tenureRanking?: { rank?: number | null; total?: number | null } | null
      alkuRanking?: { rank?: number | null; total?: number | null } | null
    } | null
  }
}

export type GetMonthlyAverageSpreadQueryVariables = Exact<{
  bullHornId: Scalars['Int']
}>

export type GetMonthlyAverageSpreadQuery = {
  monthlyAverageSpread: {
    spreadHigh?: number | null
    monthsValue?: Array<{
      fiscalMonth?: number | null
      fiscalYear?: number | null
      spreadValue?: number | null
    } | null> | null
  }
}

export type GetMySpreadOvertimeQueryVariables = Exact<{
  bullHornId: Scalars['Int']
}>

export type GetMySpreadOvertimeQuery = {
  spreadOverTime: {
    rows?: Array<{
      weeklySpread?: number | null
      gpm?: number | null
      hours?: number | null
      timeCardsPaid?: number | null
      isHighSpread?: boolean | null
      fiscalWeek?: number | null
      fiscalMonth?: number | null
      fiscalQuarter?: number | null
      fiscalYear?: number | null
      startDate?: any | null
      endDate?: any | null
    } | null> | null
    total: {
      weeklySpread?: number | null
      gpm?: number | null
      hours?: number | null
      timeCardsPaid?: number | null
    }
  }
}

export type GetWeeklyNetSpreadQueryVariables = Exact<{
  bullHornId?: InputMaybe<Scalars['Int']>
}>

export type GetWeeklyNetSpreadQuery = {
  weeklyNetSpread: {
    netSpread?: number | null
    isGreaterThanLastWeek?: number | null
    weeklySpreadHigh?: number | null
    reachedOn?: any | null
    isSpreadHigh?: boolean | null
  }
}

export const GetBullhornIdDocument = gql`
  query GetBullhornId {
    bullHornId {
      value
    }
  }
`

/**
 * __useGetBullhornIdQuery__
 *
 * To run a query within a React component, call `useGetBullhornIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBullhornIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBullhornIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBullhornIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBullhornIdQuery,
    GetBullhornIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBullhornIdQuery, GetBullhornIdQueryVariables>(
    GetBullhornIdDocument,
    options
  )
}
export function useGetBullhornIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBullhornIdQuery,
    GetBullhornIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBullhornIdQuery, GetBullhornIdQueryVariables>(
    GetBullhornIdDocument,
    options
  )
}
export type GetBullhornIdQueryHookResult = ReturnType<
  typeof useGetBullhornIdQuery
>
export type GetBullhornIdLazyQueryHookResult = ReturnType<
  typeof useGetBullhornIdLazyQuery
>
export type GetBullhornIdQueryResult = Apollo.QueryResult<
  GetBullhornIdQuery,
  GetBullhornIdQueryVariables
>
export const ApproveSpreadDocument = gql`
  mutation approveSpread {
    approveSpread
  }
`
export type ApproveSpreadMutationFn = Apollo.MutationFunction<
  ApproveSpreadMutation,
  ApproveSpreadMutationVariables
>

/**
 * __useApproveSpreadMutation__
 *
 * To run a mutation, you first call `useApproveSpreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSpreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSpreadMutation, { data, loading, error }] = useApproveSpreadMutation({
 *   variables: {
 *   },
 * });
 */
export function useApproveSpreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveSpreadMutation,
    ApproveSpreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ApproveSpreadMutation,
    ApproveSpreadMutationVariables
  >(ApproveSpreadDocument, options)
}
export type ApproveSpreadMutationHookResult = ReturnType<
  typeof useApproveSpreadMutation
>
export type ApproveSpreadMutationResult =
  Apollo.MutationResult<ApproveSpreadMutation>
export type ApproveSpreadMutationOptions = Apollo.BaseMutationOptions<
  ApproveSpreadMutation,
  ApproveSpreadMutationVariables
>
export const GetStatusSpreadDocument = gql`
  query getStatusSpread {
    statusSpread
  }
`

/**
 * __useGetStatusSpreadQuery__
 *
 * To run a query within a React component, call `useGetStatusSpreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusSpreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusSpreadQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusSpreadQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStatusSpreadQuery,
    GetStatusSpreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetStatusSpreadQuery, GetStatusSpreadQueryVariables>(
    GetStatusSpreadDocument,
    options
  )
}
export function useGetStatusSpreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatusSpreadQuery,
    GetStatusSpreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetStatusSpreadQuery,
    GetStatusSpreadQueryVariables
  >(GetStatusSpreadDocument, options)
}
export type GetStatusSpreadQueryHookResult = ReturnType<
  typeof useGetStatusSpreadQuery
>
export type GetStatusSpreadLazyQueryHookResult = ReturnType<
  typeof useGetStatusSpreadLazyQuery
>
export type GetStatusSpreadQueryResult = Apollo.QueryResult<
  GetStatusSpreadQuery,
  GetStatusSpreadQueryVariables
>
export const GetFounderStatusDocument = gql`
  query getFounderStatus($bullHornId: Int!) {
    founderRanks(bullHornId: $bullHornId) {
      founderStatus {
        status
        percentage
        average
        fiscalYear
      }
      powerRanks {
        tenure
        tenureRanking {
          rank
          total
        }
        alkuRanking {
          rank
          total
        }
      }
    }
  }
`

/**
 * __useGetFounderStatusQuery__
 *
 * To run a query within a React component, call `useGetFounderStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFounderStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFounderStatusQuery({
 *   variables: {
 *      bullHornId: // value for 'bullHornId'
 *   },
 * });
 */
export function useGetFounderStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFounderStatusQuery,
    GetFounderStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFounderStatusQuery, GetFounderStatusQueryVariables>(
    GetFounderStatusDocument,
    options
  )
}
export function useGetFounderStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFounderStatusQuery,
    GetFounderStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetFounderStatusQuery,
    GetFounderStatusQueryVariables
  >(GetFounderStatusDocument, options)
}
export type GetFounderStatusQueryHookResult = ReturnType<
  typeof useGetFounderStatusQuery
>
export type GetFounderStatusLazyQueryHookResult = ReturnType<
  typeof useGetFounderStatusLazyQuery
>
export type GetFounderStatusQueryResult = Apollo.QueryResult<
  GetFounderStatusQuery,
  GetFounderStatusQueryVariables
>
export const GetMonthlyAverageSpreadDocument = gql`
  query getMonthlyAverageSpread($bullHornId: Int!) {
    monthlyAverageSpread(bullHornId: $bullHornId) {
      monthsValue {
        fiscalMonth
        fiscalYear
        spreadValue
      }
      spreadHigh
    }
  }
`

/**
 * __useGetMonthlyAverageSpreadQuery__
 *
 * To run a query within a React component, call `useGetMonthlyAverageSpreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyAverageSpreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyAverageSpreadQuery({
 *   variables: {
 *      bullHornId: // value for 'bullHornId'
 *   },
 * });
 */
export function useGetMonthlyAverageSpreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMonthlyAverageSpreadQuery,
    GetMonthlyAverageSpreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMonthlyAverageSpreadQuery,
    GetMonthlyAverageSpreadQueryVariables
  >(GetMonthlyAverageSpreadDocument, options)
}
export function useGetMonthlyAverageSpreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonthlyAverageSpreadQuery,
    GetMonthlyAverageSpreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMonthlyAverageSpreadQuery,
    GetMonthlyAverageSpreadQueryVariables
  >(GetMonthlyAverageSpreadDocument, options)
}
export type GetMonthlyAverageSpreadQueryHookResult = ReturnType<
  typeof useGetMonthlyAverageSpreadQuery
>
export type GetMonthlyAverageSpreadLazyQueryHookResult = ReturnType<
  typeof useGetMonthlyAverageSpreadLazyQuery
>
export type GetMonthlyAverageSpreadQueryResult = Apollo.QueryResult<
  GetMonthlyAverageSpreadQuery,
  GetMonthlyAverageSpreadQueryVariables
>
export const GetMySpreadOvertimeDocument = gql`
  query getMySpreadOvertime($bullHornId: Int!) {
    spreadOverTime(bullHornId: $bullHornId) {
      rows {
        weeklySpread
        gpm
        hours
        timeCardsPaid
        isHighSpread
        fiscalWeek
        fiscalMonth
        fiscalQuarter
        fiscalYear
        startDate
        endDate
      }
      total {
        weeklySpread
        gpm
        hours
        timeCardsPaid
      }
    }
  }
`

/**
 * __useGetMySpreadOvertimeQuery__
 *
 * To run a query within a React component, call `useGetMySpreadOvertimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySpreadOvertimeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySpreadOvertimeQuery({
 *   variables: {
 *      bullHornId: // value for 'bullHornId'
 *   },
 * });
 */
export function useGetMySpreadOvertimeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMySpreadOvertimeQuery,
    GetMySpreadOvertimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetMySpreadOvertimeQuery,
    GetMySpreadOvertimeQueryVariables
  >(GetMySpreadOvertimeDocument, options)
}
export function useGetMySpreadOvertimeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMySpreadOvertimeQuery,
    GetMySpreadOvertimeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetMySpreadOvertimeQuery,
    GetMySpreadOvertimeQueryVariables
  >(GetMySpreadOvertimeDocument, options)
}
export type GetMySpreadOvertimeQueryHookResult = ReturnType<
  typeof useGetMySpreadOvertimeQuery
>
export type GetMySpreadOvertimeLazyQueryHookResult = ReturnType<
  typeof useGetMySpreadOvertimeLazyQuery
>
export type GetMySpreadOvertimeQueryResult = Apollo.QueryResult<
  GetMySpreadOvertimeQuery,
  GetMySpreadOvertimeQueryVariables
>
export const GetWeeklyNetSpreadDocument = gql`
  query getWeeklyNetSpread($bullHornId: Int) {
    weeklyNetSpread(bullHornId: $bullHornId) {
      netSpread
      isGreaterThanLastWeek
      weeklySpreadHigh
      reachedOn
      isSpreadHigh
    }
  }
`

/**
 * __useGetWeeklyNetSpreadQuery__
 *
 * To run a query within a React component, call `useGetWeeklyNetSpreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWeeklyNetSpreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWeeklyNetSpreadQuery({
 *   variables: {
 *      bullHornId: // value for 'bullHornId'
 *   },
 * });
 */
export function useGetWeeklyNetSpreadQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWeeklyNetSpreadQuery,
    GetWeeklyNetSpreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetWeeklyNetSpreadQuery,
    GetWeeklyNetSpreadQueryVariables
  >(GetWeeklyNetSpreadDocument, options)
}
export function useGetWeeklyNetSpreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWeeklyNetSpreadQuery,
    GetWeeklyNetSpreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetWeeklyNetSpreadQuery,
    GetWeeklyNetSpreadQueryVariables
  >(GetWeeklyNetSpreadDocument, options)
}
export type GetWeeklyNetSpreadQueryHookResult = ReturnType<
  typeof useGetWeeklyNetSpreadQuery
>
export type GetWeeklyNetSpreadLazyQueryHookResult = ReturnType<
  typeof useGetWeeklyNetSpreadLazyQuery
>
export type GetWeeklyNetSpreadQueryResult = Apollo.QueryResult<
  GetWeeklyNetSpreadQuery,
  GetWeeklyNetSpreadQueryVariables
>

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {}
}
export default result
