import React from 'react'
import Icon, { IconPositionType } from './Icon'

type GreenCircleCheckProps = {
  position?: IconPositionType;
  onClick?: () => void;
  className?: string;
  size: number;
}

const GreenCircleCheck = ({ position, onClick, className, size } : GreenCircleCheckProps) => (
  <Icon position={position} onClick={onClick} className={className}>
    <svg
      width={`${size ? size : 18}px`}
      height={`${size ? size : 18}px`}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path d='M9 0a9 9 0 00-9 9 9 9 0 009 9 9 9 0 009-9 9 9 0 00-9-9zM7.2 13.873L2.964 9.636l1.272-1.272L7.2 11.327l6.564-6.563 1.272 1.272L7.2 13.873z' id='prefix__a--green-circle-check'/>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b--green-circle-check' fill='#fff'>
          <use xlinkHref='#prefix__a--green-circle-check'/>
        </mask><use fill='#000' fillRule='nonzero' xlinkHref='#prefix__a--green-circle-check'/>
        <path fill='#45B118' mask='url(#prefix__b--green-circle-check)' d='M-1-1h20v20H-1z'/>
      </g>
    </svg>
  </Icon>
)

export default GreenCircleCheck
