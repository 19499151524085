import { IAppColumnConfig } from '@/shared/components/Table'
import { formatNumberToAccountingNumericalFormat } from '@/utils/tools/format'
import { FoundersReferenceWeekRecord } from '../../helpers/formatFoundersData'
import { FoundersRecord } from './query'

export type FoundersStringKeys = Extract<keyof FoundersRecord, string>

const formatReferenceWeek = (record: FoundersReferenceWeekRecord): string => {
  const [dayFrom, dayTo] = record.referenceWeek.replace(' ', '').split('-')
  const [dayFromMonth, dayFromDay] = dayFrom.split('/')
  const [dayToMonth, dayToDay] = dayTo.split('/')
  return `Week ${record.fiscalWeek}: ${dayFromMonth}/${dayFromDay} - ${dayToMonth}/${dayToDay}`
}

const createColumnConfig = (userRecords: FoundersRecord[]): Array<IAppColumnConfig<FoundersReferenceWeekRecord>> => {
  const cols = userRecords.map<IAppColumnConfig<FoundersReferenceWeekRecord>>((r, index) => {
    return {
      title: r.employee,
      dataIndex: r.repId,
      align: 'right',
      key: r.repId,
      width: 14 * (r.employee.length > 5 ? r.employee.length : 6) + (index === userRecords.length - 1 ? 10 : 0),
      renderComponent: (record) => {
        if (typeof record[r.repId] === 'number') {
          return `$${formatNumberToAccountingNumericalFormat(record[r.repId] as number, true)}`
        }
        return record[r.repId] || '--'
      }
    }
  })

  return ([
    {
      title: '',
      dataIndex: 'referenceWeek',
      align: 'center',
      key: 'referenceWeek',
      width: 170,
      renderComponent: (record) => {
        if (record.fiscalQuarter === 99) {
          return record.referenceWeek
        }
        return formatReferenceWeek(record)
      }
    },
    ...cols
  ])
}

export default createColumnConfig
