import React from 'react'

const ConsultantPerformanceSummary = () => {
  return (
    <div className='c-consultant-performance_summary__grid'>
      <div className='card'>
        <h1>Total Paid Hours</h1>
        <h2>735</h2>
      </div>
      <div className='card'>
        <h1>Total Net Spread</h1>
        <h2>$31,335.95</h2>
      </div>
    </div>
  )
}

export default ConsultantPerformanceSummary
