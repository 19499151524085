import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

type SharedPulseLinkProps = {
  to: string;
  /** if true, the link will render as an anchor tag to properly link outside the app */
  external?: boolean;
  children?: React.ReactNode | Array<React.ReactNode>;
  className?: string;
}

interface IExternalPulseLinkProps extends React.ComponentProps<'a'> {
  external: true;
}

interface IInternalPulseLinkProps extends LinkProps {
  external?: false;
}

type PulseLinkProps<T extends IExternalPulseLinkProps | IInternalPulseLinkProps> = SharedPulseLinkProps & (T extends IExternalPulseLinkProps ? IExternalPulseLinkProps : IInternalPulseLinkProps)

const PulseLink = <T extends IExternalPulseLinkProps | IInternalPulseLinkProps>({ className, external = false, ...props } : PulseLinkProps<T>) => {
  const linkClassname = `c-link${className ? ` ${className}` : ''}`

  if (external) {
    return (
      <a href={props.to} target='_blank' rel='noopener noreferrer' className={linkClassname} {...props}>
        {props.children}
      </a>
    )
  }

  return (
    <Link className={linkClassname} {...props}>
      <>
        {props.children}
      </>
    </Link>
  )
}

export default PulseLink
