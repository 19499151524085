/**
 * Formats value to be displayed on table/targets
 * @param {number} value - number to format
 * @return {string|Number} - Formatted number or 0 if no value is present
 */
export const formatNumberDisplay = (value?: number): string => {
  return value?.toLocaleString() || '0'
}

export const formatNumberToFixedDisplay = (value?: number): string => {
  return value ? value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }) : '0.00'
}

export const formatNumberToPercentage = (value?: number): string => {
  if (typeof value === 'number' && value >= 0) {
    if (value >= 0 && value <= 1) {
      return `${formatNumberToFixedDisplay(value * 100)}%`
    }
    return `${formatNumberToFixedDisplay(value)}%`
  }

  return '0.00%'
}

export const formatNumberToAccountingNumericalFormat = (value?: number, isFixed?: boolean): string => {
  if (typeof value === 'number') {
    const formattedNumber = isFixed ? formatNumberToFixedDisplay(Math.abs(value)) : formatNumberDisplay(Math.abs(value))
    if (value < 0) {
      return `(${formattedNumber})`
    }
    return formattedNumber
  }

  return ''
}

const months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
export const getMonthNameFromFiscalMonth = (fiscalMonth?: number): string => {
  const unknownVal = 'Unknown Month'
  if (typeof fiscalMonth === 'number') {
    return (0 < fiscalMonth) && (fiscalMonth <= 12) ? months[fiscalMonth - 1] : unknownVal
  }

  return unknownVal
}