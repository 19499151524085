import { getActionTypes, getMultiActionTypes } from '@/bootstrap/redux/action';

const prefix = 'AUTH';

// CLEAR_TOKEN: AUTH/CLEAR_TOKEN
const unitTypes = getActionTypes(
  [
    'SET_ACTIVE_USER_ACCOUNT'
  ],
  prefix,
);

/**
 * REFRESH_TOKEN: AUTH/REFRESH_TOKEN
 * REFRESH_TOKEN_BEGIN: AUTH/REFRESH_TOKEN_BEGIN
 * REFRESH_TOKEN_SUCCESS: AUTH/REFRESH_TOKEN_SUCCESS
 * REFRESH_TOKEN_FAIL: AUTH/REFRESH_TOKEN_FAIL
 **/
const multiTypes = getMultiActionTypes(
  [],
  prefix,
);

const authTypes = {
  ...unitTypes,
  ...multiTypes,
}

export default authTypes
