const BEGIN = 'BEGIN';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';
const separator = '/';
const suffixes = [BEGIN, SUCCESS, FAIL];

/**
 * Convert an array of strings into an object of action types
 * @param {string[]} actions - Array of strings to be converted
 * @param {string} [pre=''] - String prefixed to each action type value
 * @param {boolean} [isMulti=false] - whether or not to create three stages for each type
 * @return {object} - Object of key/value action type pairs
 */
export const getActionTypes = (actions: Array<string> = [], pre = '', isMulti = false): Record<string, string> => {
  const prefix = pre && `${pre}${separator}`;
  return actions.reduce((actionObj, nextAction) => {
    const actionVal = `${prefix}${nextAction}`;
    const newActions = {[nextAction]: actionVal};
    if (isMulti) {
      suffixes.forEach((suffix) => {
        const ACTION_KEY = `${nextAction}_${suffix}`;
        const ACTION_TYPE = `${actionVal}_${suffix}`;
        newActions[ACTION_KEY] = ACTION_TYPE;
      });
    }
    Object.assign(actionObj, newActions);
    return actionObj;
  }, {});
};

export const getMultiActionTypes = (actions = [], pre = '') => {
  return getActionTypes(actions, pre, true);
};
