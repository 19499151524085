
import { createAction } from '@reduxjs/toolkit'

import manageTargetsTypes from './manageTargetsTypes'
import { IBrandTreeNode, RequestState, TargetUpdateObject } from '@/shared/types'
import { IManageTargetsResponse } from '@/shared/types/swagger'

export const setMtRecruitingTableData = createAction<Array<IBrandTreeNode>>(manageTargetsTypes.SET_RECRUITING_TABLE_DATA)
export const setMtAmTableData = createAction<Array<IBrandTreeNode>>(manageTargetsTypes.SET_AM_TABLE_DATA)
export const discardSavedTargets = createAction(manageTargetsTypes.DISCARD_SAVED_TARGETS)
export const setUserSavedTarget = createAction<TargetUpdateObject>(manageTargetsTypes.SET_USER_SAVED_TARGET)
export const setSaveTargetsState = createAction<RequestState>(manageTargetsTypes.SET_SAVE_TARGETS_STATE)

export const fetchTargetsBegin = createAction(manageTargetsTypes.FETCH_TARGETS_BEGIN)
export const fetchTargetsSuccess = createAction<IManageTargetsResponse>(manageTargetsTypes.FETCH_TARGETS_SUCCESS)
export const fetchTargetsFail = createAction(manageTargetsTypes.FETCH_TARGETS_FAIL)

export const saveTargetsBegin = createAction(manageTargetsTypes.SAVE_TARGETS_BEGIN)
export const saveTargetsSuccess = createAction<IManageTargetsResponse>(manageTargetsTypes.SAVE_TARGETS_SUCCESS)
export const saveTargetsFail = createAction(manageTargetsTypes.SAVE_TARGETS_FAIL)
