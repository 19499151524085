import React from 'react'

import { PulseLink } from '@/shared/components'
import PulseAnimation from '@/shared/components/PulseAnimation'

const SSOServiceDown = () => {
  return (
    <div className='c-screen'>
      <div className='c-screen__error'>
        <h1 className='c-type--title-xxl c-type--bold'>
          Oh no! Microsoft Office appears to be down.
        </h1>
        <h2 className='c-type--title-xl'>
          You should be able to sign in once it’s back online.<br></br>
          In the meantime, you can{' '}
          <PulseLink to='https://admin.microsoft.com/servicestatus' external>
            check on the service status here
          </PulseLink>
          .
        </h2>
        <PulseAnimation />
      </div>
    </div>
  )
}

export default SSOServiceDown
