import { formatNumberDisplay } from '@/utils/tools/format'
import getValue from './getValue'

const sumMetrics = (data, dataIndex) => {
  if (data && dataIndex) {
    const value = data.reduce((total, nextRecord) => {
      const nextValue = getValue(nextRecord, dataIndex)
      return (total + nextValue)
    }, 0)
    return formatNumberDisplay(value)
  }
  return 0
}

export default sumMetrics
