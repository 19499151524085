// @ts-strict
import React from 'react'
import { TotalsRecord } from './query'
import { IAppColumnConfig } from '@/shared/components/Table'
import { formatNumberToPercentage, formatNumberToAccountingNumericalFormat } from '@/utils/tools/format'
import { COLUMN_MOD_KEYS } from '@/shared/constants/local'

export type TotalsStringKeys = Extract<keyof TotalsRecord, string>

const removeYearFromRefencedWeek = (week: string) => {
  const weeks = week.replace(' ','').split('-').map(w => {
    const [month, day,] = w.split('/')
    return `${month}/${day}`
  })

  return weeks.join(' - ')
}

const genColCfg = <T extends TotalsStringKeys>(
  title: string,
  dataIndex: T,
  width?: number,
  renderFn?: (value: TotalsRecord[T]) => string
): IAppColumnConfig<TotalsRecord> => ({
    title,
    dataIndex,
    key: dataIndex,
    width,
    columnMod: [COLUMN_MOD_KEYS.PLAIN],
    align: 'right',
    renderComponent: renderFn ? (record) => {
      if (typeof record[dataIndex] === 'number') {
        return renderFn(record[dataIndex])
      } else {
        return <span>{record[dataIndex]}</span>
      }
    } : undefined
  })

const totalsReportConfig: Array<IAppColumnConfig> = [
  {
    title: 'Time Period',
    dataIndex: 'referencedWeek',
    key: 'referencedWeek',
    width: 170,
    columnMod: [COLUMN_MOD_KEYS.PLAIN],
    renderComponent:(record: TotalsRecord) => {
      // using a bit of a hack here by using 99 in ./createTableConfigs for non standard week records
      if (record.fiscalQuarter !== 99) {
        return `Week ${record.fiscalWeek}: ${removeYearFromRefencedWeek(record.referencedWeek)}`
      }

      return record.referencedWeek
    },
  },
  genColCfg('Total Bill Amount', 'totalBillAmount', 165, v => `$${formatNumberToAccountingNumericalFormat(v, true)}`),
  genColCfg('Total Pay Amount', 'totalPayAmount', 165, v => `$${formatNumberToAccountingNumericalFormat(v, true)}`),
  genColCfg('Spread', 'spread', 148, v => `$${formatNumberToAccountingNumericalFormat(v, true)}`),
  genColCfg('GPM', 'gPM', 76, formatNumberToPercentage),
  genColCfg('Avg Hourly Bill', 'averageHourlyBill', 160, v => `$${formatNumberToAccountingNumericalFormat(v, true)}`),
  genColCfg('Avg Hourly Pay', 'averageHourlyPay', 164, v => `$${formatNumberToAccountingNumericalFormat(v, true)}`),
  genColCfg('Avg Hourly Spread', 'averageHourlySpread', 199, v => `$${formatNumberToAccountingNumericalFormat(v, true)}`),
  genColCfg('Time Cards Paid, Net', 'timeCardsPaidNet', 182, formatNumberToAccountingNumericalFormat),
  genColCfg('Total Hours Billed', 'totalHoursBilled', 164, v => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg('Avg Hrs/Candidate', 'averageHoursPerCandidate', 171, v => formatNumberToAccountingNumericalFormat(v, true)),
  genColCfg('Total Paid Hours', 'totalHoursPaid', 160, v => formatNumberToAccountingNumericalFormat(v, true))
]

export default totalsReportConfig
