import React from 'react'
import Icon, { IconPositionType } from './Icon'

type RefreshProps = {
  position?: IconPositionType
}

const Refresh = ({ position }: RefreshProps) => (
  <Icon position={position}>
    <svg width='11' height='13' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.903 7.444a4.438 4.438 0 01-1.29 3.146 4.347 4.347 0 01-3.114 1.303 4.347 4.347 0 01-3.113-1.303 4.443 4.443 0 01-1.29-3.146 4.44 4.44 0 011.29-3.146 4.352 4.352 0 013.113-1.304c.005 0-.948 1.267-.948 1.267a.556.556 0 00.434.891.546.546 0 00.436-.217l1.724-2.274a.556.556 0 00-.101-.776L4.758.115a.544.544 0 00-.768.102.556.556 0 00.101.777l1.158.896a5.379 5.379 0 00-1.89.432A5.533 5.533 0 00.433 5.278 5.562 5.562 0 000 7.443a5.562 5.562 0 001.611 3.93A5.47 5.47 0 005.501 13c.742 0 1.463-.147 2.141-.437a5.533 5.533 0 002.925-2.954A5.562 5.562 0 0011 7.445a.552.552 0 00-.548-.556.552.552 0 00-.549.555z'
        fill='#888'
        fillRule='nonzero'
      />
    </svg>
  </Icon>
)

export default Refresh
