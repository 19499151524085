import { put } from '@/bootstrap/axios'
import { ICurrentUserContext, ISaveMyDivisionsRequest, ISaveMyPeopleRequest } from '@/shared/types/swagger'
import { AxiosResponse } from 'axios'

export const updateMyPeopleShortcut = (ids: Array<string>) => {
  const url = '/api/my/people'
  const data = {
    'my-people': ids
  }
  return put<ISaveMyPeopleRequest, AxiosResponse<ICurrentUserContext>>(url, data)
}

export const updateMyDivisionsShortcut = (ids: Array<string>) => {
  const url = '/api/my/divisions'
  const data = {
    'my-divisions': ids
  }
  return put<ISaveMyDivisionsRequest, AxiosResponse<ICurrentUserContext>>(url, data)
}
