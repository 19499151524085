import React, { useCallback } from 'react'
import { DatePicker as AntDatePicker } from 'antd'
import en_GB from 'antd/es/date-picker/locale/en_GB'
import 'dayjs/locale/en-gb'

import COLOR_KEYS from '@/shared/constants/colors'
import { getDateKey } from '@/utils/tools/dateTime'
import renderCalendarMarkers from './helpers/renderCalendarMarkers'

const RangePicker = ({ calendarMarkers, wrapperClassName = '', ...props }) => {
  const dateRender = useCallback((current, today) => {
    const style = {};
    let markers
    if (calendarMarkers) {
      markers = calendarMarkers[getDateKey(current)]
      if (markers) {
        const validMarker = markers[0] || markers
        style.borderBottom = `solid ${COLOR_KEYS[validMarker.preset === 'quarter' ? 'red' : 'golden-dream']} 0.2rem`
      }
    }
    return (
      <div
        className='ant-picker-cell-inner c-range-picker__cell'
        style={style}
      >
        {current.date()}
        {markers && (
          renderCalendarMarkers(markers)
        )}
      </div>
    )
  }, [calendarMarkers])
  return (
    <div className={`c-range-picker ${wrapperClassName}`}>
      <AntDatePicker.RangePicker
        locale={en_GB}
        dateRender={dateRender}
        {...props}
      />
    </div>
  )
}

export default RangePicker
