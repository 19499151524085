import React from 'react'
import Icon from './Icon'

const Upload2 = ({ position }) => (
  <Icon position={position}>
    <svg
      width='14'
      height='14'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <defs>
        <path
          d='M6.095.43a.473.473 0 01.67 0l2.13 2.131a.473.473 0 01-.667.669L6.903 1.905v7.9a.473.473 0 01-.946 0v-7.9L4.632 3.23a.471.471 0 01-.667 0 .474.474 0 01-.001-.669L6.095.43zm5.974 7.742a.473.473 0 00-.473.473v2.672a.282.282 0 01-.282.281H1.518a.282.282 0 01-.282-.281V8.644a.473.473 0 00-.944 0v3.047a.85.85 0 00.849.85h10.552a.85.85 0 00.849-.85V8.644a.473.473 0 00-.473-.472z'
          id='prefix__a--upload2'
        />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M6.679 1.013a.473.473 0 01.67 0l2.13 2.132a.473.473 0 01-.667.668L7.487 2.488v7.9a.473.473 0 01-.946 0v-7.9L5.216 3.813a.471.471 0 01-.667 0 .474.474 0 01-.002-.668L6.68 1.013zm5.973 7.742a.473.473 0 00-.473.474V11.9a.282.282 0 01-.282.282H2.101a.282.282 0 01-.281-.282V9.227a.473.473 0 00-.945 0v3.048a.85.85 0 00.849.849h10.552a.85.85 0 00.849-.85V9.228a.473.473 0 00-.473-.472z'
          fill='#000'
          fillRule='nonzero'
        />
        <g transform='translate(.583 .583)'>
          <mask id='prefix__b--upload2' fill='#fff'>
            <use xlinkHref='#prefix__a--upload2' />
          </mask>
          <use fill='#4B4B4B' xlinkHref='#prefix__a--upload2' />
          <g mask='url(#prefix__b--upload2)'>
            <circle
              fill='#888'
              fillRule='nonzero'
              cx='35'
              cy='35'
              r='35'
              transform='translate(-29.167 -28.583)'
            />
          </g>
        </g>
      </g>
    </svg>
  </Icon>
)

export default Upload2
