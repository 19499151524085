import { gql } from '@apollo/client'

export type FoundersRecord = {
  brand: string
  department: string
  referenceWeek: string
  spread: number
  employee: string
  fiscalMonth: number
  fiscalQuarter: number
  fiscalWeek: string
  fiscalYear: string
  yearWeek: string
  startDate: string
  endDate: string
  repId: string
  bdmId: string
  sideOfHouse?: 'Recruiting' | 'Sales'
}

export type FoundersTableData = {
  allFounders: FoundersRecord[]
}

export const GET_ALL_FOUNDERS_QUERY = `
  allFounders {
    bdmId
    brand
    employee
    endDate
    fiscalMonth
    fiscalQuarter
    fiscalWeek
    fiscalYear
    department
    rdmId
    referenceWeek
    repId
    showOnFounders
    sideOfHouse
    spread
    startDate
    yearWeek
  }
`

export const GET_FOUNDERS_TABLE_NAMED_QUERY = gql`
  query FoundersTable {
    ${GET_ALL_FOUNDERS_QUERY}
  }
`
