import React from 'react'
import { ArrowSolidLeft } from '@/shared/components/Icons'

const renderNoneSelectedMessage = (checkedKeys, data, reportResponseLoading) => {
  if (!checkedKeys.length) {
    return (
      <>
        <ArrowSolidLeft position='left' /> Select an item from the left to check
        its Pulse
      </>
    )
  }
  if (!data) {
    if (reportResponseLoading) {
      return '...'
    }
    return 'Data Not Found'
  }

  return null
}

export default renderNoneSelectedMessage
