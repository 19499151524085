import React from 'react'
import { IEmployeeDictionaryEmployee } from '../../../../../shared/types';

import AudienceTreeIcon from './AudienceTreeIcon'

type AudienceTreeNodeProps = {
  title?: string | number | React.ReactNode;
  isInactive?: boolean;
  isOtherContributor?: boolean;
  nodeInfo?: IEmployeeDictionaryEmployee;
  onShortcutClick?: () => void
  id?: string;
}

const AudienceTreeNode = ({ title, isInactive, nodeInfo, isOtherContributor, onShortcutClick, id } : AudienceTreeNodeProps) => {
  return (
    <span className='c-audience-tree__node c-audience-tree__node--icon'>
      <span className={`c-audience-tree__node-text${isInactive ? ' u-color-oslo-gray' : ''}`}>{title}</span>
      <AudienceTreeIcon
        isInactive={isInactive}
        showOtherContributorShortcut={!isOtherContributor && !!nodeInfo?.otherContributorKeys?.length}
        onShortcutClick={onShortcutClick}
        id={id}
      />
    </span>
  )
}

export default AudienceTreeNode
