import React, { useCallback, useState, useRef, useEffect } from 'react'
import { Input } from 'antd'
import { debounce } from 'lodash'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { selectAudienceTreeSearch, selectAudienceTreeSearchValue } from '@/features/ControlPanel/redux/controlPanelSelectors'
import { clearAtSearch, onAtSearch, onAudienceSearchEnter, setAudienceTreeSearchValue } from '@/features/ControlPanel/redux/controlPanelActions'
import { updateQueryParamsByState } from '@/features/shared/redux/sharedActions'

const { Search } = Input

const AudienceSearch = () => {
  const dispatch = useAppDispatch()
  const [active, setActive] = useState(false)
  const search = useAppSelector(selectAudienceTreeSearch)
  const searchValue = useAppSelector(selectAudienceTreeSearchValue)
  const denbouncedSearch = useRef(debounce((val) => {
    dispatch(onAtSearch(val))
  }, 500)).current
  const debouncedUrlUpdate = useRef(debounce(() => {
    dispatch(updateQueryParamsByState())
  }, 1500)).current
  useEffect(() => {
    return () => {
      denbouncedSearch.cancel()
      debouncedUrlUpdate.cancel()
    }
  }, [denbouncedSearch, debouncedUrlUpdate])
  const onChange = useCallback(event => {
    const val = event?.currentTarget?.value || ''
    if (!val || val === '') {
      dispatch(clearAtSearch())
    } else {
      dispatch(setAudienceTreeSearchValue(val))
      denbouncedSearch(val)
    }
    debouncedUrlUpdate()
  }, [denbouncedSearch, debouncedUrlUpdate,dispatch])
  const onSearch = useCallback((value) => {
    if (value) {
      dispatch(onAudienceSearchEnter())
    }
  }, [dispatch])

  return (
    <div className={`c-audience-tree__search${active || search.length ? ' c-audience-tree__search--active' : ''}`}>
      <Search
        placeholder='Search by name...'
        onChange={onChange}
        onSearch={onSearch}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        value={searchValue}
        style={{ width: 244 }}
        allowClear
      />
    </div>
  )
}

export default AudienceSearch
