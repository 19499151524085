import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useAppDispatch } from '@/shared/hooks'

import Layout from '@/features/navigation/components/Layout'
import { manageGrafanaQueue } from './features/shared/redux/sharedActions'

const GRAFANA_UPDATE_INTERVAL_S = 60

const App = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    console.log(
      `
        actualDuration, // time spent rendering the committed update
        baseDuration, // estimated time to render the entire subtree without memoization
        startTime, // when React began rendering this update
        commitTime, // when React committed this update
      `
    )
    const grafanaHandler = setInterval(() => {
      dispatch(manageGrafanaQueue())
    }, 1000 * GRAFANA_UPDATE_INTERVAL_S)
    return () => clearInterval(grafanaHandler)
  }, [dispatch])

  return (
    <Switch>
      <Route path={'/'} component={Layout} />
    </Switch>
  )
}

export default App
