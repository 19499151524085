import React from 'react'

import * as sharedActions from './sharedActionsPlain'
import queryString from 'query-string'
import { push } from 'connected-react-router'

import { buildAudienceTreeData,
  generateTimeSliderMarks,
  setAudienceTreeSearch,
  setAudienceTreeSearchValue,
  setCustomDateRange,
  setCustomDateRangeEnabled,
  setTimePeriod } from '@/features/ControlPanel/redux/controlPanelActions'
import { getReport, getOneTimeResponse, getItemizedReport, /* sendGrafanaMetrics */ } from '@/utils/api/shared'
import { buildFiscalCalendarMarkers, buildReportRequestObj, formatTimeSliderData, handleDashboardQueryParamUpdate } from './sharedOperations'
import { ROUTES, DASHBOARD_TAB_KEYS, AT_SHORTCUT_KEYS } from '@/shared/constants/local'
import { INoticeOptions } from '@/shared/types'
import { allTargetGroupOptions } from '@/features/dashboard/components/Funnel/helpers/groupConfigs'
import { setActiveDashboardTabKey, setActiveTarget } from '@/features/dashboard/redux/dashboardActions'
import { METRIC_OPTIONS, METRIC_TO_TARGET, REPORT_OPTIONS, TF_OPTIONS, TF_TO_MARK_VALUE } from '@/shared/constants/queryString'
import { parseQueryStringDate, snapDatesToEndOfWeek } from '@/utils/tools/dateTime'
import { RootState } from '../../../bootstrap/redux'
import { addOcsToCheckedKeys } from '../../ControlPanel/redux/controlPanelOperations'
interface IFetchReportOptions {
  skipBuild?: boolean
  rebuildOnFail?: boolean
}
/**
 * creates a report fetch request based on the audience tree and dashboard state 
 * @param {Boolean} options.skipBuild optionally skip rebuild of audience tree
 * @param {Booelean} options.rebuildOnFail optionally rebuild tree even on fail, useful when switching between report views
 * @returns 
 */
export const fetchReport = (options: IFetchReportOptions = {
  skipBuild: false,
  rebuildOnFail: false
}) => async (dispatch, getState) => {
  const { skipBuild, rebuildOnFail } = options
  dispatch(sharedActions.fetchReportBegin())
  try {
    const {
      controlPanel: {
        audienceTree: { checkedKeys, dataList, activeShortcutKey, employeeDictionary },
        timeSlider: {
          timePeriod,
          marks,
          customDateRangeEnabled,
          customDateRange
        }
      },
      dashboard: { activeDashboardTabKey }
    }: RootState = getState()
    let requestObj
    if (activeShortcutKey === AT_SHORTCUT_KEYS.PEOPLE) {
      const allKeys = addOcsToCheckedKeys(checkedKeys, dataList, employeeDictionary)
      requestObj = buildReportRequestObj(allKeys, dataList, Number(activeDashboardTabKey))
    } else {
      requestObj = buildReportRequestObj(checkedKeys, dataList, Number(activeDashboardTabKey))
    }
    const [timeFrame, formattedDateRange] = formatTimeSliderData(
      customDateRange,
      customDateRangeEnabled,
      marks,
      timePeriod
    )
    const response = await getReport(timeFrame, formattedDateRange, requestObj)
    dispatch(sharedActions.fetchReportSuccess(response))
    dispatch(sharedActions.dismissNoticeByDuplicateId('Error fetching report'))
    if (!skipBuild) {
      dispatch(buildAudienceTreeData())
    }
  } catch (error) {
    dispatch(notifyError('Error fetching report'))
    console.error(error)
    dispatch(sharedActions.fetchReportFail())
    if (rebuildOnFail) {
      dispatch(buildAudienceTreeData())
    }
  }
}

export const fetchEntityContext = () => async (dispatch, getState) => {
  dispatch(sharedActions.fetchEntityContextBegin())
  try {
    const response = await getOneTimeResponse()
    const fiscalCalendarMarkers = buildFiscalCalendarMarkers(response)
    dispatch(sharedActions.fetchEntityContextSuccess(response))
    dispatch(generateTimeSliderMarks())
    dispatch(sharedActions.setFiscalCalendarMarkers(fiscalCalendarMarkers))
  } catch (error) {
    const { shared: { initialLoad } } = getState()
    if (initialLoad) {
      dispatch(notifyError('Error fetching one time response'))
    }
    console.error(error)
    const status = error?.response?.status
    const errorMessage = status && (status < 500 && status >= 400) ? error?.response?.data?.error : ''
    dispatch(sharedActions.fetchEntityContextFail(errorMessage))
  }
}

/**
 * dispatch action which requests the one time response if it does not already exist
 * @param {Function} parallelRequestsFn optional async function to execute in parallel of the OTR request
 * @param {Function} onSuccesFn opional function to run on success of OTR request and parrallelRequestsFn
 * @returns 
 */
export const initialLoad = (parallelRequestsFn, onSuccesFn) => async (dispatch, getState) => {
  const { shared: { oneTimeResponse } } = getState()
  await Promise.all([
    !oneTimeResponse && dispatch(fetchEntityContext()),
    parallelRequestsFn && parallelRequestsFn()
  ])
    .then(() => {
      const { shared: { meta: { oneTimeResponseError } } } = getState()
      if (!oneTimeResponseError) {
        if (typeof onSuccesFn === 'function') onSuccesFn()
        dispatch(sharedActions.setInitialLoad(true))
      } else {
        dispatch(sharedActions.setShowServiceErrorScreen(true))
      }
    })
}

export const manageGrafanaQueue = () => async (dispatch, getState) => {
  try {
    const {
      shared: { grafanaQueue }
    } = getState()
    if (grafanaQueue && grafanaQueue.length) {
      // disabled until proxy is set up
      // await sendGrafanaMetrics(grafanaQueue)
      dispatch(sharedActions.clearGrafanaQueue())
    }
  } catch (e) {
    //
  }
}

export const notify = (msg?: string, options: Partial<INoticeOptions> = {}) => dispatch => {
  const now = new Date()
  const id = now.getTime() + Math.random().toString().slice(0, 5)
  const closeFn = () => dispatch(sharedActions.dismissNotice(id))
  const { clearOtherNotices, clearDuplicates, duplicateId, timeout } = options
  const notice = {
    id,
    duplicateId,
    closeFn,
    children: <span className='c-type--tree-text c-type--bold'>{msg}</span>,
    ...options
  }
  dispatch(sharedActions.pushNotice({ notice, clearDuplicates, clearOtherNotices }))
  if (typeof timeout === 'number') {
    setTimeout(() => {
      dispatch(sharedActions.dismissNotice(id))
    }, timeout)
  }
  return id
}

export const notifyError = (msg, options = {}) => dispatch => {
  dispatch(notify(msg, {
    error: true,
    style: { width: '640px' },
    clearOtherNotices: true,
    clearDuplicates: true,
    duplicateId: msg,
    ...options
  }))
}

export const parseQueryString = () => (dispatch, getState) => {
  try {
    const {
      router: {
        location: { pathname, search }
      },
      shared: { initialLoad }
    } = getState()
    dispatch(sharedActions.setQsParsing(true))
    const parsed = queryString.parse(search)
    switch (pathname) {
    case ROUTES.DASHBOARD:
      dispatch(handleDashboardQs(parsed))
      if (initialLoad) {
        dispatch(buildAudienceTreeData(true, true))
      }
      break;
    default:
      break;
    }
    dispatch(sharedActions.setQsParsing(false))
  } catch (e) {
    console.error(e)
    dispatch(sharedActions.setQsParsing(false))
  }
}

export const updateQueryParamsByState = () => (dispatch, getState) => {
  try {
    const {
      router: {
        location: { pathname }
      },
      dashboard,
      controlPanel
    }: RootState = getState()
    // params object is mutated by the following function(s)
    const params = {}
    switch (pathname) {
    case ROUTES.DASHBOARD:
      handleDashboardQueryParamUpdate(params, dashboard, controlPanel)
      break
    default:
      break
    }
    dispatch(push({
      search: queryString.stringify(params, { sort: false })
    }))
  } catch (e) {
    console.error(e)
  }
}

export const handleDashboardQs = (parsed) => dispatch => {
  const { r, tf, sd, ed, m, search } = parsed
  let dashboardTabKey = DASHBOARD_TAB_KEYS.AM
  if (r && REPORT_OPTIONS.includes(r.toLowerCase())) {
    dashboardTabKey = DASHBOARD_TAB_KEYS[r.toUpperCase()]
    dispatch(setActiveDashboardTabKey(dashboardTabKey))
  }
  if (tf && TF_OPTIONS.includes(tf.toLowerCase())) {
    if (tf.toLowerCase() === 'custom') {
      const startDate = parseQueryStringDate(sd)
      const endDate = parseQueryStringDate(ed)
      if (startDate && endDate &&
        (startDate.isSame(endDate) || startDate.isBefore(endDate))
      ) {
        const snappedDates = snapDatesToEndOfWeek([startDate, endDate])
        dispatch(setCustomDateRangeEnabled(true))
        dispatch(setCustomDateRange(snappedDates))
      }
    } else {
      const timePeriod = TF_TO_MARK_VALUE[tf.toLowerCase()]
      dispatch(setTimePeriod(timePeriod))
    }
  }
  if (m && METRIC_OPTIONS.includes(m)) {
    const dataIndex = METRIC_TO_TARGET[dashboardTabKey][m]
    const target = allTargetGroupOptions.find(v => v.dataIndex === dataIndex)
    dispatch(
      setActiveTarget(target)
    )
  }
  if (search) {
    const value = search.replace('+', ' ')
    dispatch(setAudienceTreeSearch(value))
    dispatch(setAudienceTreeSearchValue(value))
  }
}

export const fetchItemizationResponse = () => async (dispatch, getState) => {
  dispatch(sharedActions.fetchItemizationReportBegin())
  try {
    const {
      controlPanel: {
        audienceTree: { checkedKeys, dataList },
        timeSlider: {
          timePeriod,
          marks,
          customDateRangeEnabled,
          customDateRange
        }
      },
      dashboard: {
        activeDashboardTabKey,
        funnel: { activeTarget }
      }
    } = getState()
    const requestObj = buildReportRequestObj(checkedKeys, dataList, Number(activeDashboardTabKey))
    const [timeFrame, formattedDateRange] = formatTimeSliderData(
      customDateRange,
      customDateRangeEnabled,
      marks,
      timePeriod
    )
    const response = await getItemizedReport(timeFrame, formattedDateRange, requestObj, activeTarget.apiName)
    dispatch(sharedActions.fetchItemizationReportSuccess(response))
  } catch (error) {
    console.error(error)
    dispatch(sharedActions.fetchItemizationReportFail())
  }
}

export * from './sharedActionsPlain'
