// @ts-strict
import { IAppColumnConfig } from '@/shared/components/Table'
import { useCallback } from 'react'
import { MetricsConfig, generateConfig } from '../helpers/columnConfigs'
import createPowerRankingConfig, {
  TableConfig
} from '../helpers/createTableConfig'

export default function usePowerRankingData(data) {
  const powerRankingLoading = false
  const powerRankingError = false

  const createTableConfig = useCallback(() => {
    return createPowerRankingConfig(data)
  }, [data])

  const tableConfig: TableConfig[] = createTableConfig()
  const columnConfig: IAppColumnConfig<MetricsConfig>[] = generateConfig()

  return {
    tableConfig,
    columnConfig,
    powerRankingLoading,
    powerRankingError
  }
}
