import React, { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { Button } from '@/shared/components'
import { selectActiveShortcutKey, selectMyDivisionsSaving, selectMyPeopleSaving, selectShortcutChangesPending } from '@/features/ControlPanel/redux/controlPanelSelectors'
import { onSaveMyDivisions, onSaveMyPeople } from '@/features/ControlPanel/redux/controlPanelActions'
import { AT_SHORTCUT_KEYS } from '@/shared/constants/local'

const AudienceSave = () => {
  const dispatch = useAppDispatch()
  const activeShortcutKey = useAppSelector(selectActiveShortcutKey)
  const changePending = useAppSelector(selectShortcutChangesPending)
  const myDivisionsSaving = useAppSelector(selectMyDivisionsSaving)
  const myPeopleSaving = useAppSelector(selectMyPeopleSaving)
  const onClick = useCallback(() => {
    if (activeShortcutKey === AT_SHORTCUT_KEYS.DIVISIONS) {
      dispatch(onSaveMyDivisions())
    }
    if (activeShortcutKey === AT_SHORTCUT_KEYS.PEOPLE) {
      dispatch(onSaveMyPeople())
    }
  }, [dispatch, activeShortcutKey])

  const btnName = activeShortcutKey === AT_SHORTCUT_KEYS.DIVISIONS ? 'My Divisions' : activeShortcutKey === AT_SHORTCUT_KEYS.PEOPLE ? 'My People' : ''
  const loading = activeShortcutKey === AT_SHORTCUT_KEYS.DIVISIONS ? myDivisionsSaving : myPeopleSaving
  const disabled = activeShortcutKey === AT_SHORTCUT_KEYS.ALL || !changePending || loading

  return (
    <Button
      className={`c-audience-tree__save${activeShortcutKey === AT_SHORTCUT_KEYS.ALL ? ' c-audience-tree__save--all-alku' : ''}`}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
    >
      Set as {btnName}
    </Button>
  )
}

export default AudienceSave
