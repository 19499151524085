import { SPREAD_STATUS_TYPE } from '@/shared/constants/dataKeys'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react'

interface SpreadState {
  reportHeader?: string | React.ReactNode
  etlIsProcessing: boolean
  refetchReports: boolean
  bullhornId?: number | null
  spreadStatus?: SpreadStatusType
  starredUsers?: number[]
}

export type SpreadStatusType = {
  class: string
  status: SPREAD_STATUS_TYPE
}

const getSpreadBtnClass = (status) => {
  return 'c-spread-status--' + status?.toLowerCase().replace(' ', '-')
}

const initialState = {
  reportHeader: null,
  etlIsProcessing: true,
  bullhornId: '',
  spreadStatus: { class: '', status: '' },
  starredUsers: [],
} as unknown as SpreadState

const spreadSlice = createSlice({
  name: 'spread',
  initialState,
  reducers: {
    setReportHeader(state, action: PayloadAction<string | React.ReactNode>) {
      state.reportHeader = action.payload
    },
    setEtlIsProcessing(state, action: PayloadAction<boolean>) {
      state.etlIsProcessing = action.payload
      state.refetchReports = true
    },
    setRefetchReports(state, action: PayloadAction<boolean>) {
      state.refetchReports = action.payload
    },
    setBullhornId: (state, action: PayloadAction<number | null>) => {
      state.bullhornId = action.payload
    },
    setSpreadStatus: (state, action: PayloadAction<string>) => {
      state.spreadStatus = {
        class: getSpreadBtnClass(action.payload),
        status: action.payload
      }
    },
    addStarred: (state, action: PayloadAction<number>) => {
      state.starredUsers = [action.payload]
    },
    removeStarred: (state, action: PayloadAction<number>) => {
      state.starredUsers.splice(state.starredUsers.indexOf(action.payload), 1)
    }
  }
})

export const {
  setReportHeader,
  setEtlIsProcessing,
  setRefetchReports,
  setBullhornId,
  setSpreadStatus,
  addStarred,
  removeStarred
} = spreadSlice.actions

export default spreadSlice.reducer
