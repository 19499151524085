import React from 'react'
import Icon, { IconPositionType } from './Icon'

type WaitingProps = {
  position?: IconPositionType;
  onClick?: () => void;
  className?: string;
}

const Waiting = ({ position, onClick, className }: WaitingProps) => (
  <Icon position={position} onClick={onClick} className={className}>
    <svg width='18' height='18' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9h-1.8c0 3.97-3.23 7.2-7.2 7.2S1.8 12.97 1.8 9 5.03 1.8 9 1.8a7.175 7.175 0 015.51 2.59L12.6 6.3H18V.9l-2.224 2.224C14.126 1.22 11.71 0 9 0zm-.9 3.6v5.027l-2.436 2.437 1.272 1.272L9.9 9.373V3.6H8.1z'
        fill='#404555'
        fillRule='nonzero'
      />
    </svg>
  </Icon>
)

export default Waiting
