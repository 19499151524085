import React from 'react'
import { Avatar as AntAvatar, Image } from 'antd'
import { AvatarPlaceholder } from '../Icons'
import classNames from 'classnames'

interface IDefaultAvatarProps {
  src: string;
  size?: number;
  alt?: string;
}

const DefaultAvatar = ({ src, size, alt = '' }: IDefaultAvatarProps) => {
  return (
    <Image
      alt={alt}
      src={src}
      fallback=''
      preview={false}
      height={size}
      placeholder={<AvatarPlaceholder size={size} />}
    />
  )
}

type SharedAvatarProps = {
  size?: number;
  border?: string;
  style?: React.CSSProperties;
}
type IAvatarPropsWithChildren = {
  children: React.ReactNode;
  src?: never
}
type IAvatarPropsWithSRC = {
  src: string;
  children?: never
}

type AvatarProps = | IAvatarPropsWithChildren | IAvatarPropsWithSRC

const Avatar = (props: SharedAvatarProps & AvatarProps) => {
  const { src, size, children, border, style = {}, ...rest } = props
  const className = classNames('c-avatar', { 'c-avatar--border': border })
  const compiledStyle = {
    ...style
  }

  return (
    <div className={className} style={compiledStyle}>
      <AntAvatar
        shape='circle'
        size={size}
        src={!children && <DefaultAvatar src={src} size={size} />}
        style={border ? { color: border } : {}}
        {...rest}
      >
        {children}
      </AntAvatar>
    </div>
  )
}

export default Avatar
