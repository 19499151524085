import { IReportsRequest } from '../types/swagger'
import { SIDE_OF_HOUSE_ENUM } from './dataKeys'

export type AudienceTreeShortcut = '1' | '2' | '3'

export const AT_SHORTCUT_KEYS: Record<string, AudienceTreeShortcut> = {
  ALL: '1',
  DIVISIONS: '2',
  PEOPLE: '3'
}

export const SPREAD_PR_TAB_KEYS: Record<string, string> = {
  COMPANY: 'Company',
  STARRED: 'Starred'
}

export const SPREAD_PR_VIEW_KEYS: Record<string, string> = {
  CURRENT_WEEK_VIEW: 'Current Week',
  ANUAL_VIEW: 'Annual'
}

export type AT_SHORTCUT_KEYS_TYPE = keyof typeof AT_SHORTCUT_KEYS

export const DASHBOARD_TAB_KEYS: Record<string, string> = {
  AM: `${SIDE_OF_HOUSE_ENUM.AM}`,
  RECRUITING: `${SIDE_OF_HOUSE_ENUM.RECRUITING}`
}

export type DASHBOARD_TAB_KEYS_TYPE = keyof typeof DASHBOARD_TAB_KEYS

const ENV: string = process.env.REACT_APP_ENVIRONMENT
export const USE_TEST_CONFIG: boolean = !['uat', 'prod'].includes(ENV) && !!window.Cypress

export const ROW_MOD_KEYS: Record<string, string> = {
  QUALITY: 'row-quality',
  AVATAR: 'row-avatar',
  EXPANDABLE: 'row-expandable',
  EXPANDABLE_LEFT: 'row-expandable-left',
  DATA_DISPLAY: 'row-data-display',
  EXPANDABLE_CARAT: 'row-expandable-carat',
  EXPANDABLE_FIX_RIGHT: 'row-expandable-fix-right',
  HIGH_SPREAD: 'row-high-spread',
}

export const COLUMN_MOD_KEYS: Record<string, string> = {
  METRIC: 'column-metric',
  TITLE: 'column-title',
  TARGET_INPUT: 'column-target-input',
  LINK: 'column-link',
  DATE: 'column-date',
  PLAIN: 'column-plain',
  PARTY_ICON: 'column-party-icon',
  CURRENCY: 'column-currency',
  PERCENT: 'column-percent',
  MONTH: 'column-month',
  STARRED: 'column-starred',
  REACHED_AT: 'column-reached-at'
}

export type COLUMN_MOD_KEYS_TYPE = keyof typeof COLUMN_MOD_KEYS

export const BDE_WIDTH = '25%'
export const CP_WIDTH = '12%'
export const ER_WIDTH = '25%'

export const ALL_API = 'all'
export const DEFAULT_REPORT_OBJ: IReportsRequest = {
  'audience-spec': {
    divisions: [
      {
        id: ALL_API,
        people: [
          ALL_API
        ]
      }
    ]
  }
}

export const ROUTES: Record<string, string> = {
  DASHBOARD: '/',
  MANAGE_TARGETS: 'manage-targets'
}

export type ROUTES_TYPE = keyof typeof ROUTES

export const REACT_APP_ENVIRONMENT: Record<string, string> = {
  DEV: 'dev',
  UAT: 'uat',
  PROD: 'prod'
}

export type REACT_APP_ENVIRONMENT_TYPE = keyof typeof REACT_APP_ENVIRONMENT
