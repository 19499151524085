import React, { useEffect } from 'react'

import { NavLink, Route, Switch } from 'react-router-dom'

import {
  PublicRoutes,
  SpreadHomeRoute
} from '@/features/navigation/components/Routes/routes'
import { useGetBullhornIdLazyQuery } from '@/__generated__/gql-types'
import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { setBullhornId } from '@/features/Spread/redux/spreadSlice'
import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'
import { spreadClient } from '@/bootstrap/apollo'

const CURRENT_ENV = process.env.REACT_APP_ENVIRONMENT
const TEST_BULLHORH_ID = Number(process.env.TEST_BULLHORN_ID) || 5749199
const MySpreadScreen = () => {
  const subRoutes = PublicRoutes.filter((x) => x.path === SpreadHomeRoute)[0]
    .childs

  const userBullHornId = useAppSelector(
    (state) => state.spread.bullhornId
  )
  const [getBullhornId, { data, loading }] = useGetBullhornIdLazyQuery({ client: spreadClient })

  const dispatch = useAppDispatch()
  useEffect(() => {
    /* Get bullhorn id from backend */
    if (!userBullHornId) {
      /* If current env is prod, get bullhorn id from backend */
      if (
        [
          REACT_APP_ENVIRONMENT.PROD,
          REACT_APP_ENVIRONMENT.UAT,
          //REACT_APP_ENVIRONMENT.DEV
        ].includes(CURRENT_ENV)
      ) {
        getBullhornId()
        dispatch(setBullhornId(data?.bullHornId?.value))
      } else {
        //In case of test env, use test bullhorn id
        console.log('USING TEST BULLHORN ID:' + TEST_BULLHORH_ID)
        dispatch(setBullhornId(TEST_BULLHORH_ID))
      }
    }
  }, [data, dispatch, getBullhornId, userBullHornId])
  return (
    <div className='c-screen'>
      {loading && <div className='c-screen__loading-blocker' />}
      <div className='c-sidebar'>
        <div className='c-sidebar__navigation'>
          {subRoutes.map((route, index) => (
            <NavLink
              to={route.path}
              key={index}
              exact={route.path === SpreadHomeRoute}
              activeClassName='activeLink'
            >
              {route.icon && <route.icon />}
              {route.label}
            </NavLink>
          ))}
        </div>
      </div>
      <Switch>
        {subRoutes.map((route, index) => (
          <Route
            exact
            key={index}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </div>
  )
}

export default MySpreadScreen
