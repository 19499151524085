// @ts-strict
import React from 'react'
import { Table, Skeleton } from '@/shared/components'
import spreadSummaryData from './hooks/spreadSummaryData'
import withApolloProvider from '@/shared/hoc/withApolloProvider'
import { Alert } from '@/shared/components/Icons'
import {
  spreadSummaryMock,
  spreadSummarySeniorsMock,
  spreadSummaryTCMock
} from './hooks/spreadSummaryMock'
import { spreadClient } from '@/bootstrap/apollo'

// ALKU Spread Summary
const SpreadSummaryTable = (data) => {
  const {
    tableConfig,
    columnConfig,
    spreadSummaryLoading,
    spreadSummaryError
  } = spreadSummaryData(data)

  return (
    <>
      {spreadSummaryError && (
        <div role='alert' aria-live='polite' className='c-component-error'>
          <Alert position='left' />
          The data table could not be loaded at this time
        </div>
      )}
      {!spreadSummaryError && (
        <Skeleton.Table
          active
          title
          loading={spreadSummaryLoading || !tableConfig.length}
          config={Skeleton.Table.PRESET_1}
        >
          <Table
            sticky
            tableConfig={tableConfig}
            columnConfig={columnConfig}
            includeTotalsRow
          ></Table>
        </Skeleton.Table>
      )}
    </>
  )
}

export const SpreadSummaryTableDivisions = () => {
  return SpreadSummaryTable(spreadSummaryMock)
}

export const SpreadSummaryTableSeniors = () => {
  return SpreadSummaryTable(spreadSummarySeniorsMock)
}

export const SpreadSummaryTableTrainingCenters = () => {
  return SpreadSummaryTable(spreadSummaryTCMock)
}

export default withApolloProvider({ client: spreadClient })(SpreadSummaryTable)
