import { RootState } from '@/bootstrap/redux'

import * as controlPanelActions from './controlPanelActionsPlain'
import { addPrtExpandedKeysObj, setPrtExpandedKeysObj } from '@/features/dashboard/redux/dashboardActions'
import * as controlPanelService from '@/utils/api/controlPanel'
import { buildAlkuTreeData, buildTableExpandedKeyObjFromTreeExpandedKeysArr, buildTimeSliderMarks, getParentKey } from './controlPanelOperations'
import { ALL_ALKU_KEY, BRAND_KEY, DIVISION_KEY, EMPLOYEE_KEY, OTHER_CONTRIBUTORS_KEY, SIDE_OF_HOUSE_ENUM } from '@/shared/constants/dataKeys'
import { filterMapKeysToIdsByType } from './controlPanelSelectors'
import { AT_SHORTCUT_KEYS } from '@/shared/constants/local'
import { fetchReport, notifyError, setAllReportResponse } from '@/features/shared/redux/sharedActions'
import { getReport } from '@/utils/api/shared'
import { formatTimeSliderData } from '@/features/shared/redux/sharedOperations'

/**
 * builds and sets audience tree data and an employee dictionary
 * @param {boolean} requestOcs fetches all report data to build other contributor nodes
 * @param {boolean} fetchReportOnSucces dispatch fetchReport action after build
 * @returns 
 */
export const buildAudienceTreeData = (requestOcs?: boolean, fetchReportOnSucces?: boolean) => async (dispatch, getState) => {
  try {
    const {
      shared: { oneTimeResponse, allReportResponse },
      dashboard: { activeDashboardTabKey },
      controlPanel: {
        timeSlider: {
          timePeriod,
          marks,
          customDateRangeEnabled,
          customDateRange
        },
        audienceTree: {
          searchValue
        }
      },
    }: RootState = getState()
    if (oneTimeResponse) {
      let reportResponse
      if (requestOcs) {
        const requestObj = {
          'side-of-house': activeDashboardTabKey
            ? Number(activeDashboardTabKey)
            : SIDE_OF_HOUSE_ENUM.ALL,
          'audience-spec': { divisions: [{ id: 'all', people: ['all'] }] }
        }
        const [timeFrame, formattedDateRange] = formatTimeSliderData(
          customDateRange,
          customDateRangeEnabled,
          marks,
          timePeriod
        )
        reportResponse = await getReport(timeFrame, formattedDateRange, requestObj)
        dispatch(setAllReportResponse(reportResponse))
      }
      const employeeDictionary = {}
      const treeData = buildAlkuTreeData(oneTimeResponse, reportResponse || allReportResponse, employeeDictionary, activeDashboardTabKey)
      dispatch(controlPanelActions.setAudienceTreeData(treeData))
      dispatch(controlPanelActions.setAtEmployeeDictionary(employeeDictionary))
      if (fetchReportOnSucces) {
        dispatch(fetchReport({ skipBuild: true }))
      }
      if (searchValue) {
        dispatch(onAtSearch(searchValue))
      }
    }
  } catch (e) {
    console.error(e)
    if (e?.isAxiosError && requestOcs) {
      dispatch(buildAudienceTreeData(false, fetchReportOnSucces))
      dispatch(notifyError('Error calculating other contributors.'))
    }
  }
}

export const generateTimeSliderMarks = () => (dispatch, getState) => {
  try {
    const { shared: { oneTimeResponse } }: RootState = getState()
    const timeSliderMarks = buildTimeSliderMarks(oneTimeResponse?.['time-frames'])
    dispatch(controlPanelActions.setTimeSliderMarks(timeSliderMarks))
  } catch (e) {
    //
  }
}

export const onAtSearch = (searchValue?: string) => (dispatch, getState) => {
  try {
    const { controlPanel: { audienceTree: { dataList, data, search } } }: RootState = getState()
    if (!search || !search.length) {
      dispatch(controlPanelActions.saveAtState(['expandedKeys']))
    }
    if (search && search.length && (!searchValue || !searchValue.length)) {
      dispatch(controlPanelActions.rollbackAtSaveState())
      dispatch(controlPanelActions.setAtSearchResultKeys([]))
    } else {
      const searchResultNodes = dataList.filter((node) =>
        node.title.toLowerCase().includes(searchValue.toLowerCase()) && !node.key.includes(OTHER_CONTRIBUTORS_KEY)
      )
      const expandedKeys = searchResultNodes
        .map((item) => {
          return getParentKey(item.key, data)
        })
        .filter((item, i, self) => item && self.indexOf(item) === i)
      const searchResultKeys = searchResultNodes.map(node => node.key)
      dispatch(controlPanelActions.setAtExpandedKeys(expandedKeys))
      dispatch(controlPanelActions.setAtSearchResultKeys(searchResultKeys))
      dispatch(controlPanelActions.setAtAutoExpandParent(true))
    }
    dispatch(controlPanelActions.setAudienceTreeSearch(searchValue))
  } catch (e) {
    //
  }
}

export const onAudienceShortcutTabChange = (val: string) => (dispatch, getState) => {
  try {
    const {
      shared: { oneTimeResponse },
      controlPanel: { audienceTree: { employeeDictionary, data, dataList, activeShortcutKey } }
    }: RootState = getState()
    const { divisions, people } = oneTimeResponse['current-user-context'] || { divisions: [], people: [] as Array<string> }
    const keyToEvaluate = val || activeShortcutKey
    let keysToDispatch: Array<string> = [ALL_ALKU_KEY]
    if (keyToEvaluate === AT_SHORTCUT_KEYS.DIVISIONS) {
      keysToDispatch = divisions
      if (divisions.some(id => !dataList.find(node => node.key === id))) {
        dispatch(notifyError('Some or all divisions not visible in this view. Please check report settings.'))
      }
    } else if (keyToEvaluate === AT_SHORTCUT_KEYS.PEOPLE) {
      let employeeNotFound = false
      keysToDispatch = people.reduce((allIds, nextPersonId) => {
        if (employeeDictionary[nextPersonId]) {
          allIds = [
            ...allIds,
            ...employeeDictionary[nextPersonId].employeeNodeKeys,
            ...employeeDictionary[nextPersonId].otherContributorKeys
          ]
        } else {
          employeeNotFound = true
        }
        return allIds
      }, [])
      if (keysToDispatch.length && employeeNotFound) {
        dispatch(notifyError('Some or all people not visible in this view. Please check report settings.'))
      } else if (people.length === 0 || keysToDispatch.length === 0) {
        // dispatch(notifyError('My people not visible in this view. Please check report settings.'))
      }
    }
    const expandedKeys = keyToEvaluate === AT_SHORTCUT_KEYS.ALL ? [ALL_ALKU_KEY] : keysToDispatch
      .map((key) => {
        return getParentKey(key, data)
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)

    if (val) {
      dispatch(controlPanelActions.setActiveShortcutKey(val))
    }
    dispatch(controlPanelActions.setAtCheckedKeys(keysToDispatch))
    dispatch(controlPanelActions.setAtDebouncedCheckedKeys(keysToDispatch))
    dispatch(controlPanelActions.setAtExpandedKeys(expandedKeys))
    dispatch(fetchReport({ rebuildOnFail: true }))
    let prtKeyObj = {}
    if (keyToEvaluate === AT_SHORTCUT_KEYS.ALL) {
      prtKeyObj = {
        [ALL_ALKU_KEY]: dataList.filter(node => node.type === BRAND_KEY).map(node => node.key)
      }
    } else {
      prtKeyObj = buildTableExpandedKeyObjFromTreeExpandedKeysArr(expandedKeys, data, true)
    }
    dispatch(setPrtExpandedKeysObj(prtKeyObj))
  } catch (e) {
    //
  }
}

export const onAudienceSearchEnter = () => (dispatch, getState) => {
  const { controlPanel: { audienceTree: { searchResultKeys, checkedKeys } } }: RootState = getState()
  if (searchResultKeys.length === 1 && !checkedKeys.includes(searchResultKeys[0])) {
    dispatch(controlPanelActions.setAtCheckedKeys([...checkedKeys, searchResultKeys[0]]))
    dispatch(controlPanelActions.setAtDebouncedCheckedKeys([...checkedKeys, searchResultKeys[0]]))
  }
}

export const onSaveMyDivisions = () => async (dispatch, getState) => {
  dispatch(controlPanelActions.saveMyDivisionsBegin())
  try {
    const { controlPanel: { audienceTree: { dataList, checkedKeys } } }: RootState = getState()
    const keysToSave = filterMapKeysToIdsByType(dataList, checkedKeys, DIVISION_KEY)
    const response = await controlPanelService.updateMyDivisionsShortcut(keysToSave)
    dispatch(controlPanelActions.saveMyDivisionsSuccess(response))
  } catch (e) {
    dispatch(controlPanelActions.saveMyDivisionsFail())
  }
}

export const onSaveMyPeople = () => async (dispatch, getState) => {
  dispatch(controlPanelActions.saveMyPeopleBegin())
  try {
    const { controlPanel: { audienceTree: { dataList, checkedKeys } } }: RootState = getState()
    const keysToSave = filterMapKeysToIdsByType(dataList, checkedKeys, EMPLOYEE_KEY)
    const response = await controlPanelService.updateMyPeopleShortcut(keysToSave)
    dispatch(controlPanelActions.saveMyPeopleSuccess(response))
  } catch (e) {
    dispatch(controlPanelActions.saveMyPeopleFail())
  }
}

export const checkAllRelatedOtherContributors = (id) => (dispatch, getState) => {
  try {
    const { controlPanel: { audienceTree: { employeeDictionary, data } } }: RootState = getState()
    const nodeInfo = employeeDictionary[id]
    if (nodeInfo) {
      const keysToDispatch = [
        ...nodeInfo.employeeNodeKeys,
        ...nodeInfo.otherContributorKeys
      ]
      const expandedKeys = keysToDispatch
        .map((key) => {
          return getParentKey(key, data)
        })
      dispatch(controlPanelActions.addAtCheckedKeys(keysToDispatch))
      dispatch(controlPanelActions.addAtDebouncedCheckedKeys(keysToDispatch))
      dispatch(controlPanelActions.setAtAutoExpandParent(true))
      dispatch(controlPanelActions.addAtExpandedKeys(expandedKeys))
      const prtKeyObj = buildTableExpandedKeyObjFromTreeExpandedKeysArr(
        keysToDispatch,
        data,
        true
      )
      dispatch(addPrtExpandedKeysObj(prtKeyObj))
    }
  } catch (e) {
    //
  }
}

export const resetTreeToAll = () => (dispatch) => {
  const keys = [ALL_ALKU_KEY]
  dispatch(controlPanelActions.setActiveShortcutKey(AT_SHORTCUT_KEYS.ALL))
  dispatch(controlPanelActions.setAtExpandedKeys(keys))
  dispatch(controlPanelActions.setAtCheckedKeys(keys))
  dispatch(controlPanelActions.setAtDebouncedCheckedKeys(keys))
  dispatch(buildAudienceTreeData(true, true))
}

export const resetTreeToMyPeople = () => async (dispatch) => {
  await dispatch(buildAudienceTreeData(true))
  dispatch(onAudienceShortcutTabChange(AT_SHORTCUT_KEYS.PEOPLE))
}

export * from './controlPanelActionsPlain'
