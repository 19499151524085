import React from 'react'
import Icon from './Icon'

const ArrowSolidLeft = ({ position }) => {
  return (
    <Icon position={position}>
      <svg
        width='16'
        height='16'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <defs>
          <path
            d='M8 0C3.591 0 0 3.591 0 8s3.591 8 8 8 8-3.591 8-8-3.591-8-8-8zm0 1.6c3.544 0 6.4 2.856 6.4 6.4 0 3.544-2.856 6.4-6.4 6.4A6.388 6.388 0 011.6 8c0-3.544 2.856-6.4 6.4-6.4zm.8 3.2v2.4H4v1.6h4.8v2.4L12 8 8.8 4.8z'
            id='prefix__a--arrow-solid-left'
          />
        </defs>
        <g fill='none' fillRule='evenodd'>
          <mask id='prefix__b--arrow-solid-left' fill='#fff'>
            <use xlinkHref='#prefix__a--arrow-solid-left' />
          </mask>
          <use
            fill='#000'
            fillRule='nonzero'
            transform='rotate(-180 8 8)'
            xlinkHref='#prefix__a--arrow-solid-left'
          />
          <path fill='#404555' mask='url(#prefix__b--arrow-solid-left)' d='M0 0h16v16H0z' />
        </g>
      </svg>
    </Icon>
  )
}

export default ArrowSolidLeft
