import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ color }) => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='24px'
      height='24px'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        fill={color}
        stroke='none'
      >
        <path
          d='M386 870 c-63 -16 -153 -70 -197 -117 -22 -24 -55 -74 -72 -111 -29
-61 -32 -76 -32 -163 0 -90 2 -99 37 -171 45 -91 103 -147 196 -191 61 -29 76
-32 162 -32 86 0 101 3 162 32 93 44 151 100 196 191 35 72 37 81 37 172 0 91
-2 100 -37 172 -68 136 -188 217 -336 224 -42 2 -94 -1 -116 -6z m225 -99 c69
-33 112 -74 150 -143 31 -58 34 -70 34 -148 0 -78 -3 -90 -34 -148 -38 -70
-70 -100 -145 -140 -43 -22 -64 -26 -136 -26 -78 -1 -90 2 -148 33 -69 37
-109 80 -144 151 -32 66 -31 196 2 265 42 86 123 152 215 176 49 13 158 2 206
-20z'
        />
        <path
          d='M541 616 l-135 -63 -34 -74 c-19 -41 -47 -102 -62 -136 l-29 -62 77
36 c42 19 103 47 136 61 54 24 60 30 86 86 15 34 44 96 64 139 19 42 34 77 33
76 -1 0 -62 -28 -136 -63z m-26 -136 c0 -33 -2 -35 -35 -35 -29 0 -36 4 -38
23 -6 40 5 54 40 50 30 -3 33 -6 33 -38z'
        />
      </g>
    </svg>
  </div>
)

Icon.propTypes = {
  color: PropTypes.string.isRequired
}

export default Icon
