const COLOR_KEYS: Record<string, string> = {
  black: '#000000',
  red: '#D0021B',
  'dark-blue': '#404555',
  'dark-gray': '#5D5F69',
  gray: '#C2C4C9',
  'light-gray': '#F6F6F6',
  'basalt-gray': '#999999',
  'pale-green': '#E1F3CE',
  'pale-red': '#FFE2E2',
  'pale-yellow': '#FFF9DC',
  white: '#F9F9F9',
  'golden-dream': '#EFD72D',
  'green-money': '#1B7D49',
  purple: '#8F1DA0',
  'light-purple': '#CBA6D0',
  blue: '#1363AF',
  orange: '#E88938',
  green: '#1B7D49'
} as const

export type COLOR_KEYS_TYPE = keyof typeof COLOR_KEYS

export default COLOR_KEYS
