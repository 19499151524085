import React from 'react'

export const ProgressBar = ({ width, percent = '0', className = '', text = '0' }) => {
  return (
    <div className={`c-progress ${className}`} style={{ width }}>
      <div className='c-progress__bar c-progress__bar--bg' />
      <div className='c-progress__bar' style={{ width: percent && `${percent}%`, minWidth: text && text.toString().length > 1 ? `${text.toString().length * 1.3}rem` : undefined }}>
        <span className='c-progress__text' title={text ? text : undefined}>{text}</span>
      </div>
    </div>
  )
}

export default ProgressBar
