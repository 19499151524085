// @ts-strict
import { IAppColumnConfig } from '@/shared/components/Table'
import { formatNumberToAccountingNumericalFormat } from '@/utils/tools/format'
import { COLUMN_MOD_KEYS } from '@/shared/constants/local'
import { IconixxRecord } from './query'

export type IconixxStringKeys = Extract<keyof IconixxRecord, string>

const genColCfg = <T extends IconixxStringKeys>(
  title: string,
  dataIndex: T,
  width?: number,
  renderFn?: (value: IconixxRecord[T]) => string
): IAppColumnConfig<IconixxRecord> => ({
    title,
    dataIndex,
    key: dataIndex,
    width,
    columnMod: [COLUMN_MOD_KEYS.PLAIN],
    renderComponent: renderFn ? (record) => renderFn(record[dataIndex]) : undefined
  })

const iconixxColumnConfigs: Array<IAppColumnConfig> = [
  genColCfg('Record ID', 'id', 133),
  genColCfg('Week', 'week', 69),
  genColCfg('Customer/Program ID', 'customerProgramId', 180),
  genColCfg('Client', 'client', 158),
  genColCfg('Consultant', 'consultant', 131),
  genColCfg<'netSpread'>('Net Spread', 'netSpread', 139, formatNumberToAccountingNumericalFormat),
  genColCfg('Recruiter ID', 'recruiterId', 120),
  genColCfg('Recruiter', 'recruiter', 173),
  genColCfg('Salesperson ID', 'salespersonId', 139),
  genColCfg('Salesperson', 'salesperson', 155),
  genColCfg('Department', 'department', 169),
  genColCfg('RTC', 'rTC', 128),
  genColCfg('BDM', 'bDM', 151)
]

export default iconixxColumnConfigs
