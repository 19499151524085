// @ts-strict
import React from 'react'
import { Table, Skeleton } from '@/shared/components'
import consultantPerformanceData from './hooks/consultantPerformanceData'
import { Alert } from '@/shared/components/Icons'
import PropTypes from 'prop-types'

// Power Ranking Table
export const ConsultantPerformanceTable = ({ data }) => {
  const {
    tableConfig,
    columnConfig,
    consultantPerformanceLoading,
    consultantPerformanceError
  } = consultantPerformanceData(data)

  return (
    <>
      {consultantPerformanceError && (
        <div role='alert' aria-live='polite' className='c-component-error'>
          <Alert position='left' />
          The data table could not be loaded at this time
        </div>
      )}
      {!consultantPerformanceError && (
        <Skeleton.Table
          active
          title
          loading={consultantPerformanceLoading}
          config={Skeleton.Table.PRESET_1}
        >
          <Table
            sticky
            tableConfig={tableConfig}
            columnConfig={columnConfig}
            includeTotalsRow={false}
            sortDirections={['ascend', 'descend']}
          ></Table>
        </Skeleton.Table>
      )}
    </>
  )
}

ConsultantPerformanceTable.propTypes = {
  data: PropTypes.array.isRequired
}
