// @ts-strict
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import generateCwWorksheet from './generateCurrentWeekWorksheet'
import generateFoundersWorksheet from './generateFoundersWorksheet'
import generateSpreadWorksheet from './generateSpreadWorksheet'
import groupDepartmentBrands from './groupDepartmentBrands'
import groupMonthlyTargetData from './groupMonthlyTargetData'
import { SpreadExportData } from './spreadQuery'

type SpreadSaveFileOptions = {
  onComplete?: () => void
  onError?: (error: unknown) => void
}

const saveFile = async (data: SpreadExportData, options?: SpreadSaveFileOptions) => {
  try {
    if (data) {
      const {
        allWeeklySpread,
        totalSummary,
        allDepartmentSummary,
        allFounders,
        allMonthlyTargets,
        spreadDivisionMappings
      } = data
      const workbook = new Excel.Workbook()
      const cwWorksheet = generateCwWorksheet(workbook)
      // Current Week Sheet
      if (allWeeklySpread) {
        await cwWorksheet.addRows(allWeeklySpread)
      }

      // All ALKU Spread Totals & Brand/Department Spread Totals
      if (spreadDivisionMappings && totalSummary && allDepartmentSummary) {
        const worksheetNames = []
        const groupedMonthlyTargets = groupMonthlyTargetData(spreadDivisionMappings, allMonthlyTargets)
        const groupedDepartmentBrands = groupDepartmentBrands(spreadDivisionMappings, allDepartmentSummary)
        const totalTargetData = groupedMonthlyTargets['All ALKU']
        generateSpreadWorksheet(workbook, totalSummary, 'Total Company', totalTargetData)
        Object.entries(groupedDepartmentBrands).sort((a, b) => {
          const [aBrand,] = a
          const [bBrand,] = b
          if (!aBrand) return 1
          if (!bBrand) return -1
          return aBrand.localeCompare(bBrand)
        }).forEach(([brand, groupedDepartments]) => {
          if (groupedDepartments.mergedData && !worksheetNames.includes(brand)) {
            const targetData = groupedMonthlyTargets[brand]
            generateSpreadWorksheet(workbook, groupedDepartments.mergedData, brand, targetData)
            worksheetNames.push(brand)
          }
          Object.entries(groupedDepartments).forEach(([department, data]) => {
            if (department !== 'mergedData' && !worksheetNames.includes(department)) {
              const targetData = groupedMonthlyTargets[department]
              generateSpreadWorksheet(workbook, data, department, targetData)
              worksheetNames.push(department)
            }
          })
        })
      }

      if (allFounders) {
        generateFoundersWorksheet(workbook, allFounders, 'Recruiters')
        generateFoundersWorksheet(workbook, allFounders, 'Sales')
      }

      const xls64 = await workbook.xlsx.writeBuffer()
      saveAs(
        new Blob([xls64],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        'Spread Report.xlsx'
      )
      if (typeof options?.onComplete === 'function') {
        options.onComplete()
      }
    }
  } catch (error) {
    if (typeof options?.onError === 'function') {
      options.onError(error)
    }
  }
}

export default saveFile
