import { createSelector } from 'reselect';

import { selectGovernmentDivisionIds } from '@/features/shared/redux/sharedSelectors';
import { selectUserContext } from '@/features/shared/redux/sharedSelectors';
import { ALL_ALKU_KEY, DIVISION_KEY, EMPLOYEE_KEY, BRAND_KEY } from '@/shared/constants/dataKeys';
import { AT_SHORTCUT_KEYS } from '@/shared/constants/local';
import { checkDescendantNodesForUnchecked } from './controlPanelOperations';
import { isBeforeOrSameSuppliedDate, isGreaterThanAWeek } from '@/utils/tools/dateTime';
import { RootState } from '@/bootstrap/redux';
import { AlkuTreeNodeType, TreeNode } from '@/shared/types';

export const selectTimePeriod = (state: RootState) => state.controlPanel.timeSlider.timePeriod
export const selectCustomDateRangeEnabled = (state: RootState) => state.controlPanel.timeSlider.customDateRangeEnabled
export const selectCustomDateRange = (state: RootState) => state.controlPanel.timeSlider.customDateRange
export const selectTimeSliderMarks = (state: RootState) => state.controlPanel.timeSlider.marks
export const selectSelectedDates = (state: RootState) => state.controlPanel.timeSlider.selectedDates

// audience tree selectors
export const selectAtCheckedKeys = (state: RootState) => state.controlPanel.audienceTree.checkedKeys
export const selectAtDebouncedCheckedKeys = (state: RootState) => state.controlPanel.audienceTree.debouncedCheckedKeys
export const selectAtSelectedKeys = (state: RootState) => state.controlPanel.audienceTree.selectedKeys
export const selectAtExpandedKeys = (state: RootState) => state.controlPanel.audienceTree.expandedKeys
export const selectAudienceTreeData = (state: RootState) => state.controlPanel.audienceTree.data
export const selectBrandTreeData = (state: RootState) => state.controlPanel.audienceTree.data?.[0]?.children
export const selectActiveShortcutKey = (state: RootState) => state.controlPanel.audienceTree.activeShortcutKey
export const selectEmployeeDictionary = (state: RootState) => state.controlPanel.audienceTree.employeeDictionary
export const selectAudienceTreeSearch = (state: RootState) => state.controlPanel.audienceTree.search
export const selectAudienceTreeSearchValue = (state: RootState) => state.controlPanel.audienceTree.searchValue
export const selectAtAutoExpandParent = (state: RootState) => state.controlPanel.audienceTree.autoExpandParent
export const selectAtSearchResultKeys = (state: RootState) => state.controlPanel.audienceTree.searchResultKeys
export const selectAtDataList = (state: RootState) => state.controlPanel.audienceTree.dataList

// audience tree meta
export const selectMyDivisionsSaving = (state: RootState) => state.controlPanel.audienceTree.meta.myDivisionsSaving
export const selectMyPeopleSaving = (state: RootState) => state.controlPanel.audienceTree.meta.myPeopleSaving

export const selectDataListBrands = createSelector(
  [selectAtDataList],
  (dataList) => {
    return dataList.filter((node) => node?.type === BRAND_KEY)
  }
)

// depending on how the checked keys are updated there's a possibility that the 
// checked keys arrays does not accurately reflect that all nodes have been checked
// this selector covers those bases
export const selectAllAlkuSelected = createSelector(
  [selectAtDebouncedCheckedKeys, selectDataListBrands, selectAudienceTreeData],
  (checkedKeys, brandNodes, treeData) => {
    const allAlkuSelected = checkedKeys.includes(ALL_ALKU_KEY)
    if (!allAlkuSelected) {
      const someBrandNotSelected = brandNodes.some((node) =>
        checkDescendantNodesForUnchecked(treeData, checkedKeys, node.key)
      )
      return !someBrandNotSelected
    }
    return true
  }
)

const checkKeyAndChildren = (node, checkedKeys) => {
  if (checkedKeys.includes(node.key)) {
    return true
  } else if (node.children && node.children.length && node.children.some(c => checkKeyAndChildren(c, checkedKeys))) {
    return true
  }
  return false
}

const checkKeyAndReturn = (reducedData: Array<AlkuTreeNodeType>, node: TreeNode, checkedKeys: Array<string>, parentChecked?: boolean): Array<AlkuTreeNodeType> => {
  const checked = node && checkedKeys.some((key) => key === node.key)
  let childrenChecked
  if (!checked && node.children) {
    childrenChecked = node.children.some(childNode => checkKeyAndChildren(childNode, checkedKeys))
  }
  if (parentChecked || checked || childrenChecked) {
    if (node.children) {
      reducedData.push({
        ...node,
        children: node.children.reduce((reducedChildren, nextNode) => checkKeyAndReturn(reducedChildren, nextNode, checkedKeys, checked), [])
      })
    } else {
      reducedData.push(node)
    }
  }
  return reducedData
}

// reduces the tree data to remove nodes that are not checked to use in table
export const selectCheckedBrandTreeData = createSelector(
  [selectBrandTreeData, selectAtDebouncedCheckedKeys, selectAllAlkuSelected],
  (treeData, checkedKeys, allAlkuSelected) => {
    if (treeData) {
      if (allAlkuSelected) return treeData
      return treeData.reduce((reducedTree, nextNode) => checkKeyAndReturn(reducedTree, nextNode, checkedKeys), [])
    } else {
      return []
    }
  }
)

const visiblyFilterTreeData = (treeData: Array<AlkuTreeNodeType>, filterKeys: Array<string>): Array<AlkuTreeNodeType> => {
  return treeData.map(node => {
    const isVisible = checkKeyAndChildren(node, filterKeys)
    if (isVisible) {
      if (node.children) {
        return {
          ...node,
          children: visiblyFilterTreeData(node.children, filterKeys)
        }
      } else {
        return node
      }
    } else {
      return {
        ...node,
        style: { display: 'none' }
      }
    }
  })
}

export const selectVisibleAtData = createSelector(
  [
    selectAudienceTreeData,
    selectAtSearchResultKeys,
    selectAtExpandedKeys
  ],
  (treeData, searchResultKeys, expandedKeys) => {
    if (treeData) {
      if (!searchResultKeys.length) {
        return treeData
      } else {
        const filterKeys = [...searchResultKeys, ...expandedKeys].filter((key, index, self) => self.indexOf(key) === index)
        return visiblyFilterTreeData(treeData, filterKeys)
      }
    } else {
      return []
    }
  }
)

export const selectGovernmentEmployeeNodeKeys = createSelector(
  [selectAtDataList],
  (dataList) => {
    return dataList.filter((node) => node.isGovernmentNode).map(node => node.key)
  }
)

export const selectGovernmentDivisionIsChecked = createSelector(
  [selectGovernmentDivisionIds, selectAtDebouncedCheckedKeys, selectAllAlkuSelected, selectGovernmentEmployeeNodeKeys],
  (governmentIds, checkedKeys, allAlkuSelected, govEmployeeNodeKeys) => {
    if (govEmployeeNodeKeys.length && allAlkuSelected) {
      return true
    }
    if (!governmentIds) return false
    if (!govEmployeeNodeKeys) return false
    return governmentIds.some(id => checkedKeys.includes(id)) || govEmployeeNodeKeys.some(key => checkedKeys.includes(key))
  }
)

/**
 * returns ids of currently checked keys by optional type
 * @param {array} dataList flattened tree
 * @param {array} checkedKeys audience tree currently checkedKeys
 * @param {string} filterType optional type
 */
export const filterMapKeysToIdsByType = (dataList: Array<AlkuTreeNodeType>, checkedKeys: Array<string>, filterType?: string) => {
  // we replace keys here with ids because savedKeys are ids and keys in the tree may not exactly match the id
  return checkedKeys.reduce((acc, nextKey) => {
    const node = dataList.find(
      (node) => node.key === nextKey && (!filterType || node.type === filterType) && !node.isOtherContributor
    )
    return node ? [...acc, node.id] : acc
  }, [])
}

const filterDataListAndCompareSavedKeys = (dataList: Array<AlkuTreeNodeType>, savedKeys: Array<string>, checkedKeys: Array<string>, filterType?: string) => {
  const filteredKeys = filterMapKeysToIdsByType(dataList, checkedKeys, filterType)
  // comparing length first saves the need to loop and compare each key
  if (filteredKeys.length !== savedKeys.length) {
    return true
  } else if (filteredKeys.some((key) => !savedKeys.includes(key))) {
    return true
  }
  return false
}

export const selectShortcutChangesPending = createSelector(
  [
    selectActiveShortcutKey,
    selectUserContext,
    selectAtDebouncedCheckedKeys,
    selectAtDataList
  ],
  (
    activeShortcutKey,
    userContext,
    checkedKeys,
    dataList
  ) => {
    if (activeShortcutKey !== AT_SHORTCUT_KEYS.ALL) {
      const { divisions, people } = userContext || { divisions: [], people: [] }
      if (activeShortcutKey === AT_SHORTCUT_KEYS.DIVISIONS) {
        return filterDataListAndCompareSavedKeys(dataList, divisions, checkedKeys, DIVISION_KEY)
      }
      if (activeShortcutKey === AT_SHORTCUT_KEYS.PEOPLE) {
        return filterDataListAndCompareSavedKeys(dataList, people, checkedKeys, EMPLOYEE_KEY)
      }
      return false
    }
    return false
  }
)

export const selectTimePeriodId = createSelector(
  [selectTimePeriod, selectTimeSliderMarks, selectCustomDateRangeEnabled],
  (value, marks, customDateRangeEnabled) => {
    return !customDateRangeEnabled ? marks?.[value]?.id : null
  }
)

export const selectShouldShowWeekByWeek = createSelector(
  [selectTimePeriod, selectCustomDateRangeEnabled, selectCustomDateRange],
  (timePeriod, customDateRangeEnabled, customDateRange) => {
    let shouldShowWeekByWeek = false
    if (timePeriod > 20) {
      shouldShowWeekByWeek = true
    }
    if (customDateRangeEnabled && customDateRange) {
      shouldShowWeekByWeek = isGreaterThanAWeek(customDateRange)
    }
    return shouldShowWeekByWeek
  }
)

export const selectShouldShowDataQualityWarning = createSelector(
  [selectCustomDateRange, selectTimeSliderMarks],
  (dateRange, timeSliderMarks) => {
    const ttm = timeSliderMarks?.[100]
    const timeFrom = ttm?.from
    const dateFrom = dateRange?.[0]

    return !!timeFrom && !!dateFrom && !isBeforeOrSameSuppliedDate(timeFrom, dateFrom)
  }
)