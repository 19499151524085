// eslint-disable ban-ts-comment
import { setActiveUserAccount} from '@/features/auth/redux/authActions';
import { setSsoError } from '@/features/shared/redux/sharedActions';
import { USE_TEST_CONFIG } from '@/shared/constants/local';
import { PublicClientApplication, EventType, InteractionRequiredAuthError, AccountInfo } from '@azure/msal-browser'
import store from '../redux'

const TENANT_ID = '740e0ba1-e4ed-4c5d-9a56-2559c8fb94a6'
const CLIENT_ID = '6023b669-90e1-46af-8504-2752f704cc96'

const config = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocations: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const authenticationParams = {
  scopes: [`${CLIENT_ID}/.default`]
}

export const msalInstance = new PublicClientApplication(config)

export const getAccessToken = async () => {
  // for e2e tests, use token retrieved during login command
  if (USE_TEST_CONFIG) {
    return window.sessionStorage.getItem('msal-token')
  }
  let token
  await msalInstance
    .acquireTokenSilent(authenticationParams)
    .then((tokenResponse) => {
      token = tokenResponse.accessToken
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalInstance.acquireTokenRedirect(authenticationParams);
      }
    })
  return token
}

// Account selection logic is app dependent. Adjust as needed for different use cases.
msalInstance.addEventCallback((event) => {
  // @ts-expect-error: "Property 'account' does not exist on type 'EventPayload'."
  // AuthenticationResult(https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_common.html#authenticationresult) 
  // which is a type of EventPayload(https://azuread.github.io/microsoft-authentication-library-for-js/ref/modules/_azure_msal_browser.html#eventpayload)
  // has an "account" property that ts is not picking up
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    // @ts-expect-error: see above
    const account: AccountInfo = event.payload.account
    msalInstance.setActiveAccount(account)
    store.dispatch(setActiveUserAccount(account))
  }

  if ([EventType.HANDLE_REDIRECT_END, EventType.LOGIN_FAILURE].includes(event.eventType) && event.error) {
    store.dispatch(setSsoError(true))
  }
})
