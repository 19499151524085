import { ROW_MOD_KEYS } from '@/shared/constants/local'
import { MyPeopleTableRecord } from './formatData'

const createTableConfig = (records: MyPeopleTableRecord[], isChild?: boolean) => {
  return records.map((record) => {
    const { divisionId, employeeName, employeeId, isPrimaryDivision, firstName, lastName } = record
    return {
      ...record,
      ...record.data,
      key: `${divisionId}-${employeeName}-${employeeId}`,
      rowMod: [
        isPrimaryDivision ? ROW_MOD_KEYS.QUALITY : null,
        !isChild ? ROW_MOD_KEYS.AVATAR : null,
        ROW_MOD_KEYS.DATA_DISPLAY,
        ROW_MOD_KEYS.EXPANDABLE_CARAT,
        ROW_MOD_KEYS.EXPANDABLE_FIX_RIGHT,
        record.children?.length && !isChild ? ROW_MOD_KEYS.EXPANDABLE : null
      ],
      cellClassnames: {
        employeeName: 'c-type--table-data-bold c-table__cell--avatar',
        default: 'c-type--table-numeric'
      },
      rowClassName: `c-table__row--bordered${isChild ? ' c-table__row--indent u-bg-lotion' : ''}${record.children?.length && !isChild ? ' c-table__row--expandable' : ''}`,
      'first-name': firstName,
      'last-name': lastName,
      childTable: record.children ? createTableConfig(record.children, true) : null,
      children: null // need to set children to null so antd table component does not render them
    }
  }).filter(v => v).sort((a, b) => a.lastName.localeCompare(b.lastName))
}

export default createTableConfig
