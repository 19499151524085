import React from 'react'
import Icon, { IconPositionType } from './Icon'

type DownCaratProps = {
  position?: IconPositionType
  className?: string
  rotate?: number
}

const DownCarat = ({ position, className, rotate }: DownCaratProps) => {
  const prefix = `--DownCarat-${Date.now()}`
  return (
    <Icon position={position} className={className}>
      <svg style={{ transform: rotate ? `rotate(${rotate}deg)` : '' }} width='13' height='8' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
        <defs>
          <path id={`prefix__a${prefix}`} d='M3.785-2.285l-1.5 1.5 4.57 4.57-4.57 4.57 1.5 1.5 6.07-6.07z'/>
        </defs>
        <g transform='translate(.215 .215)' fill='none' fillRule='evenodd'>
          <mask id={`prefix__b${prefix}`} fill='#fff'>
            <use xlinkHref={`#prefix__a${prefix}`} transform='rotate(90 6.07 3.785)'/>
          </mask>
          <use fill='#000' fillRule='nonzero' transform='rotate(90 6.07 3.785)' xlinkHref={`#prefix__a${prefix}`}/>
          <path fill='#5D5F69' mask={`url(#prefix__b${prefix})`} d='M-2.215-4.215h16v16h-16z'/>
        </g>
      </svg>
    </Icon>
  )
}

export default DownCarat
