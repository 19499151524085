import {
  CLIENT_VISITS_KEY,
  PIPELINES_KEY,
  SALES_DBAS_KEY,
  JOB_ORDERS_KEY,
  SALES_SENDOUTS_KEY,
  SALES_DEALS_KEY,
  SCHEDULED_CALLS_KEY,
  SUBS_KEY,
  CLIENT_SUBS_KEY,
  IN_HOUSE_INTERVIEWS_KEY,
  LEADS_KEY,
  RECRUITING_DBAS_KEY,
  RECRUITING_SENDOUTS_KEY,
  RECRUITING_DEALS_KEY
} from './dataKeys'
import { DASHBOARD_TAB_KEYS } from './local'

export const REPORT_OPTIONS: Array<string> = ['recruiting', 'am']

export const TF_OPTIONS: Array<string> = [
  'day',
  'week',
  '4wks',
  '4weeks',
  '13wks',
  '13weeks',
  'ytd',
  'ttm',
  'custom'
]

export const TF_TO_MARK_VALUE: Record<string, number> = {
  'day': 0,
  'week': 20,
  '4wks': 40,
  '4weeks': 40,
  '13wks': 60,
  '13weeks': 60,
  'ytd': 80,
  'ttm': 100
}

export const DASHBOARD_TAB_TO_QS: Record<string, string> = {
  '1': 'recruiting',
  '2': 'am'
}

export const METRIC_TO_TARGET: Record<string, Record<string, string>> = {
  [DASHBOARD_TAB_KEYS.AM]: {
    'dbas': SALES_DBAS_KEY,
    'client-visits': CLIENT_VISITS_KEY,
    'pipleines': PIPELINES_KEY,
    'job-orders': JOB_ORDERS_KEY,
    'send-outs': SALES_SENDOUTS_KEY,
    'deals': SALES_DEALS_KEY
  },
  [DASHBOARD_TAB_KEYS.RECRUITING]: {
    'sched-calls': SCHEDULED_CALLS_KEY,
    'dbas': RECRUITING_DBAS_KEY,
    'subs': SUBS_KEY,
    'client-subs': CLIENT_SUBS_KEY,
    'in-houses': IN_HOUSE_INTERVIEWS_KEY,
    'send-outs': RECRUITING_SENDOUTS_KEY,
    'deals': RECRUITING_DEALS_KEY,
    'leads': LEADS_KEY
  }
}

export const METRIC_OPTIONS: Array<string> = [
  'dbas',
  'client-visits',
  'pipelines',
  'job-orders',
  'send-outs',
  'deals',
  'sched-calls',
  'subs',
  'client-subs',
  'in-houses',
  'leads'
]