import React, { useCallback, useRef, useEffect } from 'react'
import { Checkbox, Slider } from 'antd'
import { debounce } from 'lodash'

import marks from './helpers/marks'
import { useAppDispatch, useAppSelector } from '@/shared/hooks'
import { TimeAndDate } from '@/shared/components/Icons'
import { Notice, RangePicker, Skeleton } from '@/shared/components'
import {
  selectCustomDateRange,
  selectCustomDateRangeEnabled,
  selectSelectedDates,
  selectShouldShowDataQualityWarning,
  selectTimePeriod,
  selectTimeSliderMarks
} from '@/features/ControlPanel/redux/controlPanelSelectors'
import { buildAudienceTreeData,
  setCustomDateRange,
  setCustomDateRangeEnabled,
  setTimePeriod,
  setSetSelectedDates as setSelectedDatesAction
} from '@/features/ControlPanel/redux/controlPanelActions'
import {
  /* getDefaultRangePickerMonths, */
  getTimePeriodDisplayDate,
  isDisabledTimeSliderDate,
  snapDatesToEndOfWeek,
  useTempDateLimit
} from '@/utils/tools/dateTime'
import { selectFiscalCalendarMarkers } from '@/features/shared/redux/sharedSelectors'
import { setShowDashboardLoader } from '@/features/dashboard/redux/dashboardActions'
import { fetchItemizationResponse, updateQueryParamsByState } from '@/features/shared/redux/sharedActions'
import { selectActiveTarget } from '@/features/dashboard/redux/dashboardSelectors'
import {pushEvent}  from '@/bootstrap/gtm/gaEvents'

const TimeSlider = () => {
  const dispatch = useAppDispatch()
  const selectedDates = useAppSelector(selectSelectedDates)
  const timePeriodValue = useAppSelector(selectTimePeriod)
  const checked = useAppSelector(selectCustomDateRangeEnabled)
  const customDate = useAppSelector(selectCustomDateRange)
  const timeSliderMarks = useAppSelector(selectTimeSliderMarks)
  const calendarMarkers = useAppSelector(selectFiscalCalendarMarkers)
  const shouldShowDataQualityWarning = useAppSelector(selectShouldShowDataQualityWarning)
  const activeTarget = useAppSelector(selectActiveTarget)
  const max = useTempDateLimit ? 40 : 100

  const setSelectedDates = useCallback((dates?: Parameters<typeof setSelectedDatesAction>[0]) => {
    dispatch(setSelectedDatesAction(dates))
  }, [dispatch])

  const debouncedFetch = useRef(debounce(() => {
    dispatch(buildAudienceTreeData(true, true))
    dispatch(setShowDashboardLoader(true))
    if (activeTarget) {
      dispatch(fetchItemizationResponse())
    }
    dispatch(updateQueryParamsByState())
  }, 500)).current
  useEffect(() => {
    return () => debouncedFetch.cancel()
  }, [debouncedFetch])
  const onTimeSliderChange = useCallback(val => {
    dispatch(setTimePeriod(val))
    debouncedFetch()
    pushEvent(
      {
        event: 'select time period',
        period: marks[val].value
      })
  }, [dispatch, debouncedFetch])
  const onCheckboxChange = useCallback(event => {
    dispatch(setCustomDateRangeEnabled(event.target.checked))
    if (!event.target.checked) {
      // resets rangepicker on uncheck
      // fetches preset shortcut from timeslider only if custom date range was selected before unchecking
      if (customDate !== null) {
        debouncedFetch()
      }
      dispatch(setCustomDateRange(null))
      setSelectedDates(null)
    }
  }, [dispatch, setSelectedDates, debouncedFetch, customDate])
  const onLabelClick = useCallback(() => {
    const syntheticEvent = { target: { checked: !checked } }
    onCheckboxChange(syntheticEvent)
  }, [checked, onCheckboxChange])
  const onCalendarChange = useCallback((dayJsDates) => {
    const snappedDates = snapDatesToEndOfWeek(dayJsDates)
    setSelectedDates(snappedDates)
    if (snappedDates && snappedDates[0] && snappedDates[1]) {
      dispatch(setCustomDateRange(snappedDates))
      dispatch(buildAudienceTreeData(true, true))
      pushEvent({
        event: 'ga_custom_time_period',
        // @ts-expect-error: snappedDates is actually Dayjs[]. this is correctly typed in alku-pulse-gql so I'm avoiding redoing the work here
        'fiscal week': `${(snappedDates[0]).format('YYYY/MM/DD')} - ${snappedDates[1].format('YYYY/MM/DD')}`
      })
      dispatch(updateQueryParamsByState())
    }
  }, [setSelectedDates, dispatch])
  const renderDate = useCallback(() => {
    if (checked) {
      if (customDate) {
        return `${getTimePeriodDisplayDate(customDate[0])} - ${getTimePeriodDisplayDate(customDate[1])}`
      }
      return 'Custom Date Range'
    } else if (timeSliderMarks) {
      const selectedMark = timeSliderMarks[timePeriodValue]
      if(selectedMark){
        return `${getTimePeriodDisplayDate(
          selectedMark.from
        )} - ${getTimePeriodDisplayDate(selectedMark.to)}`
      }
    }
    return <Skeleton.Input style={{width: 150, height: 12}} active size={'small'} />
  }, [checked, customDate, timeSliderMarks, timePeriodValue])
  return (
    <div className='c-time-slider'>
      <div className='u-align-center c-type--time-period'>
        <TimeAndDate position='left' margin='3.7px' scale={1.26} />
        <span className='u-color-dark-gray c-time-slider__tp-text'>
          Time Period:{' '}
        </span>
        <span className='c-time-slider__range-text c-type--bold'>
          {renderDate()}
        </span>
      </div>
      <div className={`c-time-slider__ui${checked ? '--inactive' : ''}`}>
        <Slider
          disabled={checked || !timeSliderMarks}
          value={timePeriodValue}
          range={false}
          marks={marks}
          max={max}
          step={null}
          included={false}
          defaultValue={1}
          tooltipVisible={false}
          onChange={onTimeSliderChange}
        />
      </div>
      <div className='c-time-slider__cdr'>
        <Checkbox
          checked={checked}
          onChange={onCheckboxChange}
          style={{ marginRight: '6px' }}
        />
        <span
          onClick={onLabelClick}
          className='c-type--tabs-default c-time-slider__cdr-label'
        >
          Select Fiscal Week
        </span>
      </div>
      {checked && (
        <>
          <RangePicker
            disabledDate={isDisabledTimeSliderDate}
            // defaultPickerValue={getDefaultRangePickerMonths()}
            dropdownClassName={'c-range-picker__week'}
            calendarMarkers={calendarMarkers}
            onCalendarChange={onCalendarChange}
            value={selectedDates}
          />
          {shouldShowDataQualityWarning && (
            <Notice
              title='Data Quality Warning'
              error
              style={{ marginTop: '1.1rem' }}
            >
              Certain data in this timeframe may not reflect what was originally
              reported due to divisional changes, role changes, and other data
              structure changes over time.
            </Notice>
          )}
        </>
      )}
    </div>
  )
}

export default TimeSlider
