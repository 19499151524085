import React from 'react'
import Icon, { IconPositionType } from './Icon'

type StyleGuideProps = {
  position?: IconPositionType;
  height?: number;
  width?: number;
}

const StyleGuide = ({ position, height, width }: StyleGuideProps) => {
  return (
    <Icon position={position}>
      <svg width={width || 20} height={height || 20} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 2.5v12.353c0 .964.816 1.765 1.8 1.765h5.93c.092.257.264.48.492.639a1.369 1.369 0 001.556 0c.228-.158.4-.382.493-.64H17.2c.984 0 1.8-.8 1.8-1.764V2.5h-6.3c-1.09 0-2.038.508-2.7 1.267C9.338 3.008 8.39 2.5 7.3 2.5H1zm1.8 1.765h4.5c1.005 0 1.8.78 1.8 1.764h1.8c0-.985.795-1.764 1.8-1.764h4.5v10.588H2.8V4.265zm9.9 2.647v1.764h1.8V6.912h-1.8zm0 2.647v3.53h1.8v-3.53h-1.8z'
          fill='#4E4E4E'
        />
      </svg>
    </Icon>
  )
}

export default StyleGuide
