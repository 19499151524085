// @ts-strict
import React, { useMemo, useState, useCallback } from 'react'

import { Skeleton } from '@/shared/components'

import columnConfig from './helpers/columConfigs'

import { CurrentWeekRecord, GET_CURRENT_WEEEK_TABLE_NAMED_QUERY } from './helpers/query'
import sumTotalSpread from './helpers/sumTotalSpread'
import createTableConfig from './helpers/createTableConfig'
import { Card } from '@/shared/components'
import TableHandler from '../TableHandler'
import { formatNumberDisplay } from '@/utils/tools/format'
import { formatTableDate } from '@/utils/tools/dateTime'

type Props = {
  isActive: boolean
}

const CurrentWeekTable = ({ isActive }: Props) => {
  const [data, setData] = useState(null)

  const totalSpread = useMemo(() => {
    return data?.allWeeklySpread ? sumTotalSpread(data.allWeeklySpread) : undefined
  }, [data])
  const totalTimecards = data?.allWeeklySpread ? formatNumberDisplay(data.allWeeklySpread.length) : undefined
  const setHeaderOnLoad = useCallback((record: CurrentWeekRecord) => {
    const timeRange = `${formatTableDate(record.startDate)} - ${formatTableDate(record.endDate)}`
    const week = record.week.replace(/[^0-9.]/,'')
    return <span>Spread Report for: <span className='c-type--semi-bold'>Week {week}: {timeRange}</span></span>
  }, [])

  return (
    <>
      <div className='c-spread__data-validation'>
        <Skeleton.Input
          style={{ width: 199, height: 73 }}
          active
          size={'small'}
          loading={!data ? true : totalSpread === 'undefined'}
        >
          <Card className='c-spread__card'>
            <div className='c-spread__card-title'>Total Spread</div>
            <div className='c-spread__card-value'>
              {totalSpread}
            </div>
          </Card>
        </Skeleton.Input>
        <Skeleton.Input
          style={{ width: 199, height: 73 }}
          active
          size={'small'}
          loading={!data ? true : typeof totalTimecards === 'undefined'}
        >
          <Card className='c-spread__card'>
            <div className='c-spread__card-title'>Total Timecards</div>
            <div className='c-spread__card-value'>
              {totalTimecards}
            </div>
          </Card>
        </Skeleton.Input>
      </div>
      <TableHandler<CurrentWeekRecord>
        isActive={isActive}
        createTableConfig={createTableConfig}
        columnConfig={columnConfig}
        setHeaderOnLoad={setHeaderOnLoad}
        queryString={GET_CURRENT_WEEEK_TABLE_NAMED_QUERY}
        errorMessage='Error retrieving Current Week report.'
        dataPath={['allWeeklySpread']}
        onDataLoad={setData}
      />
    </>
  )
}

export default CurrentWeekTable
