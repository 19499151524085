import React, { useState } from 'react'
import classNames from 'classnames'

import { Alert, Close, Check } from '@/shared/components/Icons'

export type NoticeProps = {
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  error?: boolean;
  success?: boolean;
  warning?: boolean;
  style?: React.CSSProperties;
  closeFn?: () => void;
  animate?: boolean;
}

const Notice = (props: NoticeProps) => {
  const { title, children, error = false, success = false, warning = false, style = {}, closeFn, animate } = props
  const [closing, setClosing] = useState(false)
  const noticeClassname = classNames('c-notice', {
    'c-notice--error': error,
    'c-notice--success': success,
    'c-notice--warning': warning,
    'c-notice--icon': error || success,
    'c-notice--dismissable': closeFn,
    'c-notice--closing': closing,
    'animated bounce': animate
  })
  let icon
  if (error) {
    icon = <Alert />
  } else if (success) {
    icon = <Check />
  }
  const onClose = () => {
    setClosing(true)
    setTimeout(() => {
      closeFn()
    }, 600)
  }

  return (
    <div className={noticeClassname} style={style}>
      {closeFn && <Close onClick={closeFn && onClose} className='c-notice__close' />}
      {title && <div className='c-notice__title'>{title}</div>}
      {children && <div className='c-notice__message'>{children}</div>}
      {icon && (
        <div className='c-notice__icon'>
          {icon}
        </div>
      )}
    </div>
  )
}

export default Notice
