import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { useAppSelector } from '@/shared/hooks'
import { selectShowServiceErrorScreen } from '@/features/shared/redux/sharedSelectors'
import { FinancialTeamRoutes, PublicRoutes } from './routes'

// screens
import DashboardScreen from '@/screens/Dashboard'
import NotFoundScreen from '@/screens/NotFound'
import ServiceErrorScreen from '@/screens/ServiceError'
import ManageTargetsScreen from '@/screens/ManageTargets'
import SpreadReportScreen from '@/screens/SpreadReport'
import MySpreadScreen from '@/screens/MySpread'

import { hideManageTargetsScreen } from '@/bootstrap/configs/routes'
import { ManageTargetsRoute, PulseHomeRoute } from './routes'

const Routes = () => {
  const showServiceErrorScreen = useAppSelector(selectShowServiceErrorScreen)

  if (showServiceErrorScreen) {
    return <ServiceErrorScreen />
  }

  const routes = [
    ...PublicRoutes.reduce((acc, x) => [...acc, ...x.childs], []).map(
      (x) => x.path
    )
  ]

  return (
    <Switch>
      <Route exact path={PulseHomeRoute} component={DashboardScreen} />
      <Route exact path={FinancialTeamRoutes} component={SpreadReportScreen} />
      <Route exact path={routes} component={MySpreadScreen} />

      {!hideManageTargetsScreen && (
        <Route
          exact
          path={ManageTargetsRoute}
          component={ManageTargetsScreen}
        />
      )}

      <Route component={NotFoundScreen} />
    </Switch>
  )
}

export default Routes
