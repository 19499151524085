import React from 'react'
import PropTypes from 'prop-types'

import { Button, Checkbox, Input, Select } from 'antd'
import { OptionsType } from 'rc-select/lib/interface'
import { initialFilterState } from '@/screens/PowerRankings'

const { Search } = Input

const PowerRankingFilters = ({ data, filters, setFilters }) => {
  const clearFilters = () => {
    setFilters((prev) => ({
      divisions: initialFilterState.divisions,
      tenure: initialFilterState.tenure,
      name: initialFilterState.name,
      onlyHigh: initialFilterState.onlyHigh,
      onlyStarred: prev.onlyStarred
    }))
  }

  const divisionsOptions = data.reduce((unique, o) => {
    if (
      !unique.some(
        (obj) => obj.label === o.division && obj.value === o.division
      )
    ) {
      unique.push({ label: o.division, value: o.division })
    }
    return unique
  }, [])

  divisionsOptions.sort((a, b) => a.label.localeCompare(b.label))

  const tenureOptions = data.reduce((unique, o) => {
    if (
      !unique.some((obj) => obj.label === o.tenure && obj.value === o.tenure)
    ) {
      unique.push({ label: o.tenure, value: o.tenure })
    }
    return unique
  }, [])

  tenureOptions.sort((a, b) => a.label.localeCompare(b.label))

  const handleChangeDivisions = (value) => {
    setFilters((prev) => ({ ...prev, divisions: value }))
  }

  const handleChangeTenure = (value) => {
    setFilters((prev) => ({ ...prev, tenure: value }))
  }

  const handleChangeSearch = (e) => {
    setFilters((prev) => ({ ...prev, name: e.target.value }))
  }

  const handleChangeSpreadHighOnly = () => {
    setFilters((prev) => ({ ...prev, onlyHigh: !prev.onlyHigh }))
  }

  return (
    <div className='c-power-ranking__grid'>
      <div className='filter'>
        <label>Filter By Division</label>
        <Select
          mode='multiple'
          allowClear
          style={{
            width: '100%'
          }}
          placeholder='Select Division`s'
          defaultValue={filters.divisions}
          value={filters.divisions}
          onChange={handleChangeDivisions}
          options={new Set(divisionsOptions) as unknown as OptionsType}
        />
      </div>
      <div className='filter'>
        <label>Filter By Tenure</label>
        <Select
          allowClear
          style={{
            width: '100%'
          }}
          placeholder='Select Tenure'
          defaultValue={filters.tenure}
          value={filters.tenure}
          onChange={handleChangeTenure}
          options={new Set(tenureOptions) as unknown as OptionsType}
        />
      </div>
      <div className='filter'>
        <label>Search By Name</label>
        <Search
          value={filters.name}
          placeholder='Search by name...'
          onChange={handleChangeSearch}
        />
      </div>
      <div className='filter'>
        <Checkbox
          onClick={(e) => handleChangeSpreadHighOnly()}
          checked={filters.onlyHigh}
        >
          Spread High Only
        </Checkbox>
      </div>
      <div className='filter'>
        <Button onClick={(e) => clearFilters()}>Clear filters</Button>
      </div>
    </div>
  )
}

PowerRankingFilters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  data: PropTypes.array
}

export default PowerRankingFilters
