// @ts-strict
import { TotalsRecord } from './query'
import { ROW_MOD_KEYS } from '@/shared/constants/local'
import groupTotalRecords from '../../helpers/groupTotalRecords'
import { getMonthNameFromFiscalMonth } from '@/utils/tools/format'
import { TotalsStringKeys } from './columnConfig'
import { FiscalMonthTargets } from '../../helpers/groupMonthlyTargetData'

// this type is necessary to be able to use blank spaces or the '- -' placeholder in the aggregated rows
type ModifiedTotalsRecord = {
  [Property in keyof TotalsRecord]: number | string
}

const allTotalsKeys: TotalsStringKeys[] = [
  'totalBillAmount',
  'totalPayAmount',
  'spread',
  'averageHourlyBill',
  'averageHourlyPay',
  'averageHourlySpread',
  'timeCardsPaidNet',
  'totalHoursBilled',
  'averageHoursPerCandidate',
  'totalHoursPaid'
]

const totalRowKeys: TotalsStringKeys[] = [
  'totalBillAmount',
  'totalPayAmount',
  'spread',
  'totalHoursBilled',
  'totalHoursPaid'
]

const createTableConfig = (data: Array<TotalsRecord>, targetData: FiscalMonthTargets) => {
  const ytdRow: Partial<ModifiedTotalsRecord> = {
    referencedWeek: 'YTD Total',
    fiscalQuarter: 99,
    averageHourlyBill: ' ',
    averageHourlyPay: ' ',
    averageHourlySpread: ' ',
    timeCardsPaidNet: ' ',
    averageHoursPerCandidate: ' '
  }
  const groupedData = groupTotalRecords(data)
  const modifiedData = groupedData.reduce<Partial<ModifiedTotalsRecord>[]>((acc, quarterData) => {
    const fiscalQuarter = quarterData.length ? quarterData[0][0].fiscalQuarter : 0
    // using fiscalMonth here to hack a spacing element right after
    const monthlyAveragesRow: Partial<ModifiedTotalsRecord> = { referencedWeek: 'Monthly Averages', fiscalQuarter: 99 }
    const quarterRow: Partial<ModifiedTotalsRecord> = { referencedWeek: `Quarter ${fiscalQuarter} - Totals`, fiscalQuarter: 99, fiscalMonth: 99 }
    quarterData.forEach((monthData, monthIndex) => {
      const fiscalMonth = monthData.length ? monthData[0].fiscalMonth : 0
      const fiscalMonthTargetData = targetData?.[fiscalMonth] || {}
      const fiscalMonthName = getMonthNameFromFiscalMonth(fiscalMonth)
      const weeklyAveragesRow: Partial<ModifiedTotalsRecord>  = { referencedWeek: 'Weekly Averages', fiscalQuarter: 99, gPM: '- -' }
      const monthlyTargetRow: Partial<ModifiedTotalsRecord>  = {
        referencedWeek: 'Monthly Target',
        fiscalQuarter: 99,
        averageHourlyBill: ' ',
        averageHourlyPay: ' ',
        averageHourlySpread: ' ',
        totalHoursBilled: ' ',
        averageHoursPerCandidate: ' ',
        totalHoursPaid: ' ',
        ...fiscalMonthTargetData
      }
      if (fiscalMonthTargetData) {
        monthlyTargetRow.gPM = fiscalMonthTargetData.spread / fiscalMonthTargetData.totalBillAmount
      }
      const monthlyTotalsRow: Partial<ModifiedTotalsRecord>  = {
        referencedWeek: `${fiscalMonthName} Totals`,
        fiscalQuarter: 99,
        fiscalMonth: monthIndex !== quarterData.length - 1 ? 99 : 0,
        averageHourlyBill: '- -',
        averageHourlyPay: '- -',
        averageHourlySpread: '- -',
        timeCardsPaidNet: '- -',
        averageHoursPerCandidate: '- -'
      }
      const allTotals: Partial<ModifiedTotalsRecord> = {}
      monthData.forEach(weekData => {
        allTotalsKeys.forEach((key: string) => {
          if (typeof weekData[key] === 'number') {
            if (typeof allTotals[key] === 'number') {
              allTotals[key] += weekData[key]
            } else {
              allTotals[key] = weekData[key]
            }
          }
        })
      })

      allTotalsKeys.forEach((key: string) => {
        weeklyAveragesRow[key] = allTotals[key] / monthData.length
        if (typeof monthlyAveragesRow[key] === 'number') {
          monthlyAveragesRow[key] += allTotals[key]
        } else {
          monthlyAveragesRow[key] = allTotals[key]
        }
      })
      totalRowKeys.forEach((key: string) => {
        monthlyTotalsRow[key] = allTotals[key]
        if (typeof ytdRow[key] === 'number') {
          ytdRow[key] += allTotals[key]
        } else {
          ytdRow[key] = allTotals[key]
        }
        if (typeof quarterRow[key] === 'number') {
          quarterRow[key] += allTotals[key]
        } else {
          quarterRow[key] = allTotals[key]
        }
      })
      monthlyTotalsRow.gPM = (allTotals.spread as number) / (allTotals.totalBillAmount as number)

      acc = [
        ...acc,
        ...monthData,
        weeklyAveragesRow,
        monthlyTargetRow,
        monthlyTotalsRow
      ]
    })
    quarterRow.gPM = (quarterRow.spread as number) / (quarterRow.totalBillAmount as number)
    allTotalsKeys.forEach(key => {
      if (typeof monthlyAveragesRow[key] === 'number') {
        monthlyAveragesRow[key] = (monthlyAveragesRow[key] as number / quarterData.length)
      }
    })

    acc = [
      ...acc,
      monthlyAveragesRow,
      quarterRow
    ]

    return acc
  }, [])

  ytdRow.gPM = (ytdRow.spread as number) / (ytdRow.totalBillAmount as number)

  if (modifiedData.length) {
    modifiedData.push(ytdRow)
  }

  try {
    const config = modifiedData.map((record, index) => {
      return {
        ...record,
        rowMod: [
          ROW_MOD_KEYS.DATA_DISPLAY
        ],
        cellClassnames: {
          default: `c-type--table-numeric${record.fiscalQuarter === 99 ? ' c-type--semi-bold' : ''}`
        },
        rowClassName: `c-table__row--bordered${record.fiscalQuarter === 99 ? ' c-table__row--indent c-table__row--white-bg' : ''}`,
        key: `${record.referencedWeek}-${index}`,
        addSpacingNext: record.fiscalMonth === 99
      }
    }).filter(v => v)
    return config
  } catch (e) {
    console.log(e)
    return []
  }
}

export default createTableConfig
