import React from 'react'

const StarSolid = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='16.000000pt'
      height='16.000000pt'
      viewBox='0 0 120.000000 120.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,120.000000) scale(0.100000,-0.100000)'
        fill='#DBA901'
        stroke='none'
      >
        <path
          d='M540 1058 c-63 -146 -110 -247 -119 -252 -3 -2 -87 -12 -186 -21 -99
-10 -189 -21 -199 -25 -16 -5 6 -29 130 -139 82 -73 150 -139 152 -147 2 -7
-15 -97 -39 -199 -23 -102 -39 -188 -36 -191 7 -7 67 24 218 112 69 41 132 74
140 74 8 0 68 -32 134 -71 144 -85 215 -122 222 -115 3 2 -13 88 -36 189 -23
101 -41 190 -41 198 0 8 61 67 136 132 136 119 155 140 137 158 -5 5 -93 17
-194 25 -101 9 -187 19 -190 23 -3 3 -41 88 -84 189 -42 100 -81 182 -85 182
-4 0 -31 -55 -60 -122z'
        />
      </g>
    </svg>
  </div>
)

export default StarSolid
