import React from 'react'

const Icon = () => (
  <div className='c-icon'>
    <svg
      version='1.0'
      xmlns='http://www.w3.org/2000/svg'
      width='18.000000pt'
      height='18.000000pt'
      viewBox='0 0 96.000000 96.000000'
      preserveAspectRatio='xMidYMid meet'
    >
      <g
        transform='translate(0.000000,96.000000) scale(0.100000,-0.100000)'
        stroke='none'
      >
        <path
          d='M412 780 c-68 -42 -91 -124 -52 -188 41 -66 113 -88 179 -54 101 51
106 183 9 242 -18 11 -49 20 -68 20 -19 0 -50 -9 -68 -20z m108 -80 c11 -11
20 -29 20 -40 0 -26 -34 -60 -60 -60 -11 0 -29 9 -40 20 -11 11 -20 29 -20 40
0 11 9 29 20 40 11 11 29 20 40 20 11 0 29 -9 40 -20z'
        />
        <path
          d='M131 666 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83 124
-149 87z m74 -86 c0 -18 -6 -26 -23 -28 -24 -4 -38 18 -28 44 3 9 15 14 28 12
17 -2 23 -10 23 -28z'
        />
        <path
          d='M731 666 c-87 -48 -50 -186 49 -186 51 0 100 49 100 99 0 75 -83 124
-149 87z m74 -86 c0 -18 -6 -26 -23 -28 -13 -2 -25 3 -28 12 -10 26 4 48 28
44 17 -2 23 -10 23 -28z'
        />
        <path
          d='M375 427 c-28 -7 -57 -17 -65 -22 -8 -5 -55 -10 -105 -10 -72 -1 -98
-5 -132 -23 -61 -32 -73 -54 -73 -139 l0 -73 480 0 480 0 0 73 c0 85 -12 107
-73 139 -33 18 -60 23 -127 23 -54 1 -103 8 -135 19 -65 23 -186 29 -250 13z
m206 -83 c77 -22 99 -39 99 -74 l0 -30 -200 0 -200 0 0 29 c0 36 15 48 87 71
72 23 143 25 214 4z m-381 -64 l0 -40 -60 0 c-52 0 -60 3 -60 18 0 33 41 60
93 61 25 1 27 -2 27 -39z m661 14 c10 -9 19 -25 19 -36 0 -15 -8 -18 -60 -18
l-60 0 0 41 0 42 41 -6 c22 -3 49 -13 60 -23z'
        />
      </g>
    </svg>
  </div>
)

export default Icon
