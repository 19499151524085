import { IAppColumnConfig } from '@/shared/components/Table'
import { SpreadSummaryMetricKeys } from '@/shared/constants/dataKeys'
// TODO: via AKAN-1379 update what can be to MetricKeys
import {
  SPREAD_WEEK_KEY,
  SpreadSummaryReport
} from '@/shared/constants/dataKeys'
import { BDE_WIDTH, COLUMN_MOD_KEYS } from '@/shared/constants/local'
import { CompareFn } from 'antd/lib/table/interface'

export type MetricsConfig = {
  title: string
  dataIndex: string[] | string
  key: string
  columnMod: string[]
  sortOrder: number
  align?: string
  ellipsis?: boolean
  width?: string
  rowMod?: string[]
  showSorterTooltip?: boolean
  sortFn?: CompareFn<SpreadSummaryReport>
}

const generateMetricsConfig = (
  title: string,
  key: string,
  sortOrder: number,
  columnMods = []
): IAppColumnConfig<MetricsConfig> => ({
  title,
  dataIndex: key,
  key: key,
  align: 'right',
  columnMod: columnMods,
  showSorterTooltip: false,
  sorter: {
    compare: (a, b) => {
      return columnMods.includes(COLUMN_MOD_KEYS.PLAIN)
        ? a[key].localeCompare(b[key])
        : a[key] - b[key]
    }
  }
})

/**
 * Creates columnConfig used by antd table component
 * @return {object[]} - Array of column config objects
 */
const spreadSummary: IAppColumnConfig<MetricsConfig>[] = [
  {
    title: 'Division',
    dataIndex: SPREAD_WEEK_KEY,
    key: SPREAD_WEEK_KEY,
    width: BDE_WIDTH,
    columnMod: [COLUMN_MOD_KEYS.TITLE],
    showSorterTooltip: false,
    sorter: {
      compare: (a, b) => a.title.localeCompare(b.title)
    }
  },
  generateMetricsConfig(
    'Weekly Spread',
    SpreadSummaryMetricKeys.SPREAD_WEEKLY_SPREAD as string,
    1,
    [COLUMN_MOD_KEYS.CURRENCY]
  ),
  generateMetricsConfig(
    'YTD Average',
    SpreadSummaryMetricKeys.SPREAD_YTD_AVERAGE as string,
    2,
    [COLUMN_MOD_KEYS.CURRENCY]
  ),
  generateMetricsConfig(
    'YTD Revenue',
    SpreadSummaryMetricKeys.SPREAD_YTD_REVENUE as string,
    3,
    [COLUMN_MOD_KEYS.CURRENCY]
  )
]

export const generateConfig = () => {
  let config: IAppColumnConfig<MetricsConfig>[] = []
  config = spreadSummary.filter((v) => v)
  return config
}
