import React from 'react'

const CalendarMarkerGroup = ({ children }) => {
  return (
    <div className='c-calendar-marker__group'>
      {children}
    </div>
  )
}

export default CalendarMarkerGroup
