import { createSelector } from 'reselect'

import { RootState } from '@/bootstrap/redux'
import { ICurrentUserContext } from '../../../shared/types/swagger'

export const selectBrandContext = (state: RootState) => state.shared.reportResponse?.['company-data']?.all?.['brand-data']
export const selectEntityContext = (state: RootState) => state.shared.oneTimeResponse
export const selectFiscalCalendarMarkers = (state: RootState) => state.shared.fiscalCalendarMarkers
export const selectInitialLoad = (state: RootState) => state.shared.initialLoad
export const selectBrandData = (state: RootState) => state.shared
export const selectAllCompanyData = (state: RootState) => state.shared.reportResponse?.['company-data']?.all?.data
export const selectReportResponseLoading = (state: RootState) => state.shared.meta.reportResponseLoading
export const selectWeekByWeekReport = (state: RootState) => state.shared.reportResponse?.['pulse-over-time-data']
export const selectSsoFailure = (state: RootState) => state.shared.meta.ssoFailure
export const selectNotices = (state: RootState) => state.shared.notices
export const selectShowServiceErrorScreen = (state: RootState) => state.shared.showServiceErrorScreen
export const selectCompanyDataCalculations = (state: RootState) => state.shared.reportResponse?.['company-data']?.all?.data?.calculations
export const selectQsParsing = (state: RootState) => state.shared.meta.parsing
export const selectServiceErrorMessage = (state: RootState) => state.shared.serviceErrorMessage
export const selectCompanyTotals = (state: RootState) => state.shared.reportResponse?.['company-data']?.all?.data
export const selectItemizationData = (state: RootState) => state.shared.itemizationResponse?.itemization

export const selectUserContext = createSelector(
  [selectEntityContext],
  (entityContext) => {
    if (entityContext) {
      return entityContext['current-user-context']
    } else {
      const emptyReturn: Partial<ICurrentUserContext> = { divisions: [], people: [] }
      return emptyReturn
    }
  }
)

export const selectDivisionEntityContext = createSelector(
  [selectEntityContext],
  (entityContext) => {
    if (entityContext) {
      return entityContext.divisions
    } else {
      return {}
    }
  }
)

export const selectGovernmentDivisionIds = createSelector(
  [selectDivisionEntityContext],
  (divisionsObj): Array<string> => {
    if(divisionsObj){
      const divisions = Object.entries(divisionsObj) || []
      return divisions.reduce((acc, [nextId, nextData]) => {
        if (nextData?.['brand-name'] === 'Government') {
          acc.push(nextId)
        }
        return acc
      }, [])
    }
  }
)
