import { SPREAD_WEEK_KEY, DIVISION_KEY } from '@/shared/constants/dataKeys'
import { ROW_MOD_KEYS } from '@/shared/constants/local'

const rowTypes = {
  DIVISION: 'division'
}

const rowModMapper = {
  [rowTypes.DIVISION]: [ROW_MOD_KEYS.DATA_DISPLAY]
}

const cellClassMapper = {
  [rowTypes.DIVISION]: {
    default: 'c-table__cell--avatar'
  }
}

export interface TableConfig {
  key?: string
  [DIVISION_KEY]?: string
  rowMod?: string[]
  childClassName?: string
  cellClassnames?: {
    default: string
  }
  childTable?: TableConfig[]
  defaultExpandAllRows?: boolean
  hideExpandArrow?: boolean
}

/**
 * Returns table configuration for the Week by Week table.
 * @param {array} data pulse-over-time-data from report response
 * @param {string} currentYear the current year, used to sort current year to the top
 */

const createSpreadSummaryConfig = (data?): TableConfig[] => {
  const config = data.rows
    .map((division) => {
      return {
        ...division,
        data: division,
        title: division.name,
        key: `division-${division.name}`,
        [SPREAD_WEEK_KEY]: `${division.name}`,
        rowMod: rowModMapper[rowTypes.DIVISION],
        cellClassnames: cellClassMapper[rowTypes.DIVISION],
        rowClassName: 'c-table__row--bordered'
      }
    })
    .filter((v) => v)

  return config
}

export default createSpreadSummaryConfig
