import React, { useMemo } from 'react'

import { useAppSelector } from '@/shared/hooks'
import { Table, Skeleton } from '@/shared/components'
import { accountManagementPulseByDivisionConfig, recruitingPulseByDivisionConfig } from './helpers/columnConfigs'
import createTableConfig from './helpers/createTableConfig'
import { selectActiveDashboardTabKey } from '@/features/dashboard/redux/dashboardSelectors'
import { selectBrandContext, selectCompanyTotals, selectEntityContext, selectReportResponseLoading } from '@/features/shared/redux/sharedSelectors'
import { selectBrandTreeData, selectGovernmentDivisionIsChecked } from '@/features/ControlPanel/redux/controlPanelSelectors'
import { DASHBOARD_TAB_KEYS } from '@/shared/constants/local'
import formatDataForMyPeopleTable from './helpers/formatData'

const MyPeopleTable = () => {
  const brandData = useAppSelector(selectBrandContext)
  const brandTreeData = useAppSelector(selectBrandTreeData)
  const entityContext = useAppSelector(selectEntityContext)
  const accountManagementTabActive = useAppSelector(selectActiveDashboardTabKey) === DASHBOARD_TAB_KEYS.AM
  const includeGovernment = useAppSelector(selectGovernmentDivisionIsChecked)
  const reportData = useAppSelector(selectBrandContext)
  const totalsData = useAppSelector(selectCompanyTotals)
  const fetchingReport = useAppSelector(selectReportResponseLoading)
  const tableConfig = useMemo(() => {
    if (brandData && entityContext) {
      const formattedData = formatDataForMyPeopleTable(brandData, entityContext)
      return createTableConfig(formattedData)
    } else {
      return []
    }
  }, [brandData, entityContext])
  const columnConfig = useMemo(() => {
    if (accountManagementTabActive) {
      return accountManagementPulseByDivisionConfig(includeGovernment)
    } else {
      return recruitingPulseByDivisionConfig(includeGovernment)
    }
  }, [includeGovernment, accountManagementTabActive])
  const locale = brandTreeData && brandTreeData.length && { emptyText: reportData ? 'Nothing is currently selected.' : 'Data Not Found' }

  return (
    <Skeleton.Table
      active
      title
      loading={(!tableConfig.length && !brandTreeData) || fetchingReport}
      config={Skeleton.Table.PRESET_1}
    >
      <h3 className='c-type c-type--table-header-text'>
        {`${accountManagementTabActive ? 'Account Management' : 'Recruiting'} Pulse by People`}
      </h3>
      <Table
        sticky
        disableSpacing
        tableConfig={tableConfig}
        columnConfig={columnConfig}
        sortTargetIndex={0}
        locale={locale}
        includeTotalsRow={tableConfig.length}
        totalsData={{...totalsData}}
      />
    </Skeleton.Table>
  )
}

export default MyPeopleTable
