import React from 'react'
import { Card, Tooltip } from '@/shared/components'
import { Info } from '@/shared/components/Icons'
import RatioText from './RatioText'
// import renderQualityIndicator from '../helpers/renderQualityIndicator'

const VARIANTS = {
  RATIO: 'ratio-variant'
}

const RatioBox = ({ variant, value, quality, direction, title, tooltip, textRenderFn }) => {
  return (
    <Card className='c-ratio-box'>
      <div className='c-ratio-box__title'>{title}</div>
      <div className='c-ratio-box__value'>
        <RatioText variant={variant} value={!textRenderFn ? value : textRenderFn(value)}/>
        {/* {renderQualityIndicator(quality)} */}
        {tooltip && (
          <Tooltip title={tooltip} className='c-ratio-box__tooltip'>
            {''}
            <Info fill='dark-gray' />
          </Tooltip>
        )}
      </div>
    </Card>
  )
}

RatioBox.VARIANTS = VARIANTS

export default RatioBox
