// @ts-strict
import { OptionProps } from 'antd/lib/select'

import formatFoundersData, {
  FormatFoundersDataReturnType,
  FoundersBrandDepartmentMapping,
  FoundersGroupedByQuarter,
  FoundersReferenceWeekRecord
} from '../../helpers/formatFoundersData'
import { FoundersRecord, FoundersTableData } from './query'
import { getMonthNameFromFiscalMonth } from '@/utils/tools/format'
import { formatTableDate, getDayFromDate } from '@/utils/tools/dateTime'

interface Option extends Partial<OptionProps> {
  selectLabel?: string
}

const DELIMITER = '____'
const ALL_ALKU_VALUE = 'allAlku'
const MERGED_DATA_VALUE = 'mergedData'

const sumFounderRecords = (records: FoundersReferenceWeekRecord[]) => {
  return records.reduce((totalObj, nextRecord) => {
    Object.entries(nextRecord).forEach(([field, value]) => {
      if (typeof value === 'number') {
        if (totalObj[field]) {
          totalObj[field] += value
        } else {
          totalObj[field] = value
        }
      }
    })
    return totalObj
  }, {})
}

const formateRecordsForTable = (dataByReferenceWeek: FoundersGroupedByQuarter[]) => {
  const quarterRows = []
  const flattenedRecords = dataByReferenceWeek.reduce<Partial<FoundersReferenceWeekRecord>[]>((acc, quarterData) => {
    const { fiscalQuarter, months } = quarterData
    const monthRows = []
    months.forEach(monthData => {
      const { fiscalMonth, records } = monthData
      acc = [...acc, ...records]
      const totals = sumFounderRecords(records)
      const monthRow = { ...totals, fiscalQuarter: 99, referenceWeek: `${getMonthNameFromFiscalMonth(fiscalMonth)} Totals` }
      monthRows.push(monthRow)
      acc.push(monthRow)
    })
    const monthTotals = sumFounderRecords(monthRows)
    const quarterRow = { ...monthTotals, fiscalQuarter: 99, referenceWeek: `Quarter ${fiscalQuarter} Totals` }
    quarterRows.push(quarterRow)
    acc.push(quarterRow)

    return acc
  }, [])

  const quarterTotals = sumFounderRecords(quarterRows)
  const ytdRow = { ...quarterTotals, fiscalQuarter: 99, referenceWeek: 'YTD Totals' }
  const data = [...flattenedRecords, ytdRow]

  return data
}

const handleFormattedData = (formattedData: FormatFoundersDataReturnType) => {
  const { uniqueUserRecords, dataByReferenceWeek, brandDepartmentMapping, lowestFiscalWeekValue, highestFiscalWeekValue, startDate, endDate } = formattedData

  const data = formateRecordsForTable(dataByReferenceWeek)
  const allAlkuOption = {
    label: 'All ALKU',
    value: `${ALL_ALKU_VALUE}${DELIMITER}${MERGED_DATA_VALUE}`,
    selectLabel: 'All ALKU',
    className: 'c-type--bold'
  }
  const optionsBrandDepartmentMapping = Object.entries(brandDepartmentMapping).reduce<Option[]>((acc, [brand, brandData]) => {
    if (brand) {
      acc.push({
        label: brand,
        value: `${brand}${DELIMITER}${MERGED_DATA_VALUE}`,
        selectLabel: brand,
        className: 'c-type--bold'
      })
    }

    Object.entries(brandData).forEach(([department,]) => {
      if (department) {
        acc.push({
          label: department,
          value: `${brand}${DELIMITER}${department}`,
          selectLabel: `${brand ? `${brand} / ` : ''}${department}`,
          className: 'c-option--indent'
        })
      }
    })

    return acc
  }, [allAlkuOption])

  const timeRange = `${getDayFromDate(startDate)} ${formatTableDate(startDate)} - ${getDayFromDate(endDate)} ${formatTableDate(endDate)}`
  const week = `${lowestFiscalWeekValue}${highestFiscalWeekValue !== lowestFiscalWeekValue ? `-${highestFiscalWeekValue}` : ''}`
  const headerDate = `Week ${week}: ${timeRange}`

  return {
    data,
    options: optionsBrandDepartmentMapping,
    columnDataSource: { [ALL_ALKU_VALUE]: { [MERGED_DATA_VALUE]: uniqueUserRecords }, ...brandDepartmentMapping },
    headerDate
  }
}

export const getColumnDataFromSource = (value: string, dataSource: FoundersBrandDepartmentMapping) => {
  if (dataSource && value) {
    const [brand, department] = value.split(DELIMITER)

    if (department === MERGED_DATA_VALUE && brand !== ALL_ALKU_VALUE) {
      const brandData = dataSource[brand]

      return Object.values(brandData).reduce<FoundersRecord[]>((acc, nextDepData) => {
        return [...acc, ...nextDepData]
      }, [])
    }

    return dataSource[brand][department]
  }

  return []
}

const onDataLoad = (data: FoundersTableData) => {
  const { recruitersData, salesData } = data.allFounders.reduce<Record<string, FoundersRecord[]>>((acc, nextRecord) => {
    if (nextRecord.sideOfHouse === 'Recruiting') {
      acc.recruitersData.push(nextRecord)
    } else if (nextRecord.sideOfHouse === 'Sales') {
      acc.salesData.push(nextRecord)
    }
    return acc
  }, { recruitersData: [], salesData: [] })
  const salesFormattedData = formatFoundersData(salesData, 'Sales')
  const recruitingFormattedData = formatFoundersData(recruitersData, 'Recruiters')

  return {
    sales: handleFormattedData(salesFormattedData),
    recruiting: handleFormattedData(recruitingFormattedData)
  }
}

export default onDataLoad
