import React from 'react'

import { AudienceTree, TimeSlider } from './components'

const ControlPanel = () => {
  return (
    <div className='c-control-panel'>
      <TimeSlider />
      <AudienceTree.Shortcuts />
      <AudienceTree />
    </div>
  )
}

export default ControlPanel
