import { Target } from '@/shared/types/swagger'

const defaultTargets: Target = {
  'recruiting-dials': 500,
  'sales-dials': 750,
  'scheduled-calls': 0,
  'sales-dbas': 6,
  'recruiting-dbas': 12,
  'client-visits': 0,
  pipelines: 2,
  jobs: 2,
  subs: 9,
  'client-subs': 4.5,
  'in-house-interviews': 0,
  'recuriting-send-outs': 2,
  'sales-send-outs': 2,
  'recruiting-deals': 0.5,
  'sales-deals': 0.5,
  leads: 10
}

export default defaultTargets
