import dayjs from 'dayjs'
import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(customParseFormat)

const env = process.env.REACT_APP_ENVIRONMENT
export const useTempDateLimit = [REACT_APP_ENVIRONMENT.PROD].includes(env)

const OBJECT_DATE_KEY_FORMAT = 'YYYY-MM-DD'
const TIME_PERIOD_FORMAT  = 'MMM DD, YYYY'
const weekUnix = 604800
const FY_2019_FIRST_DAY_UNIX = 1546232400 // 2018-12-31
const TEMP_DATE_LIMIT = 1632715200 // 2021-09-27
const dateLimitDayJs = dayjs.unix(useTempDateLimit ? TEMP_DATE_LIMIT : FY_2019_FIRST_DAY_UNIX)
export const QUERY_STRING_DATE_FORMAT = 'YYYY-MM-DD'

const getDayjs = date => dayjs.isDayjs(date) ? date : dayjs.utc(date)

export const isAfterToday = date => {
  const now = dayjs.utc()
  return now.isBefore(date, 'day')
}

export const getDateKey = date => {
  return getDayjs(date).format(OBJECT_DATE_KEY_FORMAT)
}

export const getTimePeriodDisplayDate = date => {
  return getDayjs(date).format(TIME_PERIOD_FORMAT)
}

export const getDefaultRangePickerMonths = () => {
  const now = dayjs()
  return [now.add(-1, 'month'), now]
}

export const sortTimeOldestToRecent = (a, b) => {
  const aTime = getDayjs(a)
  const bTime = getDayjs(b)
  if (aTime.isSame(bTime)) return 0
  return aTime.isBefore(bTime) ? -1 : 1
}

export const sortTimeRecentToOldest = (a, b) => {
  return sortTimeOldestToRecent(b, a)
}

export const isDisabledTimeSliderDate = (date, selected) => {
  if (dateLimitDayJs.isSame(date) || dateLimitDayJs.isAfter(date)) {
    return true
  }
  if (isAfterToday(date)) {
    // Sunday is '0' for dayjs so this value becomes 7 so it's at the end of the week
    const nowDay = dayjs().get('day') === 0 ? 7 : dayjs().get('day')
    if (nowDay === 7) return true
    const nextSunday = dayjs().add(7 - nowDay, 'day')
    return nextSunday.isBefore(date, 'day')
  }
}

export const snapDateToMonday = date => {
  if (!date) return null
  const nowDay = date.get('day') === 0 ? 7 : date.get('day')
  if (nowDay === 1) return date
  return date.subtract((nowDay - 1), 'day')
}

export const snapDateToSunday = date => {
  if (!date) return null
  const nowDay = date.get('day') === 0 ? 7 : date.get('day')
  if (nowDay === 7) return date
  return date.add(7 - nowDay, 'day')
}

export const snapDatesToEndOfWeek = (dayjsDates): [string, string] | null => {
  if (dayjsDates) {
    return [snapDateToMonday(dayjsDates[0]), snapDateToSunday(dayjsDates[1])]
  }
  return null
}

export const getCurrentTimestampInSeconds = () => {
  if (!Date.now) {
    Date.now = function () {
      return new Date().getTime()
    }
  }
  return Math.floor(Date.now() / 1000)
}

export const isGreaterThanAWeek = dateRange => {
  if (dateRange && dateRange[0] && dateRange[1]) {
    const dateStartUnix = getDayjs(dateRange[0]).unix()
    const dateEndUnix = getDayjs(dateRange[1]).unix()
    return (dateEndUnix - dateStartUnix) > weekUnix
  }
  return false
}

export const isBeforeOrSameSuppliedDate = (dateLimit, dateToCheck) => {
  if (dateLimit && dateToCheck) {
    const limitDayjs = getDayjs(dateLimit)
    const checkDayjs = getDayjs(dateToCheck)

    return !(checkDayjs.isSame(limitDayjs) || checkDayjs.isBefore(limitDayjs))
  }
}

export const isSameDateRange = (dateRangeA, dateRangeB) => {
  if (!Array.isArray(dateRangeA) || !Array.isArray(dateRangeB)) {
    console.warn('Invalid dateRange type supplied')
    return null
  }
  const dateRangeAStart = dateRangeA[0]
  const dateRangeAEnd = dateRangeA[1]
  const dateRangeBStart = dateRangeB[0]
  const dateRangeBEnd = dateRangeB[1]
  if (dateRangeAStart.isSame(dateRangeBStart, 'day') && dateRangeAEnd.isSame(dateRangeBEnd, 'day')) {
    return true
  } else {
    return false
  }
}

export const getCurrentYear = () => {
  return dayjs().get('year')
}

export const formatTableDate = date => {
  return getDayjs(date).format('MM/DD/YY')
}

export const parseQueryStringDate = date => {
  const parsed = dayjs(date, QUERY_STRING_DATE_FORMAT, true)
  return parsed.isValid() && parsed
}

export const formatLastUpdatedDate = date => {
  return dayjs(date).format('MM-DD-YYYY')
}

export const getDayFromDate = (date: dayjs.ConfigType) => {
  return dayjs.utc(date).format('ddd')
}
