import {
  BDE_KEY,
  CLIENT_SUBS_KEY,
  CLIENT_VISITS_KEY,
  IN_HOUSE_INTERVIEWS_KEY,
  JOB_ORDERS_KEY,
  LEADS_KEY,
  PIPELINES_KEY,
  RECRUITING_DBAS_KEY,
  RECRUITING_DEALS_KEY,
  RECRUITING_DIALS_KEY,
  RECRUITING_SENDOUTS_KEY,
  SALES_DBAS_KEY,
  SALES_DEALS_KEY,
  SALES_DIALS_KEY,
  SALES_SENDOUTS_KEY,
  SCHEDULED_CALLS_KEY,
  SUBS_KEY
} from '@/shared/constants/dataKeys'
import { BDE_WIDTH, COLUMN_MOD_KEYS } from '@/shared/constants/local'
import { sortRecordByLastName, sortRecordByMetric } from '@/utils/tools/sort'

const generateMetricsConfig = (title, key) => ({
  title,
  dataIndex: [key, 'actual'],
  key: key,
  align: 'right',
  sortFn: (a, b, sortOrder) => sortRecordByMetric(a, b, sortOrder, key),
  showSorterTooltip: false,
  columnMod: [COLUMN_MOD_KEYS.METRIC]
})

const accountManagementPulseByDivisionConfig = (includeGovernment) =>
  [
    {
      title: 'Sector/Division/Employee',
      dataIndex: BDE_KEY,
      key: BDE_KEY,
      ellipsis: true,
      width: BDE_WIDTH,
      sortFn: (a, b, sortOrder) => sortRecordByLastName(a, b, sortOrder),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      columnMod: [COLUMN_MOD_KEYS.TITLE]
    },
    generateMetricsConfig('Dials', SALES_DIALS_KEY),
    generateMetricsConfig('DBAs', SALES_DBAS_KEY),
    includeGovernment &&
      generateMetricsConfig('Client Visits', CLIENT_VISITS_KEY),
    generateMetricsConfig('Pipelines', PIPELINES_KEY),
    generateMetricsConfig('Jobs', JOB_ORDERS_KEY),
    generateMetricsConfig('Send Outs', SALES_SENDOUTS_KEY),
    generateMetricsConfig('Deals', SALES_DEALS_KEY)
  ].filter((v) => v)

const recruitingPulseByDivisionConfig = (includeGovernment) =>
  [
    {
      title: 'Sector/Division/Employee',
      dataIndex: BDE_KEY,
      key: BDE_KEY,
      ellipsis: true,
      width: BDE_WIDTH,
      sortFn: (a, b, sortOrder) => sortRecordByLastName(a, b, sortOrder),
      sortDirections: ['ascend', 'descend'],
      showSorterTooltip: false,
      columnMod: [COLUMN_MOD_KEYS.TITLE]
    },
    generateMetricsConfig('Dials', RECRUITING_DIALS_KEY),
    includeGovernment &&
      generateMetricsConfig('Sched. Calls', SCHEDULED_CALLS_KEY),
    generateMetricsConfig('DBAs', RECRUITING_DBAS_KEY),
    generateMetricsConfig('Subs', SUBS_KEY),
    generateMetricsConfig('Client Subs', CLIENT_SUBS_KEY),
    includeGovernment &&
      generateMetricsConfig('In-Houses', IN_HOUSE_INTERVIEWS_KEY),
    generateMetricsConfig('Send Outs', RECRUITING_SENDOUTS_KEY),
    generateMetricsConfig('Deals', RECRUITING_DEALS_KEY),
    generateMetricsConfig('Leads', LEADS_KEY)
  ].filter((v) => v)

export {
  accountManagementPulseByDivisionConfig,
  recruitingPulseByDivisionConfig
}
