import React from 'react'
import { PulseLogo } from '@/shared/assets/logos'
import { UserDropdown } from '@/features/auth/components'
import { PulseLink } from '@/shared/components'
import { NavLink } from 'react-router-dom'
import { PublicRoutes, PulseHomeRoute } from '../Routes/routes'
import { REACT_APP_ENVIRONMENT } from '@/shared/constants/local'

const CURRENT_ENV = process.env.REACT_APP_ENVIRONMENT

const Header = () => {
  return (
    <div className='c-header'>
      <div className='c-header__logo'>
        <PulseLink to={PulseHomeRoute}>
          <PulseLogo />
        </PulseLink>
      </div>
        {[REACT_APP_ENVIRONMENT.DEV, REACT_APP_ENVIRONMENT.UAT].includes(CURRENT_ENV) &&
        (
          <div className='c-header__navigation'>
            {PublicRoutes.filter((x) => x.showOnNavigationHeader).map(
              (route, index) => (
                <NavLink
                  to={route.path}
                  key={index}
                  exact={route.path === PulseHomeRoute}
                  activeClassName='activeLink'
                >
                  {route.label}
                </NavLink>
              )
            )}
          </div>
        )}
      <div className='c-header__user'>
        <UserDropdown />
      </div>
    </div>
  )
}

export default Header
