import React from 'react'
import Icon, { IconPositionType } from './Icon'

type Refresh2Props = {
  position?: IconPositionType
}

const Refresh2 = ({ position }: Refresh2Props) => (
  <Icon position={position}>
    <svg width='16' height='16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8h-1.6c0 3.529-2.871 6.4-6.4 6.4A6.407 6.407 0 011.6 8c0-3.529 2.871-6.4 6.4-6.4 1.765 0 3.362.72 4.52 1.88L10.4 5.6H16V0l-2.352 2.352A7.955 7.955 0 008 0z'
        fill='#43454F'
        fillRule='nonzero'
      />
    </svg>
  </Icon>
)

export default Refresh2
