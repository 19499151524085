import { BDE_KEY, OTHER_CONTRIBUTORS_KEY } from '@/shared/constants/dataKeys'
import { ROW_MOD_KEYS } from '@/shared/constants/local'
import { sortRecordByLastName } from '@/utils/tools/sort'

const emptyEntityReportObj = { data: { } }

/**
 * Returns table configuration for the main table display based on selected users.
 * @param entityContext data from one time response
 * @param context brand-data context object from report response {object}
 * @param selected array of nested objects {array}
 */
const createBrandDivisionEmployeeTableConfig = (entityContext, context, selected) => {
  try {
    return selected.map(({ id, children, key }) => {
      const brand = context[id] || emptyEntityReportObj
      if (brand) {
        const data = brand.data
        const divisionData = brand['division-data']
        return {
          key,
          [BDE_KEY]: id,
          ...data,
          cellClassnames: {
            [BDE_KEY]: 'c-type--table-data-black',
            default: 'c-type--table-numeric c-type--bold'
          },
          childClassName: 'c-table__row--indent',
          rowMod: [ROW_MOD_KEYS.EXPANDABLE_LEFT, ROW_MOD_KEYS.DATA_DISPLAY],
          childTable: createDivisionsEmployeeTableConfig(
            entityContext,
            divisionData,
            children
          )
        }
      } else {
        return null
      }
    }).filter(v => v)
  } catch (e) { console.log(e) }
}

const createDivisionsEmployeeTableConfig = (entityContext, divisionReportContext, selected) => {
  return selected.map(({ id, children, key }) => {
    const division = divisionReportContext?.[id] || emptyEntityReportObj
    const divisionInfo = entityContext?.divisions?.[id]
    if (division) {
      const peopleData = division['people-data']
      const flattenedChildNodes = spreadOtherContributorsNode(children)
      return {
        key,
        [BDE_KEY]: divisionInfo.name,
        ...division.data,
        cellClassnames: {
          [BDE_KEY]: 'c-type--table-data-bold',
          default: 'c-type--table-numeric c-type--bold'
        },
        rowMod: [ROW_MOD_KEYS.EXPANDABLE_LEFT, ROW_MOD_KEYS.DATA_DISPLAY],
        childClassName: 'c-table__row--quality c-table__row--indent',
        locale: { emptyText: 'No people selected.' },
        childTable: createEmployeeTableConfig(
          entityContext,
          peopleData,
          flattenedChildNodes
        )
      }
    } else {
      return null
    }
  }).filter(v => v)
}

const createEmployeeTableConfig = (entityContext, peopleReportContext, selected) => {
  return selected.map(({ id, key, isOtherContributor, title }) => {
    const employee = peopleReportContext?.[id] || { data: {} }
    const employeeInfo = entityContext?.people?.[id] || emptyEntityReportObj
    if (employeeInfo) {
      return {
        key,
        [BDE_KEY]: title,
        ...employeeInfo,
        ...employee.data,
        rowMod: [
          !isOtherContributor ? ROW_MOD_KEYS.QUALITY : null,
          ROW_MOD_KEYS.AVATAR,
          ROW_MOD_KEYS.DATA_DISPLAY
        ],
        cellClassnames: {
          [BDE_KEY]: 'c-type--table-data-bold c-table__cell--avatar',
          default: 'c-type--table-numeric'
        },
        isOtherContributor
      }
    } else {
      return null
    }
  }).filter(v => v).sort(sortRecordByLastName)
}

const spreadOtherContributorsNode = divisionChildren => {
  // last child node of a division is either an other contributors node or person
  const lastNode = divisionChildren[divisionChildren.length - 1]
  if (lastNode?.key.includes(OTHER_CONTRIBUTORS_KEY)) {
    return [...divisionChildren.slice(0, -1), ...lastNode.children]
  }
  return divisionChildren
}

export default createBrandDivisionEmployeeTableConfig
